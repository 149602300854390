import _find from 'lodash/find'
import _findIndex from 'lodash/findIndex'
import contactMixin from './contactMixin'
import firestoreMixins from './firestoreDbMixin'
import pouchDbMixin from './pouchDbMixin'

export default {
  data() {
    return {
      instance: null,
      widgetData: [],
      shopList: [],
      orderList: [],
    }
  },
  computed: {
    currentShop() {
      return this.$store.getters['eOrdering/getCurrentShop']
    },
  },
  mixins: [firestoreMixins, pouchDbMixin, contactMixin],
  methods: {
    async getShop(subId) {
      // //console.log('masuk sini')
      // const items = await this.loadEordering()
      // this.itemsOrderLink = items

      const allOrderData = await this.loadEordering(subId)

      this.shopList = allOrderData
      if (this.currentShop !== null && this.shopList.length !== 0) {
        const filteredShop = this.shopList.find(shop => shop._id === this.currentShop.id)
        if (filteredShop) {
          filteredShop.data.id = filteredShop._id
          this.$store.commit('eOrdering/setCurrentShop', JSON.parse(JSON.stringify(filteredShop.data)))
        } else {
          this.shopList[0].data.id = this.shopList[0]._id
          this.$store.commit('eOrdering/setCurrentShop', JSON.parse(JSON.stringify(this.shopList[0].data)))
        }

        // //console.log(filteredShop, this.currentShop, `INI SHOP`)
      } else if (this.currentShop === null && this.shopList.length !== 0) {
        this.shopList[0].data.id = this.shopList[0]._id
        this.$store.commit('eOrdering/setCurrentShop', JSON.parse(JSON.stringify(this.shopList[0].data)))
      }

      // this.shopList = shops
      // this.orderList = orders
      // this.$eventBus.$on(process.env.VUE_APP_DB_UPDATE_E_ORDERING, async () => {
      //   const { shops: shops2, orders: orders2 } = await this.loadEordering()
      //   this.shopList = shops2
      //   this.orderList = orders2
      // })
    },
    async getErodering(payload) {
      const allOrderData = await this.loadEorderingOrder(payload)

      this.orderList = allOrderData

      return allOrderData
    },
    async getErodering2(payload) {
      const allOrderData = await this.loadEorderingOrder(payload)

      return allOrderData
    },
    async runEventErodering() {
      // this.$eventBus.$on(process.env.VUE_APP_DB_UPDATE_E_ORDERING, async () => {
      //   if (this.currentShop !== null) {
      //     const allOrderData2 = await this.loadEorderingOrder({
      //       sub_id: this.user.sub_id,
      //       shopId: this.currentShop.id,
      //       startDate: this.startDate,
      //       endDate: this.endDate,
      //     })
      //     this.orderList = allOrderData2
      //     const allOrders = await this.loadEorderingAllOrder({ sub_id: this.user.sub_id, shopId: this.currentShop.id })
      //     this.totalShopRevenue = 0
      //     allOrders.forEach(order => {
      //       order.orderData.forEach(orderDetail => {
      //         if (orderDetail.Total && order.status === 3) {
      //           this.totalShopRevenue += orderDetail.Total
      //         }
      //       })
      //     })
      //   }
      // })
    },
    async getContactAll() {
      // const items = await this.loadContacts()
      // const itemsFilter = items.find(f => f?._id?.includes(this.shopId.data.phoneNumber))
      // //console.log('check-eodering', itemsFilter, this.shopId.data.phoneNumber)
      // for (const i in itemsFilter.ch) {
      //   const filterChatOrder = itemsFilter.ch[i].chats.filter(f => f?.message?.conversation.includes('[order-'))
      //   //console.log('check-eodering-1', filterChatOrder)
      // }
    },

    async getOrder(payload) {
      // //console.log(this.widgetData, '+++++++++_+_+_+_+_+_+_+')
      const contact = payload.doc
      const keys = Object.keys(contact.ch)
      let inbox = {}
      keys.forEach(key => {
        // //console.log(key, `INI KEY`)
        const contactOrigin = JSON.parse(JSON.stringify(contact))
        inbox = contactOrigin.ch[key]
      })
      const contactAllData = this.fdbGetAllContactEorder(this.user.sub_id)
      let selectedContact = []

      // //console.log(contact, ' == SEMUA CONTACT')

      // const dataContactlist = await this.loadSettingsById('contact_list', true)
      // if (dataContactlist && dataContactlist.value.length > 0) {
      //   dataContactlist.value.forEach(dataList => {
      //     const contactList = this.allContactsGlobal.filter(x => x._id.startsWith(dataList.id))
      //     contactAllData.push(contactList)
      //   })
      // }

      // //console.log(inbox, 'aaaaaaaaaaaaaaaaaaaaaaaaaasijahsdiuajshdiuh')

      if (!inbox.chats[0].message.conversation) {
        if (inbox?.chats[0]?.message?.extendedTextMessage.text.includes('Ticket No. C')) {
          // //console.log('masukSini1')
          const chatPayload2 = inbox?.chats[0]?.message?.extendedTextMessage.text

          let nameDataAll = ''
          await this.$store.dispatch('link/fetchListMessage', {
            sub_id: this.user.sub_id,
            token: this.user.token,
          })

          const initData = this.$store.getters['link/getMessageListLink']

          // //console.log(initData, 'masuk sini1')
          initData.forEach(el => {
            if (chatPayload2.includes(el._id)) {
              nameDataAll = el.contactList.id

              // //console.log('masukalllanme', nameDataAll)
            }
          })

          contactAllData.forEach(item => {
            if (item.length > 0) {
              if (item[0]._id.includes(nameDataAll)) {
                selectedContact = item
              }
            }
          })

          // cons
          const contactValidate = dataContactlist.value.find(el => {
            return el.id === nameDataAll
          })
          //console.log(contactValidate, '++++++++++++++++')
          if (contactValidate) {
            //console.log('masuk ke data ditemukan')
            if (selectedContact.length === 0) {
              //console.log('masuk ke IFFFFFFFFFFFFFFFFFF')
              //console.log('masuk di else data')
              await this.saveContactToDb(
                {
                  contactName: contact.name,
                  phoneNumber: contact.phone_number,
                  created_at: Date.now(),
                  data: [],
                  notes: [],
                },
                [],
                nameDataAll,
                false,
                selectedContact,
              )

              // let validateUpdate = selectedContact.find(item => {
              //   return item.phone_number === contact.phone_number
              // })
              // if (validateUpdate === undefined) {
              //   await this.saveContactToDb(
              //     {
              //       contactName: contact.name,
              //       phoneNumber: contact.phone_number,
              //       created_at: Date.now(),
              //       data: [],
              //       notes: [],
              //     },
              //     [],
              //     nameDataAll,
              //     false,
              //     selectedContact,
              //   )
              // } else {
              //   //console.log(validateUpdate)
              //   await this.saveContactToDb(
              //     {
              //       contactName: contact.name,
              //       phoneNumber: contact.phone_number,
              //       created_at: Date.now(),
              //       data: [],
              //       notes: [],
              //     },
              //     [],
              //     nameDataAll,
              //     true,
              //     selectedContact,
              //   )
              // }
            } else {
              const validateUpdate = selectedContact.find(item => {
                return item.phone_number === contact.phone_number
              })
              if (validateUpdate === undefined) {
                await this.saveContactToDb(
                  {
                    contactName: contact.name,
                    phoneNumber: contact.phone_number,
                    created_at: Date.now(),
                    data: [],
                    notes: [],
                  },
                  [],
                  nameDataAll,
                  false,
                  selectedContact,
                )
              } else {
                //console.log('masuk ke UPdate')
                validateUpdate.updated_at = Date.now()

                //console.log(validateUpdate)
                await this.saveContactToDb(validateUpdate, [], nameDataAll, true, selectedContact)
              }

              // //console.log('masuk di else data')
              // await this.saveContactToDb(
              //   {
              //     contactName: contact.name,
              //     phoneNumber: contact.phone_number,
              //     created_at: Date.now(),
              //     data: [],
              //     notes: [],
              //   },
              //   [],
              //   nameDataAll,
              //   false,
              //   selectedContact,
              // )
            }
          }
        } else if (inbox?.chats[0]?.message?.extendedTextMessage.text.includes('Ticket No. W')) {
          await this.connectLink(true)
          await this.getLink()
          const chatPayload2 = inbox?.chats[0]?.message?.extendedTextMessage.text
          let nameDataAll = ''

          // const initData = this.$store.getters['link/getMessageListLink']
          this.widgetData.forEach(el => {
            if (chatPayload2.includes(el._id)) {
              nameDataAll = el.contactList.id
              //console.log('masukalllanme', nameDataAll)
            }
          })
          contactAllData.forEach(item => {
            if (item.length > 0) {
              if (item[0]._id.includes(nameDataAll)) {
                selectedContact = item
              }
            }
          })
          const contactValidate = dataContactlist.value.find(el => {
            return el.id === nameDataAll
          })
          if (contactValidate) {
            if (selectedContact.length === 0) {
              const validateUpdate = selectedContact.find(item => {
                return item.phone_number === contact.phone_number
              })
              if (validateUpdate === undefined) {
                await this.saveContactToDb(
                  {
                    contactName: contact.name,
                    phoneNumber: contact.phone_number,
                    created_at: Date.now(),
                    data: [],
                    notes: [],
                  },
                  [],
                  nameDataAll,
                  false,
                  selectedContact,
                )
              } else {
                await this.saveContactToDb(
                  {
                    contactName: contact.name,
                    phoneNumber: contact.phone_number,
                    created_at: Date.now(),
                    data: [],
                    notes: [],
                  },
                  [],
                  nameDataAll,
                  true,
                  selectedContact,
                )
              }
            } else {
              await this.saveContactToDb(
                {
                  contactName: contact.name,
                  phoneNumber: contact.phone_number,
                  created_at: Date.now(),
                  data: [],
                  notes: [],
                },
                [],
                nameDataAll,
                false,
                selectedContact,
              )
            }
          }
        }
      } else if (inbox?.chats[0]?.message?.conversation.includes('Ticket No. C')) {
        //console.log('masukSini2')
        const chatPayload2 = inbox?.chats[0]?.message?.conversation

        let nameDataAll = ''
        await this.$store.dispatch('link/fetchListMessage', {
          sub_id: this.user.sub_id,
          token: this.user.token,
        })

        const initData = this.$store.getters['link/getMessageListLink']

        // //console.log(initData, 'masuk sini1')
        initData.forEach(el => {
          if (chatPayload2.includes(el._id)) {
            nameDataAll = el.contactList.id

            // //console.log('masukalllanme', nameDataAll)
          }
        })

        contactAllData.forEach(item => {
          if (item.length > 0) {
            if (item[0]._id.includes(nameDataAll)) {
              selectedContact = item
            }
          }
        })

        const contactValidate = dataContactlist.value.find(el => {
          return el.id === nameDataAll
        })

        // //console.log(dataContactlist.value, '===================')
        if (contactValidate) {
          if (selectedContact.length === 0) {
            const validateUpdate = selectedContact.find(item => {
              return item.phone_number === contact.phone_number
            })
            if (validateUpdate === undefined) {
              await this.saveContactToDb(
                {
                  contactName: contact.name,
                  phoneNumber: contact.phone_number,
                  created_at: Date.now(),
                  data: [],
                  notes: [],
                },
                [],
                nameDataAll,
                false,
                selectedContact,
              )
            } else {
              await this.saveContactToDb(
                {
                  contactName: contact.name,
                  phoneNumber: contact.phone_number,
                  created_at: Date.now(),
                  data: [],
                  notes: [],
                },
                [],
                nameDataAll,
                true,
                selectedContact,
              )
            }
          } else {
            await this.saveContactToDb(
              {
                contactName: contact.name,
                phoneNumber: contact.phone_number,
                created_at: Date.now(),
                data: [],
                notes: [],
              },
              [],
              nameDataAll,
              false,
              selectedContact,
            )
          }
        }
      } else if (inbox?.chats[0]?.message?.conversation.includes('Ticket No. W')) {
        await this.connectLink(true)
        await this.getLink()
        const chatPayload2 = inbox?.chats[0]?.message?.conversation
        let nameDataAll = ''

        // const initData = this.$store.getters['link/getMessageListLink']
        this.widgetData.forEach(el => {
          if (chatPayload2.includes(el._id)) {
            nameDataAll = el.contactList.id
            //console.log('masukalllanme', nameDataAll)
          }
        })
        contactAllData.forEach(item => {
          if (item.length > 0) {
            if (item[0]._id.includes(nameDataAll)) {
              selectedContact = item
            }
          }
        })
        const contactValidate = dataContactlist.value.find(el => {
          return el.id === nameDataAll
        })
        if (contactValidate) {
          if (selectedContact.length === 0) {
            const validateUpdate = selectedContact.find(item => {
              return item.phone_number === contact.phone_number
            })
            if (validateUpdate === undefined) {
              await this.saveContactToDb(
                {
                  contactName: contact.name,
                  phoneNumber: contact.phone_number,
                  created_at: Date.now(),
                  data: [],
                  notes: [],
                },
                [],
                nameDataAll,
                false,
                selectedContact,
              )
            } else {
              await this.saveContactToDb(
                {
                  contactName: contact.name,
                  phoneNumber: contact.phone_number,
                  created_at: Date.now(),
                  data: [],
                  notes: [],
                },
                [],
                nameDataAll,
                true,
                selectedContact,
              )
            }
          } else {
            await this.saveContactToDb(
              {
                contactName: contact.name,
                phoneNumber: contact.phone_number,
                created_at: Date.now(),
                data: [],
                notes: [],
              },
              [],
              nameDataAll,
              false,
              selectedContact,
            )
          }
        }
      }

      // const orderMessage = inbox.chats.find(el => el.message.conversation.match(/[order-]/g)).message.conversation

      // const orderMessage = inbox.chats.find(el => el.message.conversation.indexOf('[order') !== -1).message.conversation
    },

    async getOrdering(payload) {
      //console.log(payload)
      const contact = payload.doc
      const keys = Object.keys(contact.ch)
      let inbox = {}
      keys.forEach(key => {
        const contactOrigin = JSON.parse(JSON.stringify(contact))
        inbox = contactOrigin.ch[key]
      })

      const checkData = inbox?.chats[0]?.message?.conversation.indexOf('order-') !== -1
      const orderMessage = inbox?.chats[0]?.message?.conversation
      //console.log(`INI PAYLOAD`, orderMessage)

      if (checkData) {
        const contactAllData = this.fdbGetAllContactEorder(this.user.sub_id)
        let selectedContact = []

        let indextext = 0
        for (let i = 0; i < orderMessage.length; i++) {
          if (orderMessage[i] === ']') {
            indextext = i
            break
          }
        }

        const contactID = orderMessage.substring(27, indextext)
        contactAllData.forEach(item => {
          if (item.length > 0) {
            if (item[0].phone_number.includes(contactID)) {
              selectedContact = item
            }
          }
        })
        const dataContactlist = await this.fdbGetContactList(this.user.sub_id)
        const contactValidate = dataContactlist.value.find(el => {
          return el.list_id === contactID
        })
        if (contactValidate) {
          //console.log('masuk ke data ditemukan')
          if (selectedContact.length === 0) {
            await this.saveContactToDb(
              {
                contactName: contact.name,
                order_count: 1,
                total_order: 1,
                last_order_time: Date.now(),
                phoneNumber: contact.phone_number,
                created_at: Date.now(),
                data: [],
                notes: [],
              },
              [],
              contactID,
              false,
              selectedContact,
            )
          } else {
            const validateUpdate = selectedContact.find(item => {
              return item.phone_number === contact.phone_number
            })
            if (validateUpdate === undefined) {
              await this.saveContactToDb(
                {
                  contactName: contact.name,
                  order_count: 1,
                  total_order: 1,
                  last_order_time: Date.now(),
                  phoneNumber: contact.phone_number,
                  created_at: Date.now(),
                  data: [],
                  notes: [],
                },
                [],
                contactID,
                false,
                selectedContact,
              )
            } else {
              //console.log('masuk ke UPdate')
              validateUpdate.updated_at = Date.now()
              validateUpdate.last_order_time = Date.now()
              validateUpdate.total_order = Number(validateUpdate.total_order) + 1
              validateUpdate.order_count = Number(validateUpdate.order_count) + 1

              //console.log(validateUpdate)
              await this.saveContactToDb(validateUpdate, [], contactID, true, selectedContact)
            }
          }
        }

        const orderId = orderMessage.substring(12, 26)
        const regex = orderMessage.split(/[\n]/g)
        const strep = regex.indexOf('-----------------------------') + 1
        let index = 0
        const array = [[]]
        for (let i = 0; i < strep; i += 1) {
          regex.splice(0, 1)
        }
        regex.map((d, i) => {
          if (d !== '') {
            array[index].push(d)
          } else {
            array.push([])
            index += 1
          }
        })
        const array2 = []
        array.map((d, i) => {
          array2.push({})
          //console.log('d ini', d)
          d.map(d2 => {
            // //console.log('array_data', d2.split(':')[1])
            if (d2.split(':')[1] === undefined) {
              //console.log('d2 ini mah', d2.split(':')[0])
              if (d2.split(':')[0] !== '-----------------------------') {
                array2[i].name = d2.split(':')[0].replace(' ', '')
                //console.log('abis split', array2[i])
              }
            } else {
              //console.log('a2', array2)
              array2[i][d2.split(':')[0].replace(/[" ]/g, '')] = d2.split(':')[1].replace(' ', '')
            }
          })
        })
        const orderData = array2
        orderData.map((d, i) => {
          if (Object.keys(d).length === 0) {
            orderData.splice(i, 1)
          }
        })

        orderData.map(d => {
          if (d?.Quantity !== undefined) {
            d.Quantity = +d.Quantity.replace(/[* Pcs]/g, '')
              .split(',')
              .join('.')
          }
          //console.log('namae', d)
          if (d.name) {
            d.name = d.name.replace(/[*.\d]/g, '')
          }

          if (d['Harga(@)'] !== undefined) {
            d['Harga(@)'] = +d['Harga(@)']
              .replace(/[Rp. *]/g, '')
              .split(',')
              .join('.')
          }
          if (d.TotalHarga !== undefined) {
            d['Total Harga'] = +d.TotalHarga.replace(/[Rp. *]/g, '')
              .split(',')
              .join('.')

            delete d.TotalHarga
          }
          if (d.OngkosKirim !== undefined) {
            d['Ongkos Kirim'] = +d.OngkosKirim.replace(/[Rp. *]/g, '')
              .split(',')
              .join('.')

            delete d.OngkosKirim
          }
          if (d.SubTotal !== undefined) {
            d['Sub Total'] = +d.SubTotal.replace(/[Rp. *]/g, '')
              .split(',')
              .join('.')

            delete d.SubTotal
          }
          if (d.Total !== undefined) {
            d.Total = +d.Total.replace(/[Rp. *]/g, '')
              .split(',')
              .join('.')
          }
          if (d.IdMeja !== undefined) {
            d['Id Meja'] = d.IdMeja
            delete d.IdMeja
          }
          if (d.OrderType !== undefined) {
            d['Order Type'] = d.OrderType
            delete d.OrderType
          }
          const keys2 = Object.keys(d)
          keys2.forEach(key => {
            //console.log(key, `ini key`)
            if (key.includes('Pajak')) {
              d[key] = +d[key]
                .replace(/[Rp. *]/g, '')
                .split(',')
                .join('.')
            }
          })
        })
        //console.log('object_null', orderData)

        const { sub_id } = this.$store.getters['auth/getUser']

        const orderPayload = {
          sub_id,
          shop_id: orderData[orderData.length - 1].IDTOKO.replace(/[ ]/g, ''),
          orderData,
          status: 'pending',
          orderId,
          timestamp: inbox.chats.find(el => orderMessage === el.message.conversation).messageTimestamp,
        }

        //console.log(orderPayload, `INI PAYLOAD`)

        const createOrder = await this.$store.dispatch('eOrdering/createOrderShop', orderPayload)

        //console.log(createOrder, `INI ORDER`)
      }
    },
    async getEOrdering() {
      const items = await this.loadSettings()
      const instance = _find(items, { key: 'order_type' })
      this.form.orderType = instance.value
    },
    async editContactChannel(data) {
      const items = await this.loadSettings()
      const instance = _find(items, { key: 'instances' })
      const arrayValue = instance.value
      const filterId = _findIndex(arrayValue, { instance_id: data.instance_id })
      arrayValue[filterId].label = data.label
      this.updateSettings({
        ...instance,
        value: arrayValue,
      }).then(async () => {
        if (this.idList) {
          await this.connectLink()
          await this.getLinkByid(this.idList)
          this.showHide.channel = false
          const itemsLink = await this.loadLink()
          const filter = _find(itemsLink, { _id: this.idList })
          const { channel } = filter
          const filterChannel = _findIndex(channel, {
            value: {
              instance_id: data.instance_id,
            },
          })
          channel[filterChannel].value.label = data.label

          //console.log('channel', { ...filter, channel })
          this.updateLink({ ...filter, channel }).then(async r => {
            //console.log('checkUpdate', r)
            await this.connectLink()

            await this.getLinkByid(this.idList)
            await this.getApiList()
            await this.getContactChannel()
          })
        } else {
          this.showHide.channel = false
        }
      })
    },
    async getLinkByid(id) {
      const items = await this.loadLinkById(id, true)
      this.form = await items
      this.itemsOperator = await items.channel
      this.dataEdit = items.widget_style

      //console.log('checkDataLink', 'items')
    },
  },
}
