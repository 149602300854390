/* eslint-disable import/prefer-default-export */
export function setListInvoice(state, obj) {
  state.listInvoice = obj
}

export function setUserInfo(state, obj) {
  state.userInfo = obj
}

export function setSubscriptionPlan(state, obj) {
  state.subscriptionPlans = obj
}

export function setActiveInvoice(state, obj) {
  state.activeInvoice = obj
}

export function setSubscriptionInfo(state, obj) {
  state.subscriptionInfo = obj
}

export function setCurrentLang(state, obj) {
  state.currentLang = obj
}
