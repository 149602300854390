/* eslint-disable */

import { firestoreDb } from '@demo/database/index'
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  startAfter,
  updateDoc,
  where,
} from 'firebase/firestore'
import _orderBy from 'lodash/orderBy'

export default {
  data() {
    return {
      contactListTable: [],
    }
  },
  methods: {
    async getDocumentsByField(field, fieldValue, collectionName) {
      const ref = collection(firestoreDb, collectionName)
      const docs = await getDocs(query(ref, where(field, '==', fieldValue)))

      return docs
    },
    async getDocuments(collectionName, _orderBy = null, _limit = null, orderType = null, _startAfter = null) {
      const ref = collection(firestoreDb, collectionName)
      const docs =
        _orderBy && _limit && _startAfter
          ? await getDocs(
              query(ref, orderBy(_orderBy, orderType ? orderType : 'asc'), limit(_limit), startAfter(_startAfter)),
            )
          : _orderBy && _limit
          ? await getDocs(query(ref, orderBy(_orderBy, orderType ? orderType : 'asc'), limit(_limit)))
          : _orderBy
          ? await getDocs(query(ref, orderBy(_orderBy, orderType ? orderType : 'asc')))
          : _limit
          ? await getDocs(query(ref, limit(_limit)))
          : await getDocs(query(ref))

      return docs
    },
    async getDocument(collectionName, id) {
      const ref = doc(firestoreDb, collectionName, id)
      return await getDoc(query(ref))
    },
    async fdbGetSettings(subId) {
      const settingsDocs = await this.getDocument('settings', subId)
      return settingsDocs
    },
    async fdbGetContactList(subId) {
      const contactDocs = await this.getDocumentsByField('sub_id', subId, 'contact_list')
      const listContacts = []
      this.listDefaultValue = []
      for (var contact of contactDocs.docs) {
        // contactDocs.forEach(async contact => {
        const data = contact.data()
        const ctcs = listContacts.find(x => x.list_id === data.list_id)
        if (!data.list_id.includes('default')) {
          if (ctcs) {
            ctcs.active_contact = ctcs.active_contact + data.contacts.length
          } else {
            listContacts.push({
              active_contact: data.contacts.length,
              ...data,
            })
          }
        } else {
          this.listDefaultValue = data.contacts
        }
      }

      return listContacts
    },
    async fdbGetAllContactEorder(subId) {
      const contactDocs = await this.getDocumentsByField('sub_id', subId, 'contact_list')
      let allContact = []
      contactDocs.forEach(async contact => {
        const data = contact.data()
        // data.contacts.forEach(x => {
        //   const dataFound = this.allContact.find(y => y.phone_number == x.phone_number)
        //   if (dataFound) {
        //     if (!dataFound.list_id.find(z => z == data.list_id)) {
        //       dataFound.list_id.push(data.list_id)
        //       dataFound.list_name.push(data.list_name)
        //     }
        //   } else {
        //     this.allContact.push({
        //       list_id: [data.list_id],
        //       list_name: [data.list_name],
        //       ...x,
        //     })
        //   }
        // })
        allContact = allContact.concat(
          data.contacts.map(x => {
            return {
              ...x,
            }
          }),
        )
      })

      return allContact
    },
    async fdbGetAllContactFromRoom(subId) {
      const contactDocs = await this.getDocumentsByField('subId', subId, `chatRooms/${subId}/listRooms`)
      this.contactListTable = []
      contactDocs.forEach(async contact => {
        const data = contact.data()
        data.roomId = contact.id
        data.roomPath = contact.ref.path

        this.contactListTable.push(data)
        this.totalContact = this.contactListTable.length
      })
    },
    async fdbGetAllContactFromRoomNotify(subId, instances) {
      const contactDocs = await this.getDocumentsByField('subId', subId, `chatRooms/${subId}/listRooms`)
      this.contactNeedReply = 0
      contactDocs.forEach(contact => {
        const data = contact.data()
        // data.roomPath = contact.ref.path
        if (instances.includes(data.instanceId)) {
          if (data.unreplied) {
            this.contactNeedReply += 1
          }
        }

        // this.totalContact = this.contactListTable.length
        // //console.log(this.contactListTable)
      })
    },
    async checkWAfromChatData(subId) {
      const contactDocs = await this.getDocumentsByField('subId', subId, `chatRooms/${subId}/listRooms`)
      const res = []
      contactDocs.forEach(async contact => {
        const data = contact.data()
        data.roomPath = contact.ref.path
        res.push(data)
      })
      return res
    },
    async fdbGetAllContactInbox(subId) {
      const contactDocs = await this.getDocumentsByField('sub_id', subId, 'contact_list')
      this.allContactInbox = []
      contactDocs.forEach(async contact => {
        const data = contact.data()
        this.allContactInbox = this.allContactInbox.concat(
          data.contacts.map(x => {
            delete x.list_id
            delete x.list_name
            delete x.idtable
            return {
              idtable: this.$nanoid(),
              list_id: data.list_id,
              list_name: data.list_name ? data.list_name : 'default',
              ...x,
            }
          }),
        )
      })
    },
    async fdbGetAllContact(subId) {
      const contactDocs = await this.getDocumentsByField('sub_id', subId, 'contact_list')
      this.allContact = []
      this.listIds = []
      for (var contact of contactDocs.docs) {
        // contactDocs.forEach(async contact => {
        const data = contact.data()
        if (!this.listIds.find(x => x.list_id == data.list_id)) {
          this.listIds.push({
            list_id: data.list_id,
            list_name: data.list_name ? data.list_name : 'default',
          })
        }
        this.allContact = this.allContact.concat(
          data.contacts.map(x => {
            delete x.list_id
            delete x.list_name
            delete x.idtable
            return {
              idtable: this.$nanoid(),
              list_id: data.list_id,
              list_name: data.list_name ? data.list_name : 'default',
              ...x,
            }
          }),
        )
      }

      this.listGrouped = _.mapValues(_.groupBy(this.allContact, 'list_id'), clist =>
        clist.map(car => _.omit(car, 'list_id')),
      )

      // remove duplicate by room id
      if (this.allContact.length < 5000) {
        this.allContact = this.allContact.filter(
          (room, index, that) =>
            index ===
            that.findIndex(t => t !== undefined && room !== undefined && t.phone_number === room.phone_number),
        )
      }

      // //console.log(this.listGrouped)
      // //console.log(this.allContact, ' == ALL')
    },
    async fdbGetContactListAddRoom(subId) {
      const contactDocs = await this.getDocumentsByField('sub_id', subId, 'contact_list')

      let contacts = []

      contactDocs.forEach(async contact => {
        const data = contact.data()

        // if (!this.optionsContactListsRoom.find(x => x.list_id == data.list_id)) {
        //   this.optionsContactListsRoom.push({
        //     list_id: data.list_id,
        //     list_name: data.list_name ? data.list_name : 'default',
        //   })
        // }

        contacts = contacts.concat(
          data.contacts.map(x => {
            delete x.list_id
            delete x.list_name
            delete x.idtable

            return {
              idtable: this.$nanoid(),
              list_id: data.list_id,
              list_name: data.list_name ? data.list_name : 'default',
              ...x,
            }
          }),
        )
      })

      const contactsGrouped = _.mapValues(_.groupBy(contacts, 'list_id'), clist =>
        clist.map(car => _.omit(car, 'list_id')),
      )

      return contactsGrouped
    },
    async fdbGetAllContactList(subId) {
      const contactDocs = await this.getDocumentsByField('sub_id', subId, 'contact_list')
      let contactListTable = []
      for (var contact of contactDocs.docs) {
        const data = contact.data()
        contactListTable = contactListTable.concat(
          data.contacts.map(x => {
            return {
              ...x,
              list_name: data.list_name,
            }
          }),
        )
      }
      return contactListTable
    },
    async fdbGetContactListContent(subId, idList) {
      this.loading = true
      const contactDocs = await this.getDocumentsByField('list_id', idList, 'contact_list')
      this.contactListTable = []
      this.totalContact = 0
      for (var contact of contactDocs.docs) {
        const data = contact.data()
        this.contactListTable = this.contactListTable.concat(data.contacts)
        this.totalContact = this.totalContact + data.contacts.length
      }
      this.loading = false
    },
    async getMessagesFirestore(subId, roomId, lastMessage, callback = false) {
      const messages = !lastMessage
        ? await this.getDocuments(`chatRooms/${subId}/listRooms/${roomId}/messages`, 'timestamp', '1', 'desc')
        : await this.getDocuments(
            `chatRooms/${subId}/listRooms/${roomId}/messages`,
            'timestamp',
            '1',
            'desc',
            lastMessage,
          )

      let allMessages = []
      let curLastMessage = null

      for (var msg of messages.docs) {
        for (var msgDetail of msg.data().dataMessage) {
          allMessages.push(msgDetail)
        }
      }

      if (allMessages.length > 0) {
        curLastMessage = messages.docs.length > 0 ? messages.docs[0] : lastMessage
        allMessages = _orderBy(allMessages, ['couch_timestamp'], ['desc'])
      }

      if (callback) {
        callback({ messages: allMessages, lastMessages: curLastMessage })
      }
      return { messages: allMessages, lastMessages: curLastMessage }
    },
    async fdbGetInstances(subId) {
      const ref = doc(firestoreDb, 'settings', subId)

      onSnapshot(query(ref), settings => {
        const instancesValue = []
        settings.data().instances.forEach(data => {
          instancesValue.push({
            ...data,
            status: this.mappingStatus(data.instance_id, data.status),
          })
        })
        const instances = {
          value: instancesValue,
        }
        this.$store.commit('auth/setListInstance', instances)
      })
    },
    mappingStatus(instanceId, status) {
      const instances = this.$store.getters['auth/getListInstance']
      if (instances && instances.value) {
        const obj = instances.value.find(x => x.instance_id === instanceId)
        if (obj) return obj.status
        return status
      }

      return status
    },
    async listenListRooms(subId) {
      const roomsRef = await this.getDocuments(`chatRooms/${subId}/listRooms`)
      roomsRef.forEach(room => {
        const ref = doc(firestoreDb, `chatRooms/${subId}/listRooms`, room.id)
        onSnapshot(query(ref), data => {
          const curData = data.data()
          if (
            curData.last_message &&
            curData.last_message.seconds &&
            new Date(curData.last_message.seconds * 1000) >= new Date()
          ) {
            //console.log(new Date(curData.last_message.seconds * 1000))
          }
        })
      })
    },
    async fdbUpdateChatData(contactData) {
      try {
        await setDoc(doc(firestoreDb, contactData.roomPath), contactData)
        await this.fdbGetAllContactFromRoom(contactData.subId)
        return {
          status: true,
          message: 'Success',
        }
      } catch (err) {
        console.log(err)
        return {
          status: false,
          message: err,
        }
      }
    },
    async updateDocumentByField(path, field, value) {
      try {
        const ref = doc(firestoreDb, path)

        let document = {}
        document[field] = value

        await updateDoc(ref, document)

        return {
          status: true,
          message: 'Success',
        }
      } catch (err) {
        console.log(err)

        return {
          status: false,
          message: err,
        }
      }
    },
    async addDocument(path, value) {
      try {
        const docRef = await addDoc(collection(firestoreDb, path), value)

        return {
          status: true,
          message: 'Success',
        }
      } catch (err) {
        console.log(err)

        return {
          status: false,
          message: err,
        }
      }
    },
    async addRoomApi(subId, instanceId, roomId, room) {
      const endpoint = `${process.env.VUE_APP_API_GENERAL}room/add-room`
      const body = {
        subid: subId,
        instanceId,
        roomId,
        roomData: room,
      }

      await this.$axiosLimit
        .post(endpoint, body)
        .then(async response => {
          // //console.log(response)
        })
        .catch(error => {
          console.log(error)
        })
    },
    async updateRoomApi(subId, instanceId, roomId, room) {
      const endpoint = `${process.env.VUE_APP_API_GENERAL}room/update-room`
      const body = {
        subid: subId,
        instanceId,
        roomId,
        roomData: room,
      }

      await this.$axiosLimit
        .post(endpoint, body)
        .then(async response => {
          // //console.log(response)
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
