import * as actions from '@/store/auth/actions'
import * as getters from '@/store/auth/getters'
import * as mutations from '@/store/auth/mutations'
import state from '@/store/auth/state'

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
