export function getSchedules(state) {
  return state.schedules
}
export function getSchedule(state) {
  return state.schedule
}
export function getPostTemplates(state) {
  return state.postTemplates
}
export function getPostTemplate(state) {
  return state.postTemplate
}
export function getPostApproval(state) {
  return state.postApproval
}
