<template>
  <div class="vertical-nav-menu-container" @mouseenter="isMouseHovered = true" @mouseleave="isMouseHovered = false">
    <slot name="v-nav-menu-header">
      <vertical-nav-menu-header @close-nav-menu="$emit('close-nav-menu')"></vertical-nav-menu-header>
    </slot>

    <!-- Shadow -->
    <div :class="{ 'd-block': shallShadowBottom }" class="shadow-bottom" />

    <perfect-scrollbar
      class="ps-nav-menu-items"
      :options="perfectScrollbarOptions"
      @ps-scroll-y="
        evt => {
          shallShadowBottom = evt.srcElement.scrollTop > 0
        }
      "
    >
      <v-list expand shaped class="vertical-nav-menu-items">
        <component
          :is="resolveNavItemComponent(item)"
          v-for="item in navShow"
          :key="item.subheader || item.title"
          :item="item"
        />
      </v-list>
    </perfect-scrollbar>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { provide, ref } from '@vue/composition-api'
import _uniqBy from 'lodash/uniqBy'

// SFCs
import VerticalNavMenuGroup from '@core/layouts/components/vertical-nav-menu/components/vertical-nav-menu-group/VerticalNavMenuGroup.vue'
import VerticalNavMenuHeader from '@core/layouts/components/vertical-nav-menu/components/vertical-nav-menu-header/VerticalNavMenuHeader.vue'
import VerticalNavMenuLink from '@core/layouts/components/vertical-nav-menu/components/vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuSectionTitle from '@core/layouts/components/vertical-nav-menu/components/vertical-nav-menu-section-title/VerticalNavMenuSectionTitle.vue'
import { Capacitor } from '@capacitor/core'

// Composable
// 3rd Party
import useVerticalNavMenu from '@/@core/layouts/composable/vertical-nav/useVerticalNavMenu'
import {
  mdiAccountClock,
  mdiAccountGroup,
  mdiAccountGroupOutline,
  mdiAccountLock,
  mdiAccountMultiple,
  mdiAdvertisements,
  mdiAlarm,
  mdiBroadcast,
  mdiCardAccountDetailsStarOutline,
  mdiCardAccountMailOutline,
  mdiCellphoneMessage,
  mdiChartAreaspline,
  mdiChartBubble,
  mdiChartHistogram,
  mdiChat,
  mdiClipboardAccountOutline,
  mdiClipboardClockOutline,
  mdiExitToApp,
  mdiFormatListBulleted,
  mdiFormSelect,
  mdiForumOutline,
  mdiHelpCircle,
  mdiHomeOutline,
  mdiInformationOutline,
  mdiLabel,
  mdiLink,
  mdiMessage,
  mdiMessageTextOutline,
  mdiMonitorCellphone,
  mdiPhone,
  mdiRobotExcitedOutline,
  mdiStore,
  mdiWidgets,
} from '@mdi/js'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuGroup,
    VerticalNavMenuLink,
    VerticalNavMenuSectionTitle,

    // 3rd Party
    PerfectScrollbar,
  },
  props: {
    navMenuItems: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const { resolveNavItemComponent, isMouseHovered } = useVerticalNavMenu()
    provide('isMouseHovered', isMouseHovered)
    const perfectScrollbarOptions = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)
    provide('openGroups', ref([]))

    return {
      resolveNavItemComponent,
      perfectScrollbarOptions,
      isMouseHovered,
      shallShadowBottom,
      icons: {
        mdiAccountLock,
        mdiCardAccountDetailsStarOutline,
        mdiClipboardAccountOutline,
        mdiClipboardClockOutline,
        mdiAccountClock,
        mdiAccountGroup,
        mdiAccountMultiple,
        mdiAlarm,
        mdiBroadcast,
        mdiCardAccountMailOutline,
        mdiCellphoneMessage,
        mdiChartAreaspline,
        mdiWidgets,
        mdiChartBubble,
        mdiChartHistogram,
        mdiChat,
        mdiFormatListBulleted,
        mdiForumOutline,
        mdiExitToApp,
        mdiHomeOutline,
        mdiLabel,
        mdiLink,
        mdiMessage,
        mdiMessageTextOutline,
        mdiMonitorCellphone,
        mdiInformationOutline,
        mdiHelpCircle,
        mdiPhone,
        mdiStore,
        mdiFormSelect,
        mdiRobotExcitedOutline,
        mdiAdvertisements,
        mdiAccountGroupOutline,
      },
    }
  },
  data: () => ({
    navShow: [],
    platform: Capacitor.getPlatform(),
  }),
  computed: {
    user() {
      return this.$store.getters['auth/getUser']
    },
    subscriptionInfo() {
      return this.$store.getters['subscriptions/getSubscriptionInfo']
    },
    locale() {
      return this.$store.getters['global/getLang']
    },
    enabledApps() {
      return this.$store.getters['global/getEnabledApps']
    },
  },
  watch: {
    navMenuItems(newVal) {
      console.log(
        `🥶🥶🥶🥶 ~ フ ク ロ ウ file: VerticalNavMenu.vue:159 ~ フ ク ロ ウ navMenuItems ~ フ ク ロ ウ newVal:`,
        this.platform,
        newVal,
      )
    },
    async enabledApps(newVal, oldVal) {
      // //console.log('🚀 ~ file: VerticalNavMenu.vue ~ line 181 ~ enabledApps ~ newVar, oldVar', newVal, oldVal)

      // const isChange = newVal.filter(val => !oldVal.includes(val)).length
      // //console.log(
      //   '🚀 ~ file: VerticalNavMenu.vue ~ line 183 ~ enabledApps ~ newVal.filter(val => oldVal.includes(val))',
      //   newVal.filter(val => oldVal.includes(val)),
      // )

      // // //console.log(newVar)
      this.checkShowNav()

      // this.navShow = _uniqBy(this.navShow, 'title')

      // if (newVal.length !== oldVal.length) {
      //   this.navShow = []
      // }
    },
  },
  async mounted() {
    // console.log(this.navMenuItems, 'aaaaaaaaaaaaaaaaaaaa')

    // const enabledApps = await this.$store.dispatch('global/getSettingsByKey', {
    //   sub_id: this.user.sub_id,
    //   token: this.user.token,
    //   key: 'enabled_apps',
    // })
    // if (enabledApps.status && enabledApps.data) {
    //   // //console.log(enabledApps.data)
    //   this.$store.commit('global/setEnabledApps', enabledApps.data.value)

    //   // this.$store.commit()
    // }
    // const a = await this.$store.dispatch('auth/getChannelAccess', {
    //   sub_id: this.user.sub_id,
    //   email: this.user.email,
    //   token: this.user.token,
    // })
    this.checkShowNav()
  },
  methods: {
    async checkShowNav() {
      // const { data } = await this.$store.dispatch('subscriptions/getSubscriptionInfo', this.user.email)
      // //console.log('item nav nih', this.navMenuItems)

      const operatorMenu = {
        title: 'Team',
        icon: mdiAccountGroup,
        children: [
          {
            icon: mdiClipboardAccountOutline,
            title: 'Team Members',
            to: 'operators-list',
          },

          {
            icon: mdiAccountGroupOutline,
            title: 'Team Roles',
            to: 'team-roles',
          },

          // {
          //   icon: mdiCardAccountDetailsStarOutline,
          //   title: 'User Performance',
          //   to: 'user-performance',
          // },
        ],
      }

      // //console.log(
      //   '🚀 ~ file: VerticalNavMenu.vue ~ line 447 ~ checkShowNav ~ this.navMenuItems',
      //   this.navMenuItems,
      //   this.user.package,
      // )
      let helpAndCenter = true
      if ((this.user.role === 'Member' || this.user.role !== 'Admin') && this.user.view_assign_only) {
        helpAndCenter = false
        this.navShow = [
          {
            icon: mdiForumOutline,
            title: 'chat',
            to: 'inbox',
          },
          {
            title: 'Subscriptions',
            icon: mdiMonitorCellphone,
            to: 'billings',
          },
          {
            title: 'Logout',
            icon: mdiExitToApp,
            to: 'logout',
          },
        ]
      } else if (this.user.package === 'business_plus') {
        // //console.log(this.user.limited_menu, 'LIMITED MENU SEBELUM')
        if (this.user.limited_menu) {
          // //console.log(this.user.enabled_menu.includes('Installed Apps'), 'LIMITED MENU SEB')

          let index = 0

          if (this.platform === 'web') {
            if (this.user.enabled_menu.includes('Channels')) {
              this.navShow = [this.navMenuItems[0]]
              index = 0
            }
            if (this.user.enabled_menu.includes('Chat')) {
              this.navShow.push(this.navMenuItems[1])
              index += 1
            }
            if (this.user.enabled_menu.includes('Broadcasts')) {
              this.navShow.push(this.navMenuItems[2])
            }
            if (this.user.enabled_menu.includes('Statistics')) {
              this.navShow.push(this.navMenuItems[3])
            }
            if (this.user.enabled_menu.includes('Contacts')) {
              this.navShow.push(this.navMenuItems[4])
            }
            if (this.user.enabled_menu.includes('Templates')) {
              this.navShow.push(this.navMenuItems[5])
            }

            // ||this.user.enabled_menu.includes('Teams')
            if (this.user.role === 'Admin') {
              this.navShow.push(this.navMenuItems[6])
            }
            if (this.user.enabled_menu.includes('My Assets')) {
              this.navShow.push(this.navMenuItems[7])
            }
            if (this.user.enabled_menu.includes('Chatbot')) {
              this.navShow.push(this.navMenuItems[8])
            }
          } else if (this.platform !== 'web') {
            if (this.user.enabled_menu.includes('Channels')) {
              this.navShow = [this.navMenuItems[0]]
              index = 0
            }
            if (this.user.enabled_menu.includes('Chat')) {
              this.navShow.push(this.navMenuItems[1])
              index += 1
            }
            if (this.user.enabled_menu.includes('Broadcasts')) {
              // this.navShow.push(this.navMenuItems[2])
            }
            if (this.user.enabled_menu.includes('Statistics')) {
              // this.navShow.push(this.navMenuItems[3])
            }
            if (this.user.enabled_menu.includes('Contacts')) {
              this.navShow.push(this.navMenuItems[4])
            }
            if (this.user.enabled_menu.includes('Templates')) {
              // this.navShow.push(this.navMenuItems[5])
            }

            // ||this.user.enabled_menu.includes('Teams')
            if (this.user.role === 'Admin') {
              // this.navShow.push(this.navMenuItems[6])
            }
            if (this.user.enabled_menu.includes('My Assets')) {
              this.navShow.push(this.navMenuItems[7])
            }
            if (this.user.enabled_menu.includes('Chatbot')) {
              // this.navShow.push(this.navMenuItems[8])
            }
          }

          if (this.user.enabled_menu.includes('Settings')) {
            this.navShow.push(this.navMenuItems[12])
          }
        } else {
          const x = 10

          if (this.platform === 'web') {
            if (this.user.role !== 'Admin') {
              this.navShow = [
                this.navMenuItems[0],
                this.navMenuItems[1],
                this.navMenuItems[2],

                this.navMenuItems[3],

                this.navMenuItems[4],
                this.navMenuItems[5],
                this.navMenuItems[7],
                this.navMenuItems[8],
              ]
            } else {
              this.navShow = [
                this.navMenuItems[0],
                this.navMenuItems[1],
                this.navMenuItems[2],

                this.navMenuItems[3],

                this.navMenuItems[4],
                this.navMenuItems[5],
                this.navMenuItems[6],

                this.navMenuItems[7],
                this.navMenuItems[8],
              ]
            }
          } else if (this.platform !== 'web') {
            if (this.user.role !== 'Admin') {
              this.navShow = [
                this.navMenuItems[0],
                this.navMenuItems[1],

                // this.navMenuItems[2],

                // this.navMenuItems[3],

                this.navMenuItems[4],

                // this.navMenuItems[5],
                this.navMenuItems[7],

                // this.navMenuItems[8],
              ]
            } else {
              this.navShow = [
                this.navMenuItems[0],
                this.navMenuItems[1],

                // this.navMenuItems[2],

                // this.navMenuItems[3],

                this.navMenuItems[4],

                // this.navMenuItems[5],
                // this.navMenuItems[6],

                this.navMenuItems[7],

                // this.navMenuItems[8],
              ]
            }
          }

          // if (this.enabledApps?.length === 0) {
          //   //console.log(this.navShow, 'ini navsb')
          //   this.navShow[x].children = this.navShow[x].children = [
          //     {
          //       title: 'Test',
          //     },
          //   ]
          //   this.navShow[x].children = this.navShow[x].children.filter(child => child.title !== 'Test')
          // } else if (this.enabledApps.length !== 0) {
          //   if (
          //     this.enabledApps.includes('E-Shop') &&
          //     !this.navShow[x].children.find(child => child.title === 'E-Shop')
          //   ) {
          //     this.navShow[x].children.push({
          //       title: 'E-Shop',
          //       icon: mdiStore,
          //       to: 'e-ordering',
          //     })
          //   } else if (
          //     !this.enabledApps.includes('E-Shop') &&
          //     this.navShow[x].children.find(child => child.title === 'E-Shop')
          //   ) {
          //     const arr = []
          //     this.navShow[x].children.forEach(el => {
          //       if (el.title !== 'E-Shop') {
          //         arr.push(el)
          //       }
          //     })
          //     this.navShow[x].children = arr
          //   }
          //   if (
          //     this.enabledApps.includes('E-Form') &&
          //     !this.navShow[x].children.find(child => child.title === 'E-Form')
          //   ) {
          //     this.navShow[x].children.push({
          //       title: 'E-Form',
          //       icon: mdiFormSelect,
          //       to: 'e-form',
          //     })
          //   } else if (
          //     !this.enabledApps.includes('E-Form') &&
          //     this.navShow[x].children.find(child => child.title === 'E-Form')
          //   ) {
          //     const arr = []
          //     this.navShow[x].children.forEach(el => {
          //       if (el.title !== 'E-Form') {
          //         arr.push(el)
          //       }
          //     })
          //     this.navShow[x].children = arr
          //   }
          //   if (
          //     this.enabledApps.includes('ChatBot') &&
          //     !this.navShow[x].children.find(child => child.title === 'ChatBot')
          //   ) {
          //     this.navShow[x].children.push({
          //       title: 'ChatBot',
          //       icon: mdiRobotExcitedOutline,
          //       to: 'chatbot',
          //     })
          //   } else if (
          //     !this.enabledApps.includes('ChatBot') &&
          //     this.navShow[x].children.find(child => child.title === 'ChatBot')
          //   ) {
          //     const arr = []
          //     this.navShow[x].children.forEach(el => {
          //       if (el.title !== 'ChatBot') {
          //         arr.push(el)
          //       }
          //     })
          //     this.navShow[x].children = arr
          //   }
          //   if (
          //     this.enabledApps.includes('Campaign Tracker') &&
          //     !this.navShow[x].children.find(child => child.title === 'Campaign Tracker')
          //   ) {
          //     this.navShow[x].children.push({
          //       title: 'Campaign Tracker',
          //       icon: mdiAdvertisements,
          //       to: 'campaign-tracker',
          //     })
          //   } else if (
          //     !this.enabledApps.includes('Campaign Tracker') &&
          //     this.navShow[x].children.find(child => child.title === 'Campaign Tracker')
          //   ) {
          //     const arr = []
          //     this.navShow[x].children.forEach(el => {
          //       if (el.title !== 'Campaign Tracker') {
          //         arr.push(el)
          //       }
          //     })
          //     this.navShow[x].children = arr
          //   }
          //   if (
          //     this.enabledApps.includes('Wa-Login') &&
          //     !this.navShow[x].children.find(child => child.title === 'Login by WA')
          //   ) {
          //     this.navShow[x].children.push({
          //       title: 'Login by WA',
          //       icon: mdiAccountLock,
          //       to: 'LoginByWa',
          //     })
          //   } else if (
          //     !this.enabledApps.includes('Wa-Login') &&
          //     this.navShow[x].children.find(child => child.title === 'Login by WA')
          //   ) {
          //     const arr = []
          //     this.navShow[x].children.forEach(el => {
          //       if (el.title !== 'Wa-Login') {
          //         arr.push(el)
          //       }
          //     })
          //     this.navShow[x].children = arr
          //   }
          // }

          // //console.log(this.enabledApps, '<< enable app', this.navMenuItems[7])
        }
      } else if (this.user.package === 'business') {
        if (this.user.limited_menu) {
          if (this.user.enabled_menu.includes('Channels')) {
            this.navShow = [this.navMenuItems[0]]
          }
          if (this.user.enabled_menu.includes('Chat')) {
            this.navShow.push([this.navMenuItems[1]])
          }
          if (this.user.enabled_menu.includes('Send Broadcast')) {
            this.navShow.push(this.navMenuItems[2])
          }
          if (this.user.enabled_menu.includes('Contact List')) {
            this.navShow.push(this.navMenuItems[3])
          }
          if (this.user.enabled_menu.includes('Inbox')) {
            this.navShow.push(this.navMenuItems[4])
          }
          if (this.user.enabled_menu.includes('Broadcast')) {
            this.navShow.push(this.navMenuItems[7])
          }
          if (this.user.enabled_menu.includes('Users')) {
            this.navShow.push(operatorMenu)
          }
          this.navShow.push(this.navMenuItems[8])
        } else {
          this.navShow = [
            this.navMenuItems[0],
            this.navMenuItems[1],
            this.navMenuItems[2],
            this.navMenuItems[3],
            this.navMenuItems[4],
            this.navMenuItems[6],

            // this.navMenuItems[4],
          ]
          if (this.user.enabled_menu.includes('Users') || this.user.role === 'Admin') {
            this.navShow.push(operatorMenu)
          }
          this.navShow.push(this.navMenuItems[8])
        }
      } else if (this.user.package === 'pro') {
        this.navShow = [
          this.navMenuItems[0],
          this.navMenuItems[1],
          this.navMenuItems[2],
          this.navMenuItems[3],
          this.navMenuItems[4],
          this.navMenuItems[6],
          this.navMenuItems[8],

          // this.navMenuItems[4],
        ]
      } else if (this.user.package === 'basic') {
        this.navMenuItems[4].children = this.navMenuItems[4].children.filter(
          item => item.title !== 'Label' && item.title !== 'Chat Data',
        )
        this.navMenuItems[7].children = this.navMenuItems[7].children.filter(item => item.title !== 'Replies Statistic')
        this.navShow = [
          this.navMenuItems[0],
          this.navMenuItems[2],
          this.navMenuItems[3],
          this.navMenuItems[4],
          this.navMenuItems[7],
          this.navMenuItems[8],
        ]
      } else {
        this.navShow = [
          this.navMenuItems[0],
          {
            title: 'Subscriptions',
            icon: mdiMonitorCellphone,
            to: 'billings',
          },
        ]
      }
      const obj = {
        title: 'HelpCenter',
        icon: mdiHelpCircle,
        children: [
          {
            title: 'contactUs',
            icon: mdiPhone,
            to: 'call-center',
          },
        ],
      }
      if (helpAndCenter) {
        obj.children.push({
          title: 'Tutorial',
          icon: mdiInformationOutline,
          href: this.locale === 'id' ? 'https://marketa.gitbook.io/guideline' : 'https://marketa.gitbook.io/guideline',
        })
      }

      // this.navShow.push(obj)

      // if (showTutorial) {
      //   this.navShow.push({
      //     title: 'Tutorial',
      //     icon: mdiInformationOutline,
      //     href: this.locale === 'id' ? 'https://docs.superwa.io/s/6AjFMj5Cx' : 'https://docs.superwa.io/s/vtlarAUSU',
      //   })
      // }
      // //console.log('🚀 ~ file: VerticalNavMenu.vue ~ line 682 ~ checkShowNav ~ this.navShow', this.navShow)
      this.navShow = _uniqBy(this.navShow, 'title')

      // //console.log('🚀 ~ file: VerticalNavMenu.vue ~ line 682 ~ checkShowNav ~ this.navShow', this.navShow)
    },
  },
}
</script>

<style lang="scss">
@import '~vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';
</style>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';
@import '~@core/preset/preset/mixins.scss';
.app-navigation-menu {
  transition-duration: 0.4s !important;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1), ease !important;
  will-change: box-shadow, transform !important;
  .v-list-item,
  .v-subheader {
    padding: 0 18px 0 22px;
  }
  .v-list-item {
    margin-top: 0.375rem;
  }
  .v-list-group .v-list-group__header .v-list-item__icon.v-list-group__header__append-icon {
    min-width: 24px;
  }
  .v-navigation-drawer__border {
    background-color: transparent !important;
  }
  // &.v-navigation-drawer--open-on-hover.v-navigation-drawer--is-mouseover {
  //   @include elevation(9);
  //   @include elevationTransition();
  // }
}
@include app-elevation('app-navigation-menu.v-navigation-drawer--open-on-hover.v-navigation-drawer--is-mouseover', 9);
// Set Perfect Scrollbar Container Height
.vertical-nav-menu-container {
  height: 100%;
  .ps-nav-menu-items {
    height: calc(100% - 57px) !important;
  }
  // menu scroll shadow
  .shadow-bottom {
    display: none;
    position: absolute;
    z-index: 2;
    height: 50px;
    width: 100%;
    left: -6px;
    pointer-events: none;
    margin-top: -0.7rem;
    filter: blur(5px);
  }
}
@include theme--child(vertical-nav-menu-container) using ($material) {
  .shadow-bottom {
    background: linear-gradient(
      map-deep-get($material, 'background') 40%,
      rgba(map-deep-get($material, 'background'), 0.1) 95%,
      rgba(map-deep-get($material, 'background'), 0.05)
    );
    margin-top: -0.5rem;
    filter: blur(4px);
  }
}
@include theme(vertical-nav-menu-items) using ($material) {
  @include ltr() {
    padding-right: 20px !important;
  }
  @include rtl() {
    padding-left: 20px !important;
  }
  @at-root .app-navigation-menu.v-navigation-drawer--mini-variant:not(.v-navigation-drawer--is-mouseover) {
    .vertical-nav-menu-items {
      @include ltr() {
        padding-right: 5px !important;
      }
      @include rtl() {
        padding-left: 5px !important;
      }
    }
  }
  .v-subheader:not(:first-child) {
    margin-top: 18px;
  }
  .v-list-item__icon {
    &:first-child {
      @include ltr() {
        margin: 0 12px 0 0;
      }
      @include rtl() {
        margin: 0 0 0 12px;
      }
      align-self: center;
    }
    &.v-list-group__header__append-icon {
      @include ltr() {
        margin-left: 4px;
      }
      @include rtl() {
        margin-right: 4px;
      }
    }
  }
  // Center align small icon
  .v-list-group__items .v-list-item__icon {
    margin-top: 0px;
    @include ltr() {
      margin-left: 5px;
    }
    @include rtl() {
      margin-right: 5px;
    }
  }
  .v-list-item {
    @include app-states($material);
    height: 44px;
    min-height: 44px;
    color: map-deep-get($material, 'text', 'primary');
    // ? Reason: In collapsed mode due to modified mini width & flex grow icon change position while collapsing the drawer
    justify-content: flex-start !important;
    &.v-list-item--active {
      &:not(.v-list-group__header) {
        color: white;
        // background-image: linear-gradient(98deg, #c48eff, var(--v-primary-base) 94%);
      }
      &.v-list-group__header {
        background-color: rgba(
          map-deep-get($material, 'text', 'primary'),
          map-deep-get($material, 'states', 'selected')
        );
      }
    }
    &.vertical-nav-menu-link {
      .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}
</style>
