export function setChartStatistic(state, obj) {
  state.chartStatistic = obj;
}

export function setStatAgregate(state, obj) {
  state.statAgregate = obj;
}

export function setIsWorkspaceEdited(state, data) {
  state.isWorkspaceEdited = data
}