import * as actions from '@/store/firestore/actions'
import * as getters from '@/store/firestore/getters'
import * as mutations from '@/store/firestore/mutations'
import state from '@/store/firestore/state'

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
