/* eslint-disable */
import axios from 'axios'

// axios limit
const MAX_REQUESTS_COUNT = 15
const INTERVAL_MS = 10
let PENDING_REQUESTS = 0

// create new axios instance
const conn = axios.create({})
conn.defaults.timeout = 20 * 60 * 1000
/**
 * Axios Request Interceptor
 */
conn.interceptors.request.use(function (config) {
  return new Promise((resolve, reject) => {
    let interval = setInterval(() => {
      if (PENDING_REQUESTS < MAX_REQUESTS_COUNT) {
        PENDING_REQUESTS++
        clearInterval(interval)
        resolve(config)
      }
    }, INTERVAL_MS)
  })
})

/**
 * Axios Response Interceptor
 */
conn.interceptors.response.use(
  function (response) {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1)
    return Promise.resolve(response)
  },
  function (error) {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1)
    return Promise.reject(error)
  },
)

export async function deleteBroadcasts({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '')}superwa/couchdb/broadcasts/delete`

  return conn
    .post(
      endpoint,
      {
        subId: obj.subId,
        _id: obj._id,
      },
      { headers: { Authorization: `Bearer ` + obj.token }, timeout: 0 },
    )
    .then(async response => {
      return {
        statusCode: response.status,
        status: response.data.status,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      return {
        statusCode: error.response.status,
        status: false,
        errorMessage: error,
      }
    })
}

export async function pauseBroadcasts({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_BROADCAST_SERVICE_API_URL.replace(
    /['"]+/g,
    '',
  )}superwa/broadcasts/postgre/pause`

  return conn
    .post(endpoint, obj, { headers: { Authorization: `Bearer ` + obj.token }, timeout: 0 })
    .then(async response => {
      return {
        statusCode: response.status,
        status: response.data.status,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      return {
        statusCode: error.response.status,
        status: false,
        errorMessage: error,
      }
    })
}

export async function resumeBroadcasts({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '')}superwa/couchdb/broadcasts/resume`

  return conn
    .post(
      endpoint,
      {
        broadcastId: obj.broadcastId,
      },
      { headers: { Authorization: `Bearer ` + obj.token }, timeout: 0 },
    )
    .then(async response => {
      return {
        statusCode: response.status,
        status: response.data.status,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      return {
        statusCode: error.response.status,
        status: false,
        errorMessage: error,
      }
    })
}

export async function resendBroadcast({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '')}superwa/broadcasts/postgre/resend`

  return conn
    .post(endpoint, obj, { headers: { Authorization: `Bearer ` + obj.token }, timeout: 0 })
    .then(async response => {
      return response.data
    })
    .catch(error => {
      return error
    })
}

export async function fetchBroadcastStatistic({ commit, state }, obj) {
  const endpoint =
    process.env.VUE_APP_BROADCAST_SERVICE_API_URL.replace(/['"]+/g, '') +
    'superwa/couchdb/broadcasts/broadcast_statistic_postgres'

  return conn
    .post(
      endpoint,
      {
        sub_id: obj.sub_id,
        start_date: obj.start_date,
        end_date: obj.end_date,
      },
      { headers: { Authorization: `Bearer ` + obj.token } },
    )
    .then(async response => {
      if (response.data.status) {
        commit('setBroadcastStatistic', response.data.data)
        return {
          statusCode: response.status,
          status: true,
          errorMessage: '',
        }
      }
      return {
        statusCode: response.status,
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      return {
        statusCode: error.response.status,
        status: false,
        errorMessage: error,
      }
    })
}

export async function deleteBroadcastById({ commit, state }, obj) {
  //console.log(obj, `INI OBJ`)
  const endpoint = `${process.env.VUE_APP_BROADCAST_SERVICE_API_URL.replace(/['"]+/g, '')}superwa/broadcasts/postgre/${
    obj.id
  }/${obj.sub_id}`

  try {
    const res = await conn.delete(endpoint)
    if (res.data.status) {
      return {
        status: true,
      }
    }

    return {
      status: false,
      message: res.data.message,
    }
  } catch (err) {
    return {
      status: false,
      message: err,
    }
  }
}

export async function getAllBroadcasts({ commit, state }, subId) {
  const endpoint = `${process.env.VUE_APP_BROADCAST_SERVICE_API_URL.replace(
    /['"]+/g,
    '',
  )}superwa/broadcasts/postgre/getAll`

  try {
    const res = await conn.post(endpoint, {
      sub_id: subId,
    })
    if (res.data.status) {
      return res.data
    }

    return []
  } catch (err) {
    return []
  }
}

export async function getContacts({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '')}superwa/couchdb/broadcasts/get-contact-list`

  return conn
    .post(
      endpoint,
      {
        subId: obj.subId,
      },
      { headers: { Authorization: `Bearer ` + obj.token } },
    )
    .then(async response => {
      // commit('setBroadcastContacts', {
      //   contacts: response.data.contacts,
      //   contacts_list: response.data.contacts_list,
      //   extra_field: response.data.extra_field,
      // })
      return {
        statusCode: response.status,
        status: response.data.status,
        errorMessage: response.data.message,
        data: {
          contacts: response.data.contacts,
          contacts_list: response.data.contacts_list,
          extra_field: response.data.extra_field,
        },
      }
    })
    .catch(error => {
      return {
        statusCode: error.response.status,
        status: false,
        errorMessage: error,
      }
    })
}

export async function getAllContacts({ commit, state }, obj) {
  const endpoint = process.env.VUE_APP_WA_SERVICES_URL.replace(/['"]+/g, '') + 'chats/allContacts'
  //console.log(obj.subId,'ini subsv')

  return conn
    .post(
      endpoint,
      {
        sub_id: obj.subId,
        instances: obj.instances,
      },
      { headers: { Authorization: `Bearer ` + obj.token } },
    )
    .then(async response => {
      // commit('setBroadcastContacts', {
      //   contacts: response.data.contacts,
      //   contacts_list: response.data.contacts_list,
      //   extra_field: response.data.extra_field,
      // })
      return {
        status: true,
        errorMessage: '',
        data: response.data.data,
      }
    })
    .catch(error => {
      return {
        statusCode: error.response.status,
        status: false,
        errorMessage: error,
      }
    })
}

export async function getContactsExtraField({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(
    /['"]+/g,
    '',
  )}superwa/couchdb/broadcasts/get-contact-list-extrafield`

  return conn
    .post(
      endpoint,
      {
        subId: obj.subId,
      },
      { headers: { Authorization: `Bearer ` + obj.token } },
    )
    .then(async response => {
      // commit('setBroadcastContacts', {
      //   contacts: response.data.contacts,
      //   contacts_list: response.data.contacts_list,
      //   extra_field: response.data.extra_field,
      // })
      return {
        statusCode: response.status,
        status: response.data.status,
        errorMessage: response.data.message,
        data: {
          contacts_list: response.data.contacts_list,
          extra_field: response.data.extra_field,
        },
      }
    })
    .catch(error => {
      return {
        statusCode: error.response.status,
        status: false,
        errorMessage: error,
      }
    })
}

export async function getChannelStatus({ commit, state }, subidInstance) {
  const endpoint = `${process.env.VUE_APP_WA_SERVICES_URL}sessions/status/${subidInstance}`

  return conn
    .get(endpoint)
    .then(() => {
      return 1
    })
    .catch(error => {
      console.log(error, 'ini errorrss')
      return 0
    })
}
export async function sendQuickReplyWithBroadcast({ commit, state }, obj) {
  console.log('🚀 nyund ~ file: actions.js:342 ~ sendQuickReplyWithBroadcast ~ obj:', obj)
  let endpoint
  if (obj.fromChatwoot) {
    try {
      endpoint = `${process.env.VUE_APP_WA_SERVICES_URL.replace(/['"]+/g, '')}chats/send?id=${obj.endpoint}`
      const formData = new FormData()
      formData.append('operator', obj.operator)
      formData.append('isChatwoot', true)
      formData.append('receiver', obj.conversation_id)
      formData.append('isInstagram', obj.raw_data?.meta?.sender?.additional_attributes?.social_profiles?.instagram != null)
      // obj.attachment_chatwoot.forEach(attachment => {
      //   console.log(attachment, 'ini attachment di sini nyund')
      //   formData.append('attachments', attachment.data, attachment.filename)
      // })

      for (let i = 0; i < obj.attachments.length; i++) {
        const attachment = obj.attachments[i]
        // const { data } = await axios.get(attachment.uploadURL, { responseType: 'arraybuffer' })
        const data = await fetch(attachment.uploadURL)
        const blob = await data.blob()
        console.log(attachment.uploadURL, blob, data, 'data attachment nyund')
        formData.append('attachments', blob, attachment.filename)
      }

      formData.append('nameFile', obj.nameFile)
      formData.append('conversation_id', obj.conversation_id)
      formData.append('message', obj.message)
      formData.append('account_id', obj.account_id)
      formData.append('instance', obj.instance_id)
      formData.append('subId', obj.subId)
      formData.append('access_token', obj.access_token)

      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      }
      const { data } = await axios.post(endpoint, formData, config)
      // console.log('masuk sini gak coba nyund', formData)
      if (data.success) {
        return {
          status: true,
        }
      }
    } catch (error) {
      console.log(error, 'error send quick reply or template')
      return {
        status: false,
        errorMessage: error,
      }
    }
  } else {
    endpoint = `${process.env.VUE_APP_WA_SERVICES_URL.replace(/['"]+/g, '')}chats/send-template?id=${obj.endpoint}`

    return conn
      .post(endpoint, obj)
      .then(async response => {
        // commit('setBroadcastContacts', {
        //   contacts: response.data.contacts,
        //   contacts_list: response.data.contacts_list,
        //   extra_field: response.data.extra_field,
        // })
        return {
          status: true,
        }
      })
      .catch(error => {
        return {
          status: false,
          errorMessage: error,
        }
      })
  }
}

export function setDefaultCustomMessage({ commit, state }, obj) {
  //console.log(obj.message, '<<')
  state.defaultCustomMessage = obj && obj.message ? obj.message : obj
}

export function setForwardMessages({ commit, state }, obj) {
  //console.log(obj, '<<')
  state.forwardMessages =
    obj && obj.messages ? { messages: obj.messages.map(msg => msg.original_message), roomId: obj.roomId } : []
}
