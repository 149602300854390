/* eslint-disable */
import axios from 'axios'
// import store from '../index'
// axios limit
const MAX_REQUESTS_COUNT = 15
const INTERVAL_MS = 10
let PENDING_REQUESTS = 0

// create new axios instance
const conn = axios.create({})
conn.defaults.timeout = 20000
/**
 * Axios Request Interceptor
 */
conn.interceptors.request.use(function (config) {
  return new Promise((resolve, reject) => {
    let interval = setInterval(() => {
      if (PENDING_REQUESTS < MAX_REQUESTS_COUNT) {
        PENDING_REQUESTS++
        clearInterval(interval)
        resolve(config)
      }
    }, INTERVAL_MS)
  })
})

/**
 * Axios Response Interceptor
 */
conn.interceptors.response.use(
  function (response) {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1)
    return Promise.resolve(response)
  },
  function (error) {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1)
    return Promise.reject(error)
  },
)

export async function getBroadcasts({ commit, state }, user) {
  const endpoint = process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '') + 'superwa/broadcasts/get-broadcasts'

  return conn
    .post(endpoint, {
      db_name: user.db_name,
    })
    .then(async response => {
      if (response.data.status) {
        commit('setBroadcastListData', response.data.data)

        return {
          status: true,
          errorMessage: '',
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      return {
        status: false,
        errorMessage: error,
      }
    })
}

export async function insertBroadcasts({ commit, state }, obj) {
  const endpoint = process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '') + 'superwa/broadcasts/insert-broadcasts'

  return conn
    .post(endpoint, {
      db_name: obj.db_name,
      broadcasts: obj.data,
    })
    .then(async response => {
      if (response.data.status) {
        return {
          status: true,
          errorMessage: '',
        }
      }
      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      return {
        status: false,
        errorMessage: error,
      }
    })
}

export async function deleteBroadcasts({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '')}superwa/couchdb/broadcasts/delete`

  return conn
    .post(endpoint, {
      subId: obj.subId,
      _id: obj._id,
    })
    .then(async response => {
      return {
        status: response.data.status,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      return {
        status: false,
        errorMessage: error,
      }
    })
}

export async function getUserData({ commit, state }, obj) {
  const endpoint = process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '') + 'superwa/couchdb/contact/find-contact-data'

  const config = {
    headers: {
      authorization: `Bearer ${obj.token}`,
    },
  }

  return conn
    .post(endpoint, obj, config)
    .then(async response => {
      if (response && response.data && response.data.status) {
        return {
          status: true,
          errorMessage: '',
          data: response.data.data,
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      return {
        status: false,
        errorMessage: error,
      }
    })
}

export async function fetchBroadcastStatistic({ commit, state }, obj) {
  const endpoint =
    process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '') + 'superwa/couchdb/broadcasts/broadcast_statistic'

  return conn
    .post(endpoint, {
      sub_id: obj.sub_id,
      start_date: obj.start_date,
      end_date: obj.end_date,
    })
    .then(async response => {
      if (response.data.status) {
        commit('setBroadcastStatistic', response.data.data)
        return {
          status: true,
          errorMessage: '',
        }
      }
      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      return {
        status: false,
        errorMessage: error,
      }
    })
}

export async function getUsers({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_API_GENERAL.replace(/['"]+/g, '')}room/sync-participants`

  return conn
    .post(endpoint, obj)
    .then(async response => {
      // //console.log(response.data, `INI RES DATA`)
      return {
        data: response.data,
        status: response.data ? true : false,
        errorMessage: '',
      }
    })
    .catch(error => {
      return {
        status: false,
        errorMessage: error,
      }
    })
}

export async function getUserNameCouch({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '')}superwa/couchdb/contact/find-contact-room`
  // const { token } = store.getters['auth/getUser']
  const config = {
    headers: {
      authorization: `Bearer ${obj.token}`,
    },
  }

  return conn
    .post(endpoint, obj, config)
    .then(async response => {
      if (response.data.status) {
        return {
          status: true,
          errorMessage: '',
          data: response.data,
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      // to do check auth
      return {
        status: false,
        errorMessage: error,
      }
    })
}

export async function getUserNameInGroupCouch({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(
    /['"]+/g,
    '',
  )}superwa/couchdb/contact/find-contact-room-group`
  // const { token } = store.getters['auth/getUser']
  const config = {
    headers: {
      authorization: `Bearer ${obj.token}`,
    },
  }

  return conn
    .post(endpoint, obj, config)
    .then(async response => {
      //console.log(response, 'ini di store res')
      if (response.data.status) {
        return {
          status: true,
          errorMessage: '',
          data: response.data,
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      // to do check auth
      return {
        status: false,
        errorMessage: error,
      }
    })
}

export async function getContacts({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '')}superwa/couchdb/broadcasts/get-contact-list`

  return conn
    .post(endpoint, {
      subId: obj.subId,
    })
    .then(async response => {
      commit('setBroadcastContacts', {
        contacts: response.data.contacts,
        contacts_list: response.data.contacts_list,
        extra_field: response.data.extra_field,
      })
      return {
        status: response.data.status,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      return {
        status: false,
        errorMessage: error,
      }
    })
}

export function getContactInbox({ store, commit, state }, obj) {
  //  /superwa/couchdb/contact/detail
  // /superwa/couchdb/contact/delete-field-value
  // /superwa/couchdb/contact/update-field-value
  const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '')}superwa/couchdb/contact/detail`
  return conn.post(endpoint, obj.data, { headers: { Authorization: `Bearer ${obj.token}` } })
}

export function updateContactInbox({ store, commit, state }, obj) {
  //  /superwa/couchdb/contact/detail
  // /superwa/couchdb/contact/delete-field-value
  // /superwa/couchdb/contact/update-field-value
  const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '')}superwa/couchdb/contact/update-field-value`
  return conn.post(endpoint, obj.data, { headers: { Authorization: `Bearer ${obj.token}` } })
}
export function deleteContactInbox({ store, commit, state }, obj) {
  //  /superwa/couchdb/contact/detail
  // /superwa/couchdb/contact/delete-field-value
  // /superwa/couchdb/contact/update-field-value
  const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '')}superwa/couchdb/contact/delete-field-value`
  return conn.post(endpoint, obj.data, { headers: { Authorization: `Bearer ${obj.token}` } })
}

export async function getNotifyTime({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '')}superwa/couchdb/settings/get-time-notif`
  // //console.log('masuk Store', obj)

  return conn
    .post(
      endpoint,
      {
        sub_id: obj,
      },

      // { headers: { Authorization: `Bearer ` + obj.token } },
    )

    .then(async response => {
      // //console.log('ini res', response)
      if (response.data.status) {
        // //console.log('ini res', response.data.data)

        return {
          status: response.data.data,
          errorMessage: '',
          data: response.data,
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      // to do check auth
      return {
        status: false,
        errorMessage: error,
      }
    })
}
export async function setNotifyTime({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '')}superwa/couchdb/settings/update-time-notif`
  // //console.log('masuk Store', obj)

  return conn
    .post(
      endpoint,
      {
        sub_id: obj.sub_id,
        time: obj.time,
      },

      // { headers: { Authorization: `Bearer ` + obj.token } },
    )

    .then(async response => {
      // //console.log('ini res', response)
      if (response.data.status) {
        // //console.log('ini res', response.data.data)

        return {
          status: response.data.data,
          errorMessage: '',
          data: response.data,
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      // to do check auth
      return {
        status: false,
        errorMessage: error,
      }
    })
}
