import '@/plugins/crypto'
import '@/plugins/eventBus'
import { i18n } from '@/plugins/i18n'
import '@/plugins/moment'
import '@/plugins/mustache'
import '@/plugins/nanoSubId'
import '@/plugins/nanoid'
import '@/plugins/vue-composition-api'
import '@/plugins/vuex'
import '@/styles/styles.scss'
import VueCompositionApi from '@vue/composition-api'

// import Countly from 'countly-sdk-web'
import Vue from 'vue'

// import VueCountly from 'vue-countly'
import VueGtag from 'vue-gtag'
import VueHead from 'vue-head'

// import VueMatomo from 'vue-matomo'
import VuetifyDialog from 'vuetify-dialog'
import 'vuetify-dialog/dist/vuetify-dialog.css'

// ------------------------------
// x5-modal plugin install START
// ------------------------------
import x5GMaps from 'x5-gmaps'

// import x5GMaps from 'x5-gmaps'
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import 'tiptap-vuetify/dist/main.css'
import VueExcelXlsx from 'vue-excel-xlsx'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

Vue.use(VueExcelXlsx)

// Vue.use(x5GMaps, {
//   key: 'AIzaSyBxna5ko9XPpBQxw9Uqj_1RrGHTYcb1D_c',
//   libraries: ['visualization'],
// })
Vue.use(x5GMaps, 'AIzaSyBxna5ko9XPpBQxw9Uqj_1RrGHTYcb1D_c')

// ------------------------------
// x5-modal plugin install END
// ------------------------------
Vue.use(VueHead)
Vue.use(VueCompositionApi)
Vue.use(VuetifyDialog, {
  context: {
    vuetify,
  },
})
Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID },
})

// Vue.use(VueMatomo, {
//   // Configure your matomo server and site by providing
//   host: process.env.VUE_APP_ANALYTICS_HOST,
//   siteId: 1,

//   // Changes the default .js and .php endpoint's filename
//   // Default: 'matomo'
//   trackerFileName: 'matomo',

//   // Overrides the autogenerated tracker endpoint entirely
//   // Default: undefined
//   // trackerUrl: 'https://example.com/whatever/endpoint/you/have',

//   // Overrides the autogenerated tracker script path entirely
//   // Default: undefined
//   // trackerScriptUrl: 'https://example.com/whatever/script/path/you/have',

//   // Enables automatically registering pageviews on the router
//   router,

//   // Enables link tracking on regular links. Note that this won't
//   // work for routing links (ie. internal Vue router links)
//   // Default: true
//   enableLinkTracking: true,

//   // Require consent before sending tracking information to matomo
//   // Default: false
//   requireConsent: false,

//   // Whether to track the initial page view
//   // Default: true
//   trackInitialView: true,

//   // Run Matomo without cookies
//   // Default: false
//   disableCookies: false,

//   // Require consent before creating matomo session cookie
//   // Default: false
//   requireCookieConsent: false,

//   // Enable the heartbeat timer (https://developer.matomo.org/guides/tracking-javascript-guide#accurately-measure-the-time-spent-on-each-page)
//   // Default: false
//   enableHeartBeatTimer: false,

//   // Set the heartbeat timer interval
//   // Default: 15
//   heartBeatTimerInterval: 15,

//   // Whether or not to log debug information
//   // Default: false
//   debug: false,

//   // UserID passed to Matomo (see https://developer.matomo.org/guides/tracking-javascript-guide#user-id)
//   // Default: undefined
//   userId: undefined,

//   // Share the tracking cookie across subdomains (see https://developer.matomo.org/guides/tracking-javascript-guide#measuring-domains-andor-sub-domains)
//   // Default: undefined, example '*.example.com'
//   cookieDomain: undefined,

//   // Tell Matomo the website domain so that clicks on these domains are not tracked as 'Outlinks'
//   // Default: undefined, example: '*.example.com'
//   domains: undefined,

//   // A list of pre-initialization actions that run before matomo is loaded
//   // Default: []
//   // Example: [
//   //   ['API_method_name', parameter_list],
//   //   ['setCustomVariable','1','VisitorType','Member'],
//   //   ['appendToTrackingUrl', 'new_visit=1'],
//   //   etc.
//   // ]
//   preInitActions: [],

//   // A function to determine whether to track an interaction as a site search
//   // instead of as a page view. If not a function, all interactions will be
//   // tracked as page views. Receives the new route as an argument, and
//   // returns either an object of keyword, category (optional) and resultsCount
//   // (optional) to track as a site search, or a falsey value to track as a page
//   // view.
//   // Default: false, i.e. track all interactions as page views
//   // Example: (to) => {
//   //   if (to.query.q && to.name === 'search') {
//   //     return { keyword: to.query.q, category: to.params.category }
//   //   } else {
//   //    return null
//   //   }
//   // }
//   trackSiteSearch: false,

//   // Set this to include crossorigin attribute on the matomo script import
//   // Default: undefined, possible values : 'anonymous', 'use-credentials'
//   crossOrigin: undefined,
// })

// Vue.use(VueCountly, Countly, {
//   app_key: process.env.VUE_APP_COUNTLY_API_KEY,
//   url: process.env.VUE_APP_COUNTLY_DOMAIN,

//   // app_key: '7b1487c2cdcd93c6e007b9265f7c7d46d98c18b4',
//   // url: 'https://countly.superwa.io',
// })

Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: 'mdiSvg',
})

// //console.log(Vue.Countly === Countly)
// eslint-disable-next-line no-multi-assign
window.$ = window.jQuery = require('jquery')

Vue.config.productionTip = false

Vue.mixin({
  methods: {
    login(user, pass) {
      return clientPocketBase
        .collection('users')
        .authWithPassword(user, pass)
        .then((response) => {
          this.$store.commit('setUser', response.data.user); // assuming you are saving the user info in vuex store.
        })
        .catch((error) => {
          console.error("Error logging in: ", error);
        });
    }
  },
  created() {
    // Try to auto re-login
    let storedUser = localStorage.getItem('user'); // Assuming you stored user and pass in localStorage
    let storedPass = localStorage.getItem('pass');

    if (storedUser && storedPass) {
      this.login(storedUser, storedPass);
    }
  },
});


new Vue({
  router,
  store,
  i18n,
  vuetify,

  // created() {
  //   if (window.Capacitor.isPluginAvailable('BackgroundMode')) {
  //     const { BackgroundMode } = window
  //     BackgroundMode.enable()
  //     BackgroundMode.setDefaults({
  //       title: 'My App is running in the background',
  //       text: 'Background mode is active',
  //       color: '#000000',
  //     })
  //   }
  // },

  // created() {
  //   //console.log(this.$Countly === Countly, '=============')

  //   // > true

  //   // Track sessions automatically (recommended)
  //   this.$Countly.q.push(['track_sessions'])

  //   // // Track web page views automatically (recommended)
  //   this.$Countly.q.push(['track_pageview'])
  // },
  render: h => h(App),
}).$mount('#app')
