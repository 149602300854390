export default function () {
  return {
    notifAllOrAssigned: 2,
    autoAssignChatOnReply: true,
    autoCloseChat: {
      id: 3,
      label: `24 Hours`,
      unit: 'hours',
      value: 24,
    },
    currentScanned: null,
    loadingMessages: false,
    globalChannel: null,
    locale: 'en',
    serverConnection: true,
    contacts: [],
    settings: [],
    loginState: null,
    link: [],
    enabledApps: [],
    rooms: null,
    syncFromServer: false,
    filterChat: 'all',
    filterRoom: null,
    currentRoom: null,
    search: '',
    lang: 'en',
    lastSeq: 0,
    replicateStatus: {
      contacts: 'idle',
      settings: 'idle',
      broadcasts: 'idle',
      history: 'idle',
      chat: 'idle',
      link: 'idle',
    },
    statusChannels: false,
    muteValue: false,
    boldUnreplied: false,
    version: null,
    messages_offline: [],
    initReplicateStatus: {
      contacts: false,
      settings: false,
      broadcasts: false,
      history: false,
      chat: false,
      link: false,
    },
    chatsLokiDb: null,
    contactsLokiDb: null,
    lokiDb: null,
    notifPayload: null,
    allContactData: [],
    roleSettings: [],
    chatHeaderPayload: null,
    selectedChatHeader: {
      status: false,
      roomId: '',
    },
    customerListContacts: [],
    customerGroups: [],
    mentionChatPayload: {
      status: false,
      phone: '',
      instance_id: '',
    },
    IsCustomerGroupChanged: false,
    isScanning: false,
    lastInserted: 0,
    storeContacts: [],
    messageObj: {
      message: 'cegah',
    },
  }
}
