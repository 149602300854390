/* eslint-disable */
import conn from '@/plugins/axios-limit'

export async function singleApproveLoanSubmission(_, payload) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/loan/single-approve-loan-submission`

  return conn
    .post(endpoint, payload)
    .then(async response => {
      //console.log(response)

      // commit('setLoanSubs', response.data.data)

      return response.data
    })
    .catch(error => ({
      status: false,
      errorMessage: error,
    }))
}
export async function singleRejectLoanSubmission(_, payload) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/loan/single-reject-loan-submission`

  return conn
    .post(endpoint, payload)
    .then(async response => {
      //console.log(response)

      // commit('setLoanSubs', response.data.data)

      return response.data
    })
    .catch(error => ({
      status: false,
      errorMessage: error,
    }))
}
export async function bulkApproveRejectLoanSubmission(_, payload) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/loan/bulk-approve-reject-loan-submissions`

  return conn
    .post(endpoint, payload)
    .then(async response => {
      //console.log(response)

      // commit('setLoanSubs', response.data.data)

      return response.data
    })
    .catch(error => ({
      status: false,
      errorMessage: error,
    }))
}
export async function createLoanSubmissions({ commit, state }, payload) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/loan/create-loan-submission`

  return conn
    .post(endpoint, payload)
    .then(async response => {
      //console.log(response, 'daya')
      commit('setLoanSubs', [...state.loanSubs, response.data.loanSub])

      return response.data
    })
    .catch(error => ({
      status: false,
      errorMessage: error,
    }))
}
export const editLoanSubmissions = async ({ commit, state }, payload) => {
  //console.log(payload, 'idLoanSub')
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/loan/update-loan-submission`
  const body = { sub_id: payload.sub_id, input_data: payload.input_data }

  try {
    const { data: res } = await conn.post(endpoint, body)
    if (!res.status) {
      throw res
    }

    return
  } catch (error) {
    console.log(error)
  }
}
export async function deleteLoanSubmissions({ commit, state }, payload) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/loan/delete-loan-submission`

  return conn
    .post(endpoint, payload)
    .then(async response => {
      return response.data
    })
    .catch(error => ({
      status: false,
      errorMessage: error,
    }))
}
export async function getLoanSubmissionsNeedMyApproval({ commit }, payload) {
  //console.log('entering getLoanSubmissionsNeedMyApproval')
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/loan/list-loan-submission-need-my-approval`

  return conn
    .post(endpoint, payload)
    .then(response => {
      commit('setLoanSubsNeedMyApproval', response.data.data)
    })
    .catch(error => ({
      status: false,
      errorMessage: error,
    }))
}
export const getDisbursementsNeedApproval = async ({ commit, state }, payload) => {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/disbursement/need-approval`
  const body = { sub_id: payload.user.sub_id, approver: payload.user }

  try {
    const { data: res } = await conn.post(endpoint, body)
    if (!res.status) {
      throw res
    }
    commit('setDisbursementsNeedApproval', res.data)
    return res.data
  } catch (error) {
    console.log(error)
  }
}
export const updateDisbursementApprovalStatus = async ({ commit, state }, payload) => {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/disbursement/update-approval-status`
  const body = {
    sub_id: payload.sub_id,
    disbursementId: payload.disbursementId,
    approver: payload.approver,
    action: payload.action,
  }

  try {
    const { data: res } = await conn.post(endpoint, body)
    if (!res.status) {
      throw res
    }

    return res.data
  } catch (error) {
    console.log(error)
  }
}
