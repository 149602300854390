export default {
  methods: {
    paginator(items, current_page, per_page_items) {
      const page = current_page || 1
      const per_page = per_page_items || 10
      const offset = (page - 1) * per_page
      const paginatedItems = items.slice(offset).slice(0, per_page_items)
      const total_pages = Math.ceil(items.length / per_page)

      return {
        page,
        per_page,
        previous_page: page - 1 ? page - 1 : null,
        next_page: total_pages > page ? page + 1 : null,
        total: items.length,
        total_pages,
        data: paginatedItems,
      }
    },
    formatPhoneNumber(phoneNumber, group = true) {
      if (!phoneNumber?.includes('-') && phoneNumber?.length <= 16) {
        const countryCode = `+${phoneNumber.substr(0, 2)}` // +62
        const splitOne = phoneNumber.substr(2, 3)
        const splitTwo = phoneNumber.substr(5, 4)
        const splitThree = phoneNumber.substr(9, phoneNumber.length - 1)
        phoneNumber = `${countryCode} ${splitOne}-${splitTwo}-${splitThree}`
      } else if (group) {
        phoneNumber = 'Group'
      }

      return phoneNumber
    },
  },
}
