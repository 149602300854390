export const setLoanSubs = (state, payload) => {
  state.loanSubs = payload
}
export const setLoanSub = (state, payload) => {
  state.loanSub = payload
}
export const setLoanTypes = (state, payload) => {
  state.loanTypes = payload
}
export const setLoanType = (state, payload) => {
  state.loanType = payload
}
export const setLoanSubsNeedMyApproval = (state, payload) => {
  state.loanSubsNeedMyApproval = payload
}
export const setBillingByLoanId = (state, payload) => {
  state.billingByLoanId = payload
}
export const setBillingById = (state, payload) => {
  state.billing = payload
}
export const setTransaction = (state, payload) => {
  state.transaction = payload
}
