<template>
  <v-btn
    v-if="!$vuetify.breakpoint.mobile"
    class="text-capitalize"
    color="primary"
    outlined
    :block="$vuetify.breakpoint.smAndDown"
    @click="toLoanApprovalPage"
  >
    <v-chip
      v-if="totalNotifications > 0"
      small
      color="error"
      class="mr-2"
    >
      {{ totalNotifications }}
    </v-chip>
    <span>Need Approval</span>
  </v-btn>
</template>

<script>
/* eslint-disable */

import useAppConfig from '@core/@app-config/useAppConfig'
import store from '@/store'
// import { loadLanguageAsync } from '@/plugins/i18n'

export default {
  name: 'LoanApprovalButton',
  methods: {
    async toLoanApprovalPage() {
      await this.$store.dispatch('manageLoan/getLoanSubmissionsNeedMyApproval', {
        sub_id: this.user.sub_id,
        input_data: { my_user_data: this.user },
      })
      this.$router.push('/manage-approval')
    },
  },
  computed: {
    user() {
      return this.$store.getters['auth/getUser']
    },
    loanSubmissionsNeedApproval() {
      return this.$store.getters['manageLoan/getLoanSubsNeedMyApproval']
    },
    disbursementsNeedApproval() {
      return this.$store.getters['manageApproval/getDisbursementsNeedApproval']
    },
    totalNotifications() {
      const total = +this.loanSubmissionsNeedApproval.length + +this.disbursementsNeedApproval.length
      return !total ? 0 : total
    },
  },

  // props: {
  //   reload: { type: Boolean, default: true },
  // },
  // setup(props) {
  //   const { isRtl, lang } = useAppConfig()

  // const locales = [
  //   {
  //     title: 'English',

  //     img: require('@/assets/images/flags/en.png'),
  //     locale: 'en',
  //   },
  //   {
  //     title: 'Indonesia',

  //     img: require('@/assets/images/flags/id.png'),
  //     locale: 'id',
  //   },
  // ]

  // const updateActiveLocale = locale => {
  //   // Set to RTL mode if locale is arabic
  //   isRtl.value = locale === 'ar'

  //   store.dispatch('global/setLang', locale)
  //   loadLanguageAsync(locale)

  //   if (props.reload) {
  //     window.location.reload()
  //   }
  // }

  // return {
  //   locales,
  //   updateActiveLocale,
  // }
  // },
}
</script>
