export default function () {
  return {
    projects: [],
    currentProject: {
      logo: {},
      name: '',
      address: '',
      channel: {},
      email: '',
      ID_xendit_account: '',
    },
  }
}
