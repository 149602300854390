/* eslint-disable */
import axios from 'axios'
// axios limit
const MAX_REQUESTS_COUNT = 15
const INTERVAL_MS = 10
let PENDING_REQUESTS = 0

// create new axios instance
const conn = axios.create({})
conn.defaults.timeout = 20000000
/**
 * Axios Request Interceptor
 */
conn.interceptors.request.use(function (config) {
  return new Promise((resolve, reject) => {
    let interval = setInterval(() => {
      if (PENDING_REQUESTS < MAX_REQUESTS_COUNT) {
        PENDING_REQUESTS++
        clearInterval(interval)
        resolve(config)
      }
    }, INTERVAL_MS)
  })
})

/**
 * Axios Response Interceptor
 */
conn.interceptors.response.use(
  function (response) {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1)
    return Promise.resolve(response)
  },
  function (error) {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1)
    return Promise.reject(error)
  },
)

export async function deleteChannels({ commit, state }, obj) {
  const endpoint = process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '') + 'superwa/couchdb/rooms/delete-channels'

  const config = {
    headers: {
      authorization: `Bearer ${obj.token}`,
    },
  }
  let query = {}
  query.instance_id = obj.instance_id
  query.sub_id = obj.sub_id

  return conn
    .post(endpoint, query, config)
    .then(async response => {
      if (response && response.data && response.data.status) {
        return {
          status: true,
          errorMessage: '',
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      return {
        status: false,
        errorMessage: error,
      }
    })
}

export async function getRoomsFromLastMessage({ commit, state }, obj) {
  const endpoint = process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '') + 'superwa/couchdb/rooms/get-rooms-last-message'

  const config = {
    headers: {
      authorization: `Bearer ${obj.token}`,
    },
  }

  return conn
    .post(endpoint, obj, config)
    .then(async response => {
      if (response && response.data && response.data.status) {
        return response.data
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      return {
        status: false,
        errorMessage: error,
      }
    })
}

export async function resetChannels({ commit, state }, obj) {
  const endpoint = process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '') + 'superwa/couchdb/rooms/reset-channels'

  const config = {
    headers: {
      authorization: `Bearer ${obj.token}`,
    },
  }
  let query = {}
  query.instance_id = obj.instance_id
  query.sub_id = obj.sub_id

  return conn
    .post(endpoint, query, config)
    .then(async response => {
      if (response && response.data && response.data.status) {
        return {
          status: true,
          errorMessage: '',
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      return {
        status: false,
        errorMessage: error,
      }
    })
}

export async function getMessagesPostgres({ commit, state }, obj) {
  // //console.log(obj, '+========================+')
  const endpoint = process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '') + 'superwa/couchdb/rooms/get-messages'

  const config = {
    headers: {
      authorization: `Bearer ${obj.token}`,
    },
  }
  let query = {}
  query.sub_id = obj.sub_id
  if (obj.roomId) {
    query.roomId = obj.roomId
  }
  if (obj.couch_timestamp !== undefined) {
    query.couch_timestamp = obj.couch_timestamp
  }
  if (obj.limit) {
    query.limit = obj.limit
  }
  if (obj.querySearch !== undefined) {
    query.querySearch = obj.querySearch
  }

  return conn
    .post(endpoint, query, config)
    .then(async response => {
      if (response && response.data && response.data.status) {
        return {
          status: true,
          errorMessage: '',
          data: response.data.data,
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      return {
        status: false,
        errorMessage: error,
      }
    })
}

export async function getRoomsDefaultNotif({ commit, state }, obj) {
  const endpoint =
    process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '') + 'superwa/couchdb/rooms/get-rooms-default-notif'

  const config = {
    headers: {
      authorization: `Bearer ${obj.token}`,
    },
  }

  return conn
    .post(endpoint, obj, config)
    .then(async response => {
      if (response.data.status) {
        return response.data
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      return {
        status: false,
        errorMessage: error,
      }
    })
}

export async function changeOperator({ commit, state }, obj) {
  const endpoint = process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '') + 'superwa/couchdb/rooms/change-operator'

  const config = {
    headers: {
      authorization: `Bearer ${obj.token}`,
    },
  }

  return conn
    .post(endpoint, obj, config)
    .then(async response => {
      if (response.data.status) {
        return response.data
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      return {
        status: false,
        errorMessage: error,
      }
    })
}

export async function getRoomsContactDataPostgres({ commit, state }, obj) {
  const endpoint = process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '') + 'superwa/couchdb/rooms/get-rooms-contact-data'

  const config = {
    headers: {
      authorization: `Bearer ${obj.token}`,
    },
  }

  return conn
    .post(endpoint, obj, config)
    .then(async response => {
      if (response && response.data && response.data.status) {
        return {
          status: true,
          errorMessage: '',
          data: response.data.data,
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      return {
        status: false,
        errorMessage: error,
      }
    })
}

export async function getRoomsContactImportPostgres({ commit, state }, obj) {
  // const endpoint =
  //   process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '') + 'superwa/couchdb/rooms/get-rooms-contact-import'
  const endpoint = process.env.VUE_APP_WA_SERVICES_URL.replace(/['"]+/g, '') + 'chats/allcontactsImport'

  const config = {
    headers: {
      authorization: `Bearer ${obj.token}`,
    },
  }

  return conn
    .post(endpoint, obj, config)
    .then(async response => {
      if (response && response.data && response.data.status) {
        return {
          status: true,
          errorMessage: '',
          data: response.data.data,
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      return {
        status: false,
        errorMessage: error,
      }
    })
}

export async function getRoomsPostgres({ commit, state }, obj) {
  const endpoint = process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '') + 'superwa/couchdb/rooms/get-rooms'

  const config = {
    headers: {
      authorization: `Bearer ${obj.token}`,
    },
  }

  return conn
    .post(endpoint, obj, config)
    .then(async response => {
      if (response && response.data && response.data.status) {
        // //console.log(response.data, "resps")
        return {
          status: true,
          errorMessage: '',
          data: response.data.data,
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      return {
        status: false,
        errorMessage: error,
      }
    })
}

export async function searchRooms({ commit, state }, obj) {
  // const endpoint = process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '') + 'superwa/couchdb/rooms/search-rooms'
  // const subidInstance = this.user.sub_id + '-' + room.roomId.substring(0, room.roomId.indexOf('-'))
  const endpoint = process.env.VUE_APP_WA_SERVICES_URL.replace(/['"]+/g, '') + 'chats/search'
  //console.log(endpoint, 'ini endpoint yang mau dicek')
  const config = {
    headers: {
      authorization: `Bearer ${obj.token}`,
    },
  }

  return conn
    .post(endpoint, obj, config)
    .then(async response => {
      if (response && response.data && response.data.status) {
        //console.log(response.data, 'resps')
        return response.data
      }

      return response.data
    })
    .catch(error => {
      return {
        status: false,
        errorMessage: error,
      }
    })
}

export async function filterRooms({ commit, state }, obj) {
  const endpoint = process.env.VUE_APP_WA_SERVICES_URL.replace(/['"]+/g, '') + 'chats/filter'
  const config = {
    headers: {
      authorization: `Bearer ${obj.token}`,
    },
  }

  return conn
    .post(endpoint, obj, config)
    .then(async response => {
      if (response && response.data && response.data.status) {
        //console.log(response.data, 'resps')
        return response.data
      }

      return response.data
    })
    .catch(error => {
      return {
        status: false,
        errorMessage: error,
      }
    })
}

export async function fetchChats({ commit, state }, obj) {
  const endpoint = process.env.VUE_APP_WA_SERVICES_URL.replace(/['"]+/g, '') + 'chats/fetch'
  const config = {
    headers: {
      authorization: `Bearer ${obj.token}`,
    },
  }

  return conn
    .post(endpoint, obj, config)
    .then(async response => {
      if (response && response.data && response.data.status) {
        //console.log(response.data, 'resps')
        return response.data
      }

      return response.data
    })
    .catch(error => {
      return {
        status: false,
        errorMessage: error,
      }
    })
}

export async function deleteRoom({ commit, state }, obj) {
  const endpoint = process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '') + 'superwa/couchdb/rooms/delete-room'

  const config = {
    headers: {
      authorization: `Bearer ${obj.token}`,
    },
  }

  return conn
    .post(endpoint, obj, config)
    .then(async response => {
      if (response && response.data && response.data.status) {
        // //console.log(response.data, 'resps')
        return response.data
      }

      return response.data
    })
    .catch(error => {
      return {
        status: false,
        errorMessage: error,
      }
    })
}

export async function getOneRoomsPostgres({ commit, state }, obj) {
  const endpoint = process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '') + 'superwa/couchdb/rooms/get-one-room'

  const config = {
    headers: {
      authorization: `Bearer ${obj.token}`,
    },
  }

  return conn
    .post(endpoint, obj, config)
    .then(async response => {
      // //console.log(response,"oooooooooooooooo")
      if (response && response.data && response.data.status) {
        return {
          status: true,
          errorMessage: '',
          data: response.data.data,
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      return {
        status: false,
        errorMessage: error,
      }
    })
}
export async function pingOperator({ commit, state }, obj) {
  const endpoint = process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '') + 'superwa/couchdb/rooms/ping-operator'

  const config = {
    headers: {
      authorization: `Bearer ${obj.token}`,
    },
  }

  return conn
    .post(endpoint, obj, config)
    .then(async response => {
      // //console.log(response,"oooooooooooooooo")
      if (response && response.data && response.data.status) {
        return {
          status: true,
          errorMessage: '',
          data: response.data,
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      return {
        status: false,
        errorMessage: error,
      }
    })
}
export async function statisticOperator({ commit, state }, obj) {
  const endpoint = process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '') + 'superwa/couchdb/rooms/get-operator-history'

  const config = {
    headers: {
      authorization: `Bearer ${obj.token}`,
    },
  }

  return conn
    .post(endpoint, obj, config)
    .then(async response => {
      // //console.log(response,"oooooooooooooooo")
      if (response && response.data && response.data.status) {
        return {
          status: true,
          errorMessage: '',
          data: response.data,
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      return {
        status: false,
        errorMessage: error,
      }
    })
}
// export async function statisticMessage({ commit, state }, obj) {
//   const endpoint = process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '') + 'superwa/couchdb/rooms/get-messages-statistic'

//   const config = {
//     headers: {
//       authorization: `Bearer ${obj.token}`,
//     },
//   }

//   return conn
//     .post(endpoint, obj, config)
//     .then(async response => {
//       // //console.log(response,"oooooooooooooooo")
//       if (response && response.data && response.data.status) {
//         return {
//           status: true,
//           errorMessage: '',
//           data: response.data,
//         }
//       }

//       return {
//         status: false,
//         errorMessage: response.data.message,
//       }
//     })
//     .catch(error => {
//       return {
//         status: false,
//         errorMessage: error,
//       }
//     })
// }
export async function messageSendBy({ commit, state }, obj) {
  const endpoint =
    process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '') + 'superwa/couchdb/rooms/get-operator-message-recap'

  const config = {
    headers: {
      authorization: `Bearer ${obj.token}`,
    },
  }

  return conn
    .post(endpoint, obj, config)
    .then(async response => {
      // //console.log(response,"oooooooooooooooo")
      if (response && response.data && response.data.status) {
        return {
          status: true,
          errorMessage: '',
          data: response.data,
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      return {
        status: false,
        errorMessage: error,
      }
    })
}
