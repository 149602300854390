import messageTemplate from '@/plugins/i18n/message-template'

export default {
  // message template
  ...messageTemplate,

  // menu
  itemChat: 'Chat',
  [`itemSend Broadcast`]: 'Send Broadcast',
  [`itemContact List`]: 'Customer List',
  [`itemChannels`]: 'Channel Wa',
  [`itemInbox`]: 'Chat Setting',
  [`itemBroadcasts`]: 'Broadcasts',
  [`itemUsers`]: 'Team',
  [`itemBusiness+`]: 'Enterprise',
  [`itemStatistics`]: 'Statistics',
  [`itemBroadcast Timeline`]: 'Broadcast Timeline',
  [`itemTemplate`]: 'Template',
  [`itemLabel`]: 'Label',
  [`itemChat Data`]: 'Chat Data',
  [`itemMy Apps`]: 'My Apps',
  [`itemE-Shop`]: 'E-Shop',
  [`itemE-Form`]: 'E-Form',
  [`itemCampaign Tracker`]: 'Campaign Tracker',
  [`itemLogin by Whatsapp`]: 'Login by Whatsapp',
  [`itemBroadcast Statistic`]: 'Broadcast Statistic',
  [`itemUser Perfomance`]: 'User Perfomance',
  [`itemChat Statistic`]: 'Chat Statistic',
  Approval: 'Approval',
  campaignManager: 'Campaign Manager',
  landingPage: 'Landing Page',
  appsMarketplace: 'Apps Marketplace',
  chatSetting: 'Chat Setting',
  statistic: 'Statistic',
  installedApps: 'Installed Apps',
  finance: 'Finance',
  every: 'Every',
  none: 'None',
  needMoreHelpTextIg: 'Visit Us on Instagram',
  needMoreHelpTextYt: 'Visit Us on Youtube',
  needMoreHelpTextEmail: 'Best way to get answer faster!',
  needMoreHelpText: 'contact us below. we will help you as soon as we can',
  needMoreHelp: 'Need more Help?',
  HelpCenter: 'Help Center',
  contactUs: 'Contact Us',
  eOrdering: 'eOrdering',
  eForm: 'eForm',
  member: 'Member',
  loadingDialog: 'Loading... Please Wait...',
  price: 'Price',
  startConversation: 'Start Conversation',
  customer: 'Customer',
  notes: 'Notes',
  note: 'Note',
  lastMessage: 'Last Message',
  confirmDelete: 'Are you sure to delete?',
  deleteTemplate: 'Are you sure want to delete this template?',
  bulkDeleteTemplate: 'Are you sure want to delete all selected template?',
  messageTemplatesRead: 'Message Template',
  Channels: 'Channels',
  Inbox: 'Inbox',
  Broadcast: 'Broadcasts',
  'Broadcast List': 'Broadcast List',
  'Scheduled Broadcast': 'Scheduled Broadcast',
  'List Broadcast': 'Broadcasts List',
  'Broadcast Schedule': 'Broadcasts Schedule',
  'Marketing Calendar': 'Marketing Calendar',
  titleCampaign: 'Campaign Title',
  createSchedulePost: 'Create Schedule Post',
  createPost: 'Create Post',
  Statistic: 'Statistic',
  Contact: 'Contact',
  Contacts: 'Contacts',
  Label: 'Label',
  Widget: 'Widget',
  Template: 'Template',
  Operators: 'Operators',
  'Operators List': 'Operators List',
  'Replies History': 'Replies History',
  'User Statistic': 'User Statistic',
  Subscriptions: 'Subscriptions',
  'Link Generator': 'Link Generator',
  Chat: 'Chat',
  'Chat Data': 'Chat Data',
  'Live Chat Widget': 'Live Chat Widget',
  'Contact List': 'Contact List',
  contactGroup: 'Customer Data',
  group: 'Group',
  Tutorial: 'Tutorial',
  'Help Center': 'Help Center',
  'Contact Us': 'Contact Us',
  'Business+': 'Business+',
  'Link Tracker': 'Link Tracker',
  'Replies Statistic': 'Replies Statistic',
  Settings: 'Settings',
  User: 'User',
  'Send Broadcast': 'Send Broadcast',
  'User List': 'User List',

  // key
  pinned: 'Pinned',
  version: 'Version',
  whatsappContacts: 'WhatsApp Contacts',
  share: 'Share',
  filter: 'Filter',
  sort: 'Sort',
  tag: 'Tag',
  text: 'Text',
  phoneNumberInput: 'Type phone number without country code',
  notifFromAll: 'Notification from All Chat',
  notifFromChannel: 'Only get notification from Active Channel',
  notifFromAssigned: 'Only get notification from Assigned Chat',
  notifUnreplied: 'Receive notification from',
  contact: 'contact',
  downloadMessages: 'Download Messages',
  freeTrialRemaining: 'Free trial remaining:',
  demoMessageSuccess: 'Yeay, your message has been sent!',
  update: 'Update',
  channel: 'Channel',
  connectInstagram: 'Connect',
  refresh: 'Refresh',
  message: 'message',
  Message: 'Message',
  List: 'List',
  Import: 'Import',
  scan: 'scan',
  Fields: 'Fields',
  field: 'field',
  value: 'value',
  setLabel: 'Set Label',
  config: 'config',
  logout: 'Logout',
  ok: 'OK',
  new: 'New',
  create: 'Create',
  add: 'Add',
  addTo: 'Add To',
  edit: 'Edit',
  cancel: 'Cancel',
  save: 'Save',
  warning: 'Warning',
  delete: 'Delete',
  deleteConfirmation: 'Are you sure you want to delete',
  disconnectedConfirmation: 'Are you sure want to disconnect this channel',
  deleteConfirmationEorder:
    'This contact list is connected to the eshop, if you delete this, you will not be able to see the related eshop results contact List',
  deleteConfirmationSurvey:
    'This contact list is connected to the survey form, if you delete this, you will not be able to see the related survey results',
  archive: 'Archive',
  unarchived: 'Unarchive',
  sendyourfirstmessage: 'Send your first message',
  archivedConfirmation: 'Are you sure you want to archive',
  unarchivedConfirmation: 'Are you sure you want to unarchive',
  deleteMessage: 'Are you sure you want to delete this data?',
  warningfailedsync: 'Failed to sync from server refresh to retry',
  warningactiveinvoice: 'You have an active Xendit invoice',
  warningpackageexpired: 'Your workspace is expired',
  warningmaxchannel: 'You reached max Channel, upgrade package or buy extra to add more Channel',
  warningmaxoperator: 'You reached max Operator, upgrade package or buy extra to add more Operator',
  warningmaxcontacts: 'You reached max Contacts, upgrade package or buy extra to add more Contacts',
  warningfailedsavedata: 'Save data failed, please check your connection',
  syncfromserver: 'Sync data from server',
  pay: 'Pay',
  search: 'Search',
  select: 'Select',
  selectall: 'Select All',
  send: 'Send',
  submit: 'Submit',
  next: 'Next',
  previous: 'Previous',
  finish: 'Finish',
  close: 'Close',
  open: 'Open',
  from: 'From',
  clear: 'Clear',
  rowsPerPageLabel: 'Records per page:',
  noDataLabel: 'No data available',
  loadingLabel: 'Loading data...',
  phoneNumber: 'Phone number',
  notFound: 'not found',
  noResults: 'No results',
  view: 'View',
  preview: 'Preview',
  tryAgain: 'Try Again',
  column: 'Column',
  source: 'Source',
  lastReply: 'Last Reply',
  favourite: 'Favourite',
  interaction: 'Interaction',
  needReply: 'Need Reply',
  notificationNeedReplyOne: 'Currently there are',
  notificationNeedReplyTwo: 'chats that need reply',
  reply: 'Reply',
  assignTo: 'Assign To',
  messageStatus: 'Message Status',
  name: 'Name',
  action: 'Action',
  continuousSync: 'Continuous Sync',
  continuousSyncInfo: 'Continuously sync contact with this sheet every 5 minutes',
  sheetName: 'Sheet Name',
  record: 'record',
  selectedOf: 'selected of',
  you: 'You',
  or: 'or',
  import: 'Import',
  link: 'Link',
  exportToCsv: 'Export to Excel',
  exportToVcf: 'Export to vCard',
  export: 'Export',
  changeSelectedData: 'Change Selected Data',
  changeLabel: 'Change Label',
  change: 'Change',
  deleteSelectedData: 'Delete Selected Data',
  year: 'Year',
  years: 'years',
  month: 'Month',
  monthsInterval: 'Months',
  months: 'months',
  day: 'day',
  days: 'days',
  min: 'min',
  mins: 'mins',
  minute: 'minute',
  minutes: 'minutes',
  hour: 'hour',
  hours: 'hours',
  second: 'second',
  seconds: 'seconds',
  week: 'week',
  never: 'Never',

  templates: {
    templates: 'Templates',
    templateName: 'Template Name',
    categories: 'Categories',
    quickReplies: 'Quick Replies',
    lastUpdated: 'Last Updated',
    actions: 'Actions',
    addMessageTemplate: 'Add Message Template',
    addCategory: 'Add Category',
    searchTemplate: 'Search Template',
  },

  // chat: 'Chat',
  chat: {
    pressEnterToChat: 'Press Enter to Search',
    totalRooms: 'Total rooms',
    newChat: 'New',
    channels: 'channels',
  },
  serverConnectionLost: 'Server Connection Lost',
  dialog: {
    invalidFile: 'Please choose a valid file and try again',
    requiredMessage: 'Message is required',
    channelRequired: 'Please select channel you want to import',
    mappingallfield: 'Please mapping field Name and Phone Number',
    failedgetdata: 'Failed to get data',
    notvalidsheet: 'Please specify a valid Spreadsheet URL',
    pleaseselectone: ' Please select contact',
    isRequired: ' is required',
    errorSendMessage: 'Cannot send message, please try again',
    errorUploadAttachment: 'Cannot send attachment, please try again',
    selectChannel: 'Please Select Channel',
    selectGroupContact: 'Please Select Customer Data',
    errorImportUser: 'Error Downloading Group User Data',
  },
  user: {
    edit: 'Edit User',
    delete: 'Delete user',
  },
  channels: {
    socialMedia: 'Social Media',
    disconnectChannel: 'Disconnect Channel',
    noDataTitle: 'Integrate Tokopedia Chat',
    noDataSub: 'Integrate your Tokopedia Chat',
    noDataTitleFacebook: 'Integrasikan Facebook Pages',
    noDataSubFacebook: 'Integrasikan Facebook Pages Anda',
    noDataTitleInstagram: 'Connect With Instagram Business Account',
    noDataSubInstagram: 'Connect your Instagram Business Account',
    channel: 'Channel',
    label: 'Label',
    color: 'Color',
    phoneNumber: 'Phone Number',
    actions: 'Actions',
    backgroundMessage: 'Active Background Message',
    infoBackgroundMessage:
      'To avoid whatsapp spam detection you could activate this feature to increase number of incoming chat',
    syncSetting: 'Sync Setting',
    syncSettingOptionOne: 'All Messages',
    syncSettingOptionTwo: 'Only From Known Contacts',
    syncContact: 'Sync Contact',
    muteNotification: 'Mute Notification',
    boldUnreplied: `Don't send read acknowledge`,
    infoStealthMode: 'Only mark chat as read when replied',
    connected: 'Connected',
    notConnected: 'Not Login',
    disconnected: 'Disconnected',
    preparing: 'Preparing',
    deviceOffline: 'Connection Timeout',
    howToUse: 'How To Connect Whatsapp',
    howToUseConnect: 'How To Use',
    disclaimer:
      '<i><span class="text-subtitle-2">*Before <span class="primary--text font-weight-bold">connecting Whatsapp account</span> to Marketa Chat, <span class="primary--text font-weight-bold">make sure</span> your account has <span class="primary--text font-weight-bold">previously join multi-device beta and then leave multi-device beta,</span> to <span class="primary--text font-weight-bold"> reduce possibility of disruption</span> from Whatsapp</span></i>',
    howToUseOne: '1. Open <span class="font-weight-bold">Whatsapp</span> on your phone',
    howToUseTwo: '2.  Click <span class="font-weight-bold">3-dots</span> menu on the top right corner',
    howToUseThree: '3. Tap on <span class="font-weight-bold">"Linked Devices"</span>',
    howToUseFour: `4. Click <span class="font-weight-bold">"Join Beta"</span> if you haven't already`,
    howToUseFive: '5. Tap <span class="font-weight-bold">"Link a device"</span>',
    howToUseSix: '6. Scan the <span class="font-weight-bold">QR Code</span> shown',
    howToUseFourBelow: '4. Scan the <span class="font-weight-bold">QR Code</span> below',
    howToUseOneConnect1: '1. Reply your customer chat in',
    howToUseOneConnect2: 'menu',
    howToUseTwoConnect1: '2. Go to',
    howToUseTwoConnect2: 'menu and select or add the target contacts',
    howToUseThreeConnect1: '3. Type your message or use template from',
    howToUseThreeConnect2: 'menu',
    howToUseFourConnect1: '4. Check the sending history for your broadcast activity in',
    howToUseFourConnect2: 'menu',

    // howToUseOne:
    //   '1. Scan QR code using your Whatsapp app. If you already scaned it, please select the scanned phone number',
    // howToUseTwo: '2. Go to <span class="font-weight-bold">Broadcast</span> menu and select or add the target contacts',
    // howToUseThree:
    //   '3. Type your message or use template from <span class="font-weight-bold">Message Template</span> menu',
    // howToUseFour:
    //   '4. Check the sending history for your broadcast activity in <span class="font-weight-bold">History</span> menu',
  },
  workspaces: {
    editHeader: 'Edit Workspace Name',
  },
  inbox: {
    autoAssignChatOnReply: 'Auto Assign Chat On Reply',
    autoAssignNewChat: 'Auto Assign New Chat',
    autoCloseChat: 'Auto Close Chat',
    uploading: 'Uploading...',
    noteMessage: 'Add to notes',
    unsendMessage: 'Delete message',
    selectMessages: 'Select messages',
    attachment: 'Attachment',
    typingPlaceholder: 'Type a message',
    newChat: 'New Chat',
    enterPhoneNumber: 'Enter Phone Number',
    or: 'or',
    quickReplies: 'Quick Replies',
    quickRepliesNote: 'You could use template to add image(s)',
    quickRepliesAction: 'Quick replies',
    selectFromExistingContact: 'Select From Existing Contact',
    lastInteractWith: 'Last interact with ',
    assignedTo: 'Assigned to ',
    exportChat: 'Export Chat',
    exportMessages: 'Export messages',
    labelMessages: 'Label messages',
    infiniteLoadingErrorMessage: 'Cannot fetch messages',
    infoPhone: 'Keep your phone connected',
    infoPhoneSub:
      'WhatsApp Web connects to your phone to sync messages. To reduce data usage, connect your phone to Wi-Fi.',
    contactEmpty: 'No contacts',
    noChatSelected: 'Start a conversation by clicking a chat room',
    chatEmpty: 'No messages',
    archiveContact: 'Archive this contact',
    deleteRoom: 'Delete this chat',
    addToContactGroup: 'Add to Customer Data',
    unarchiveContact: 'Unarchive this contact',
    addedLabelContact: "You don't have any label list yet",
    addedLabelMessage: 'All labels have been added to this message',
    shareRoom: 'Share room',
    sharedRoomExpiryTime: 'Expiry Time',
    sharedRoomType: 'Type',
    sharedRoomUrl: 'Room Link',
  },
  importContactPreview: {
    emptyFields: 'Please select column by field [name and phone number]',
  },
  history: {
    headerMarkupTable: 'Chat History',
    operators: 'Operators',
    statistics: 'Statistics',
    replies: 'Replies History',
    customerServed: 'Customer Served',
    totalReply: 'Total Replied',
    avgReply: 'Average Reply (Sec)',
    autoAssign: 'Auto Assign',
    viewAssignOnly: 'Restrict Access to Only Assigned Contact',
    channelList: 'Enabled Channel(s)',
    limitedChannel: 'Restrict Access to Only Selected Channel',
    enabledMenu: 'Enabled Menu',
    limitedMenu: 'Restrict Access to Only Selected Menu',
    preparing: 'Preparing',
    chatHistoryData: 'No Conversation Have Done',
    attachments: 'Attachments',
    attachmentsData: 'No Attachment Found',
    to: 'To',
    replyTime: 'Avg Reply Time',
    role: 'Role',
    contact: 'Contact',
    assign: 'Assign To',
    channel: 'Channel',
    duration: 'Duration',
    rating: 'Rating',
  },
  messageTemplates: {
    emptyList: `You don't have any template yet`,
    emptyTemplate1: 'Customize the message content according to your needs,',
    emptyTemplate2: 'and send it to your customers without retyping',
    open: 'Open Template',
    edit: 'Edit Template',
    delete: 'Delete Template',
    templateName: 'Template Name',
    message: 'Message',
    category: 'Category',
    lastUpdate: 'Last Update',

    // openingChat: 'Opening Chat',
    openingChat: 'Message Content',
    onReply: 'On Reply',
    fromTemplates: 'Use Templates',
  },
  broadcasts: {
    selectChannel: 'Select Channel',
    createBroadcast: 'Create Broadcast',
    channelFilter: 'Channel Filter',
    noTemplate: `Don't have the template ?`,
    uploadFormat: 'Please upload excel according to the given format',
    emptyList: `You don't have any broadcast history yet`,
    linkAlert: 'Links must start with https://',
    deleteForMe: 'Auto delete broadcast (Only from your device)',
    showBroadcastMessage: 'Show Broadcast Message',
    hero: {
      easiestWay: 'The Easiest Way',
      moreSales: 'To Add More Sales',
      isByAdding: 'Is By Adding Your Broadcast List',
      here: 'Here',
    },
    templateHero: {
      easiestWay: 'The Easiest Way',
      moreSales: 'To Add More Sales',
      isByAdding: 'Is By Adding Your Message Template',
      here: 'Here',
    },
    notesSendingBroadcasts:
      '* Please make sure your <span class="primary--text">channel is connected</span> while the <span class="primary--text">broadcast is being sent</span>',
    loginExpired: 'Login Expired, redirect to Login Page',
    sendingSpeed: 'Sending Speed',
    stepOne: 'New Broadcast - Step 1: Select/Add Message Template',
    stepTwo: 'New Broadcast - Step 2: Select/Upload List of Contacts',
    stepThree: 'New Broadcast - Step 3: Review The Broadcast Plan',
    broadcastName: 'Broadcast Title',
    broadcastList: 'Broadcast',
    broadcastHistory: 'Broadcast History',
    broadcastTime: 'Broadcast Time',
    broadcastTimeline: 'Broadcast Timeline',
    broadcastScheduled: 'Broadcast Schedule',
    broadcastFrom: 'Broadcast from my contacts',
    broadcastFromInfo: 'Download sample format for contact upload',
    broadcastFromExcel: 'Broadcast from imported excel file',
    broadcastFromExcelInfo: 'Select file to upload list of receivers',
    channel: 'Channel',
    messageTemplate: 'Message Template',
    executionType: 'Execution Type',
    immediately: 'Immediately',
    scheduled: 'Scheduled',
    date: 'Date',
    time: 'Time',
    numberContacts: 'Number of Receipients',
    executionDatetime: 'Execution Date/Time',
    totalContacts: 'Total Contacts',
    recipients: 'Recipients',
    processing: 'Processing',
    queued: 'Queued',
    sending: 'Sent',
    failed: 'Failed',
    sent: 'Sent',
    delivered: 'Delivered',
    read: 'Read',
    replied: 'Replied',
    received: 'Received',
    unsend: 'Processing',
    unknown: 'Unknown',
    progress: 'Progress',
    statistics: 'Statistic',
    totalBroadcasts: 'Total Broadcast',
    broadcastStatistic: 'Broadcast Statistic',
    linkClicked: 'Link Clicked',
    clickedAt: 'Clicked At',
    today: 'Today',
    day: 'Day',
    week: 'Week',
    month: 'Month',
    four_day: '4 Days',
    mustFilled: 'Must be filled',
    alertSelectContact: 'Please Select Minimal 1 Contact',
    stepOneSubtitle: 'Select Contact(s) for Broadcast',
    stepOneTitle: 'Channel & Contact',
    stepTwoSubtitle: 'Select Message for Broadcast',
    stepTwoTitle: 'Message Template',
    stepThreeSubtitle: 'Check Broadcast Data',
    stepThreeTitle: 'Review & Submit',
    selected: 'Selected',
    search: 'Search',
    chooseTemplateFirst: 'Choose Template First',
    custom: 'Custom',
    datetime: 'Custom',
    today08am: 'Morning at 8AM',
    today09am: 'Morning at 9AM',
    today10am: 'Morning at 10AM',
    noon01pm: 'Noon at 1PM',
    afternoon04pm: 'Afternoon at 4PM',
    tonight7pm: 'Afternoon at 7PM',
    createBroadcast: 'Create Broadcast',
    editBroadcast: 'Edit Broadcast',
    deleteBroadcast: 'Delete Broadcast',
    detailBroadcast: 'Broadcast Details',
    statisticBroadcast: 'Statistics Broadcast',
    pauseBroadcast: 'Pause Broadcast',
    resumeBroadcast: 'Resume Broadcast',
    sendPreview: 'Send Preview',
    saveDraft: 'Save As Draft',
    send: 'Send',
    customMessage: 'Custom Message',
    noLink: 'No Available Link',
    linkOff: 'Off',
    linkOn: 'On',
    getContacts: 'Getting Contacts',
    customDelay: 'Set Custom Delay',
    delayInterval: 'Delay Interval',
    seconds: 'Seconds',
    delayFrom: 'From',
    delayTo: 'To',
    restMode: 'Rest Mode',
    additionalDelay: 'For (Seconds)',
    stopAfter: 'Stop Sending After (Messages) ',
    pleaseSelect: 'Please Select',
    contactSource: 'Contact Source',
    chooseFrom: 'Choose from',
    customFrom: 'Choose from',
    otherList: 'Customer List',
    thereAre: 'There are',
    duplicatePhone: 'Duplicate Phone Number',
    unsubscribePhone: 'Unsubscribe Phone Number',
    confirmDelete: 'Are you sure want to Delete',
    confirmPause: 'Are you sure want to pause sending broadcasts',
    confirmResume: 'Are you sure want to resume sending broadcasts',
    confirm: 'Confirmation',
    notAllField: 'Not All Field',
    inOpeningChat: 'in "Opening Chat" message, available in',
    onReplyChat: 'in "On Reply" message, available in',
    contactListSelected: 'Contact List Selected',
    allChannelDisconnected: 'All channel Disconnected',
    checkChannels: 'Checking Channels Status',
    uploadNotes: 'Upload Image Only',
    deleteFailedNotCreated: 'Cannot be deleted, broadcast message is being created',
    pauseFailedNotCreated: 'Cannot be paused, broadcast message is being created',
    resumeFailedNotCreated: 'Cannot be resumed, broadcast message is being created',
    broadcastModeAggresive: 'Send Broadcast to all selected contact',
    broadcastModeSafe: 'Do not send broadcast to contact with no previous interaction',
  },
  contacts: {
    search: 'Search',
    batchActions: 'Batch actions',
    channelFilter: 'Channel filter',
    allChannels: 'All Channels',
    contacts: 'Contacts',
    tabs: {
      contacts: 'Contacts',
      labels: 'Labels',
      form: 'Form',
      campaign: 'Campaign',
    },
    headers: {
      user: 'User',
      assignTo: 'Assign To',
      email: 'Email',
      labels: 'Labels',
      campaign: 'Campaign',
      action: 'Action',
    },
    selectLabelDialog: {
      selectLabels: 'Select Labels',
      cancel: 'Cancel',
      add: 'Add',
      save: 'Save',
    },
    addContactDialog: {
      name: 'Name',
      avatarURL: 'Avatar URL',
      nickname: 'Nickname',
      phoneNumber: 'Phone Number',
      email: 'Email',
      addLabels: 'Add Labels',
      errorMessage: 'This field is required',
    },
    labels: {
      export: 'Export',
      labelConfig: 'Label Config',
      searchUser: 'Search User',
      filter: 'Filter',
      headers: {
        user: 'User',
        email: 'Email',
        message: 'Message',
        assignDate: 'Assign Date',
        label: 'Label',
      },
      pleaseSelectContactToExport: 'Please Select Contact To Export',
      configLabelDialog: {
        configLabel: 'Config Label',
        variant: 'Variant',
        channel: 'Channel',
      },
    },
    form: {
      addForm: 'Add Form',
      editForm: 'Edit Form',
      headers: {
        formName: 'Form Name',
        contentAmount: 'Content Amount',
        label: 'Label',
        createdAt: 'Created At',
        lastUpdated: 'Last Updated At',
        actions: 'Actions',
      },
    },
    statistic: {
      statisticCampaignAll: 'Statistic Campaign All',
      headers: {
        user: 'User',
        message: 'Message',
        channel: 'Channel',
        date: 'Date',
      },
    },
    placeHolder: `This is menu description text`,
    emptyList: `You don't have any contact list yet`,
    contact: 'Contact',
    additional: ' Additional Field ',
    noAdditionalField: `You don't have any additional field for this contact list.`,
    contactList: 'Contacts',
    createContactGroupList: 'Create Contact List',
    hero: {
      easiestWay: 'The Easiest Way',
      moreSales: 'To Add More Sales',
      isByAdding: 'Is By Adding Your Contact List',
      here: 'Here',
    },
    importContact: 'Import Contacts',
    importContactSub: 'Import your contacts from different sources',
    contactName: 'Contact Name',
    name: 'Name',
    phoneNumber: 'Phone Number',
    source: 'Source',
    profilePicture: 'Profile Picture',
    currentSetting: 'Current Setting',
    currentSettingName: 'Setting Name',
    attribute: 'Attribute',
    listManage: 'List Manage',
    listName: 'List Name',
    fromSystem: 'From System',
    lastModified: 'Last Modified',
    showChat: 'Show Chat',
    newLabel: 'New Label',
    color: 'Color',
    value: 'Value',
    field: 'Field',
    action: 'Action',
    config: 'Config',
    columnName: 'Column Name',
    defaultValue: 'Default Value',
    showField: 'Show Field',
    required: 'Required',
    chooseCsv: 'Choose CSV File',
    chooseExcel: 'Choose Excel File',
    chooseVcf: 'Choose vCard File',
    scanQr: 'Scan if you want to import from new number',
    hintSheet: 'Make sure your spreadsheet setting is share with anyone with this link',
    deleteSelectedContact: 'Delete Selected Contact',
    changeSelectedContact: 'Change Selected Contact',
    broadcastSelectedContact: 'Broadcast Selected Contact',
    setLabelSelectedContact: 'Set Label Selected Contact',
    deleteSelectedSetting: 'Delete Selected Setting',
    deleteSelectedLabel: 'Delete Selected Label',
    columnNameRequired: 'Column Name is Required',
    contactsNameRequired: 'Contact Name is Required',
    phoneNumberRequired: 'Phone Number is Required',
    nicknameRequired: 'Nickname is Required',
    phoneNumberNotValid: 'Phone Number is Not Valid',
    phoneNumberExist: 'Phone Number is Exist',
    answerData: 'Answer Data',
    contactType: 'Contact Type',
    nickName: 'Nickname',
    showLabel: 'Show Label',
  },
  subscriptions: {
    subscriptionPlan: 'Subscription Plan',
    monthly: 'Monthly',
    annually: 'Annually',
    package: 'Package',
    coupon: 'Coupon',
    action: 'Action',
    extra: 'Extra',
    maxOperators: 'Max Operators',
    maxChannels: 'Max Channels',
    invoiceHistory: 'Invoice History',
    currentPlan: 'Current Plan',
    ExpiredAt: 'Expired at',
    CreatedAt: 'Created At',
    TotalPrice: 'Total Price',
    PayWith: 'Pay With',
    yourCurrentPersonalWorkspacePlan: 'Your Current Personal Workspace Plan',
    detail:
      'All plans include 40+ advanced tools and features to boost your product. Choose the best plan to fit your needs.',
  },
  widget: {
    hero: {
      easiestWay: 'The Easiest Way',
      moreSales: 'To Add More Sales',
      isByAdding: 'Is By Adding Your Widget',
      here: 'Here',
    },
    showWidget: 'SHOW WIDGET',
    lastModified: 'LAST MODIFIED',
  },
  LinkGenerator: {
    //     "create a chat link for your customers
    // make it easier for customers to contact you by creating a direct link with a message addressed to you"
    hero: {
      easiestWay: 'create a chat link for your customers',
      moreSales: ', make it easier',
      isByAdding: 'for customers to contact you by creating a direct link',
      here: 'with a message addressed to you',
    },
    trackerHero: {
      easiestWay: 'See how many visitors are on your link.',
      moreSales: 'create and see how effectively ',
      isByAdding: "you attract customer's ",
      here: 'attention',
    },
    createNewLink: 'Create New Link',
    slugIsAvailability: 'Slug is available',
    slugIsNotAvailability: 'Slug is not available',
    copySuccess: 'Copied Successfully',
    copyUnsupported: 'Copy is not supported in this device/browser.',
    settings: 'Settings',
    alertSlug: 'Please Check slug Availability',
    alertChannel: 'Please Select the Channel',
    confirmDeleteLink: 'Do you want delete this Link Generate?',
    tutorialSlugOne: 'Slug link is used to differentiate directed link from other user',
    tutorialSlugTwo: 'You can specify the message that the user will sent to You',
    tutorialSlugThree: `You can determine how many Operators that will received messages from the link that's clicked by the user`,
    tutorialSlugFour: 'You have Google Tag ? Paste here to track your Google Tag Manager',
    tutorialSlugFive:
      'You can also set loading when user clicked the link that you made to maximize Google Tag Manager',
  },
  EOrdering: {
    chatBotActive: 'Active',
    AddPromo: 'Tambah Promo',
    addNewFaq: 'Add FAQ',
    serveTo: 'Select the user who serve',
    selectShop: 'Select Shop',
    greatingMsg: 'Greeting Message',
    product: 'Product',
    openChatbotSetting: 'Chat Bot',
    placeHolder: `This is menu description text`,
    chatbotSetting: 'Chatbot Settings',
    keyword: { msg: 'Keyword Message', detail: 'Keyword Message' },
    greeting: { msg: 'Greeting Message', detail: 'Greeting Message To Customer' },
    talktoCS: {
      msg: 'Talk to CS Message',
      detail: 'e.g. : Thank you for contacting us. Our team will serve you shortly (queue number: 2)',
    },
    choosenShop: { msg: 'Choosen E-Shop', detail: 'Choose E-Shop' },
    qna: { msg: 'Q & A Message', detail: 'Q & A Message To Customer' },
    closingMsg: {
      msg: 'Closing Message (after 5 minutes)',
      detail: 'e.g. : Thank you for contacting Lavenir Studio, good luck.',
    },
    faqMsg: {
      qs: 'Input Question Message',
      as: 'Input Answer Message',
    },
    additionalMenu: 'Additional Menu',
    shopAddress: 'Shop Address',
    canceledOrder: 'Canceled',
    bulkUpdateStatus: 'Update All Status',
    cancel: 'Cancel',
    fullDelete: 'Full Delete',
    roomNo: 'Room Number',
    formValidationMessage: 'All Required Data Must be Filled !',
    populatedSuccess: 'Data Populated Successfully !',
    shop: 'Shop',
    customSheet: 'Custom Sheet',
    phoneNumber: 'Phone Number',
    shopType: 'Shop Type',
    includeTax: 'Include Tax',
    shopTax: 'Shop Tax',
    tax: 'Tax Pecentage :eg 10',
    taxlabel: 'Tax',
    discountlabel: 'Discount',
    calculateDeliveryPricing: 'Calculate Delivery Pricing(ID)',
    calculateDeliveryPricingInter: 'Calculate Delivery Pricing(ID to International)',
    availableCourier: 'Available Courier',
    province: 'Province',
    city: 'City',
    subdistrict: 'Subdistrict',
    save: 'Save',
    addressDetail: 'Full Address',
    customGSheet: 'Custom Google Sheet',
    enableCash: 'Cash',
    enableXendit: 'Xendit',
    enablePaypal: 'Paypal',
    paymentMethod: 'Payment Method',
    addShop: 'Add Shop',
    deleteConfirm: 'Are you sure ?',
    primaryColor: 'Shop Primary Color',
    secondaryColor: 'Shop Secondary Color',
    tertiaryColor: 'Shop TertiaryColor',
    shopLogo: 'Shop Logo',
    gmaps: 'Select On Map',
    gmapsCurrentLocInfo:
      "If your location isn't accurate, kindly check your connection and make sure you are not using any Proxy or VPN",
    gmapsCurrentLoc: 'Use my current location',
    calculatePricingInfo: 'Shop Only Located in Indonesia',

    createShop: 'Create Shop',
    deleteShop: 'Delete Shop',
    editShop: 'Edit Shop',
    updateProduct: 'Update Product',
    addLink: 'Add Link',
    viewContact: 'View Contact',
    shopName: 'Shop Name',
    shopNameLabel: 'input Shop Name',
    generatedLink: 'Generated Link',
    noLink: 'No Link Yet',
    copyLink: 'Copy Link',
    editLink: 'Edit Link',
    deleteLink: 'Delete Link',
    broadcastLink: 'Broadcast Link',
    driveLink: 'Google Sheet Link',
    toDrive: 'Go to Google Sheet',
    address: 'Address',
    totalRevenue: 'Total Income',
    totalIncoming: 'Total Incoming',
    totalPending: 'Total Pending',
    completedOrder: 'Completed Order',
    transaction: 'Transaction',
    pending: 'Pending',
    onProgress: 'On Progress',
    paid: 'Paid',
    done: 'Done',
    orderType: 'ORDER TYPE',
    orderTime: 'ORDER TIME',
    idOrder: 'ID ORDER',
    name: 'NAME',
    detailTable: 'Detail Table',
    price: 'PRICE',
    totalPrice: 'TOTAL PRICE',

    note: 'Note',
    subTotal: 'Sub Total',
    orderTypeDetail: 'Order Type',
    deliveryPrice: 'Delivery Price',
    courier: 'Courier',
    source: 'Source',
    total: 'Total',
    coupon: 'Coupon',
    date: 'Date',
    time: 'Time',
    cover: 'Cover',
    idTable: 'Table Id',

    hero: {
      easiestWay: 'Create a unique shop.',
      moreSales: 'Create your shop and send it to all your customers',
      isByAdding: 'They can directly order through the shop you ',
      here: 'created',
    },
    createNewLink: 'Create New Link',
    slugIsAvailability: 'slug is available',
    slugIsNotAvailability: 'slug is not available',
    copySuccess: 'Copied Successfully',
    copyUnsupported: 'Copy is not supported in this device/browser.',
    settings: 'Settings',
    alertSlug: 'Please Check slug Availability',
    alertChannel: 'Please Select the Channel',
    confirmDeleteLink: 'Do you want delete this Link Generate?',
    tutorialAddress: 'Write Full Address clearly',
    tutorialName: 'Create a unique shop name',
    tutorialSlugTwo: 'You can specify the message that the user will sent to You',
    tutorialSlugThree: `You can determine how many Operators that will received messages from the link that's clicked by the user`,
    tutorialSlugFour: 'You have Google Tag ? Paste here to track your Google Tag Manager',
    tutorialSlugFive:
      'You can also set loading when user clicked the link that you made to maximize Google Tag Manager',
  },
  OperatorsTranslate: {
    confirmWant: 'Do you want delete',
    meaningAction: 'as your Operator?',
  },
  coupons: {
    coupon: 'Coupon',
  },
  landing: {
    hero: {
      sendManyMsg: 'Send Many WhatsApp Messages With Only',
      oneClick: 'One Click',
      increaseSales: 'Increase Your Sales Up to 300% by directly interacting with your customer using WhatsApp',
      tryNow: 'Try Now',
      sendMessage: 'Send Message',
    },
    howItWork: {
      title: 'How It Work',
      headline: 'How Does It Work ?',
      stepOne: {
        title: '1. Connect Your WhatsApp Number',
        detail: 'Scan the given QR Code that we have provided, after that, You will be connected to our server.',
      },
      stepTwo: {
        title: '2. Select The Receivers Of Your Broadcast',
        detail: 'Please select the contacts that You want to receive the message.',
      },
      stepThree: {
        title: '3. Automated Message Sending Started',
        detail: 'If finished, let our system do the work. You can sit tight, and drink some coffee.',
      },
    },
    features: {
      title: 'Features',
      headline: 'What are the advanced features in Marketa Chat ?',
      description:
        'Marketa Chat is a web based tool for you as an online entrepreneur that can help increase Your sales become far more effective & efficient',
      featureOne: {
        title: 'Send Unlimited Messages',
        body: 'Send your message to hundred or thousand customers every day without limit.',
      },
      featureTwo: {
        title: 'Multiple User WhatsApp Web',
        body: 'One access for your WhatsApp web with multiple users to facilitate serving the customer efficiently,',
      },
      featureThree: {
        title: 'Fully Customizable Message',
        body: `Make Your messages dynamic by adding the customer's name and emoji.`,
      },
      featureFour: {
        title: 'Attach Image on Broadcast Message',
        body: 'Make Your messages more entrancing by attaching pictures.',
      },
      featureFive: {
        title: 'Custom Broadcast Scheduling',
        body: `Planning your broadcast with simple steps. Broadcast your message whenever you need.`,
      },
      featureSix: {
        title: 'Sync Contacts from WhatsApp',
        body: 'With just one click, you can import or export all your contacts from WhatsApp.',
      },
      featureSeven: {
        title: 'Label your customer',
        body: `Group your customer based on a label you’ve created.`,
      },
      featureEight: {
        title: 'Import & Export Contacts',
        body: 'Manually or automatically input contacts with only one click.',
      },
      featureNine: {
        title: 'Track Your Team Performance',
        body: `Track Your team's performance and reply speed daily, weekly, and monthly.`,
      },
      featureTen: {
        title: 'Message Labeling',
        body: 'Label chats for Everytime You process Your order',
      },
    },
    demo: {
      title: 'Demo',
      headline: 'Marketa Chat Demo',
      subTitle: 'Scan by WA and send your first message',
      subTitlesuccess: 'Your first message is send successfully, try our app to send broadcast and more',
    },
    testimonials: {
      title: 'Testimonials',
      headline: 'Testimonial',
      testimonialOneDesc:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      testimonialTwoDesc:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    },
    demoLanding: {
      stepOne: {
        android: { open: 'Open', desc: 'on Your phone', moreOpt: `Tap 'More Options'` },
        ios: {
          open: 'Open',
          desc: 'on Your Phone',
          moreOpt: 'Go to WhatsApp',
          moreOpt2: 'Setting',
        },
      },
      stepTwo: {
        android: {
          tap: 'Tap',
          linkDevice: 'LINK A DEVICE',
          desc: 'Follow the on-screen instructions if Your device has biometric authentication. If You don’t have biometric authentication enabled, You’ll be prompted to enter the pin You use to unlock Your phone.',
        },
        ios: {
          desc: `On iOS 14 and above, use Touch ID or Face ID to unlock. If you don’t have biometric authentication enabled, you’ll be prompted to enter the pin you use to unlock your phone.`,
        },
      },
      stepThree: {
        android: {
          select: 'Select',
          keepSigned: 'Keep Me Signed In',
          desc: {
            head: 'Select the checkbox next to',
            body: 'on the QR screen on your computer or Portal to stay logged in on this device.',
          },
        },
      },
      stepFour: {
        android: {
          scan: 'Scan',
          qrCode: 'QR Code',
          below: 'below',
        },
      },
      note: {
        headline: 'NOTE:',
        body: `"If You can't scan the QR Code, Make sure that You're using WhatsApp's multi device beta, You can see the instruction to join the multi device beta`,
        link: `here`,
      },
    },
    faq: {
      title: 'FAQ',
      subTitle: 'Let us help answer the most common questions.',
      faqOne: {
        question: 'What is Marketa Chat ?',
        answer:
          'Marketa Chat is part of Marketa SuperApp for Multi Chat and Broadcast services. Marketa Chat brings all your customers closer and faster to your business easily and efficiently.',
      },
      faqTwo: {
        question: 'What is Marketa Chat App ?',
        answer: `Marketa SuperApp is a platform to manage all Digital Marketing activities on multi-channels such as Google ads, Facebook ads, Instagram, Whatsapp, and Tiktok. Marketa is here to make it easier for business people to develop their business in the digital marketing and customer service aspects.`,
      },
      faqThree: {
        question: 'Why I Need Marketa Chat ?',
        answer: `In Marketa Chat you can do things that you can't do in WA Bisnis. Services such as Unlimited Broadcast, Smart Replay (Chat Bot), User Statistics, and Broadcast as well as many other features are integrated into one tool. With Marketa Chat you will have access to features and insights that can help your business grow faster.`,
      },
      faqFour: {
        question: 'Does Marketa Chat Need to be Installed on My Gadget ?',
        answer: `No, Marketa is a web-based software/tool that you only need to find in a search engine like Google, so you don't need to install anything on your device.`,
      },
      faqFive: {
        question: `I'm just an MSME owner and a little tech-savvy, can I operate Marketa Chat ?`,
        answer: `CAN! You can use Marketa Chat as easily as using your personal WA.`,
      },
      faqSix: {
        question: `
        Is Marketa Chat paid or free ?`,
        answer: `You have a great opportunity to try our tool for FREE to feel the benefits, then you can choose the price that suits your business needs.`,
      },

      // faqSeven: {
      //   question: 'Is there a money back warranty / refund ?',
      //   answer: `NO THERE ISN'T`,
      // },
      // faqEight: {
      //   question: 'If my number gets blocked, Can I get a refund ?',
      //   answer: `There are no third-party tool that's 100% safe from being blocked by WhatsApp. Marketa Chat has over 1.000 daily active users. If you get blocked by WhatsApp, You cannot Refund.`,
      // },
    },
  },
  eform: {
    addTo: 'Add to',
    copy: 'Copy E-form',
    add: 'Starting a new form',
    contact: 'Contact',
    broadcast: 'Broadcast',
    action: 'Action',
    notFound: 'No form yet',
    answerCount: 'Answer Count',
    selectedFields: 'Selected Fields',
    addToSurvey: 'Show On Chat Menu',
    addToQuickReplay: 'Show On Quick Replay Menu',
    createdAt: 'Created At',
    updatedAt: 'Updated At',
    notFoundAction: 'Create a custom form for your business forms, opinions, surveys, or ratings?',
    notFoundAction2: 'Make all that easy, create and share with all your customers',
    title: 'Form Title',
    titlePlaceholder: 'Untitled form',
    titleEmpty: 'Title is required.',
    description: 'Form Description',
    descriptionPlaceholder: 'Form description',
    discard: 'Discard this form?',
    notFoundById: 'E-form not found',
    copyUrlSurvey: 'Copy E-form Link',
    broadcastForm: 'Broadcast E-Form',
    viewContact: 'View Contact',
    openStatistic: 'Open Statistic',
    editEform: 'Edit E-form',
    deleteEform: 'Delete E-form',
    openSurvey: 'Open E-Form',
    surveyAnalytics: 'Survey Analytics',
    savePdfSurvey: 'Save PDF E-form',
    deleteEformConfirm: 'Are you sure Delete this E-form? ',
    deleteEformConfirm2: 'Full Delete for deleting with the connected Contact List ',
  },
  label: {
    emptyList: `You don't have any label yet`,
    emptyLabel1: 'Create label markers to make it easier to',
    emptyLabel2: 'sort message content, selected room, even order status',
  },
  campaignTracker: {
    description: 'Create custom links, and make it easier for your team to handle customers.',
  },

  // campaignManager: {},
  waLogin: {
    description: 'Login by Wa description',
  },
}
