/* eslint-disable */
export default function () {
  return {
    broadcastListData: [],
    broadcastScheduledData: [],
    broadcastStatistic: {
      failed: 0,
      broadcast: 0,
      delivered_count: 0,
      read: 0,
      received: 0,
      replied: 0,
      sent: 0,
      chart: {},
    },
    broadcastContacts: { Whatsapp: [] },
    broadcastContactsList: [],
    broadcastExtraField: { global: [], Whatsapp: [] },

    // get channel
    channel: null,
    valueChannel: {
      label: 'All Channels',
      value: {
        all_sender: true,
        phone_number: null,
        color: '#3fc19f',
      },
    },
    filterContact: null,
    valueContact: null,
  }
}
