import axios from 'axios'
import store from '../index'

// axios limit
const MAX_REQUESTS_COUNT = 15
const INTERVAL_MS = 10
let PENDING_REQUESTS = 0

// create new axios instance
const conn = axios.create({})
conn.defaults.timeout = 20000

/**
 * Axios Request Interceptor
 */
conn.interceptors.request.use(function (config) {
  return new Promise((resolve, reject) => {
    const interval = setInterval(() => {
      if (PENDING_REQUESTS < MAX_REQUESTS_COUNT) {
        PENDING_REQUESTS++
        clearInterval(interval)
        resolve(config)
      }
    }, INTERVAL_MS)
  })
})

/**
 * Axios Response Interceptor
 */
conn.interceptors.response.use(
  function (response) {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1)

    return Promise.resolve(response)
  },
  function (error) {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1)

    return Promise.reject(error)
  },
)

export async function fetchChartStatistic({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(
    /['"]+/g,
    '',
  )}superwa/couchdb/history/get-operator-statistic`

  return conn
    .post(endpoint, {
      sub_id: obj.sub_id,
      end_date: obj.end_date,
      start_date: obj.start_date,
      email: obj.email,
    })
    .then(async response => {
      if (response.data.status) {
        commit('setChartStatistic', response.data.data)
        commit('setStatAgregate', response.data.stat)

        return {
          status: true,
          errorMessage: '',
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      // to do check auth
      return {
        status: false,
        errorMessage: error,
      }
    })
}

export async function getSubdistrictRajaOngkir({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '')}superwa/couchdb/eordering/get-subdistrict`

  return conn
    .post(endpoint, obj)
    .then(async response => {
      if (response.data.status) {
        // commit('setSubDistrict', response.data)

        return response.data.data
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      // to do check auth
      return {
        status: false,
        errorMessage: error,
      }
    })
}

export async function editGenerateLink({ commit, state }, obj) {
  const endpoint = `${process.env.DB_API_URL.replace(/['"]+/g, '')}superwa/couchdb/link/update-shop-data`

  return conn
    .post(endpoint, {
      sub_id: obj.sub_id,
      link_id: obj.link_id,
      shop_data: {
        name: obj.shop_data.name,
        alamat: obj.shop_data.alamat,
        shop_open: obj.shop_data.shop_open,
        shop_banner: obj.shop_data.shop_banner,
        currency: obj.shop_data.currency,
        currency_symbol: obj.shop_data.currency_symbol,
        menu: obj.shop_data.menu,
        open: obj.shop_data.open,
        close: obj.shop_data.close,
      },
    })
    .then(async response => {
      if (response.data.status) {
        return {
          status: true,
          errorMessage: '',
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      // to do check auth
      return {
        status: false,
        errorMessage: error,
      }
    })
}

export async function getOrdering({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '')}superwa/couchdb/eordering/get-list-order`
  const { token } = store.getters['auth/getUser']
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  }

  return conn
    .get(endpoint, config, obj)
    .then(async response => {
      if (response.data.status) {
        commit('setShopList', response.data.data.rows)

        return {
          status: true,
          errorMessage: '',
          data: response.data.data.rows,
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      // to do check auth
      return {
        status: false,
        errorMessage: error,
      }
    })
}

export async function getContactListCheck({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(
    /['"]+/g,
    '',
  )}superwa/couchdb/contact/get-list-contact-data`
  const { token } = store.getters['auth/getUser']
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  }

  return conn
    .post(endpoint, obj, config)
    .then(async response => {
      return response.data
    })
    .catch(error => {
      // to do check auth
      return {
        status: false,
        errorMessage: error,
      }
    })
}

export async function createOrdering({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '')}superwa/couchdb/eordering/create-order`
  const { token } = store.getters['auth/getUser']
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  }

  return conn
    .post(endpoint, obj, config)
    .then(async response => {
      if (response.data.status) {
        return {
          status: true,
          errorMessage: '',
          data: response.data,
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      // to do check auth
      return {
        status: false,
        errorMessage: error,
      }
    })
}

export async function editOrdering({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '')}superwa/couchdb/eordering/edit-order`
  const { token } = store.getters['auth/getUser']
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  }

  return conn
    .put(endpoint, obj, config)
    .then(async response => {
      if (response.data.status) {
        return {
          status: true,
          errorMessage: '',
          data: response.data.data,
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      // to do check auth
      return {
        status: false,
        errorMessage: error,
      }
    })
}

export async function deleteOrdering({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '')}superwa/couchdb/eordering/delete-order`
  const { token } = store.getters['auth/getUser']
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
    data: obj,
  }

  return conn
    .delete(endpoint, config)
    .then(async response => {
      if (response.data.status) {
        return {
          status: true,
          errorMessage: '',
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      // to do check auth
      return {
        status: false,
        errorMessage: error,
      }
    })
}
export async function deleteOrderingandContact({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(
    /['"]+/g,
    '',
  )}superwa/couchdb/eordering/delete-order-and-contact-list`
  const { token } = store.getters['auth/getUser']
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
    data: obj,
  }

  return conn
    .delete(endpoint, config)
    .then(async response => {
      if (response.data.status) {
        return {
          status: true,
          errorMessage: '',
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      // to do check auth
      return {
        status: false,
        errorMessage: error,
      }
    })
}

export async function fetchExcelProducts({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '')}superwa/couchdb/eordering/insert-excel`
  const { token } = store.getters['auth/getUser']
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  }

  return conn
    .post(endpoint, obj, config)
    .then(async response => {
      if (response.data.status) {
        return {
          status: true,
          data: response.data,
          errorMessage: '',
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      // to do check auth
      return {
        status: false,
        errorMessage: error,
      }
    })
}

export async function fetchSheetProducts({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '')}superwa/couchdb/eordering/copy-sheet`
  const { token } = store.getters['auth/getUser']
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  }

  return conn
    .post(endpoint, obj, config)
    .then(async response => {
      return response.data
    })
    .catch(error => {
      // to do check auth
      return {
        status: false,
        errorMessage: error,
      }
    })
}

export async function getAirtableProducts({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '')}superwa/couchdb/link/get-airtable-records`

  return conn
    .get(endpoint, obj)
    .then(async response => {
      if (response.data.status) {
        return {
          status: true,
          errorMessage: '',
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      // to do check auth
      return {
        status: false,
        errorMessage: error,
      }
    })
}

export async function syncExcelProducts({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '')}superwa/couchdb/eordering/update-excel`
  const { token } = store.getters['auth/getUser']
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  }

  return conn
    .put(endpoint, obj, config)
    .then(async response => {
      if (response.data.status) {
        return {
          status: true,
          errorMessage: '',
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      // to do check auth
      return {
        status: false,
        errorMessage: error,
      }
    })
}

export async function getSlugEordering({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '')}superwa/couchdb/link/get-shop-link`

  // const { token } = store.getters['auth/getUser']
  // const config = {
  //   headers: {
  //     authorization: `Bearer ${token}`,
  //   },
  // }

  return conn
    .post(endpoint, obj)
    .then(async response => {
      if (response.data.status) {
        return {
          status: true,
          errorMessage: '',
          data: response.data,
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      // to do check auth
      return {
        status: false,
        errorMessage: error,
      }
    })
}

export async function getShopDetail({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '')}superwa/couchdb/link/detail-shop`

  // const { token } = store.getters['auth/getUser']
  // const config = {
  //   headers: {
  //     authorization: `Bearer ${token}`,
  //   },
  // }

  return conn
    .post(endpoint, obj)
    .then(async response => {
      if (response.data.status) {
        return response.data
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      // to do check auth
      return {
        status: false,
        errorMessage: error,
      }
    })
}

export async function getSlugEorderingDetailItem({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '')}superwa/couchdb/link/get-shop-link/${
    obj.params
  }`

  // const { token } = store.getters['auth/getUser']
  // const config = {
  //   headers: {
  //     authorization: `Bearer ${token}`,
  //   },
  // }

  return conn
    .post(endpoint, obj)
    .then(async response => {
      if (response.data.status) {
        return {
          status: true,
          errorMessage: '',
          data: response.data,
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      // to do check auth
      return {
        status: false,
        errorMessage: error,
      }
    })
}

export async function checkSlugEordering({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '')}superwa/couchdb/link/check-slug-shop`
  const { token } = store.getters['auth/getUser']
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  }

  return conn
    .post(endpoint, obj, config)
    .then(async response => {
      if (response.data.status) {
        return response.data
      }

      return {
        status: false,
        errorMessage: response.data.message,
        data: false,
      }
    })
    .catch(error => {
      // to do check auth
      return {
        status: false,
        errorMessage: error,
        data: false,
      }
    })
}

export async function createSlugShop({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '')}superwa/couchdb/link/generate-shop-link`
  const { token } = store.getters['auth/getUser']
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  }

  return conn
    .post(endpoint, obj, config)
    .then(async response => {
      if (response.data.status) {
        return {
          status: true,
          errorMessage: '',
          data: response.data,
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      // to do check auth
      return {
        status: false,
        errorMessage: error,
      }
    })
}

export async function updateSlugEordering({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '')}superwa/couchdb/link/update-shop-link`
  const { token } = store.getters['auth/getUser']
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  }

  return conn
    .post(endpoint, obj, config)
    .then(async response => {
      if (response.data.status) {
        return {
          status: true,
          errorMessage: '',
          data: response.data,
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      // to do check auth
      return {
        status: false,
        errorMessage: error,
      }
    })
}

export async function deleteSlugEordering({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '')}superwa/couchdb/link/delete-shop-link`
  const { token } = store.getters['auth/getUser']
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  }

  return conn
    .post(endpoint, obj, config)
    .then(async response => {
      if (response.data.status) {
        return {
          status: true,
          errorMessage: '',
          data: response.data,
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      // to do check auth
      return {
        status: false,
        errorMessage: error,
      }
    })
}

export async function createOrderShop({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '')}superwa/couchdb/eordering/create-order-shop`
  const { token } = store.getters['auth/getUser']
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  }

  return conn
    .post(endpoint, obj, config)
    .then(async response => {
      if (response.data.status) {
        return {
          status: true,
          errorMessage: '',
          data: response.data,
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      // to do check auth
      return {
        status: false,
        errorMessage: error,
      }
    })
}

export async function editOrderShop({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(
    /['"]+/g,
    '',
  )}superwa/couchdb/eordering/edit-status-order-shop`

  const { token } = store.getters['auth/getUser']
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  }

  return conn
    .post(endpoint, obj, config)
    .then(async response => {
      if (response.data.status) {
        return {
          status: true,
          errorMessage: '',
          data: response.data,
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      // to do check auth
      return {
        status: false,
        errorMessage: error,
      }
    })
}

export async function getProvinceRajaOngkir({ commit, state }) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '')}superwa/couchdb/eordering/get-province`

  return conn
    .get(endpoint)
    .then(async response => {
      if (response.data.status) {
        commit('setProvince', response.data)

        return {
          status: true,
          errorMessage: '',
          data: response.data,
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      // to do check auth
      return {
        status: false,
        errorMessage: error,
      }
    })
}

export async function getAvailableLocalCourier({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(
    /['"]+/g,
    '',
  )}superwa/couchdb/eordering/get-domestic-courier`

  return conn
    .get(endpoint)
    .then(async response => {
      if (response.data.status) {
        commit('setLocalCouriers', response.data.data)

        return response.data
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      // to do check auth
      return {
        status: false,
        errorMessage: error,
      }
    })
}
export async function getAvailableInterCourier({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(
    /['"]+/g,
    '',
  )}superwa/couchdb/eordering/get-international-courier`

  return conn
    .get(endpoint)
    .then(async response => {
      if (response.data.status) {
        commit('setInterCouriers', response.data.data)

        return response.data
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      // to do check auth
      return {
        status: false,
        errorMessage: error,
      }
    })
}

export async function getCityRajaOngkir({ commit, state }) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '')}superwa/couchdb/eordering/get-city`

  return conn
    .get(endpoint)
    .then(async response => {
      if (response.data.status) {
        commit('setCity', response.data)

        return {
          status: true,
          errorMessage: '',
          data: response.data,
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      // to do check auth
      return {
        status: false,
        errorMessage: error,
      }
    })
}

export async function getCostRajaOngkir({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '')}superwa/couchdb/eordering/get-cost`

  return conn
    .post(endpoint, obj)
    .then(async response => {
      if (response.data.status) {
        commit('setCost', response.data)

        return {
          status: true,
          errorMessage: '',
          data: response.data,
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      // to do check auth
      return {
        status: false,
        errorMessage: error,
      }
    })
}

export async function bulkUpdateStatus({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(
    /['"]+/g,
    '',
  )}superwa/couchdb/eordering/edit-status-order-shop-bulk`
  const { token } = store.getters['auth/getUser']
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  }

  return conn
    .post(endpoint, obj, config)
    .then(async response => {
      if (response.data.status) {
        return response.data
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      // to do check auth
      return error
    })
}

export async function getSheetData({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '')}superwa/couchdb/eordering/get-sheet-data`
  const { token } = store.getters['auth/getUser']
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  }

  return conn
    .post(endpoint, obj, config)
    .then(async response => {
      if (response.data.status) {
        commit('setSheetData', response.data.data)

        return response.data.data
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      // to do check auth
      return error
    })
}

export async function addSheet({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '')}superwa/couchdb/eordering/add-excel`
  const { token } = store.getters['auth/getUser']
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  }

  return conn
    .post(endpoint, obj, config)
    .then(async response => {
      return response.data
    })
    .catch(error => {
      // to do check auth
      return error
    })
}

export async function editSheet({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '')}superwa/couchdb/eordering/edit-excel`
  const { token } = store.getters['auth/getUser']
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  }

  return conn
    .post(endpoint, obj, config)
    .then(async response => {
      return response.data
    })
    .catch(error => {
      // to do check auth
      return error
    })
}

export async function deleteSheet({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '')}superwa/couchdb/eordering/delete-excel`
  const { token } = store.getters['auth/getUser']
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  }

  return conn
    .post(endpoint, obj, config)
    .then(async response => {
      return response.data
    })
    .catch(error => {
      // to do check auth
      return error
    })
}
export async function getChatbotList({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '')}superwa/couchdb/eordering/get-list-chatbot`
  const { token } = store.getters['auth/getUser']
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  }

  return conn
    .post(endpoint, obj, config)
    .then(async response => {
      return response.data.data
    })
    .catch(error => {
      // to do check auth
      return error
    })
}
export async function updateChatbot({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '')}superwa/couchdb/eordering/update-chatbot`
  const { token } = store.getters['auth/getUser']
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  }

  return conn
    .post(endpoint, obj, config)
    .then(async response => {
      return response.data
    })
    .catch(error => {
      // to do check auth
      return error
    })
}
export async function deleteChatbot({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '')}superwa/couchdb/eordering/delete-chatbot`
  const { token } = store.getters['auth/getUser']
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  }

  return conn
    .post(endpoint, obj, config)
    .then(async response => {
      return response.data
    })
    .catch(error => {
      // to do check auth
      return error
    })
}
export async function registerChatbot({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_WA_SERVICES_URL.replace(/['"]+/g, '')}chatbot/add`
  const { token } = store.getters['auth/getUser']
  const config = {
    headers: {
      authorization: `Bearer ${obj.token}`,
    },
  }

  return conn
    .post(endpoint, obj, config)
    .then(async response => {
      return response.data
    })
    .catch(error => {
      // to do check auth
      return error
    })
}

export async function getChatbot({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '')}superwa/couchdb/eordering/get-chatbot`
  const { token } = store.getters['auth/getUser']
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  }

  return conn
    .post(endpoint, obj, config)
    .then(async response => {
      return response.data
    })
    .catch(error => {
      // to do check auth
      return error
    })
}
