/* eslint-disable */

export function setBroadcastListData(state, obj) {
  state.broadcastListData = obj
}

export function setBroadcastStatistic(state, obj) {
  state.broadcastStatistic = obj
}

export function setBroadcastContacts(state, obj) {
  state.broadcastContacts = obj.contacts
  state.broadcastContactsList = obj.contacts_list
  state.broadcastExtraField = obj.extra_field
}

// set channel
export function setChannel(state, obj) {
  state.channel = obj
}

// set contact
export function setContact(state, obj) {
  state.filterContact = obj
}

// set channel
export function setValueChannel(state, obj) {
  state.valueChannel = obj
}

// set contact
export function setValueContact(state, obj) {
  state.valueContact = obj
}

export function setScreen(state, data) {
  state.screen.height = data.height
  state.screen.width = data.width
}
