/* eslint-disable import/prefer-default-export */
export function getListInvoice(state) {
  return state.listInvoice
}

export function getUserInfo(state) {
  return state.userInfo
}

export function getSubscriptionPlan(state) {
  return state.subscriptionPlans
}

export function getActiveInvoice(state) {
  return state.activeInvoice
}

export function getSubscriptionInfo(state) {
  return state.subscriptionInfo
}

export function getCurrentLang(state) {
  return state.currentLang
}
