<template>
  <layout-content-vertical-nav :nav-menu-items="navMenuItems">
    <v-dialog
      v-model="dialogSettingInbox"
      width="600"
    >
      <v-card>
        <v-card-text>
          <v-switch
            v-model="boldUnreplied"
            :disabled="boldUnrepliedSetting === null"
            class="mb-n4  v-input--reverse"
            @change="changeValueBold"
          >
            <template v-slot:label>
              {{ $t('channels.boldUnreplied') }}
              <v-progress-circular
                v-if="switchBold"
                :indeterminate="switchBold"
                :value="0"
                size="24"
                class="ml-2 "
              ></v-progress-circular>
            </template>
          </v-switch>
          <v-switch
            v-model="autoAssignChatOnReply"
            class="mb-n4 v-input--reverse"
            @change="changeAutoAssignChatOnReply"
          >
            <template v-slot:label>
              {{ $t('inbox.autoAssignChatOnReply') }}
              <v-progress-circular
                v-if="switchAutoAssignChatOnReply"
                :indeterminate="switchAutoAssignChatOnReply"
                :value="0"
                size="24"
                class="ml-2"
              ></v-progress-circular>
            </template>
          </v-switch>
          <v-switch
            v-if="user.package == 'business' || user.package == 'business_plus'"
            v-model="autoAssignNewChat"
            class="mb-n4 v-input--reverse"
            @change="changeAutoAssignNewChat"
          >
            <template v-slot:label>
              {{ $t('inbox.autoAssignNewChat') }}
              <v-progress-circular
                v-if="switchAutoAssignNewChat"
                :indeterminate="switchAutoAssignNewChat"
                :value="0"
                size="24"
                class="ml-2"
              ></v-progress-circular>
            </template>
          </v-switch>
          <v-select
            v-model="notifAllOrAssigned"
            outlined
            :items="dataNotifAllOrAssigned"
            item-text="name"
            item-value="data"
            :label="$t('notifUnreplied')"
            return-object
            z-index="5"
            hide-details
            :class="{ 'light-bg mt-3': isDark, 'dark-bg mt-3': !isDark }"
            class="mt-4"
            @change="changeNotifAllOrAssigned"
          ></v-select>
          <!-- <v-switch
            v-model="notifAllOrAssigned"
            class="mb-n4 v-input--reverse"
            @change="changeNotifAllOrAssigned"
          >
            <template v-slot:label>
              {{ notifAllOrAssigned ? $t('notifFromAll') : $t('notifFromAssigned') }}
              <v-progress-circular
                v-if="switchNotifAllOrAssigned"
                :indeterminate="switchNotifAllOrAssigned"
                :value="0"
                size="24"
                class="ml-2"
              ></v-progress-circular>
            </template>
          </v-switch> -->

          <!-- <v-select
            v-model="autoCloseChat"
            outlined
            :items="autoCloseChatItems"
            item-text="label"
            :label="$t('inbox.autoCloseChat')"
            return-object
            z-index="5"
            hide-details
            :class="{ 'light-bg': isDark, 'dark-bg': !isDark }"
            class="mt-2"
            @change="changeAutoCloseChat"
          ></v-select> -->
          <v-select
            v-model="selectedNotifUnreplied"
            outlined
            :items="notifUnreplied"
            item-text="name"
            :label="$t('notifUnreplied')"
            return-object
            z-index="5"
            hide-details
            :class="{ 'light-bg mt-3': isDark, 'dark-bg mt-3': !isDark }"
            class="mt-4"
            @change="changeNotifTime"
          ></v-select>
        </v-card-text>
      </v-card>
    </v-dialog>

    <div
      :class="`mobile-check-header ${showHeader === false ? 'active' : ''} ${
        $vuetify.breakpoint.xs && statusChannelsBanner ? '' : ''
      }`"
      style=""
    >
      <slot>
      </slot>
    </div>

    <!-- Slot: Navbar -->
    <template
      v-if="showHeader"
      #navbar="{ isVerticalNavMenuActive, toggleVerticalNavMenuActive }"
    >
      <!-- <div
        :class="`navbar-content-container  align-center ${
          $vuetify.breakpoint.xs && statusChannelsBanner ? 'mt-2' : 'mt-4'
        }`"
      > -->
      <div class="navbar-content-container align-center mt-4">
        <div
          v-if="$vuetify.breakpoint.mdAndDown"
          class="d-flex align-center col-1 p-0 m-o"
        >
          <v-icon
            class=""
            @click="toggleVerticalNavMenuActive"
          >
            {{ icons.mdiMenu }}
          </v-icon>
        </div>
        <v-col
          class="p-0 m-0 mr-2"
          :cols="$vuetify.breakpoint.xs ? '4' : '3'"
          md="3"
        >
          <v-select
            v-model="selectedInstanceVal"
            outlined
            :items="optionsInstances"
            item-text="label"
            label="Channels"
            dense
            return-object
            z-index="5"
            hide-details
            :class="{ 'light-bg': isDark, 'dark-bg': !isDark }"
            @change="changeInstance"
          >
            <template
              slot="item"
              slot-scope="{ item }"
            >
              <div
                v-if="item.value.color"
                class="state-circle"
                :style="{ 'background-color': item.value.color }"
              />
              {{ item.label }}
            </template>
          </v-select>
        </v-col>

        <v-col
          class="p-0 m-0 mr-2"
          :cols="$vuetify.breakpoint.xs ? '4' : '3'"
          md="3"
        >
          <v-select
            v-model="selectedLabel"
            outlined
            :items="optionsLabel"
            item-text="label"
            item-value="label"
            label="Room Label"
            dense
            z-index="5"
            :disabled="labelValue.length === 0"
            hide-details
            :class="{ 'light-bg': isDark, 'dark-bg': !isDark }"
            multiple
            @change="filterLabel"
          >
            <template v-slot:selection="{ item, index }">
              <span
                v-if="index < maxDisplay"
                class="me-1"
              >
                <v-icon
                  dark
                  :style="{ color: item.color }"
                >
                  {{ icons.mdiLabel }}
                </v-icon>
                {{ $vuetify.breakpoint.xs ? '' : item.label }}
              </span>
              <span
                v-if="index === maxDisplay"
                class="grey--text caption"
              >(+{{ selectedLabel.length - maxDisplay }})</span>
            </template>
            <template
              slot="item"
              slot-scope="{ item }"
            >
              <v-icon
                dark
                :style="{ color: item.color }"
                class="me-1"
              >
                {{ icons.mdiLabel }}
              </v-icon>
              {{ item.label }}
            </template>
          </v-select>
        </v-col>

        <!-- <v-col
          class="p-0 m-0 show"
          :cols="$vuetify.breakpoint.xs ? '2' : '2'"
          md="3"
        >

          <v-select
            v-model="roomFilter"
            outlined
            :items="roomFilterList"
            label="Filter"
            dense
            z-index="5"
            hide-details
            :class="{ 'light-bg': isDark, 'dark-bg': !isDark }"
            @change="changeSettingFilter"
          ></v-select>
        </v-col> -->
        <!-- <v-col
          class="p-0 m-0 hide-tab"
          :cols="$vuetify.breakpoint.xs ? '5' : '4'"
          md="4"
        >
          <v-tabs
            v-model="tabRoomFilter"
            show-arrows
          >
            <v-tab
              v-for="(filter, index) in roomFilterList"
              :key="index"
              @click="changeSettingFilter(filter)"
            >
              <span>{{ filter }}</span>
            </v-tab>
          </v-tabs>
        </v-col> -->
        <v-col cols="auto mr-auto hide"></v-col>
        <v-col
          class="p-0 m-0"
          cols="auto"
        >
          <v-btn
            class="primary"
            icon
            @click="dialogSettingInbox = true"
          >
            <v-icon>{{ icons.mdiCog }}</v-icon>
          </v-btn>
        </v-col>
        <!-- <v-col class="p-0 m-0 show-tab" cols="12" md="12">

          <v-tabs
            v-model="tabRoomFilter"
            show-arrows
            centered
          >
            <v-tab
              v-for="(filter, index) in roomFilterList"
              :key="index"
              @click="changeSettingFilter(filter)"
            >
              <span>{{ filter }}</span>
            </v-tab>
          </v-tabs>
        </v-col> -->
        <!-- <v-col v-if="$vuetify.breakpoint.xs === false"></v-col> -->
        <!-- <v-col
          v-if="statusChannelsBanner"
          class="col-12"
        >
          <v-alert
            color="warning"
            dark
            class="mb-0 height-costum-alert-inbox"
          >
            {{ labelStatusInstanceAlert }}
          </v-alert>
        </v-col> -->
      </div>
    </template>
  </layout-content-vertical-nav>
</template>

<script>
/* eslint-disable */

import LayoutContentVerticalNav from '@/layouts/variants/contentInbox/vertical-nav/LayoutContentVerticalNav'
import navMenuItems from '@/navigation/vertical'
import { getVuetify } from '@core/utils'

// App Bar Components
import AppBarI18n from '@/components/AppBarI18n.vue'
import AppBarUserMenu from '@/components/AppBarUserMenu.vue'
import AppBarNotification from '@core/layouts/components/app-bar/AppBarNotification.vue'
import AppBarSearch from '@core/layouts/components/app-bar/AppBarSearch.vue'
import AppBarThemeSwitcher from '@core/layouts/components/app-bar/AppBarThemeSwitcher.vue'
import { mdiCog, mdiHeartOutline, mdiInformation, mdiLabel, mdiMenu } from '@mdi/js'

// Search Data
import appBarSearchData from '@/assets/app-bar-search-data'

import useAppConfig from '@core/@app-config/useAppConfig'
import { ref, watch } from '@vue/composition-api'

import channelMixin from '@/mixins/channelMixin'
import contactMixin from '@/mixins/contactMixin'
import eOrderingMixin from '@/mixins/e-orderingMixin'
import firestoreDbMixin from '@/mixins/firestoreDbMixin'
import inboxMixin from '@/mixins/inboxMixin'
import pouchDbMixin from '@/mixins/pouchDbMixin'

import { loadLanguageAsync } from '@/plugins/i18n'

const sound = require('@/assets/sound/ting.wav')

export default {
  mixins: [pouchDbMixin, channelMixin, inboxMixin, eOrderingMixin, firestoreDbMixin, contactMixin],
  components: {
    LayoutContentVerticalNav,

    // App Bar Components
    AppBarSearch,
    AppBarUserMenu,
    AppBarI18n,
    AppBarThemeSwitcher,
    AppBarNotification,
  },
  setup() {
    const $vuetify = getVuetify()
    const { isDark } = useAppConfig()
    let theme = ''

    if (isDark.value) {
      theme = 'dark'
    } else {
      theme = 'light'
    }

    // Search

    const appBarSearchQuery = ref('')
    const shallShowFullSearch = ref(false)
    const maxItemsInGroup = 5
    const totalItemsInGroup = ref({
      pages: 0,
      files: 0,
      contacts: 0,
    })
    watch(appBarSearchQuery, () => {
      totalItemsInGroup.value = {
        pages: 0,
        files: 0,
        contacts: 0,
      }
    })

    // NOTE: Update search function according to your usage
    const searchFilterFunc = (item, queryText, itemText) => {
      if (item.header || item.divider) return true

      const itemGroup = (() => {
        if (item.to !== undefined) return 'pages'
        if (item.size !== undefined) return 'files'
        if (item.email !== undefined) return 'contacts'

        return null
      })()

      const isMatched = itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1

      if (isMatched) {
        if (itemGroup === 'pages') totalItemsInGroup.value.pages += 1
        else if (itemGroup === 'files') totalItemsInGroup.value.files += 1
        else if (itemGroup === 'contacts') totalItemsInGroup.value.contacts += 1
      }

      return appBarSearchQuery.value && isMatched && totalItemsInGroup.value[itemGroup] <= maxItemsInGroup
    }

    // ? Handles case where in <lg vertical nav menu is open and search is triggered using hotkey then searchbox is hidden behind vertical nav menu overlaty
    const handleShallShowFullSearchUpdate = (isVerticalNavMenuActive, toggleVerticalNavMenuActive) => {
      if ($vuetify.breakpoint.mdAndDown && isVerticalNavMenuActive) {
        toggleVerticalNavMenuActive()
      }
    }

    return {
      // dark and light
      isDark,
      theme,

      navMenuItems,
      handleShallShowFullSearchUpdate,

      // Search
      appBarSearchQuery,
      shallShowFullSearch,
      appBarSearchData,
      searchFilterFunc,

      icons: {
        mdiMenu,
        mdiLabel,
        mdiHeartOutline,
        mdiInformation,
        mdiCog,
      },
    }
  },
  data() {
    return {
      // channel
      selectedInstanceVal: {
        label: 'All Channels',
        value: {
          all_sender: true,
          phone_number: null,
          color: '#3fc19f',
        },
      },
      optionsInstances: [],

      // label
      selectedLabel: [],
      // optionsLabel: [],
      labelData: [],
      // labelValue: [],
      labelSetting: [],
      maxDisplay: 1,

      // filter
      settingFilter: [],
      selectedSettingFilter: null,
      roomFilterList: ['all', 'open', 'close'],
      roomFilter: 'all',
      tabRoomFilter: 0,

      // db settings
      intervalId: null,
      instancesSetting: null,
      showSnakbar: false,
      intervalIdCheckHealth: null,

      // setting
      dialogSettingInbox: false,

      autoAssignSetting: null,
      autoAssignNewChatSetting: null,
      autoCloseSetting: null,
      boldUnrepliedSetting: null,
      boldUnreplied: false,
      autoAssign: true,
      autoClose: {
        id: 3,
        label: `24 ${this.$t('hours')}`,
        unit: 'hours',
        value: 24,
      },
      switchBold: false,

      autoAssignChatOnReply: true,
      switchAutoAssignChatOnReply: false,

      autoAssignNewChat: true,
      switchAutoAssignNewChat: false,

      notifAllOrAssignedSetting: null,
      notifAllOrAssigned: { name: this.$t('notifFromChannel'), data: 2 },
      switchNotifAllOrAssigned: false,

      autoCloseChat: {
        id: 3,
        label: `24 ${this.$t('hours')}`,
        unit: 'hours',
        value: 24,
      },
      autoCloseChatItems: [],

      selectedNotifUnreplied: null,
      notifUnreplied: [
        { name: this.$t('never'), time: 0 },
        { name: `${this.$t('every')} 1 ${this.$t('minute')}`, time: 60000 },
        { name: `${this.$t('every')} 5 ${this.$t('minute')}`, time: 300000 },
        { name: `${this.$t('every')} 10 ${this.$t('minute')}`, time: 600000 },
      ],
      dataNotifAllOrAssigned: [
        { name: this.$t('notifFromAll'), data: 1 },
        { name: this.$t('notifFromChannel'), data: 2 },
        { name: this.$t('notifFromAssigned'), data: 3 },
        { name: this.$t('none'), data: null },
      ],
    }
  },
  async beforeDestroy() {
    clearInterval(this.intervalId)
    this.setOnlineFalse
  },
  async mounted() {
    await this.connectSettings(false, false)
    const instances = this.$store.getters['auth/getListInstance']
    if (!instances || instances.length === 0) {
      const items = await this.mixinLoadSettingsRemote()
      const instancesSetting = items.find(x => x.key === 'instances')
      this.$store.commit('auth/setListInstance', instancesSetting)
    }
    let getTime = await this.$store.dispatch('inboxNavbar/getNotifyTime', this.user.sub_id)
    // //console.log(getTime, '--------------------------')
    let notifData = this.notifUnreplied.find(el => {
      return el.time === getTime.data.data.time.time
    })
    this.selectedNotifUnreplied = notifData
    // //console.log(this.selectedNotifUnreplied)
   // this.$store.commit('inbox/setLabelFilter', [])

    window.document.addEventListener('beforeunload', this.setOnlineFalse)
    const versionData = await this.$store.dispatch('auth/getVersions', { token: this.user.token })
    if (this.currentVersion === null || (versionData.status && this.currentVersion !== versionData.data)) {
      this.logout()
    }
    // this.$store.dispatch('auth/setOnlineStatus', {
    //   token: this.user.token,
    //   subId: this.user.sub_id,
    //   email: this.user.email,
    //   online: true,
    // })
    // await this.$store.dispatch('auth/getChannelAccess', {
    //   sub_id: this.user.sub_id,
    //   email: this.user.email,
    //   token: this.user.token,
    // })
    // await this.fdbGetAllContactFromRoom(this.user.sub_id)
    loadLanguageAsync(this.lang)
    if (this.filterChat != null) {
      this.selectedSettingFilter = this.filterChat
    }

    await this.getSettings()

    this.optionsInstances = this.getChannel

    if (this.optionsInstances.length > 0) {
      if (this.getGlobalChannel) {
        this.selectedInstanceVal = this.getGlobalChannel
        this.$store.commit('inboxNavbar/setValueChannel', this.getGlobalChannel)
      } else {
        this.selectedInstanceVal = this.optionsInstances[0]
        this.$store.commit('inboxNavbar/setValueChannel', this.optionsInstances[0])
      }
    }

    if (this.$route.params && this.$route.params.filterName) {
      if (this.settingFilter.length > 0) {
        const filter = this.settingFilter.find(x => x.name === this.$route.params.filterName)
        if (filter) {
          this.selectedSettingFilter = filter
          this.$store.dispatch('global/setFilterChat', filter)
        }
      }
    }

    if (this.filterChat == null) {
      this.selectedSettingFilter = this.settingFilter.find(item => item.name == 'All Contacts')
    }

    //  style
    // let doc = document.getElementsByClassName('height-costum-alert-inbox')[0]

    window.addEventListener('resize', () => {
      // doc = document.getElementsByClassName('height-costum-alert-inbox')[0]

      // if (doc) {
      //   this.$store.commit('inbox/setStyleHeight2', doc.clientHeight)
      // }
      this.$store.commit('inbox/setBannerAlert', this.statusChannelsBanner)
      this.setAlertChannelText()
    })
    this.$store.commit('inbox/setBannerAlert', this.statusChannelsBanner)
    this.setAlertChannelText()
    // if (doc) {
    //   this.$store.commit('inbox/setStyleHeight2', doc.clientHeight)
    // }

    if (this.getAutoCloseChat) {
      this.autoCloseChat = JSON.parse(JSON.stringify(this.getAutoCloseChat))
    } else {
      this.autoCloseChat = {
        id: '24hrs',
        label: `24 ${this.$t('hours')}`,
      }
    }

    this.autoCloseChatItems = [
      {
        id: 1,
        label: this.$t('never'),
        unit: 'never',
        value: 0,
      },
      {
        id: 2,
        label: `6 ${this.$t('hours')}`,
        unit: 'hours',
        value: 6,
      },
      {
        id: 3,
        label: `24 ${this.$t('hours')}`,
        unit: 'hours',
        value: 24,
      },
      {
        id: 4,
        label: `3 ${this.$t('days')}`,
        unit: 'days',
        value: 3,
      },
      {
        id: 5,
        label: `1 ${this.$t('week')}`,
        unit: 'week',
        value: 1,
      },
    ]
    // await this.loadContactsIdList(this.idList)
    this.tabRoomFilter = this.roomFilterList.findIndex(item => item == this.filterChat)
    // this.$store.commit('inbox/setContactRooms', this.contactListTable)
    // //console.log(this.contactListTable, `INI TABLE`)
    // this.contactListTable.forEach(async el => {
    //   await this.getListLabel(el)
    // })
  },
  methods: {
    async changeNotifTime() {
      await this.$store.dispatch('inboxNavbar/setNotifyTime', {
        sub_id: this.user.sub_id,
        time: this.selectedNotifUnreplied,
      })
    },
    getListLabel(contact) {
      const listLabel = []
      const filtered = this.labelValue.filter(
        x => x.contact && x.contact.phone_number == contact.phone_number && !x.chat,
      )
      // //console.log('label data', this.labelData)
      for (var data of filtered) {
        const label = this.labelData.find(x => x.label == data.label.id)

        if (label) {
          listLabel.push(label)
        } else {
          listLabel.push(data.label)
        }
      }

      _.uniq(listLabel).forEach(el => {
        this.optionsLabel.push(el)
      })

      return _.uniq(listLabel)
    },
    filterLabel() {
      // //console.log(this.rooms, `INI ROOMS`)
      // //console.log(this.optionsLabel, `INI DATA`)
      // //console.log(true, this.selectedLabel, `MASUK SINI`)
      this.$store.commit('inbox/setLabelFilter', this.selectedLabel)
      // //console.log(this.$store.getters['inbox/getLabelFilter'], `INI FILTER`)
    },
    logout() {
      // this.$store.dispatch('auth/setOnlineStatus', {
      //   token: this.user.token,
      //   subId: this.user.sub_id,
      //   email: this.user.email,
      //   online: false,
      // })
      this.$store.dispatch('auth/logout')
      this.$router.push('/login')
      setTimeout(function () {
        window.location.reload()
      }, 500)
    },
    async setOnlineFalse() {
      // await this.$store.dispatch('auth/setOnlineStatus', {
      //   token: this.user.token,
      //   subId: this.user.sub_id,
      //   email: this.user.email,
      //   online: false,
      // })
    },
    changeInstance(value) {
      this.$store.commit('inboxNavbar/setValueChannel', value)
      this.$store.commit('global/setGlobalChannel', value)
    },
    changeSettingFilter(value) {
      this.tabRoomFilter = this.roomFilterList.findIndex(item => item == value)
      this.$store.commit('inboxNavbar/setValueContact', value)
      this.$store.dispatch('global/setFilterChat', value)
    },
    async loadSettingCon(isExist = false) {
      if (!isExist) {
        await this.loadSettings()
      }
      const res = await this.loadSettingContactsList(this.allSettings)
      this.labelData = res.labelData
      this.contactListSetting = res.contactListSetting
      // this.listContactValue = res.listContactValue
      this.deleteUnusedContact(this.contactListTable, this.listContactValue)
    },
    selectionSort(data) {
      const filterArray = (arr, idx1, idx2) => {
        ;[arr[idx1], arr[idx2]] = [arr[idx2], arr[idx1]]
      }

      for (let i = 0; i < data.length; i += 1) {
        let lowers = i

        for (let j = i + 1; j < data.length; j += 1) {
          if (data[lowers].order_number > data[j].order_number) {
            lowers = j
          }
        }
        if (i !== lowers) filterArray(data, i, lowers)
      }

      return data
    },
    async checkInstances() {
      if (this.instanceValues) {
        let isChange = false
        for (var data of this.instanceValues.value) {
          if (data.phone_number) {
            const isReady = await this.$store.dispatch('auth/checkChannelReady', data.sub_id + '-' + data.instance_id)
            if (!isReady) {
              await this.$store.dispatch('auth/checkStatusMd', {
                id: data.sub_id + '-' + data.instance_id,
                ready: isReady,
              })

              // if (!data.status || data.status !== this.$store.getters['auth/getStatusInstance']) {
              data.status = this.$store.getters['auth/getStatusInstance']
              isChange = true
              // }
            } else {
              await this.$store.dispatch('auth/checkStatusMd', {
                id: data.sub_id + '-' + data.instance_id,
                ready: isReady,
              })
              //console.log('check status channel', data.sub_id + '-' + data.instance_id)
              if (!data.label || data.label == '-') {
                data.label = data.label_server
                  ? data.label_server
                  : this.$store.getters['auth/getInstanceName']
                  ? this.$store.getters['auth/getInstanceName']
                  : data.phone_number
                isChange = true
              }
              // if (!data.status || data.status !== this.$store.getters['auth/getStatusInstance']) {
              data.status = this.$store.getters['auth/getStatusInstance']
              isChange = true
              // }
            }
            // this.syncContactsWa(data)
          }
        }

        if (this.instanceValues.value.filter(x => (x.status === 0 || x.status === 3) && x.phone_number).length > 0) {
          this.$store.dispatch('global/setStatusChannels', true)
        } else {
          this.$store.dispatch('global/setStatusChannels', false)
          this.$store.commit('inbox/setBannerAlertText', ``)
        }
        if (isChange) {
          await this.updateSettings(this.instancesSetting)
          this.$store.commit('auth/setListInstance', this.instanceValues)
        }
        this.setAlertChannelText()
      }
    },
    setAlertChannelText() {
      if (!this.instanceValues) this.$store.commit('inbox/setBannerAlertText', ``)
      const labels = []

      if (this.instanceValues) {
        this.instanceValues.value.forEach(instance => {
          if ((instance.status == 0 || instance.status == 3) && instance.phone_number) {
            if (this.user.limited_channel) {
              if (this.user.enabled_channel && this.user.enabled_channel.find(y => y === instance._id)) {
                labels.push(instance.label === '-' ? instance.label_server : instance.label)
              }
            } else {
              labels.push(instance.label === '-' ? instance.label_server : instance.label)
            }
          }
        })
      }

      if (labels.length > 0) {
        if (
          this.$store.getters['inbox/getBannerAlertText'] !=
          `Channel ${labels.join(', ')} is not connected, please check your device connection`
        ) {
          return this.$store.commit(
            'inbox/setBannerAlertText',
            `Channel ${labels.join(', ')} is not connected, please check your device connection`,
          )
        }
      } else {
        return this.$store.commit('inbox/setBannerAlertText', ``)
      }
    },
    async changeValueBold() {
      this.switchBold = true
      this.boldUnrepliedSetting.value = this.boldUnreplied
      await this.updateSettings(this.boldUnrepliedSetting, async x => {
        if (x === false) {
          this.boldUnreplied = !this.boldUnreplied
        }
        this.$store.dispatch('global/setBoldUnreplied', this.boldUnreplied)
        this.switchBold = false
      })
    },
    async getSettings() {
      const items = await this.loadSettings()
      this.instancesSetting = items.find(x => x.key === 'instances')

      this.checkInstances()
      const parent = this
      const obj = await this.mappingSettingsContacts(this.idlist, this.allSettings)

      let filteredObjColumn = obj.tableColumns
      filteredObjColumn = this.selectionSort(filteredObjColumn)
      this.$store.commit('inbox/setLabelValue', obj.labelValue)
      this.labelData = obj.labelData
      this.labelSetting = obj.labelSetting
      // this.labelValue = obj.labelValue
      // //console.log(obj.labelData, obj.labelValue, `INI DATA LABELNYA`)

      this.intervalId = window.setInterval(() => {
        // this.$store.dispatch('auth/setOnlineStatus', {
        //   token: this.user.token,
        //   subId: this.user.sub_id,
        //   email: this.user.email,
        //   online: true,
        // })
        parent.checkInstances()
      }, 10000)

      const res = await this.mappingSettingChannels(this.allSettings)

      if (res.boldUnrepliedSetting) {
        this.boldUnrepliedSetting = res.boldUnrepliedSetting
        this.boldUnreplied = this.boldUnrepliedSetting.value
        this.$store.dispatch('global/setBoldUnreplied', this.boldUnreplied)
      } else {
        await this.updateSettings(
          { _id: 'bold_unreplied', key: 'bold_unreplied', value: this.boldUnreplied },
          async x => {
            this.boldUnrepliedSetting = await this.loadSettingsById(x.id)
            this.boldUnreplied = this.boldUnrepliedSetting.value
            this.$store.dispatch('global/setBoldUnreplied', this.boldUnreplied)
          },
        )
      }
      //console.log(res.autoAssignSetting, '<<<< AUTO')

      if (res.autoAssignSetting) {
        this.autoAssignSetting = res.autoAssignSetting
        this.autoAssignChatOnReply = this.autoAssignSetting.value
        this.$store.commit('global/setAutoAssignChatOnReply', this.autoAssignChatOnReply)
      } else {
        await this.updateSettings(
          { _id: 'auto_assign', key: 'auto_assign', value: this.autoAssignChatOnReply },
          async x => {
            this.autoAssignSetting = await this.loadSettingsById(x.id)
            this.autoAssignChatOnReply = this.autoAssignSetting.value
            this.$store.commit('global/setAutoAssignChatOnReply', this.autoAssignChatOnReply)
          },
        )
      }

      if (res.autoAssignNewChatSetting) {
        this.autoAssignNewChatSetting = res.autoAssignNewChatSetting
        this.autoAssignNewChat = this.autoAssignNewChatSetting.value
        this.$store.commit('global/setAutoAssignNewChat', this.autoAssignNewChat)
      } else {
        await this.updateSettings(
          { _id: 'auto_assign', key: 'auto_assign', value: this.autoAssignNewChat },
          async x => {
            this.autoAssignNewChatSetting = await this.loadSettingsById(x.id)
            this.autoAssignNewChat = this.autoAssignNewChatSetting.value
            this.$store.commit('global/setAutoAssignNewChat', this.autoAssignNewChat)
          },
        )
      }

      if (res.notifAllOrAssignedSetting) {
        this.notifAllOrAssignedSetting = res.notifAllOrAssignedSetting
        if (this.notifAllOrAssignedSetting && this.notifAllOrAssignedSetting.value) {
          this.notifAllOrAssigned = this.notifAllOrAssignedSetting.value
        }
        this.$store.commit('global/setNotifAllOrAssigned', this.notifAllOrAssigned)
      } else {
        await this.updateSettings(
          { _id: 'notif_all_or_assigned', key: 'notif_all_or_assigned', value: this.notifAllOrAssigned },
          async x => {
            this.notifAllOrAssignedSetting = await this.loadSettingsById(x.id)
            this.notifAllOrAssigned = this.notifAllOrAssignedSetting.value
            this.$store.commit('global/setNotifAllOrAssigned', this.notifAllOrAssigned)
          },
        )
      }

      if (res.autoCloseSetting) {
        this.autoCloseSetting = res.autoCloseSetting
        this.autoCloseChat = this.autoCloseSetting.value
        this.$store.commit('global/setAutoCloseChat', this.autoCloseChat)
      } else {
        await this.updateSettings({ _id: 'auto_close', key: 'auto_close', value: this.boldUnreplied }, async x => {
          this.autoCloseSetting = await this.loadSettingsById(x.id)
          this.autoCloseChat = this.autoCloseSetting.value
          this.$store.commit('global/setAutoCloseChat', this.autoCloseChat)
        })
      }
    },
    async changeAutoCloseChat(value) {
      this.autoCloseSetting.value = value
      await this.updateSettings(this.autoCloseSetting, async x => {
        if (x === false) {
          this.$store.commit('global/setAutoCloseChat', value)
        }
        // this.switchAutoAssignChatOnReply = false
      })
    },
    async changeAutoAssignChatOnReply() {
      this.switchAutoAssignChatOnReply = true
      this.autoAssignSetting.value = this.autoAssignChatOnReply
      await this.updateSettings(this.autoAssignSetting, async x => {
        if (x === false) {
          this.autoAssignChatOnReply = !this.autoAssignChatOnReply
        }
        this.$store.commit('global/setAutoAssignChatOnReply', this.autoAssignChatOnReply)
        this.switchAutoAssignChatOnReply = false
      })
      // this.switchAutoAssignChatOnReply = false
    },
    async changeAutoAssignNewChat() {
      this.switchAutoAssignNewChat = true
      this.autoAssignNewChatSetting.value = this.autoAssignNewChat
      await this.updateSettings(this.autoAssignNewChatSetting, async x => {
        if (x === false) {
          this.autoAssignNewChat = !this.autoAssignNewChat
        }
        this.$store.commit('global/setAutoAssignNewChat', this.autoAssignNewChat)
        this.switchAutoAssignNewChat = false
      })
      // this.switchAutoAssignChatOnReply = false
    },
    async changeNotifAllOrAssigned() {
      // //console.log(this.notifAllOrAssigned)
      this.switchNotifAllOrAssigned = true
      this.notifAllOrAssignedSetting.value = this.notifAllOrAssigned.data
      await this.updateSettings(this.notifAllOrAssignedSetting, async x => {
        if (x === false) {
          this.notifAllOrAssigned = { name: this.$t('notifFromChannel'), data: 2 }
        }
        this.$store.commit('global/setNotifAllOrAssigned', this.notifAllOrAssigned.data)
        this.switchNotifAllOrAssigned = false
      })
      // this.switchNotifAllOrAssigned = false
    },
  },

  computed: {
    optionsLabel() {
      const listLabel = []
      const filtered = this.labelValue.filter(x => x.contact && !x.chat)
      for (var data of filtered) {
        const label = this.labelData.find(x => x.label == data.label.id)

        if (label) {
          listLabel.push(label)
        } else {
          listLabel.push(data.label)
        }
      }

      return _.uniq(listLabel)
    },
    contactRooms() {
      return this.$store.getters['inbox/getContactRooms']
    },
    currentVersion() {
      return this.$store.getters['global/getVersion']
    },
    labelValue() {
      return this.$store.getters['inbox/getLabelValue']
    },
    // labelStatusInstanceAlert() {
    //   if (!this.instancesSetting) return ''
    //   const labels = []

    //   this.instancesSetting.value.forEach(instance => {
    //     if ((instance.status == 0 || instance.status == 3) && instance.phone_number) {
    //       labels.push(instance.label)
    //     }
    //   })

    //   if (labels.length > 0) {
    //     this.$store.commit(
    //       'inbox/setBannerAlertText',
    //       `Channel ${labels.join(', ')} is not connected, please check your device connection`,
    //     )
    //     return `Channel ${labels.join(', ')} is not connected, please check your device connection`
    //   }

    //   return ''
    // },
    lang() {
      return this.$store.getters['global/getLang']
    },
    filterChat() {
      return this.$store.getters['global/getFilterChat']
    },
    syncFromServerBanner() {
      return this.$store.getters['global/getSyncFromServer']
    },
    statusChannelsBanner() {
      return this.$store.getters['global/getStatusChannels']
    },
    statusBanner() {
      return this.$store.getters['auth/isSuccessSync']
    },
    getChannel() {
      let channels = [
        {
          label: 'All Channels',
          value: {
            all_sender: true,
            phone_number: null,
            color: '#3fc19f',
          },
        },
      ]

      if (!this.user.view_assign_only) {
        const instances = this.$store.getters['auth/getListInstance']
        if (instances && instances.value) {
          instances.value.forEach(instance => {
            if (this.user.limited_channel) {
              if (this.user.enabled_channel && this.user.enabled_channel.find(y => y === instance._id)) {
                channels.push({
                  label: instance.label,
                  value: instance,
                })
              }
            } else {
              channels.push({
                label: instance.label,
                value: instance,
              })
            }
          })
        }
      }

      return channels
    },
    getGlobalChannel() {
      return this.$store.getters['global/getGlobalChannel']
    },
    getAutoCloseChat() {
      return this.$store.getters['global/getAutoCloseChat']
    },
    getContact() {
      return this.$store.getters['inboxNavbar/getContact']
    },
    instanceValues() {
      const instances = this.$store.getters['auth/getListInstance']

      if (instances) {
        if (!instances.value) {
          instances.value = []
        }
      }

      return instances
    },
    user() {
      return this.$store.getters['auth/getUser']
    },
    showRoomsList() {
      return this.$store.getters['inbox/getShowRoomsList']
    },
    showHeader() {
      if (this.$vuetify.breakpoint.mdAndDown === true && this.showRoomsList === false) {
        this.$eventBus.$emit('mobile-check-header', false)
        return false
      }
      // //console.log(`ini break`, this.$vuetify.breakpoint.mdAndDown, `ini show room`, this.showRoomsList)
      this.$eventBus.$emit('mobile-check-header', true)
      return true
    },
    allSettings() {
      return this.$store.getters['global/getSettings']
    },
    getNotifAllOrAssigned() {
      return this.$store.getters['global/getNotifAllOrAssigned']
    },
  },
  watch: {
    statusChannelsBanner(varnew) {
      this.$store.commit('inbox/setBannerAlert', this.statusChannelsBanner)
      this.setAlertChannelText()
    },

    getContact(varnew) {
      this.settingFilter = varnew
    },
  },
}
</script>

<style lang="scss">
.mobile-alert {
  @include screen('0px') {
    position: fixed;
    top: 220px;
    right: -10px;
    //border-radius: 100%;
  }
  @include screen('md') {
    position: relative;
    width: 100px;
    top: auto;
    right: auto;
    //border-radius: 100%;
  }
}
.mobile-check-header {
  position: relative;
  width: 100%;
  &.active {
    position: fixed;
    top: 0;
    z-index: 5;
  }
}
.sticky-card {
  width: 344px;
  position: fixed;
  top: 90px;
  z-index: 1;
  /* centering */
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}

.after-sticky {
  margin-top: 90px;
}

.navbar-content-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  position: relative;
  flex-flow: row wrap;
}

// ? Handle bg of autocomplete for blured appBar
.v-app-bar.bg-blur {
  .expanded-search {
    ::v-deep .app-bar-autocomplete-box div[role='combobox'] {
      background-color: transparent;
    }

    > .d-flex > button.v-icon {
      display: none;
    }

    // ===

    & > .right-row {
      visibility: hidden;
      opacity: 0;
    }

    ::v-deep .app-bar-search-toggler {
      visibility: hidden;
    }
  }
}

.state-circle {
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: var(--chat-room-color-offline);
  margin-right: 6px;
  transition: 0.3s;
}
//show or hide
.show {
  display: none;
}
.show-tab {
  display: none;
}
@media (max-width: 600px) {
  .hide {
    display: none;
  }
  .show {
    display: block;
  }

  /* .hero-subtitle {
    width: 200px;
  } */
}
// Reversed input variant
.v-input--reverse .v-input__slot {
  flex-direction: row-reverse;
  justify-content: flex-end;
  .v-application--is-ltr & {
    .v-input--selection-controls__input {
      margin-right: 0;
      margin-left: 8px;
    }
  }
  .v-application--is-rtl & {
    .v-input--selection-controls__input {
      margin-left: 0;
      margin-right: 8px;
    }
  }
}

// Bonus "expand" variant
.v-input--expand .v-input__slot {
  // justify-content: space-between;
  .v-label {
    display: block;
    flex: 1;
  }
}
// @media (max-width: 900px) {
//   .hide-tab {
//     display: none;
//   }
//   .show-tab {
//     display: block;
//   }
//   .mobile-check-header {
//     padding-top: 46px;
//     position: relative;
//     width: 100%;
//     &.active {
//       padding-top: 0px;

//       position: fixed;
//       top: 0;
//       z-index: 5;
//     }
//   }
// }
</style>
