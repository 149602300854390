/* eslint-disable func-names */
export default function () {
  return {
    subscriptionPlans: [],
    listInvoice: [],
    userInfo: '',
    activeInvoice: [],
    subscriptionInfo: {},
    currentLang: '',
  }
}
