import axios from 'axios'

// axios limit
const MAX_REQUESTS_COUNT = 15
const INTERVAL_MS = 10
let PENDING_REQUESTS = 0

// create new axios instance
const conn = axios.create({})
conn.defaults.timeout = 20000

/**
 * Axios Request Interceptor
 */
conn.interceptors.request.use(function (config) {
  return new Promise((resolve, reject) => {
    const interval = setInterval(() => {
      if (PENDING_REQUESTS < MAX_REQUESTS_COUNT) {
        PENDING_REQUESTS++
        clearInterval(interval)
        resolve(config)
      }
    }, INTERVAL_MS)
  })
})

/**
 * Axios Response Interceptor
 */
conn.interceptors.response.use(
  function (response) {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1)

    return Promise.resolve(response)
  },
  function (error) {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1)

    return Promise.reject(error)
  },
)

export async function fetchListShop({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '')}superwa/couchdb/link/get-shop-list`
  commit('setShopListLink', [])

  return conn
    .post(endpoint, {
      sub_id: obj.sub_id,
    })
    .then(async response => {
      if (response.data.status) {
        commit('setShopListLink', response.data.data)

        return {
          status: true,
          errorMessage: '',
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      // to do check auth
      return {
        status: false,
        errorMessage: error,
      }
    })
}

export async function fetchListMessage({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '')}superwa/couchdb/link/get-chat-list`
  commit('setMessageListLink', [])

  return conn
    .post(
      endpoint,
      {
        sub_id: obj.sub_id,
      },
      { headers: { Authorization: `Bearer ${obj.token}` } },
    )
    .then(async response => {
      if (response.data.status) {
        commit('setMessageListLink', response.data.data)

        return {
          status: true,
          errorMessage: '',
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      // to do check auth
      return {
        status: false,
        errorMessage: error,
      }
    })
}

export async function editGenerateLink({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '')}superwa/couchdb/link/update-shop-data`

  return conn
    .post(endpoint, {
      sub_id: obj.sub_id,
      link_id: obj.link_id,
      shop_data: obj.shop_data,
    })
    .then(async response => {
      if (response.data.status) {
        return {
          status: true,
          errorMessage: '',
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      // to do check auth
      return {
        status: false,
        errorMessage: error,
      }
    })
}

export async function editLinkTracker({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '')}superwa/couchdb/link/update-link-tracker`

  return conn
    .post(endpoint, obj)
    .then(async response => {
      if (response.data.status) {
        return {
          status: true,
          errorMessage: '',
          data: response.data.data,
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      // to do check auth
      return {
        status: false,
        errorMessage: error,
      }
    })
}

export async function saveGenerateLink({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '')}superwa/couchdb/link/generate-order-link`

  return conn
    .post(endpoint, {
      sub_id: obj.sub_id,
      instance_id: obj.instance_id,
      shop_data: obj.shop_data,
    })
    .then(async response => {
      if (response.data.status) {
        return {
          status: true,
          errorMessage: '',
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      // to do check auth
      return {
        status: false,
        errorMessage: error,
      }
    })
}

export async function updateEnabledMessageLink({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '')}superwa/couchdb/link/toggle-chat-link`

  return conn
    .post(
      endpoint,
      {
        sub_id: obj.sub_id,
        link_id: obj.link_id,
      },
      { headers: { Authorization: `Bearer ${obj.token}` } },
    )
    .then(async response => {
      if (response.data.status) {
        return {
          status: true,
          errorMessage: '',
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      // to do check auth
      return {
        status: false,
        errorMessage: error,
      }
    })
}

export async function updateGenerateMessageLink({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '')}superwa/couchdb/link/update-chat-data`

  return conn
    .post(
      endpoint,
      {
        sub_id: obj.sub_id,
        link_id: obj.link_id,
        channel: obj.channel,
        message: obj.message,
        slug: obj.slug,
        google: obj.google,
        facebook_pixel: obj.facebook_pixel,
        contactList: obj.contactList,

        // airtable: obj.airtable,
        domain: obj.domain,
        loading: obj.loading,
        rotation: obj.rotation,
        enabled: obj.enabled,
      },
      { headers: { Authorization: `Bearer ${obj.token}` } },
    )
    .then(async response => {
      if (response.data.status) {
        return {
          status: true,
          errorMessage: '',
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      // to do check auth
      return {
        status: false,
        errorMessage: error,
      }
    })
}

export async function saveGenerateMessageLink({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '')}superwa/couchdb/link/generate-chat-link`

  //console.log(obj.slug)

  return conn
    .post(
      endpoint,
      {
        sub_id: obj.sub_id,
        channel: obj.channel,
        message: obj.message,
        slug: obj.slug,
        google: obj.google,
        facebook_pixel: obj.facebook_pixel,
        contactList: obj.contactList,
        domain: obj.domain,
        loading: obj.loading,
        rotation: obj.rotation,
        enabled: obj.enabled,
      },
      { headers: { Authorization: `Bearer ${obj.token}` } },
    )
    .then(async response => {
      if (response.data.status) {
        return {
          status: true,
          errorMessage: '',
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      // to do check auth
      return {
        status: false,
        errorMessage: error,
      }
    })
}

export async function deleteShopLink({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '')}superwa/couchdb/link/delete-shop-data`

  return conn
    .post(endpoint, {
      sub_id: obj.sub_id,
      link_id: obj.link_id,
    })
    .then(async response => {
      if (response.data.status) {
        return {
          status: true,
          errorMessage: '',
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      // to do check auth
      return {
        status: false,
        errorMessage: error,
      }
    })
}

export async function deleteMessageLink({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '')}superwa/couchdb/link/delete-chat-data`

  return conn
    .post(
      endpoint,
      {
        sub_id: obj.sub_id,
        link_id: obj.link_id,
      },
      { headers: { Authorization: `Bearer ${obj.token}` } },
    )
    .then(async response => {
      if (response.data.status) {
        return {
          status: true,
          errorMessage: '',
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      // to do check auth
      return {
        status: false,
        errorMessage: error,
      }
    })
}

export async function checkSlug({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '')}superwa/couchdb/link/check-slug`

  return conn
    .post(
      endpoint,
      {
        slug: obj.slug,
      },
      { headers: { Authorization: `Bearer ${obj.token}` } },
    )
    .then(async response => {
      if (response.data.status) {
        return {
          status: response.data.data,
          errorMessage: '',
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      // to do check auth
      return {
        status: false,
        errorMessage: error,
      }
    })
}

// checkSlugTracker

export async function checkSlugTracker({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '')}superwa/couchdb/link/check-slug-tracker`

  // //console.log('masuk Store', obj)

  return conn
    .post(
      endpoint,
      {
        slug: obj.slug,
      },

      // { headers: { Authorization: `Bearer ` + obj.token } },
    )

    .then(async response => {
      //console.log('ini res', response)
      if (response.data.status) {
        //console.log('ini res', response.data.data)

        return {
          status: response.data.data,
          errorMessage: '',
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      // to do check auth
      return {
        status: false,
        errorMessage: error,
      }
    })
}

export async function uploadFile({ commit, state }, obj) {
  const endpoint = 'https://wa-link.deploy.cbs.co.id/uploadfile'

  const bodyFormData = new FormData()
  bodyFormData.append('media', obj)

  return conn
    .post(endpoint, bodyFormData, { 'Content-Type': 'multipart/form-data' })
    .then(async response => {
      if (response.data.status) {
        //console.log(response.data)

        return {
          status: true,
          errorMessage: '',
          data: response.data.data[0],
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      // to do check auth
      return {
        status: false,
        errorMessage: error,
      }
    })
}

/// /////////////////////////////////////// LINK Tracker
export async function fetchListTracker({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '')}superwa/couchdb/link/get-my-tracker-link`
  commit('setTrackerListLink', [])

  return conn
    .post(
      endpoint,
      {
        sub_id: obj.sub_id,
      },

      // { headers: { Authorization: `Bearer ` + obj.token } },
    )
    .then(async response => {
      // //console.log(response)
      if (response.data.status) {
        commit('setTrackerListLink', response.data.data)

        return {
          status: true,
          errorMessage: '',
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      // to do check auth
      return {
        status: false,
        errorMessage: error,
      }
    })
}
export async function saveGenerateLinkTracker({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '')}superwa/couchdb/link/generate-link-tracker`

  return conn
    .post(endpoint, {
      sub_id: obj.sub_id,
      slug: obj.slug,
      redirect_url: obj.redirect_url,
      googleTag: obj.googleTag,
      facebookId: obj.facebookId,
      contactList: obj.contactList,
    })
    .then(async response => {
      if (response.data.status) {
        return {
          status: true,
          errorMessage: '',
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      // to do check auth
      return {
        status: false,
        errorMessage: error,
      }
    })
}
export async function deleteTrackerLink({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '')}superwa/couchdb/link/delete-link-tracker`

  return conn
    .post(endpoint, {
      sub_id: obj.sub_id,
      slug: obj.slug,
    })
    .then(async response => {
      if (response.data.status) {
        return {
          status: true,
          errorMessage: '',
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      // to do check auth
      return {
        status: false,
        errorMessage: error,
      }
    })
}

export async function updateClickCount({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '')}superwa/couchdb/link/update-count-click`

  return conn
    .post(endpoint, {
      slug: obj.slug,
      phone_number: obj.phone_number,
    })
    .then(async response => {
      if (response.data.status) {
        return {
          status: true,
          errorMessage: '',
          url: response.data.data,
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      // to do check auth
      return {
        status: false,
        errorMessage: error,
      }
    })
}
export async function fetchListCampaign({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '')}superwa/couchdb/link/get-campaign-tracker`

  return conn
    .post(
      endpoint,
      {
        sub_id: obj.sub_id,
      },
      { headers: { Authorization: `Bearer ${obj.token}` } },
    )
    .then(async response => {
      if (response.data.status) {
        return {
          status: true,
          errorMessage: '',
          data: response.data.data,
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
        data: [],
      }
    })
    .catch(error => {
      // to do check auth
      return {
        status: false,
        errorMessage: error,
        data: [],
      }
    })
}
export async function createCampaignLink({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '')}superwa/couchdb/link/create-campaign-tracker`

  return conn
    .post(endpoint, obj, { headers: { Authorization: `Bearer ${obj.token}` } })
    .then(async response => {
      if (response.data.status) {
        return {
          status: true,
          errorMessage: '',
          data: response.data.data,
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
        data: [],
      }
    })
    .catch(error => {
      // to do check auth
      return {
        status: false,
        errorMessage: error,
        data: [],
      }
    })
}
// checkCampaignLink
export async function checkCampaignLink({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '')}superwa/couchdb/link/get-avability-tracker`

  return conn
    .post(endpoint, obj, { headers: { Authorization: `Bearer ${obj.token}` } })
    .then(async response => {
      if (response.data.status) {
        return {
          status: true,
          errorMessage: '',
          data: response.data.data,
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
        data: [],
      }
    })
    .catch(error => {
      // to do check auth
      return {
        status: false,
        errorMessage: error,
        data: [],
      }
    })
}
export async function updateCampaignLink({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '')}superwa/couchdb/link/update-campaign-tracker`

  return conn
    .post(endpoint, obj, { headers: { Authorization: `Bearer ${obj.token}` } })
    .then(async response => {
      if (response.data.status) {
        return {
          status: true,
          errorMessage: '',
          data: response.data.data,
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
        data: [],
      }
    })
    .catch(error => {
      // to do check auth
      return {
        status: false,
        errorMessage: error,
        data: [],
      }
    })
}

export async function sendLinkToBroadcast({ commit, state }, obj) {
  //console.log(obj, 'ini obj nyund')
  commit('sendLinkToBroadcast', obj)
}

export async function saveDataEform({ commit, state }, obj) {
  commit('setDataEform', obj)
}
export async function saveopenStatisticEform({ commit, state }, obj) {
  // //console.log('masuk sini mas yes,=============================')
  commit('setopenStatisticEform', obj)
}
export async function saveCampaignId({ commit, state }, obj) {
  // //console.log('masuk sini mas yes,=============================')
  commit('setCampaignId', obj)
}

export async function saveLoginLink({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/otp-login/login-link`

  return conn
    .post(endpoint, obj, { headers: { Authorization: `Bearer ${obj.token}` } })
    .then(async response => {
      return response.data
    })
    .catch(error => {
      //console.log('error :', error)
      return {
        status: false,
        errorMessage: error,
        data: [],
      }
    })
}

export async function fetchLoginLinkList({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/otp-login/login-link/${obj.sub_id}`

  return conn
    .get(endpoint, { headers: { Authorization: `Bearer ${obj.token}` } })
    .then(async response => {
      return response.data
    })
    .catch(error => {
      return {
        status: false,
        errorMessage: error,
        data: [],
      }
    })
}

export async function deleteLoginLink({ commit, state }, obj) {
  const query = `sub_id=${obj.sub_id}&loginLinkId=${obj.loginLinkId}`
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/otp-login/login-link?${query}`

  return conn
    .delete(endpoint, { headers: { Authorization: `Bearer ${obj.token}` } })
    .then(async response => {
      return response.data
    })
    .catch(error => {
      return {
        status: false,
        errorMessage: error,
        data: [],
      }
    })
}

export async function updateLoginLink({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/otp-login/login-link`

  return conn
    .put(endpoint, obj, { headers: { Authorization: `Bearer ${obj.token}` } })
    .then(async response => {
      return response.data
    })
    .catch(error => {
      //console.log('error :', error)
      return {
        status: false,
        errorMessage: error,
        data: [],
      }
    })
}

export async function updateJwtKey({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/otp-login/login-link`

  return conn
    .patch(endpoint, obj, { headers: { Authorization: `Bearer ${obj.token}` } })
    .then(async response => {
      return response.data
    })
    .catch(error => {
      //console.log('error :', error)
      return {
        status: false,
        errorMessage: error,
        data: [],
      }
    })
}

export async function getOneLoginLink({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/otp-login/login-link/single/${obj.loginLinkId}`

  return conn
    .get(endpoint, obj, { headers: { Authorization: `Bearer ${obj.token}` } })
    .then(async response => {
      return response.data
    })
    .catch(error => {
      return {
        status: false,
        errorMessage: error,
        data: [],
      }
    })
}
