import _find from 'lodash/find'
import _findIndex from 'lodash/findIndex'
import _orderBy from 'lodash/orderBy'
import _unescape from 'lodash/unescape'
import mime from 'mime-types'
import PouchDB from 'pouchdb-browser'
import PouchDBFind from 'pouchdb-find'
import sanitizeHtml from 'sanitize-html'
import TransformPouch from 'transform-pouch'
import firestoreDbMixin from './firestoreDbMixin'
import utilsMixin from './utilsMixin'

PouchDB.adapter('worker', require('worker-pouch'))

PouchDB.plugin(PouchDBFind)
PouchDB.plugin(TransformPouch)

const sanitizeOptions = {
  allowedTags: ['a', 'usertag'],
  allowedAttributes: {
    '*': ['*'],
  },
  disallowedTagsMode: 'escape',
}
const { customAlphabet } = require('nanoid')

const alphabet = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ'

function wrapCsvValue(val, formatFn) {
  let formatted = formatFn !== void 0 ? formatFn(val) : val

  formatted = formatted === void 0 || formatted === null ? '' : String(formatted)

  formatted = formatted.split('"').join('""')

  /**
   * Excel accepts \n and \r in strings, but some other CSV parsers do not
   * Uncomment the next two lines to escape new lines
   */
  // .split('\n').join('\\n')
  // .split('\r').join('\\r')

  return `"${formatted}"`
}

export default {
  mixins: [utilsMixin, firestoreDbMixin],
  data() {
    return {
      isReadUserRoom: false,
      readUserRoom: '',
    }
  },
  methods: {
    async loadContactsTransform(user) {
      const self = this
      const dbName = `${user.sub_id}_contacts`
      const host = `${process.env.VUE_APP_COUCH_DB_URL}${dbName}`
      const remote = new PouchDB(host, {
        fetch(url, opts) {
          opts.headers.set('Authorization', `Bearer ${user.token}`)
          opts.headers.set('Content-Type', 'application/json')

          return PouchDB.fetch(url, opts)
        },
      })

      remote.transform({
        incoming(doc) {
          // do something to the document before storage
          return doc
        },
        async outgoing(doc) {
          // do something to the document after retrieval
          doc = await self.getInboxTransform(doc)

          return doc
        },
      })

      const allDocs = await remote.allDocs({
        include_docs: true,
      })

      const items = allDocs.rows.map(row => row.doc)
      const contacts = items.filter(doc => !doc.roomId)
      const rooms = items.filter(doc => doc.roomId)
      const roomsOrdered = _orderBy(rooms, ['favourite', 'messageTimestamp'], ['desc', 'desc'])

      this.contactsPouch = [...contacts]
      this.roomsLoaded = false
      this.allRoomsGlobal = roomsOrdered
      this.roomsOrigin = roomsOrdered
      this.roomsFiltered = roomsOrdered
      this.rooms = roomsOrdered
      this.roomsLoaded = true
      this.loadingRooms = false
    },
    async getInboxTransform(contact) {
      let inbox = null

      if (contact.ch) {
        const keys = Object.keys(contact.ch)
        const offlineChats = this.$store.getters['global/getMessageOffline'].filter(
          x => x.person.split('@')[0] === contact.phone_number,
        )

        if (keys && keys.length > 1) {
          // check contact has message from multi channels
          keys.forEach(key => {
            const contactOrigin = JSON.parse(JSON.stringify(contact))
            delete contactOrigin.ch[key]

            offlineChats.forEach(data => {
              if (!contact.ch[key].chats) {
                contact.ch[key].chats = []
              }

              contact.ch[key].chats.unshift({
                ...data.data,
                status: 5,
              })
            })

            contact.ch[keys[0]].chats = _orderBy(contact.ch[keys[0]].chats, ['messageTimestamp'], ['desc'])

            inbox = contactOrigin
          })
        } else if (keys && keys.length == 1) {
          offlineChats.forEach(data => {
            if (!contact.ch[keys[0]].chats) {
              contact.ch[keys[0]].chats = []
            }

            contact.ch[keys[0]].chats.unshift({
              ...data.data,
              status: 5,
            })

            contact.ch[keys[0]].chats = _orderBy(contact.ch[keys[0]].chats, ['messageTimestamp'], ['desc'])
          })

          inbox = contact
        }
      }

      if (inbox) {
        const room = await this.parseInboxTransform(inbox)

        return room
      }

      return contact
    },
    async parseInboxTransform(item) {
      const id = this.$nanoid()
      let chat = {}

      // parse inbox by channel key
      item.hidden_inbox = false

      const key = Object.keys(item.ch)[0]
      item.data_channel = key || ''

      const instance = this.optionsInstances.find(option => option.value.phone_number == item.data_channel)

      if (item.ch[key] && item.ch[key].chats && item.ch[key].chats.length > 0) {
        const messages = item.ch[key].chats
        const firstMessage = item.ch[key].chats[0]
        const { unread } = item.ch[key]

        if (instance) {
          firstMessage.color = instance.value.color
          firstMessage.source = instance.value.phone_number
        }

        // filter shown ignore this msg from background message channel
        if (firstMessage.message) {
          if (firstMessage.message.conversation) {
            if (firstMessage.message.conversation.startsWith('ignore this msg')) {
              item.hidden_inbox = true
            }
          }
        }

        item.data_timestamp = firstMessage.messageTimestamp
        item.data_chats = messages
        item.data_unread = unread

        // set contact label
        item.labels = this.setLabels(item)

        // if data has instance and not hidden, push
        if (instance && !item.hidden_inbox) {
          chat = this.setContactInbox(firstMessage, id, this.contactsPouch, this.selectedInstance, item, true)

          chat.users = [
            {
              _id: item.data_channel,
              username: 'You',
              avatar: '',
              status: null,
            },
            {
              _id: item.phone_number,
              username: item.name,
              avatar: '',
              status: null,
            },
          ]

          // check group participants
          if (item.users) {
            const { participants } = item.users

            if (participants && participants.length > 0) {
              const usersGroup = []

              participants.forEach(participant => {
                const phoneNumber = participant.id.replace('c.us', 's.whatsapp.net')
                const id = phoneNumber.substr(0, phoneNumber.indexOf('@'))

                if (this.instances) {
                  const instance = this.instances.find(instance => instance.phone_number == item.data_channel)
                  const conversation = {
                    person: phoneNumber,
                  }

                  if (instance) {
                    conversation.instance = instance
                  }

                  const user = {
                    _id: id,
                    username: this.getContactsName(
                      phoneNumber,
                      this.contactsPouch,
                      this.selectedInstance,
                      chat.instance,
                    ),
                    avatar: this.getProfilePicture(conversation),
                    status: null,
                  }

                  usersGroup.push(user)
                }
              })

              chat.users = usersGroup
            }
          }
        }
      }

      return chat
    },
    getContactsName(id, contacts, selectedInstance, instance = null) {
      const parseId = id.substr(0, id.indexOf('@'))
      let phoneNumber = parseId

      if (!parseId.includes('-') && parseId.length <= 16) {
        const countryCode = `+${parseId.substr(0, 2)}` // +62
        const splitOne = parseId.substr(2, 3)
        const splitTwo = parseId.substr(5, 4)
        const splitThree = parseId.substr(9, parseId.length - 1)
        phoneNumber = `${countryCode} ${splitOne}-${splitTwo}-${splitThree}`
      }

      const contact = contacts.find(contact => contact.phone_number === parseId)

      if (contact) {
        if (contact.name) {
          phoneNumber = contact.name
        }
      }

      if (instance) {
        var source = instance.phone_number
        if (parseId == source) {
          phoneNumber = 'You'
        }
      } else {
        var source = selectedInstance.phone_number
        if (parseId == source) {
          phoneNumber = 'You'
        }
      }

      return phoneNumber
    },
    getContactsFavourite(id) {
      const parseId = id.substr(0, id.indexOf('@'))

      const contact = this.contacts.find(x => x.phone_number === parseId)

      if (contact) {
        return contact.favourite ? 1 : 0
      }

      return 0
    },
    getProfilePicture(conversation) {
      let person = ''
      let phoneNumber = ''

      if (conversation) {
        person = conversation.person
      }

      if (person) {
        phoneNumber = person.substr(0, person.indexOf('@'))
      }

      if (conversation.instance) {
        return `${process.env.VUE_APP_WA_API_URL}ppic/${conversation.instance.sub_id}-${conversation.instance.instance_id}/${phoneNumber}/a.jpg`
      }

      if (this.selectedInstance) {
        return `${process.env.VUE_APP_WA_API_URL}ppic/${this.selectedInstance.sub_id}-${this.selectedInstance.instance_id}/${phoneNumber}/a.jpg`
      }

      return ''
    },
    getHeaderDate(idx, data, chat) {
      if (idx === 0) {
        return this.getHeaderText(data)
      }
      if (data.messageTimestamp) {
        if (
          !this.$moment(data.messageTimestamp * 1000).isSame(this.$moment(chat[idx - 1].messageTimestamp * 1000), 'day')
        ) {
          return this.getHeaderText(data)
        }
      } else {
        return ''
      }

      return ''
    },
    getHeaderText(data) {
      const locale = this.$store.getters['global/getLocale']

      if (data.messageTimestamp) {
        if (this.$moment(data.messageTimestamp * 1000).isSame(this.$moment(), 'day')) {
          return locale == 'en' ? 'Today' : 'Hari ini'
        }
        if (this.$moment(data.messageTimestamp * 1000).isSame(this.$moment().subtract(1, 'day'), 'day')) {
          return locale == 'en' ? 'Yesterday' : 'Kemarin'
        }

        return this.$moment(data.messageTimestamp * 1000).format('DD/MM/YY')
      }

      return ''
    },
    setContactInbox(data, id, contacts, selectedInstance, currentContact, isTransform = false) {
      const self = this
      const keyId = data.key.id
      const person = data.key.remoteJid
      const sent = data.key.fromMe
      let mentionedJid = []
      let type = null
      let conv = ''
      let participant = ''
      let messageStatus = null
      let username = ''
      const conversation = {
        person,
      }
      let instance = this.optionsInstances.find(item => item.value.phone_number == data.source)

      if (instance) {
        instance = instance.value
        conversation.instance = instance
      } else {
        instance = null
      }

      if (data.message) {
        if (data.message.conversation) {
          conv = data.message.conversation
        }

        if (data.message.protocolMessage) {
          type = 'deleted'

          if (sent) {
            conv = 'You deleted this message.'
          } else {
            conv = 'This message was deleted.'
          }
        }

        if (data.message.templateMessage) {
          if (data.message.templateMessage.hydratedTemplate) {
            if (data.message.templateMessage.hydratedTemplate.Title) {
              if (data.message.templateMessage.hydratedTemplate.Title.ImageMessage) {
                type = 'image'
              }
            }

            conv = data.message.templateMessage.hydratedTemplate.hydratedContentText
          }
        }

        if (data.message.viewOnceMessage) {
          type = 'image'
          conv = 'Photo'

          if (data.message.viewOnceMessage.message) {
            if (data.message.viewOnceMessage.message.imageMessage) {
              if (data.message.viewOnceMessage.message.imageMessage.caption) {
                conv = data.message.viewOnceMessage.message.imageMessage.caption
              }
            }
          }
        }

        if (data.message.imageMessage) {
          type = 'image'

          if (data.message.imageMessage.caption) {
            conv = data.message.imageMessage.caption
          } else {
            conv = 'Photo'
          }
        }

        if (data.message.locationMessage) {
          type = 'location'

          if (data.message.locationMessage.name) {
            conv = data.message.locationMessage.name
          } else {
            conv = 'Location'
          }

          if (data.message.locationMessage.address) {
            conv += `, ${data.message.locationMessage.address}`
          }

          conv += ` https://maps.google.com/?q=${data.message.locationMessage.degreesLatitude},${data.message.locationMessage.degreesLongitude}`
        }

        if (data.message.liveLocationMessage) {
          type = 'location'

          if (data.message.liveLocationMessage.caption) {
            conv = data.message.liveLocationMessage.caption
          } else {
            conv = 'Live Location'
          }

          if (data.message.liveLocationMessage.address) {
            conv += `, ${data.message.liveLocationMessage.address}`
          }

          conv += ` https://maps.google.com/?q=${data.message.liveLocationMessage.degreesLatitude},${data.message.liveLocationMessage.degreesLongitude}`
        }

        if (data.message.contactMessage) {
          type = 'contact'
          conv = data.message.contactMessage.displayName
        }

        if (data.message.audioMessage) {
          type = 'audio'
          conv = 'Audio'
        }

        if (data.message.documentMessage) {
          type = 'document'
          conv = data.message.documentMessage.fileName
            ? data.message.documentMessage.fileName
            : data.message.documentMessage.title
        }

        if (data.message.stickerMessage) {
          type = 'sticker'
          conv = 'Sticker'
        }

        if (data.message.videoMessage) {
          type = 'video'
          conv = 'Video'
        }

        if (data.message.extendedTextMessage) {
          if (data.message.extendedTextMessage.text) {
            conv = data.message.extendedTextMessage.text
          }

          if (data.message.extendedTextMessage.contextInfo) {
            if (data.message.extendedTextMessage.contextInfo.mentionedJid) {
              mentionedJid = data.message.extendedTextMessage.contextInfo.mentionedJid

              conv = this.parseMentionedJid(mentionedJid, conv)
            }
          }
        }
      }

      if (data.participant) {
        participant = data.participant
        username = self.getContactsName(participant, contacts, selectedInstance, instance)
      }

      let time = self.$moment().format('DD/MM/YYYY HH:mm')
      let timeLastMessage = self.$moment().format()
      let messageTimestamp = self.$moment().unix()

      if (data.messageTimestamp) {
        const format = self.$moment(data.messageTimestamp * 1000).isSame(self.$moment(), 'day') ? 'HH:mm' : 'DD/MM/YYYY'
        messageTimestamp = data.messageTimestamp
        time = self.$moment(data.messageTimestamp * 1000).format(format)
        timeLastMessage = self.$moment.unix(data.messageTimestamp).format()
      }

      if (data.status) {
        messageStatus = data.status
      }

      // normal
      const name = this.getContactsName(person, contacts, selectedInstance, instance)

      // transform
      // const name = currentContact.name

      const favourite = currentContact.favourite ? (currentContact.favourite === true ? 1 : 0) : 0

      let caption = this.sanitizeCaption(conv)
      caption = _unescape(caption)
      caption = caption
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
        .replace(/&amp;/g, '&')
        .replace(/&quot;/g, '"')
      const obj = {
        id: `${person}-${data.source}`,
        keyId,
        name,
        person,
        caption,
        time,
        messageTimestamp,
        sent,
        data,
        participant,
        messageStatus,
        type,
        favourite,
        instance,
        contact: currentContact,

        // advanced chat
        roomId: `${person}-${data.source}`,
        roomName: name,
        avatar: this.getProfilePicture(conversation),
        unreadCount: 0,

        // index: messageTimestamp,
        lastMessage: {
          content: caption,
          senderId: id,
          username: participant ? username : this.$t('you'),
          timestamp: time,
          saved: messageStatus <= 1,
          send: messageStatus == 2,
          distributed: messageStatus == 3,
          seen: messageStatus >= 4,
          new: false,
        },
        users: [],
        typingUsers: [],
      }

      return obj
    },
    setChatContactInbox(detail, instance, contacts, selectedInstance) {
      const captionKeys = ['extendedTextMessage', 'imageMessage', 'viewOnceMessage']

      if (detail.message) {
        const keyId = detail.key.id
        const person = detail.key.remoteJid
        let sent = true
        let quotedParticipant = ''
        let quoted = ''
        let quotedData = ''
        const quotedImage = ''
        const quotedType = ''
        let imgBase64 = ''
        let docBase64 = ''
        let type = ''
        let mentionedJid = []
        let status = null
        let deleted = false
        const selected = false
        let conv = ''
        let gmapsUrl = ''
        let participant = ''
        let paramBlob = null
        const contactName = this.getContactsName(person, contacts, selectedInstance, instance)
        let contactPhoneNumber = person.substr(0, person.indexOf('@'))
        const channelName = instance.label
        const channelPhoneNumber = instance.phone_number
        let username = ''
        let isButtons = []

        if (person.includes('-') || person.includes('@g')) {
          if (detail.key.fromMe) {
            username = this.getContactsName(
              `${channelPhoneNumber}@s.whatsapp.net`,
              contacts,
              selectedInstance,
              instance,
            )
          } else {
            username = this.getContactsName(detail.participant, contacts, selectedInstance, instance)
          }
        } else {
          // eslint-disable-next-line no-lonely-if
          if (detail.key.fromMe) {
            username = 'You'
          } else {
            username = contactName
          }
        }

        if (contactPhoneNumber.includes('-') || contactPhoneNumber.length > 16) {
          contactPhoneNumber = 'Group'
        }

        if (detail.key) {
          sent = detail.key.fromMe
        }

        if (detail.status) {
          status = detail.status
        }

        if (detail.message.conversation) {
          conv = detail.message.conversation
        }

        if (detail.message.protocolMessage) {
          deleted = true

          if (sent) {
            conv = 'You deleted this message.'
          } else {
            conv = 'This message was deleted.'
          }
        }

        if (detail.message.templateMessage) {
          if (detail.message.templateMessage.hydratedTemplate) {
            if (detail.message.templateMessage.hydratedTemplate.Title) {
              if (detail.message.templateMessage.hydratedTemplate.Title.ImageMessage) {
                if (detail.message.templateMessage.hydratedTemplate.Title.ImageMessage.jpegThumbnail) {
                  imgBase64 = `data:${detail.message.templateMessage.hydratedTemplate.Title.ImageMessage.mimetype};base64, ${detail.message.templateMessage.hydratedTemplate.Title.ImageMessage.jpegThumbnail}`
                }

                paramBlob = {
                  urlType: 'img',
                  url: detail.message.templateMessage.hydratedTemplate.Title.ImageMessage.url,
                  fileLength: detail.message.templateMessage.hydratedTemplate.Title.ImageMessage.fileLength,
                  mediaKey: detail.message.templateMessage.hydratedTemplate.Title.ImageMessage.mediaKey,
                  mimeType: detail.message.templateMessage.hydratedTemplate.Title.ImageMessage.mimetype,
                  directPath: detail.message.templateMessage.hydratedTemplate.Title.ImageMessage.directPath
                    ? detail.message.templateMessage.hydratedTemplate.Title.ImageMessage.directPath
                    : null,
                  fileEncSha256: detail.message.templateMessage.hydratedTemplate.Title.ImageMessage.fileEncSha256,
                }
              }
            }

            if (
              detail.message.templateMessage.hydratedTemplate.hydratedButtons &&
              detail.message.templateMessage.hydratedTemplate.hydratedButtons.length > 0
            ) {
              isButtons = detail.message.templateMessage.hydratedTemplate.hydratedButtons
            }

            conv = detail.message.templateMessage.hydratedTemplate.hydratedContentText
          }
        }

        if (detail.message.locationMessage) {
          if (detail.message.locationMessage.name) {
            conv = detail.message.locationMessage.name
          } else {
            conv = 'Location'
          }

          if (detail.message.locationMessage.address) {
            conv += `, ${detail.message.locationMessage.address}`
          }

          gmapsUrl = `https://maps.google.com/?q=${detail.message.locationMessage.degreesLatitude},${detail.message.locationMessage.degreesLongitude}`

          type = 'location'

          if (detail.message.locationMessage.contextInfo) {
            quotedData = detail.message.locationMessage.contextInfo

            if (detail.message.locationMessage.contextInfo.participant) {
              quotedParticipant = detail.message.locationMessage.contextInfo.participant
            }

            if (detail.message.locationMessage.contextInfo.quotedMessage) {
              captionKeys.forEach(key => {
                if (detail.message.locationMessage.contextInfo.quotedMessage[key]) {
                  if (detail.message.locationMessage.contextInfo.quotedMessage[key].contextInfo) {
                    if (detail.message.locationMessage.contextInfo.quotedMessage[key].contextInfo.mentionedJid) {
                      mentionedJid =
                        detail.message.locationMessage.contextInfo.quotedMessage[key].contextInfo.mentionedJid
                    }
                  }

                  if (key == 'extendedTextMessage') {
                    quoted = this.parseMentionedJid(
                      mentionedJid,
                      detail.message.locationMessage.contextInfo.quotedMessage.extendedTextMessage.text,
                    )
                  } else if (key == 'imageMessage') {
                    detail.message.locationMessage.contextInfo.quotedMessage.imageMessage.caption =
                      this.parseMentionedJid(
                        mentionedJid,
                        detail.message.locationMessage.contextInfo.quotedMessage.imageMessage.caption,
                      )
                  } else if (key == 'viewOnceMessage') {
                    if (
                      detail.message.locationMessage.contextInfo.quotedMessage.viewOnceMessage &&
                      detail.message.locationMessage.contextInfo.quotedMessage.viewOnceMessage.message &&
                      detail.message.locationMessage.contextInfo.quotedMessage.viewOnceMessage.message.imageMessage
                    ) {
                      const parseQuoted = this.parseMentionedJid(
                        mentionedJid,
                        detail.message.locationMessage.contextInfo.quotedMessage.viewOnceMessage.message.imageMessage,
                      )

                      detail.message.locationMessage.contextInfo.quotedMessage.viewOnceMessage.message.imageMessage =
                        parseQuoted

                      if (parseQuoted.caption) {
                        quoted = parseQuoted.caption
                      }
                    }
                  }
                }
              })

              if (detail.message.locationMessage.contextInfo.quotedMessage.conversation) {
                quoted = detail.message.locationMessage.contextInfo.quotedMessage.conversation
              }

              if (detail.message.locationMessage.contextInfo.quotedMessage.imageMessage) {
                if (detail.message.locationMessage.contextInfo.quotedMessage.imageMessage.caption) {
                  quoted = detail.message.locationMessage.contextInfo.quotedMessage.imageMessage.caption
                }
              }

              if (detail.message.locationMessage.contextInfo.quotedMessage.locationMessage) {
                if (detail.message.locationMessage.contextInfo.quotedMessage.locationMessage.name) {
                  quoted = detail.message.locationMessage.contextInfo.quotedMessage.locationMessage.name
                }

                if (detail.message.locationMessage.contextInfo.quotedMessage.locationMessage.address) {
                  quoted += `, ${detail.message.locationMessage.contextInfo.quotedMessage.locationMessage.address}`
                }
              }
            }

            if (detail.message.locationMessage.contextInfo.mentionedJid) {
              mentionedJid = detail.message.locationMessage.contextInfo.mentionedJid

              conv = this.parseMentionedJid(mentionedJid, conv)
            }
          }
        }

        if (detail.message.liveLocationMessage) {
          if (detail.message.liveLocationMessage.caption) {
            conv = detail.message.liveLocationMessage.caption
          } else {
            conv = 'Live Location'
          }

          if (detail.message.liveLocationMessage.address) {
            conv += `, ${detail.message.liveLocationMessage.address}`
          }

          gmapsUrl = `https://maps.google.com/?q=${detail.message.liveLocationMessage.degreesLatitude},${detail.message.liveLocationMessage.degreesLongitude}`

          type = 'location'

          if (detail.message.liveLocationMessage.contextInfo) {
            quotedData = detail.message.liveLocationMessage.contextInfo

            if (detail.message.liveLocationMessage.contextInfo.participant) {
              quotedParticipant = detail.message.liveLocationMessage.contextInfo.participant
            }

            if (detail.message.liveLocationMessage.contextInfo.quotedMessage) {
              captionKeys.forEach(key => {
                if (detail.message.liveLocationMessage.contextInfo.quotedMessage[key]) {
                  if (detail.message.liveLocationMessage.contextInfo.quotedMessage[key].contextInfo) {
                    if (detail.message.liveLocationMessage.contextInfo.quotedMessage[key].contextInfo.mentionedJid) {
                      mentionedJid =
                        detail.message.liveLocationMessage.contextInfo.quotedMessage[key].contextInfo.mentionedJid
                    }
                  }

                  if (key == 'extendedTextMessage') {
                    quoted = this.parseMentionedJid(
                      mentionedJid,
                      detail.message.liveLocationMessage.contextInfo.quotedMessage.extendedTextMessage.text,
                    )
                  } else if (key == 'imageMessage') {
                    detail.message.liveLocationMessage.contextInfo.quotedMessage.imageMessage.caption =
                      this.parseMentionedJid(
                        mentionedJid,
                        detail.message.liveLocationMessage.contextInfo.quotedMessage.imageMessage.caption,
                      )
                  } else if (key == 'viewOnceMessage') {
                    if (
                      detail.message.liveLocationMessage.contextInfo.quotedMessage.viewOnceMessage &&
                      detail.message.liveLocationMessage.contextInfo.quotedMessage.viewOnceMessage.message &&
                      detail.message.liveLocationMessage.contextInfo.quotedMessage.viewOnceMessage.message.imageMessage
                    ) {
                      const parseQuoted = this.parseMentionedJid(
                        mentionedJid,
                        detail.message.liveLocationMessage.contextInfo.quotedMessage.viewOnceMessage.message
                          .imageMessage,
                      )

                      detail.message.liveLocationMessage.contextInfo.quotedMessage.viewOnceMessage.message.imageMessage =
                        parseQuoted

                      if (parseQuoted.caption) {
                        quoted = parseQuoted.caption
                      }
                    }
                  }
                }
              })

              if (detail.message.liveLocationMessage.contextInfo.quotedMessage.conversation) {
                quoted = detail.message.liveLocationMessage.contextInfo.quotedMessage.conversation
              }

              if (detail.message.liveLocationMessage.contextInfo.quotedMessage.templateMessage) {
                if (detail.message.liveLocationMessage.contextInfo.quotedMessage.templateMessage.hydratedTemplate) {
                  quoted =
                    detail.message.liveLocationMessage.contextInfo.quotedMessage.templateMessage.hydratedTemplate
                      .hydratedContentText
                }
              }

              if (detail.message.liveLocationMessage.contextInfo.quotedMessage.imageMessage) {
                if (detail.message.liveLocationMessage.contextInfo.quotedMessage.imageMessage.caption) {
                  quoted = detail.message.liveLocationMessage.contextInfo.quotedMessage.imageMessage.caption
                }
              }

              if (detail.message.liveLocationMessage.contextInfo.quotedMessage.locationMessage) {
                if (detail.message.liveLocationMessage.contextInfo.quotedMessage.locationMessage.name) {
                  quoted = detail.message.liveLocationMessage.contextInfo.quotedMessage.locationMessage.name
                }

                if (detail.message.liveLocationMessage.contextInfo.quotedMessage.locationMessage.address) {
                  quoted += `, ${detail.message.liveLocationMessage.contextInfo.quotedMessage.locationMessage.address}`
                }
              }
            }

            if (detail.message.liveLocationMessage.contextInfo.mentionedJid) {
              mentionedJid = detail.message.liveLocationMessage.contextInfo.mentionedJid

              conv = this.parseMentionedJid(mentionedJid, conv)
            }
          }
        }

        if (detail.message.contactMessage) {
          conv = detail.message.contactMessage.displayName
          type = 'contactFile'

          if (detail.message.contactMessage.contextInfo) {
            quotedData = detail.message.contactMessage.contextInfo

            if (detail.message.contactMessage.contextInfo.participant) {
              quotedParticipant = detail.message.contactMessage.contextInfo.participant
            }

            if (detail.message.contactMessage.contextInfo.quotedMessage) {
              captionKeys.forEach(key => {
                if (detail.message.contactMessage.contextInfo.quotedMessage[key]) {
                  if (detail.message.contactMessage.contextInfo.quotedMessage[key].contextInfo) {
                    if (detail.message.contactMessage.contextInfo.quotedMessage[key].contextInfo.mentionedJid) {
                      mentionedJid =
                        detail.message.contactMessage.contextInfo.quotedMessage[key].contextInfo.mentionedJid
                    }
                  }

                  if (key == 'extendedTextMessage') {
                    quoted = this.parseMentionedJid(
                      mentionedJid,
                      detail.message.contactMessage.contextInfo.quotedMessage.extendedTextMessage.text,
                    )
                  } else if (key == 'imageMessage') {
                    detail.message.contactMessage.contextInfo.quotedMessage.imageMessage.caption =
                      this.parseMentionedJid(
                        mentionedJid,
                        detail.message.contactMessage.contextInfo.quotedMessage.imageMessage.caption,
                      )
                  } else if (key == 'viewOnceMessage') {
                    if (
                      detail.message.contactMessage.contextInfo.quotedMessage.viewOnceMessage &&
                      detail.message.contactMessage.contextInfo.quotedMessage.viewOnceMessage.message &&
                      detail.message.contactMessage.contextInfo.quotedMessage.viewOnceMessage.message.imageMessage
                    ) {
                      const parseQuoted = this.parseMentionedJid(
                        mentionedJid,
                        detail.message.contactMessage.contextInfo.quotedMessage.viewOnceMessage.message.imageMessage,
                      )

                      detail.message.contactMessage.contextInfo.quotedMessage.viewOnceMessage.message.imageMessage =
                        parseQuoted

                      if (parseQuoted.caption) {
                        quoted = parseQuoted.caption
                      }
                    }
                  }
                }
              })

              if (detail.message.contactMessage.contextInfo.quotedMessage.conversation) {
                quoted = detail.message.contactMessage.contextInfo.quotedMessage.conversation
              }

              if (detail.message.contactMessage.contextInfo.quotedMessage.templateMessage) {
                if (detail.message.contactMessage.contextInfo.quotedMessage.templateMessage.hydratedTemplate) {
                  quoted =
                    detail.message.contactMessage.contextInfo.quotedMessage.templateMessage.hydratedTemplate
                      .hydratedContentText
                }
              }

              if (detail.message.contactMessage.contextInfo.quotedMessage.imageMessage) {
                if (detail.message.contactMessage.contextInfo.quotedMessage.imageMessage.caption) {
                  quoted = detail.message.contactMessage.contextInfo.quotedMessage.imageMessage.caption
                }
              }

              if (detail.message.contactMessage.contextInfo.quotedMessage.locationMessage) {
                if (detail.message.contactMessage.contextInfo.quotedMessage.locationMessage.name) {
                  quoted = detail.message.contactMessage.contextInfo.quotedMessage.locationMessage.name
                }

                if (detail.message.contactMessage.contextInfo.quotedMessage.locationMessage.address) {
                  quoted += `, ${detail.message.contactMessage.contextInfo.quotedMessage.locationMessage.address}`
                }
              }
            }

            if (detail.message.contactMessage.contextInfo.mentionedJid) {
              mentionedJid = detail.message.contactMessage.contextInfo.mentionedJid

              conv = this.parseMentionedJid(mentionedJid, conv)
            }
          }
        }

        if (detail.message.audioMessage) {
          conv = 'Audio'
          type = 'audioFile'

          paramBlob = {
            urlType: 'aud',
            url: detail.message.audioMessage.url,
            fileLength: detail.message.audioMessage.fileLength,
            mediaKey: detail.message.audioMessage.mediaKey,
            mimeType: detail.message.audioMessage.mimetype,
            directPath: detail.message.audioMessage.directPath ? detail.message.audioMessage.directPath : null,
            fileEncSha256: detail.message.audioMessage.fileEncSha256,
            audio: true,
            duration: detail.message.audioMessage.seconds ? detail.message.audioMessage.seconds : 0,
          }

          if (detail.message.audioMessage.contextInfo) {
            quotedData = detail.message.audioMessage.contextInfo

            if (detail.message.audioMessage.contextInfo.participant) {
              quotedParticipant = detail.message.audioMessage.contextInfo.participant
            }

            if (detail.message.audioMessage.contextInfo.quotedMessage) {
              captionKeys.forEach(key => {
                if (detail.message.audioMessage.contextInfo.quotedMessage[key]) {
                  if (detail.message.audioMessage.contextInfo.quotedMessage[key].contextInfo) {
                    if (detail.message.audioMessage.contextInfo.quotedMessage[key].contextInfo.mentionedJid) {
                      mentionedJid = detail.message.audioMessage.contextInfo.quotedMessage[key].contextInfo.mentionedJid
                    }
                  }

                  if (key == 'extendedTextMessage') {
                    quoted = this.parseMentionedJid(
                      mentionedJid,
                      detail.message.audioMessage.contextInfo.quotedMessage.extendedTextMessage.text,
                    )
                  } else if (key == 'imageMessage') {
                    detail.message.audioMessage.contextInfo.quotedMessage.imageMessage.caption = this.parseMentionedJid(
                      mentionedJid,
                      detail.message.audioMessage.contextInfo.quotedMessage.imageMessage.caption,
                    )
                  } else if (key == 'viewOnceMessage') {
                    if (
                      detail.message.audioMessage.contextInfo.quotedMessage.viewOnceMessage &&
                      detail.message.audioMessage.contextInfo.quotedMessage.viewOnceMessage.message &&
                      detail.message.audioMessage.contextInfo.quotedMessage.viewOnceMessage.message.imageMessage
                    ) {
                      const parseQuoted = this.parseMentionedJid(
                        mentionedJid,
                        detail.message.audioMessage.contextInfo.quotedMessage.viewOnceMessage.message.imageMessage,
                      )

                      detail.message.audioMessage.contextInfo.quotedMessage.viewOnceMessage.message.imageMessage =
                        parseQuoted

                      if (parseQuoted.caption) {
                        quoted = parseQuoted.caption
                      }
                    }
                  }
                }
              })

              if (detail.message.audioMessage.contextInfo.quotedMessage.conversation) {
                quoted = detail.message.audioMessage.contextInfo.quotedMessage.conversation
              }

              if (detail.message.audioMessage.contextInfo.quotedMessage.templateMessage) {
                if (detail.message.audioMessage.contextInfo.quotedMessage.templateMessage.hydratedTemplate) {
                  quoted =
                    detail.message.audioMessage.contextInfo.quotedMessage.templateMessage.hydratedTemplate
                      .hydratedContentText
                }
              }

              if (detail.message.audioMessage.contextInfo.quotedMessage.imageMessage) {
                if (detail.message.audioMessage.contextInfo.quotedMessage.imageMessage.caption) {
                  quoted = detail.message.audioMessage.contextInfo.quotedMessage.imageMessage.caption
                }
              }

              if (detail.message.audioMessage.contextInfo.quotedMessage.locationMessage) {
                if (detail.message.audioMessage.contextInfo.quotedMessage.locationMessage.name) {
                  quoted = detail.message.audioMessage.contextInfo.quotedMessage.locationMessage.name
                }

                if (detail.message.audioMessage.contextInfo.quotedMessage.locationMessage.address) {
                  quoted += `, ${detail.message.audioMessage.contextInfo.quotedMessage.locationMessage.address}`
                }
              }
            }

            if (detail.message.audioMessage.contextInfo.mentionedJid) {
              mentionedJid = detail.message.audioMessage.contextInfo.mentionedJid

              conv = this.parseMentionedJid(mentionedJid, conv)
            }
          }
        }

        if (detail.message.documentMessage) {
          if (detail.message.documentMessage.jpegThumbnail) {
            docBase64 = `data:${detail.message.documentMessage.mimetype};base64, ${detail.message.documentMessage.jpegThumbnail}`
          }

          paramBlob = {
            urlType: 'doc',
            url: detail.message.documentMessage.url,
            fileLength: detail.message.documentMessage.fileLength,
            mediaKey: detail.message.documentMessage.mediaKey,
            mimeType: detail.message.documentMessage.mimetype,
            directPath: detail.message.documentMessage.directPath ? detail.message.documentMessage.directPath : null,
            fileEncSha256: detail.message.documentMessage.fileEncSha256,
            fileName: detail.message.documentMessage.fileName
              ? detail.message.documentMessage.fileName
              : detail.message.documentMessage.title,
          }

          conv = detail.message.documentMessage.fileName
            ? detail.message.documentMessage.fileName
            : detail.message.documentMessage.title

          type = 'docFile'

          if (detail.message.documentMessage.contextInfo) {
            quotedData = detail.message.documentMessage.contextInfo

            if (detail.message.documentMessage.contextInfo.participant) {
              quotedParticipant = detail.message.documentMessage.contextInfo.participant
            }

            if (detail.message.documentMessage.contextInfo.quotedMessage) {
              captionKeys.forEach(key => {
                if (detail.message.documentMessage.contextInfo.quotedMessage[key]) {
                  if (detail.message.documentMessage.contextInfo.quotedMessage[key].contextInfo) {
                    if (detail.message.documentMessage.contextInfo.quotedMessage[key].contextInfo.mentionedJid) {
                      mentionedJid =
                        detail.message.documentMessage.contextInfo.quotedMessage[key].contextInfo.mentionedJid
                    }
                  }

                  if (key == 'extendedTextMessage') {
                    quoted = this.parseMentionedJid(
                      mentionedJid,
                      detail.message.documentMessage.contextInfo.quotedMessage.extendedTextMessage.text,
                    )
                  } else if (key == 'imageMessage') {
                    detail.message.documentMessage.contextInfo.quotedMessage.imageMessage.caption =
                      this.parseMentionedJid(
                        mentionedJid,
                        detail.message.documentMessage.contextInfo.quotedMessage.imageMessage.caption,
                      )
                  } else if (key == 'viewOnceMessage') {
                    if (
                      detail.message.documentMessage.contextInfo.quotedMessage.viewOnceMessage &&
                      detail.message.documentMessage.contextInfo.quotedMessage.viewOnceMessage.message &&
                      detail.message.documentMessage.contextInfo.quotedMessage.viewOnceMessage.message.imageMessage
                    ) {
                      const parseQuoted = this.parseMentionedJid(
                        mentionedJid,
                        detail.message.documentMessage.contextInfo.quotedMessage.viewOnceMessage.message.imageMessage,
                      )

                      detail.message.documentMessage.contextInfo.quotedMessage.viewOnceMessage.message.imageMessage =
                        parseQuoted

                      if (parseQuoted.caption) {
                        quoted = parseQuoted.caption
                      }
                    }
                  }
                }
              })

              if (detail.message.documentMessage.contextInfo.quotedMessage.conversation) {
                quoted = detail.message.documentMessage.contextInfo.quotedMessage.conversation
              }

              if (detail.message.documentMessage.contextInfo.quotedMessage.templateMessage) {
                if (detail.message.documentMessage.contextInfo.quotedMessage.templateMessage.hydratedTemplate) {
                  quoted =
                    detail.message.documentMessage.contextInfo.quotedMessage.templateMessage.hydratedTemplate
                      .hydratedContentText
                }
              }

              if (detail.message.documentMessage.contextInfo.quotedMessage.imageMessage) {
                if (detail.message.documentMessage.contextInfo.quotedMessage.imageMessage.caption) {
                  quoted = detail.message.documentMessage.contextInfo.quotedMessage.imageMessage.caption
                }
              }

              if (detail.message.documentMessage.contextInfo.quotedMessage.locationMessage) {
                if (detail.message.documentMessage.contextInfo.quotedMessage.locationMessage.name) {
                  quoted = detail.message.documentMessage.contextInfo.quotedMessage.locationMessage.name
                }

                if (detail.message.documentMessage.contextInfo.quotedMessage.locationMessage.address) {
                  quoted += `, ${detail.message.documentMessage.contextInfo.quotedMessage.locationMessage.address}`
                }
              }
            }

            if (detail.message.documentMessage.contextInfo.mentionedJid) {
              mentionedJid = detail.message.documentMessage.contextInfo.mentionedJid

              conv = this.parseMentionedJid(mentionedJid, conv)
            }
          }
        }

        if (detail.message.videoMessage) {
          if (detail.message.videoMessage.jpegThumbnail) {
            docBase64 = `data:${detail.message.videoMessage.mimetype};base64, ${detail.message.videoMessage.jpegThumbnail}`
          }

          paramBlob = {
            urlType: 'vid',
            url: detail.message.videoMessage.url,
            fileLength: detail.message.videoMessage.fileLength,
            mediaKey: detail.message.videoMessage.mediaKey,
            mimeType: detail.message.videoMessage.mimetype,
            directPath: detail.message.videoMessage.directPath ? detail.message.videoMessage.directPath : null,
            fileEncSha256: detail.message.videoMessage.fileEncSha256,
          }

          if (detail.message.videoMessage.caption) {
            conv = detail.message.videoMessage.caption
          }

          type = 'videoFile'

          if (detail.message.videoMessage.contextInfo) {
            quotedData = detail.message.videoMessage.contextInfo

            if (detail.message.videoMessage.contextInfo.participant) {
              quotedParticipant = detail.message.videoMessage.contextInfo.participant
            }

            if (detail.message.videoMessage.contextInfo.quotedMessage) {
              captionKeys.forEach(key => {
                if (detail.message.videoMessage.contextInfo.quotedMessage[key]) {
                  if (detail.message.videoMessage.contextInfo.quotedMessage[key].contextInfo) {
                    if (detail.message.videoMessage.contextInfo.quotedMessage[key].contextInfo.mentionedJid) {
                      mentionedJid = detail.message.videoMessage.contextInfo.quotedMessage[key].contextInfo.mentionedJid
                    }
                  }

                  if (key == 'extendedTextMessage') {
                    quoted = this.parseMentionedJid(
                      mentionedJid,
                      detail.message.videoMessage.contextInfo.quotedMessage.extendedTextMessage.text,
                    )
                  } else if (key == 'imageMessage') {
                    detail.message.videoMessage.contextInfo.quotedMessage.imageMessage.caption = this.parseMentionedJid(
                      mentionedJid,
                      detail.message.videoMessage.contextInfo.quotedMessage.imageMessage.caption,
                    )
                  } else if (key == 'viewOnceMessage') {
                    if (
                      detail.message.videoMessage.contextInfo.quotedMessage.viewOnceMessage &&
                      detail.message.videoMessage.contextInfo.quotedMessage.viewOnceMessage.message &&
                      detail.message.videoMessage.contextInfo.quotedMessage.viewOnceMessage.message.imageMessage
                    ) {
                      const parseQuoted = this.parseMentionedJid(
                        mentionedJid,
                        detail.message.videoMessage.contextInfo.quotedMessage.viewOnceMessage.message.imageMessage,
                      )

                      detail.message.videoMessage.contextInfo.quotedMessage.viewOnceMessage.message.imageMessage =
                        parseQuoted

                      if (parseQuoted.caption) {
                        quoted = parseQuoted.caption
                      }
                    }
                  }
                }
              })

              if (detail.message.videoMessage.contextInfo.quotedMessage.conversation) {
                quoted = detail.message.videoMessage.contextInfo.quotedMessage.conversation
              }

              if (detail.message.videoMessage.contextInfo.quotedMessage.templateMessage) {
                if (detail.message.videoMessage.contextInfo.quotedMessage.templateMessage.hydratedTemplate) {
                  quoted =
                    detail.message.videoMessage.contextInfo.quotedMessage.templateMessage.hydratedTemplate
                      .hydratedContentText
                }
              }

              if (detail.message.videoMessage.contextInfo.quotedMessage.imageMessage) {
                if (detail.message.videoMessage.contextInfo.quotedMessage.imageMessage.caption) {
                  quoted = detail.message.videoMessage.contextInfo.quotedMessage.imageMessage.caption
                }
              }

              if (detail.message.videoMessage.contextInfo.quotedMessage.locationMessage) {
                if (detail.message.videoMessage.contextInfo.quotedMessage.locationMessage.name) {
                  quoted = detail.message.videoMessage.contextInfo.quotedMessage.locationMessage.name
                }

                if (detail.message.videoMessage.contextInfo.quotedMessage.locationMessage.address) {
                  quoted += `, ${detail.message.videoMessage.contextInfo.quotedMessage.locationMessage.address}`
                }
              }
            }

            if (detail.message.videoMessage.contextInfo.mentionedJid) {
              mentionedJid = detail.message.videoMessage.contextInfo.mentionedJid

              conv = this.parseMentionedJid(mentionedJid, conv)
            }
          }
        }

        if (detail.message.stickerMessage) {
          paramBlob = {
            urlType: 'img',
            url: detail.message.stickerMessage.url ? detail.message.stickerMessage.url : null,
            fileLength: detail.message.stickerMessage.fileLength,
            mediaKey: detail.message.stickerMessage.mediaKey,
            mimeType: detail.message.stickerMessage.mimetype,
            directPath: detail.message.stickerMessage.directPath ? detail.message.stickerMessage.directPath : null,
            fileEncSha256: detail.message.stickerMessage.fileEncSha256,
          }
        }

        if (detail.message.imageMessage) {
          if (detail.message.imageMessage.jpegThumbnail) {
            imgBase64 = `data:${detail.message.imageMessage.mimetype};base64, ${detail.message.imageMessage.jpegThumbnail}`
          }

          paramBlob = {
            urlType: 'img',
            url: detail.message.imageMessage.url,
            fileLength: detail.message.imageMessage.fileLength,
            mediaKey: detail.message.imageMessage.mediaKey,
            mimeType: detail.message.imageMessage.mimetype,
            directPath: detail.message.imageMessage.directPath ? detail.message.imageMessage.directPath : null,
            fileEncSha256: detail.message.imageMessage.fileEncSha256,
          }

          if (detail.message.imageMessage.caption) {
            conv = detail.message.imageMessage.caption
          }

          if (detail.message.imageMessage.contextInfo) {
            quotedData = detail.message.imageMessage.contextInfo

            if (detail.message.imageMessage.contextInfo.participant) {
              quotedParticipant = detail.message.imageMessage.contextInfo.participant
            }

            if (detail.message.imageMessage.contextInfo.quotedMessage) {
              captionKeys.forEach(key => {
                if (detail.message.imageMessage.contextInfo.quotedMessage[key]) {
                  if (detail.message.imageMessage.contextInfo.quotedMessage[key].contextInfo) {
                    if (detail.message.imageMessage.contextInfo.quotedMessage[key].contextInfo.mentionedJid) {
                      mentionedJid = detail.message.imageMessage.contextInfo.quotedMessage[key].contextInfo.mentionedJid
                    }
                  }

                  if (key == 'extendedTextMessage') {
                    quoted = this.parseMentionedJid(
                      mentionedJid,
                      detail.message.imageMessage.contextInfo.quotedMessage.extendedTextMessage.text,
                    )
                  } else if (key == 'imageMessage') {
                    detail.message.imageMessage.contextInfo.quotedMessage.imageMessage.caption = this.parseMentionedJid(
                      mentionedJid,
                      detail.message.imageMessage.contextInfo.quotedMessage.imageMessage.caption,
                    )
                  } else if (key == 'viewOnceMessage') {
                    if (
                      detail.message.stickerMessage.contextInfo.quotedMessage.viewOnceMessage &&
                      detail.message.stickerMessage.contextInfo.quotedMessage.viewOnceMessage.message &&
                      detail.message.stickerMessage.contextInfo.quotedMessage.viewOnceMessage.message.imageMessage
                    ) {
                      const parseQuoted = this.parseMentionedJid(
                        mentionedJid,
                        detail.message.stickerMessage.contextInfo.quotedMessage.viewOnceMessage.message.imageMessage,
                      )

                      detail.message.stickerMessage.contextInfo.quotedMessage.viewOnceMessage.message.imageMessage =
                        parseQuoted

                      if (parseQuoted.caption) {
                        quoted = parseQuoted.caption
                      }
                    }
                  }
                }
              })

              if (detail.message.imageMessage.contextInfo.quotedMessage.conversation) {
                quoted = detail.message.imageMessage.contextInfo.quotedMessage.conversation
              }

              if (detail.message.imageMessage.contextInfo.quotedMessage.templateMessage) {
                if (detail.message.imageMessage.contextInfo.quotedMessage.templateMessage.hydratedTemplate) {
                  quoted =
                    detail.message.imageMessage.contextInfo.quotedMessage.templateMessage.hydratedTemplate
                      .hydratedContentText
                }
              }

              if (detail.message.imageMessage.contextInfo.quotedMessage.imageMessage) {
                if (detail.message.imageMessage.contextInfo.quotedMessage.imageMessage.caption) {
                  quoted = detail.message.imageMessage.contextInfo.quotedMessage.imageMessage.caption
                }
              }

              if (detail.message.imageMessage.contextInfo.quotedMessage.locationMessage) {
                if (detail.message.imageMessage.contextInfo.quotedMessage.locationMessage.name) {
                  quoted = detail.message.imageMessage.contextInfo.quotedMessage.locationMessage.name
                }

                if (detail.message.imageMessage.contextInfo.quotedMessage.locationMessage.address) {
                  quoted += `, ${detail.message.imageMessage.contextInfo.quotedMessage.locationMessage.address}`
                }
              }
            }

            if (detail.message.imageMessage.contextInfo.mentionedJid) {
              mentionedJid = detail.message.imageMessage.contextInfo.mentionedJid

              conv = this.parseMentionedJid(mentionedJid, conv)
            }
          }
        }

        if (
          detail.message.viewOnceMessage &&
          detail.message.viewOnceMessage.message &&
          detail.message.viewOnceMessage.message.imageMessage
        ) {
          if (detail.message.viewOnceMessage.message.imageMessage.jpegThumbnail) {
            imgBase64 = `data:${detail.message.viewOnceMessage.message.imageMessage.mimetype};base64, ${detail.message.viewOnceMessage.message.imageMessage.jpegThumbnail}`
          }

          paramBlob = {
            urlType: 'img',
            url: detail.message.viewOnceMessage.message.imageMessage.url,
            fileLength: detail.message.viewOnceMessage.message.imageMessage.fileLength,
            mediaKey: detail.message.viewOnceMessage.message.imageMessage.mediaKey,
            mimeType: detail.message.viewOnceMessage.message.imageMessage.mimetype,
            directPath: detail.message.viewOnceMessage.message.imageMessage.directPath
              ? detail.message.viewOnceMessage.message.imageMessage.directPath
              : null,
            fileEncSha256: detail.message.viewOnceMessage.message.imageMessage.fileEncSha256,
          }

          if (detail.message.viewOnceMessage.message.imageMessage.caption) {
            conv = detail.message.viewOnceMessage.message.imageMessage.caption
          }

          if (detail.message.viewOnceMessage.message.imageMessage.contextInfo) {
            quotedData = detail.message.viewOnceMessage.contextInfo

            if (detail.message.viewOnceMessage.contextInfo.participant) {
              quotedParticipant = detail.message.viewOnceMessage.contextInfo.participant
            }

            if (detail.message.viewOnceMessage.contextInfo.quotedMessage) {
              captionKeys.forEach(key => {
                if (detail.message.viewOnceMessage.contextInfo.quotedMessage[key]) {
                  if (detail.message.viewOnceMessage.contextInfo.quotedMessage[key].contextInfo) {
                    if (detail.message.viewOnceMessage.contextInfo.quotedMessage[key].contextInfo.mentionedJid) {
                      mentionedJid =
                        detail.message.viewOnceMessage.contextInfo.quotedMessage[key].contextInfo.mentionedJid
                    }
                  }

                  if (key == 'extendedTextMessage') {
                    quoted = this.parseMentionedJid(
                      mentionedJid,
                      detail.message.viewOnceMessage.contextInfo.quotedMessage.extendedTextMessage.text,
                    )
                  } else if (key == 'imageMessage') {
                    detail.message.viewOnceMessage.contextInfo.quotedMessage.imageMessage.caption =
                      this.parseMentionedJid(
                        mentionedJid,
                        detail.message.viewOnceMessage.contextInfo.quotedMessage.imageMessage.caption,
                      )
                  } else if (key == 'viewOnceMessage') {
                    if (detail.message.viewOnceMessage.contextInfo.quotedMessage.viewOnceMessage) {
                      const parseQuoted = this.parseMentionedJid(
                        mentionedJid,
                        detail.message.viewOnceMessage.contextInfo.quotedMessage.viewOnceMessage.message.imageMessage
                          .caption,
                      )

                      detail.message.viewOnceMessage.contextInfo.quotedMessage.viewOnceMessage = parseQuoted

                      if (parseQuoted.caption) {
                        quoted = parseQuoted.caption
                      }
                    }
                  }
                }
              })

              if (detail.message.viewOnceMessage.contextInfo.quotedMessage.conversation) {
                quoted = detail.message.viewOnceMessage.contextInfo.quotedMessage.conversation
              }

              if (detail.message.viewOnceMessage.contextInfo.quotedMessage.templateMessage) {
                if (detail.message.viewOnceMessage.contextInfo.quotedMessage.templateMessage.hydratedTemplate) {
                  quoted =
                    detail.message.viewOnceMessage.contextInfo.quotedMessage.templateMessage.hydratedTemplate
                      .hydratedContentText
                }
              }

              if (detail.message.viewOnceMessage.contextInfo.quotedMessage.imageMessage) {
                if (detail.message.viewOnceMessage.contextInfo.quotedMessage.imageMessage.caption) {
                  quoted = detail.message.viewOnceMessage.contextInfo.quotedMessage.imageMessage.caption
                }
              }

              if (detail.message.viewOnceMessage.contextInfo.quotedMessage.locationMessage) {
                if (detail.message.viewOnceMessage.contextInfo.quotedMessage.locationMessage.name) {
                  quoted = detail.message.viewOnceMessage.contextInfo.quotedMessage.locationMessage.name
                }

                if (detail.message.viewOnceMessage.contextInfo.quotedMessage.locationMessage.address) {
                  quoted += `, ${detail.message.viewOnceMessage.contextInfo.quotedMessage.locationMessage.address}`
                }
              }
            }

            if (detail.message.viewOnceMessage.contextInfo.mentionedJid) {
              mentionedJid = detail.message.viewOnceMessage.contextInfo.mentionedJid

              conv = this.parseMentionedJid(mentionedJid, conv)
            }
          }
        }

        if (detail.message.extendedTextMessage) {
          if (detail.message.extendedTextMessage.text) {
            conv = detail.message.extendedTextMessage.text
          }

          if (detail.message.extendedTextMessage.contextInfo) {
            quotedData = detail.message.extendedTextMessage.contextInfo

            if (detail.message.extendedTextMessage.contextInfo.participant) {
              quotedParticipant = detail.message.extendedTextMessage.contextInfo.participant
            }

            if (detail.message.extendedTextMessage.contextInfo.quotedMessage) {
              captionKeys.forEach(key => {
                if (detail.message.extendedTextMessage.contextInfo.quotedMessage[key]) {
                  if (detail.message.extendedTextMessage.contextInfo.quotedMessage[key].contextInfo) {
                    if (detail.message.extendedTextMessage.contextInfo.quotedMessage[key].contextInfo.mentionedJid) {
                      mentionedJid =
                        detail.message.extendedTextMessage.contextInfo.quotedMessage[key].contextInfo.mentionedJid
                    }
                  }

                  if (key == 'extendedTextMessage') {
                    quoted = this.parseMentionedJid(
                      mentionedJid,
                      detail.message.extendedTextMessage.contextInfo.quotedMessage.extendedTextMessage.text,
                    )
                  } else if (key == 'imageMessage') {
                    detail.message.extendedTextMessage.contextInfo.quotedMessage.imageMessage.caption =
                      this.parseMentionedJid(
                        mentionedJid,
                        detail.message.extendedTextMessage.contextInfo.quotedMessage.imageMessage.caption,
                      )
                  } else if (key == 'viewOnceMessage') {
                    if (
                      detail.message.extendedTextMessage.contextInfo.quotedMessage.viewOnceMessage &&
                      detail.message.extendedTextMessage.contextInfo.quotedMessage.viewOnceMessage.message &&
                      detail.message.extendedTextMessage.contextInfo.quotedMessage.viewOnceMessage.message.imageMessage
                    ) {
                      const parseQuoted = this.parseMentionedJid(
                        mentionedJid,
                        detail.message.extendedTextMessage.contextInfo.quotedMessage.viewOnceMessage.message
                          .imageMessage,
                      )

                      detail.message.extendedTextMessage.contextInfo.quotedMessage.viewOnceMessage.message.imageMessage =
                        parseQuoted

                      if (parseQuoted.caption) {
                        quoted = parseQuoted.caption
                      }
                    }
                  }
                }
              })

              if (detail.message.extendedTextMessage.contextInfo.quotedMessage.conversation) {
                quoted = detail.message.extendedTextMessage.contextInfo.quotedMessage.conversation
              }

              if (detail.message.extendedTextMessage.contextInfo.quotedMessage.templateMessage) {
                if (detail.message.extendedTextMessage.contextInfo.quotedMessage.templateMessage.hydratedTemplate) {
                  quoted =
                    detail.message.extendedTextMessage.contextInfo.quotedMessage.templateMessage.hydratedTemplate
                      .hydratedContentText
                }
              }

              if (detail.message.extendedTextMessage.contextInfo.quotedMessage.imageMessage) {
                if (detail.message.extendedTextMessage.contextInfo.quotedMessage.imageMessage.caption) {
                  quoted = detail.message.extendedTextMessage.contextInfo.quotedMessage.imageMessage.caption
                }
              }

              if (detail.message.extendedTextMessage.contextInfo.quotedMessage.locationMessage) {
                if (detail.message.extendedTextMessage.contextInfo.quotedMessage.locationMessage.name) {
                  quoted = detail.message.extendedTextMessage.contextInfo.quotedMessage.locationMessage.name
                }

                if (detail.message.extendedTextMessage.contextInfo.quotedMessage.locationMessage.address) {
                  quoted += `, ${detail.message.extendedTextMessage.contextInfo.quotedMessage.locationMessage.address}`
                }
              }
            }

            if (detail.message.extendedTextMessage.contextInfo.mentionedJid) {
              mentionedJid = detail.message.extendedTextMessage.contextInfo.mentionedJid

              conv = this.parseMentionedJid(mentionedJid, conv)
            }
          }
        }

        if (detail.participant) {
          participant = detail.participant
        }

        let time = this.$moment().format('HH:mm')
        let date = this.$moment().format()
        let timeLastMessage = this.$moment().format()
        let messageTimestamp = this.$moment()
        let formattedMessageTimestamp = messageTimestamp.format()

        if (detail.messageTimestamp) {
          const format = 'HH:mm'
          messageTimestamp = detail.messageTimestamp
          formattedMessageTimestamp = this.$moment.unix(messageTimestamp).format()
          time = this.$moment(detail.messageTimestamp * 1000).format(format)
          timeLastMessage = this.$moment.unix(detail.messageTimestamp).format()

          if (this.$moment(detail.messageTimestamp * 1000).isSame(this.$moment(), 'day')) {
            date = this.$root.$i18n.locale == 'en' ? 'Today' : 'Hari ini'
          } else if (this.$moment(detail.messageTimestamp * 1000).isSame(this.$moment().subtract(1, 'day'), 'day')) {
            date = this.$root.$i18n.locale == 'en' ? 'Yesterday' : 'Kemarin'
          } else {
            date = this.$moment(detail.messageTimestamp * 1000).format('DD/MM/YY')
          }
        }

        let attachment = null

        if (paramBlob && !quotedType) {
          attachment = this.actionFileBlob(paramBlob, true)
        }

        let caption = this.sanitizeCaption(conv)
        caption = _unescape(caption)
        caption = caption
          .replace(/&lt;/g, '<')
          .replace(/&gt;/g, '>')
          .replace(/&amp;/g, '&')
          .replace(/&quot;/g, '"')

        const conversation = {
          person,
        }

        if (!detail.key.fromMe) {
          detail.source = `${detail.key.remoteJid}-${detail.source}`
        } else {
          detail.source = `${detail.source}@s.whatsapp.net-${detail.source}`
        }

        const senderId = detail.source.substr(0, detail.source.indexOf('@'))

        const obj = {
          contactName,
          contactPhoneNumber,
          channelName,
          channelPhoneNumber,
          attachment,
          formattedMessageTimestamp,
          keyId,
          person,
          avatar: this.getProfilePicture(conversation),
          caption,
          captionOrigin: caption,
          time,
          messageTimestamp,
          sent,
          data: detail,
          participant,
          quotedParticipant,
          quoted: quoted.substring(0, 100) + (quoted.length > 100 ? '...' : ''),
          quotedData,
          imgBase64,
          docBase64,
          type,
          gmapsUrl,
          quotedImage,
          quotedType,
          status,
          deleted,
          paramBlob,
          selected,
          instance,
          isButtons,

          // advanced chat
          _id: keyId,
          indexId: keyId,
          content: caption,
          senderId,
          username,
          date,
          timestamp: time,
          system: false,
          saved: status <= 1,
          send: status == 2,
          distributed: status == 3,
          seen: status >= 4,
          disableActions: false,
          disableReactions: true,
        }

        if (obj.attachment) {
          const extension = mime.extension(paramBlob.mimeType)

          const files = [
            {
              name: paramBlob.fileName ? paramBlob.fileName : `file.${extension}`,
              size: paramBlob.fileLength ? paramBlob.fileLength : 0,
              type: extension,
              audio: !!paramBlob.audio,
              duration: paramBlob.duration ? paramBlob.duration : 0,
              url: obj.attachment,
              preview: imgBase64,
              previewVideo: false,
            },
          ]

          obj.files = files
        }

        if (obj.type == 'contactFile') {
          const blob = new Blob([obj.data.message.contactMessage.vcard], {
            type: 'text/plain;charset=utf-8',
          })

          const files = [
            {
              name: `${obj.caption}.vcf`,
              size: 0,
              type: 'contact',
              audio: false,
              duration: 0,
              url: blob,
              preview: '',
              previewVideo: false,
            },
          ]

          obj.files = files
        }

        if (obj.quotedData && obj.quotedData.participant) {
          obj.replyMessage = {
            content: quoted,
            senderId: quotedParticipant.substr(0, quotedParticipant.indexOf('@')),
            messageId: obj.quotedData.stanzaId,
          }

          let quotedParamBlob = null
          const objKeys = Object.keys(obj.quotedData.quotedMessage)
          const objKey = objKeys.length > 0 ? objKeys[0] : null

          if (objKey) {
            let isFile = false
            let quotedUrlType = ''

            if (objKey.includes('image')) {
              quotedUrlType = 'img'
              isFile = true
            } else if (objKey.includes('template')) {
              quotedUrlType = 'img'
              isFile = true
            } else if (objKey.includes('sticker')) {
              quotedUrlType = 'img'
              isFile = true
            } else if (objKey.includes('viewOnce')) {
              quotedUrlType = 'img'
              isFile = true
            } else if (objKey.includes('video')) {
              quotedUrlType = 'vid'
              isFile = true
            } else if (objKey.includes('audio')) {
              quotedUrlType = 'aud'
              isFile = true
            } else if (objKey.includes('contact')) {
              quotedUrlType = 'contact'
              isFile = true
            } else if (objKey.includes('conversation')) {
              quotedUrlType = 'conv'
            } else if (objKey.includes('location')) {
              quotedUrlType = 'location'
            } else if (objKey.includes('extendedTextMessage')) {
              quotedUrlType = 'conv'
            } else {
              quotedUrlType = 'doc'
              isFile = true
            }

            if (isFile) {
              if (objKey.includes('template')) {
                if (obj.quotedData.quotedMessage[objKey].hydratedTemplate) {
                  if (obj.quotedData.quotedMessage[objKey].hydratedTemplate.Title) {
                    if (obj.quotedData.quotedMessage[objKey].hydratedTemplate.Title.ImageMessage) {
                      quotedParamBlob = {
                        urlType: quotedUrlType,
                        url: obj.quotedData.quotedMessage[objKey].hydratedTemplate.Title.ImageMessage.url,
                        fileLength: obj.quotedData.quotedMessage[objKey].hydratedTemplate.Title.ImageMessage.fileLength,
                        mediaKey: obj.quotedData.quotedMessage[objKey].hydratedTemplate.Title.ImageMessage.mediaKey,
                        mimeType: obj.quotedData.quotedMessage[objKey].hydratedTemplate.Title.ImageMessage.mimetype,
                        directPath: obj.quotedData.quotedMessage[objKey].hydratedTemplate.Title.ImageMessage.directPath
                          ? obj.quotedData.quotedMessage[objKey].hydratedTemplate.Title.ImageMessage.directPath
                          : null,
                        fileEncSha256:
                          obj.quotedData.quotedMessage[objKey].hydratedTemplate.Title.ImageMessage.fileEncSha256,
                      }
                    }
                  }
                }
              } else if (objKey.includes('viewOnce')) {
                if (obj.quotedData.quotedMessage[objKey].message) {
                  if (obj.quotedData.quotedMessage[objKey].message.imageMessage) {
                    quotedParamBlob = {
                      urlType: quotedUrlType,
                      url: obj.quotedData.quotedMessage[objKey].message.imageMessage.url,
                      fileLength: obj.quotedData.quotedMessage[objKey].message.imageMessage.fileLength,
                      mediaKey: obj.quotedData.quotedMessage[objKey].message.imageMessage.mediaKey,
                      mimeType: obj.quotedData.quotedMessage[objKey].message.imageMessage.mimetype,
                      directPath: obj.quotedData.quotedMessage[objKey].message.imageMessage.directPath
                        ? obj.quotedData.quotedMessage[objKey].message.imageMessage.directPath
                        : null,
                      fileEncSha256: obj.quotedData.quotedMessage[objKey].message.imageMessage.fileEncSha256,
                    }
                  }
                }
              } else {
                quotedParamBlob = {
                  urlType: quotedUrlType,
                  url: obj.quotedData.quotedMessage[objKey].url,
                  fileLength: obj.quotedData.quotedMessage[objKey].fileLength,
                  mediaKey: obj.quotedData.quotedMessage[objKey].mediaKey,
                  mimeType: obj.quotedData.quotedMessage[objKey].mimetype,
                  directPath: obj.quotedData.quotedMessage[objKey].directPath
                    ? obj.quotedData.quotedMessage[objKey].directPath
                    : null,
                  fileEncSha256: obj.quotedData.quotedMessage[objKey].fileEncSha256,
                }
              }
            }
          }

          if (quotedParamBlob) {
            const quotedExtension = mime.extension(quotedParamBlob.mimeType)
            let name = ''

            if (quotedParamBlob.urlType == 'contact') {
              name = `${obj.quotedData.quotedMessage[objKey].displayName}.vcf`
            } else {
              name = obj.quotedData.quotedMessage[objKey].fileName
                ? obj.quotedData.quotedMessage[objKey].fileName
                : `file.${quotedExtension}`
            }

            const files = [
              {
                name,
                size: 0,
                type: quotedExtension,
                audio: false,
                duration: 0,
                url: this.actionFileBlob(quotedParamBlob, true),
                preview: '',
                previewVideo: false,
              },
            ]

            obj.replyMessage.files = files
          }
        }

        return obj
      }
    },
    actionFileBlob(data, src = false, isAlt = false) {
      if (data) {
        if (data.directPath && !isAlt) {
          var url = `https://media-lax3-2.cdn.whatsapp.net${data.directPath}&hash=${data.fileEncSha256}=&mode=manual&mms-type=image&__wa-mms=`
        } else {
          var { url } = data
        }

        const objJsonStr = JSON.stringify({
          url,
          fileLength: data.fileLength,
          mediaKey: data.mediaKey,
        })
        const objJsonB64 = Buffer.from(objJsonStr).toString('base64')
        const ext = mime.extension(data.mimeType)
        const srcFile = `${process.env.VUE_APP_ATTACH_URL + data.urlType}/${objJsonB64}/.jpg`

        if (src) {
          if (isAlt) {
            return `this.onerror=null; this.src='${isAlt}'`
          }

          return srcFile
        }

        this.srcOpenFile = srcFile
      } else {
        return null
      }
    },
    actionFile(file) {
      if (file.type == 'contact') {
        const blob = new Blob([file.url], {
          type: 'text/plain;charset=utf-8',
        })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${file.name}.vcf`)
        document.body.appendChild(link)
        link.click()
        link.remove()
        window.URL.revokeObjectURL(url)
      } else {
        let fileName = file.name
        const params = {
          url: file.url,
          method: 'GET',
          responseType: 'blob', // important
        }

        this.overlayDownload = true
        this.$axiosLimit(params)
          .then(response => {
            const blob = new Blob([response.data], { type: response.data.type })
            const url = window.URL.createObjectURL(blob)
            const link = document.createElement('a')
            link.href = url
            const contentDisposition = response.headers['content-disposition']

            if (contentDisposition) {
              const fileNameMatch = contentDisposition.match(/filename="(.+)"/)

              if (fileNameMatch.length === 2) fileName = fileNameMatch[1]
            }

            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            link.remove()
            window.URL.revokeObjectURL(url)
            this.overlayDownload = false
          })
          .catch(error => {
            console.log(error)
            this.overlayDownload = false
          })
      }
    },
    sanitizeCaption(message) {
      if (!message) return ''

      // const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g

      // message = message.replace(urlRegex, url => {
      //   let hyperlink = url

      //   if (!hyperlink.match('^https?://')) {
      //     hyperlink = `http://${hyperlink}`
      //   }

      //   return `<a href="${hyperlink}" target="_blank">${url}</a>`
      // })

      return sanitizeHtml(message, sanitizeOptions)
    },
    parseMentionedJid(mentionedJid, content) {
      if (content) {
        mentionedJid.forEach(jid => {
          const parseId = jid.substr(0, jid.indexOf('@'))

          if (content.includes(`@${parseId}`)) {
            const contact = `<usertag>${parseId}</usertag>`
            content = content.replace(`@${parseId}`, contact)
          }
        })
      }

      return content || ''
    },
    async editListContact() {
      const data = this.data_edit
      this.loadingEdit = true

      await this.loadSettings().then(async r => {
        const filterContactList = _find(r, {
          key: 'contact_list',
        })
        const index = _findIndex(filterContactList.value, { id: data.id })
        filterContactList.value[index].list_name = data.list_name
        await this.updateSettings({
          ...filterContactList,
        }).then(r => {
          this.showhide_edit = false
          this.loadingEdit = false
        })
      })
    },
    deleteListContact(data) {
      const pouch = this.connectPouchDb(process.env.VUE_APP_DB_SETTING_NAME, self.user)
      this.loadingEdit = true

      pouch.db
        .get('contact_list', {
          include_docs: true,
        })
        .then(r => {
          pouch.db
            .put({
              ...r,
              value: this.listDataContact,
            })
            .then(r2 => {
              this.loadingEdit = false
              this.errorAlerh = false

              this.showhide_edit = false
            })
            .catch(() => {
              this.errorAlerh = true
            })
        })
    },
    dataListContact() {
      return this.listContactValue.filter(x => {
        let isFiltered = false

        if (this.filter !== '') {
          for (const field in x) {
            if (x[field] && x[field].toString().toLowerCase().includes(this.filter.toLowerCase())) {
              isFiltered = true
            }
            if (field === 'last_modified' && x[field] && !isFiltered) {
              const dateString = this.$moment(x[field]).format('DD MM YYYY HH:mm:ss')
              isFiltered = dateString.toString().toLowerCase().includes(this.filter.toLowerCase())
            }
          }
        } else {
          isFiltered = true
        }

        return isFiltered
      })
    },
    async getSettings(change = false, callback = false) {
      const settings = await this.loadSettings()
      this.settingsPouch = settings

      // const instances = settings.find(item => item.key == 'instances')
      const columns = settings.find(item => item.key == 'columns')
      const settingFilter = settings.find(item => item.key == 'setting_filter')
      const syncSetting = settings.find(item => item.key == 'sync_setting')

      // const messageTemplates = settings.find(item => item.key == 'message_templates')
      // const savedReplies = settings.find(item => item.key == 'saved_replies')
      // const label = settings.find(item => item.key == 'label')
      const contactList = settings.find(item => item.key == 'contact_list')

      if (change) {
        // if (change.doc.key == 'instances') {
        //   this.instances = change.doc ? change.doc.value : []
        // }

        if (change.doc.key == 'columns') {
          this.columns = change.doc ? change.doc.value : []
        }

        if (change.doc.key == 'setting_filter') {
          this.settingFilter = change.doc ? change.doc.value : []
        }

        if (change.doc.key == 'sync_setting') {
          this.syncSetting = change.doc.value
        }

        // if (change.doc.key == 'message_templates') {
        //   this.messageTemplates = change.doc || []
        // }

        // if (change.doc.key == 'saved_replies') {
        //   this.savedReplies = change.doc || []
        // }

        // if (change.doc.key == 'label') {
        //   this.label = change.doc || []
        // }

        if (change.doc.key == 'contact_list') {
          this.contactList = change.doc || []
        }
      } else {
        // this.instances = instances ? instances.value : []
        this.columns = columns ? columns.value : []
        this.settingFilter = settingFilter ? settingFilter.value : []
        this.syncSetting = syncSetting ? syncSetting.value : 0

        // this.messageTemplates = messageTemplates || []
        // this.savedReplies = savedReplies || []

        // this.label = label || []
        this.contactList = contactList || []
      }

      // check saved replies from message templates
      // if (this.messageTemplates.value) {
      //   this.messageTemplates.value.forEach(messageTemplate => {
      //     if (messageTemplate.quick_reply) {
      //       this.savedReplies.value.push({
      //         id: messageTemplate.id,
      //         name: messageTemplate.message,
      //         message_templates: messageTemplate,
      //       })
      //     } else {
      //       const index = this.savedReplies.value.findIndex(item => item.id == messageTemplate.id)

      //       if (index !== -1) {
      //         this.savedReplies.value.splice(index, 1)
      //       }
      //     }
      //   })

      //   // remove duplicate by quick reply id
      //   this.savedReplies.value = this.savedReplies.value.filter(
      //     (item, index, that) =>
      //       index === that.findIndex(t => t !== undefined && item !== undefined && t.id === item.id),
      //   )
      // }

      // // get labels
      // const labels = this.columns.find(item => item.field == 'label')

      // if (labels) {
      //   if (labels.listValue && labels.listValue.length > 0) {
      //     this.labels = labels.listValue
      //     this.labelsOrigin = labels.listValue
      //   }
      // }

      if (callback) {
        callback(settings)
      }
    },
    async getContactsVuex() {
      this.contactsPouch = [...this.contactsVuex]
    },
    async getContacts(fromServer = true) {
      // normal
      const contacts = fromServer ? await this.loadContacts() : await this.loadContactsChange()
      this.contactsPouch = [...contacts].filter(el => el._id === el.phone_number)

      // transform
      // if (fromServer) {
      //   var load = await this.loadContacts(false, true)
      //   var contacts = load.contacts
      //   var rooms = load.rooms
      //   const roomsOrdered = _orderBy(rooms, ['favourite', 'messageTimestamp'], ['desc', 'desc'])

      //   this.contactsPouch = [...contacts]
      //   this.roomsLoaded = false
      //   this.allRoomsGlobal = roomsOrdered
      //   this.roomsOrigin = roomsOrdered
      //   this.roomsFiltered = roomsOrdered
      //   this.rooms = roomsOrdered
      //   this.roomsLoaded = true
      //   this.loadingRooms = false
      // } else {
      //   var contacts = await this.loadContactsChange()
      //   this.contactsPouch = [...contacts]
      // }
    },
    async getContactsv2(fromServer = true) {
      const contacts = await this.loadContactsv2(fromServer)
      this.contactsPouch = [...contacts]
    },
    async setRooms(rooms) {
      const roomsLimit = rooms.slice(0, this.limitChatList)
      this.roomsLoaded = false
      this.rooms = roomsLimit
      this.roomsOrigin = rooms
      this.roomsFiltered = roomsLimit
      this.roomsLoaded = true
      this.loadingRooms = false
      this.roomsLoaded = true
    },
    async getInboxFirst() {
      this.roomsLoaded = false

      // get inbox per contact
      const inbox = []
      const contactsPouch = JSON.parse(JSON.stringify(this.contactsPouch))
      const contactsPouchOrdered = _orderBy(contactsPouch, ['last_message'], ['desc'])

      let count = 0
      contactsPouchOrdered.forEach(async contact => {
        if (count < 25) {
          if (contact.ch) {
            const keys = Object.keys(contact.ch)
            const offlineChats = this.$store.getters['global/getMessageOffline'].filter(
              x => x.person.split('@')[0] === contact.phone_number,
            )

            if (keys && keys.length > 1) {
              // check contact has message from multi channels
              keys.forEach(key => {
                const contactOrigin = JSON.parse(JSON.stringify(contact))
                delete contactOrigin.ch[key]
                offlineChats.forEach(data => {
                  if (!contact.ch[key].chats) {
                    contact.ch[key].chats = []
                  }
                  contact.ch[key].chats.unshift({
                    ...data.data,
                    status: 5,
                  })
                  contact.ch[key].chats = _orderBy(contact.ch[key].chats, ['messageTimestamp'], ['desc'])
                })
                inbox.push(contactOrigin)
              })
            } else if (keys && keys.length == 1) {
              offlineChats.forEach(data => {
                if (!contact.ch[keys[0]].chats) {
                  contact.ch[keys[0]].chats = []
                }
                contact.ch[keys[0]].chats.unshift({
                  ...data.data,
                  status: 5,
                })
                contact.ch[keys[0]].chats = _orderBy(contact.ch[keys[0]].chats, ['messageTimestamp'], ['desc'])
              })
              inbox.push(contact)
            }
            count += 1
          }
        }
      })

      const rooms = await this.parseInbox(inbox)
      const roomsOrdered = _orderBy(rooms, ['favourite', 'messageTimestamp'], ['desc', 'desc'])
      const curLimit = this.limitChatList
      const roomsLimit = roomsOrdered.slice(0, curLimit)

      this.allRoomsGlobal = roomsOrdered
      this.roomsOrigin = roomsOrdered
      this.roomsFiltered = roomsLimit
      this.rooms = roomsLimit

      // this.roomsLoaded = true
      this.loadingRooms = false

      // const paginate = JSON.parse(JSON.stringify(this.rooms))
      // const paginateRooms = this.paginator(rooms, this.roomsPage, this.roomsPerPage)
      // //console.log(paginateRooms)
      // this.roomsTotalPages = paginateRooms.total_pages
      // this.roomsPage = paginateRooms.next_page
      // paginateRooms.data.forEach(room => {
      //   paginate.push(room)
      // })

      // this.rooms = paginate
      // this.roomsOrigin = paginate
      // this.roomsFiltered = paginate
      // this.roomsLoaded = false
      // this.loadingRooms = false
    },
    async getInbox() {
      this.roomsLoaded = false

      // get inbox per contact
      const inbox = []
      const contactsPouch = JSON.parse(JSON.stringify(this.contactsPouch))
      contactsPouch.forEach(async contact => {
        if (contact.ch) {
          // //console.log(this.$store.getters['global/getMessageOffline'][0].person.split('@')[0], ' == PERSON')
          // //console.log(contact.phone_number, ' == Phone Numbers')
          const keys = Object.keys(contact.ch)
          const offlineChats = this.$store.getters['global/getMessageOffline'].filter(
            x => x.person.split('@')[0] === contact.phone_number,
          )

          if (keys && keys.length > 1) {
            // check contact has message from multi channels
            keys.forEach(key => {
              const contactOrigin = JSON.parse(JSON.stringify(contact))
              delete contactOrigin.ch[key]
              offlineChats.forEach(data => {
                if (!contact.ch[key].chats) {
                  contact.ch[key].chats = []
                }
                contact.ch[key].chats.unshift({
                  ...data.data,
                  status: 5,
                })
              })
              contact.ch[keys[0]].chats = _orderBy(contact.ch[keys[0]].chats, ['messageTimestamp'], ['desc'])
              inbox.push(contactOrigin)
            })
          } else if (keys && keys.length == 1) {
            offlineChats.forEach(data => {
              if (!contact.ch[keys[0]].chats) {
                contact.ch[keys[0]].chats = []
              }
              contact.ch[keys[0]].chats.unshift({
                ...data.data,
                status: 5,
              })
              contact.ch[keys[0]].chats = _orderBy(contact.ch[keys[0]].chats, ['messageTimestamp'], ['desc'])
            })
            inbox.push(contact)
          }
        }
      })

      const rooms = await this.parseInbox(inbox)
      const roomsOrdered = _orderBy(rooms, ['favourite', 'messageTimestamp'], ['desc', 'desc'])
      const curLimit = this.limitChatList
      const roomsLimit = roomsOrdered.slice(0, curLimit)

      this.allRoomsGlobal = roomsOrdered
      this.roomsOrigin = roomsOrdered
      this.roomsFiltered = roomsLimit
      this.rooms = roomsLimit

      //console.log(JSON.stringify(roomsLimit), 'roomsLimit')

      // this.roomsLoaded = true
      this.loadingRooms = false

      // const paginate = JSON.parse(JSON.stringify(this.rooms))
      // const paginateRooms = this.paginator(rooms, this.roomsPage, this.roomsPerPage)
      // //console.log(paginateRooms)
      // this.roomsTotalPages = paginateRooms.total_pages
      // this.roomsPage = paginateRooms.next_page
      // paginateRooms.data.forEach(room => {
      //   paginate.push(room)
      // })

      // this.rooms = paginate
      // this.roomsOrigin = paginate
      // this.roomsFiltered = paginate
      // this.roomsLoaded = false
      // this.loadingRooms = false
    },
    async onChangeInbox(change, notification = false, isChange = true) {
      const contact = isChange ? change.doc : change
      const inbox = []

      if (contact.ch) {
        const keys = Object.keys(contact.ch)

        if (keys && keys.length > 1) {
          // check contact has message from multi channels
          keys.forEach(key => {
            const contactOrigin = JSON.parse(JSON.stringify(contact))
            delete contactOrigin.ch[key]
            inbox.push(contactOrigin)
          })
        } else if (keys && keys.length == 1) {
          inbox.push(contact)
        }

        // update current room
        const rooms = await this.parseInbox(inbox)
        let parsedRoom = null

        if (rooms && rooms.length > 0) {
          parsedRoom = rooms[0]
        }

        if (parsedRoom) {
          if (notification) {
            return parsedRoom
            // eslint-disable-next-line no-else-return
          } else {
            let messages = JSON.parse(JSON.stringify(this.messages))
            const roomIndex = this.rooms.findIndex(room => room.id == parsedRoom.id)

            if (roomIndex >= 0) {
              this.rooms[roomIndex] = parsedRoom
              this.rooms = this.rooms.filter(
                (room, index, that) => index === that.findIndex(t => t && room && t.roomId === room.roomId),
              )
              this.rooms = [...this.rooms]
              this.rooms = _orderBy(this.rooms, ['favourite', 'messageTimestamp'], ['desc', 'desc'])
            }

            const roomOriginIndex = this.roomsOrigin.findIndex(room => room.id == parsedRoom.id)

            if (roomOriginIndex >= 0) {
              this.roomsOrigin[roomOriginIndex] = parsedRoom
              this.roomsOrigin = this.roomsOrigin.filter(
                (room, index, that) => index === that.findIndex(t => t && room && t.roomId === room.roomId),
              )
              this.roomsOrigin = [...this.roomsOrigin]
              this.roomsOrigin = _orderBy(this.roomsOrigin, ['favourite', 'messageTimestamp'], ['desc', 'desc'])
            }

            const roomFilteredIndex = this.roomsFiltered.findIndex(room => room.id == parsedRoom.id)

            if (roomFilteredIndex >= 0) {
              this.roomsFiltered[roomFilteredIndex] = parsedRoom
              this.roomsFiltered = this.roomsFiltered.filter(
                (room, index, that) => index === that.findIndex(t => t && room && t.roomId === room.roomId),
              )
              this.roomsFiltered = [...this.roomsFiltered]
              this.roomsFiltered = _orderBy(this.roomsFiltered, ['favourite', 'messageTimestamp'], ['desc', 'desc'])
            }

            // update current room message
            if (this.currentPerson == parsedRoom.person) {
              messages = messages.filter((message, index, that) => index === that.findIndex(t => t._id === message._id))

              messages = messages.filter(message => !message._id.startsWith('msgid'))

              messages = _orderBy(messages, 'messageTimestamp', 'asc')

              if (parsedRoom.data.message) {
                const obj = this.setChatContactInbox(
                  parsedRoom.data,
                  parsedRoom.instance,
                  this.contactsPouch,
                  this.selectedInstance,
                )

                const exists = this.messages ? this.messages.find(item => item._id == parsedRoom.data.key.id) : null

                if (!exists) {
                  messages.push(obj)

                  messages = messages.filter(
                    (message, index, that) => index === that.findIndex(t => t._id === message._id),
                  )

                  messages = messages.filter(message => !message._id.startsWith('msgid'))

                  messages = _orderBy(messages, 'messageTimestamp', 'asc')

                  setTimeout(() => {
                    this.messages = [...messages]
                  })
                } else {
                  for (const msg of parsedRoom.contact.data_chats) {
                    const obj = this.setChatContactInbox(
                      msg,
                      parsedRoom.instance,
                      this.contactsPouch,
                      this.selectedInstance,
                    )

                    const curMsg = this.messages ? this.messages.find(item => item._id == obj._id) : null
                    if (curMsg) {
                      const messageIndex = this.messages.findIndex(item => item._id == curMsg._id)

                      if (messageIndex >= 0 && this.messages[messageIndex].status !== obj.status) {
                        if (!obj.senderId || obj.senderId == 'undefined') {
                          obj.senderId = this.selectedInstance.phone_number
                        }
                        this.messages[messageIndex] = obj

                        this.messages = this.messages.filter(
                          (message, index, that) => index === that.findIndex(t => t._id === message._id),
                        )

                        this.messages = _orderBy(this.messages, 'messageTimestamp', 'asc')

                        setTimeout(() => {
                          this.messages = [...this.messages]
                        })
                      }
                    }
                  }

                  // const messageIndex = this.messages.findIndex(item => item._id == exists._id)

                  // if (messageIndex >= 0) {
                  //   this.messages[messageIndex] = obj
                  // }

                  // this.messages = _orderBy(this.messages, 'messageTimestamp', 'asc')

                  // setTimeout(() => {
                  //   this.messages = [...this.messages]
                  // })
                }
              }
            }
          }
        }
      }
    },
    async parseInbox(inbox) {
      let rooms = []
      let id = 0

      // parse inbox by channel key
      inbox.forEach(item => {
        item.hidden_inbox = false

        const key = Object.keys(item.ch)[0]
        item.data_channel = key || ''

        const instance = this.optionsInstances.find(option => option.value.phone_number == item.data_channel)

        if (item.ch[key] && item.ch[key].chats && item.ch[key].chats.length > 0) {
          const messages = item.ch[key].chats
          const firstMessage = item.ch[key].chats[0]
          const { unread } = item.ch[key]

          if (instance) {
            firstMessage.color = instance.value.color
            firstMessage.source = instance.value.phone_number
          }

          // filter shown ignore this msg from background message channel
          if (firstMessage.message) {
            if (firstMessage.message.conversation) {
              if (firstMessage.message.conversation.startsWith('ignore this msg')) {
                item.hidden_inbox = true
              }
            }
          }

          item.data_timestamp = firstMessage.messageTimestamp
          item.data_chats = messages
          item.data_unread = unread

          // set contact label
          item.labels = this.setLabels(item)

          // if data has instance and not hidden, push
          if (instance && !item.hidden_inbox) {
            const chat = this.setContactInbox(firstMessage, id, this.contactsPouch, this.selectedInstance, item)

            chat.users = [
              {
                _id: item.data_channel,
                username: 'You',
                avatar: '',
                status: null,
              },
              {
                _id: item.phone_number,
                username: item.name,
                avatar: '',
                status: null,
              },
            ]

            // check group participants
            if (item.users) {
              const { participants } = item.users

              if (participants && participants.length > 0) {
                const usersGroup = []

                participants.forEach(participant => {
                  const phoneNumber = participant.id.replace('c.us', 's.whatsapp.net')
                  const id = phoneNumber.substr(0, phoneNumber.indexOf('@'))

                  if (this.instances) {
                    const instance = this.instances.find(instance => instance.phone_number == item.data_channel)
                    const conversation = {
                      person: phoneNumber,
                    }

                    if (instance) {
                      conversation.instance = instance
                    }

                    const user = {
                      _id: id,
                      username: this.getContactsName(
                        phoneNumber,
                        this.contactsPouch,
                        this.selectedInstance,
                        chat.instance,
                      ),
                      avatar: this.getProfilePicture(conversation),
                      status: null,
                    }

                    usersGroup.push(user)
                  }
                })

                chat.users = usersGroup
              }
            }

            rooms.push(chat)
            id += 1
          }
        }
      })

      rooms = _orderBy(rooms, ['favourite', 'messageTimestamp'], ['desc', 'desc'])

      return rooms
    },
    async openChat(data, isNew = false) {
      const index = this.rooms.findIndex(room => room.contact._id === data._id)
      let room = {}

      if (index > -1) {
        room = this.rooms[index]
        await this.fetchMessages({ room, reset: true }, true)
      } else if (isNew) {
        // from add chat inbox
        const messageId = customAlphabet(alphabet, 32)()
        let person = ''
        const channelPhoneNumber = this.selectedInstanceRoom.value.phone_number

        if (data.phone_number.includes('-') || data.phone_number.length > 16) {
          person = `${data.phone_number}@g.us`
        } else {
          person = `${data.phone_number}@s.whatsapp.net`
        }

        const message = {
          key: {
            fromMe: true,
            id: messageId,
            remoteJid: person,
          },
          message: { conversation: '' },
          messageTimestamp: this.$moment().unix(),
          status: 1,
          color: this.selectedInstanceRoom.value.color,
          source: channelPhoneNumber,
        }

        const chat = this.setContactInbox(
          message,
          this.$nanoid(),
          this.contactsPouch,
          this.selectedInstanceRoom.value,
          data,
        )

        chat.users = [
          {
            _id: channelPhoneNumber,
            username: 'You',
            avatar: '',
            status: null,
          },
          {
            _id: data.phone_number,
            username: chat.name,
            avatar: '',
            status: null,
          },
        ]

        // check group participants
        if (data.users) {
          const { participants } = data.users

          if (participants && participants.length > 0) {
            const usersGroup = []

            participants.forEach(participant => {
              const phoneNumber = participant.id.replace('c.us', 's.whatsapp.net')
              const id = phoneNumber.substr(0, phoneNumber.indexOf('@'))
              const conversation = {
                person: phoneNumber,
              }

              if (this.selectedInstanceRoom) {
                conversation.instance = this.selectedInstanceRoom
              }

              const user = {
                _id: id,
                username: this.getContactsName(
                  phoneNumber,
                  this.contactsPouch,
                  this.selectedInstanceRoom,
                  chat.instance,
                ),
                avatar: this.getProfilePicture(conversation),
                status: null,
              }

              usersGroup.push(user)
            })

            chat.users = usersGroup
          }
        }

        const roomsOrigin = JSON.parse(JSON.stringify(this.roomsOrigin))
        roomsOrigin.push(chat)
        this.roomsOrigin = [...roomsOrigin]
        this.roomsOrigin = _orderBy(this.roomsOrigin, ['favourite', 'messageTimestamp'], ['desc', 'desc'])

        const roomsFiltered = JSON.parse(JSON.stringify(this.roomsFiltered))
        roomsFiltered.push(chat)
        this.roomsFiltered = [...roomsFiltered]
        this.roomsFiltered = _orderBy(this.roomsFiltered, ['favourite', 'messageTimestamp'], ['desc', 'desc'])

        await this.fetchMessages({ room: chat, reset: true }, true)
      }
    },
    readRoom() {
      if (this.selectedInstance && !this.$store.getters['global/getBoldUnreplied']) {
        if (
          this.currentRoom &&
          this.currentRoom.contact &&
          this.currentRoom.contact.ch &&
          this.currentRoom.contact.ch[this.selectedInstance.phone_number] &&
          this.currentRoom.contact.ch[this.selectedInstance.phone_number].unread > '0' &&
          this.currentRoom.data &&
          this.currentRoom.data.key &&
          !this.currentRoom.data.key.fromMe
        ) {
          const endpoint = `${process.env.VUE_APP_WA_API_URL}read/${this.selectedInstance.sub_id}-${this.selectedInstance.instance_id}/${this.currentPerson}`

          this.$axios
            .get(endpoint)
            .then(async response => {
              if (response.data && response.data.Success === 1) {
                this.currentRoom.contact.ch[this.selectedInstance.phone_number].unread = '0'
              }
            })
            .catch(error => {
              console.log(error, ' == ERROR')
            })
        }
      }
    },
    async fetchMessagesOld({ room, options }, reset = false) {
      // await this.$store.commit('inbox/setMessages', room.users)

      /* await this.$nextTick(() => {
        this.$eventBus.$emit(process.env.VUE_APP_EVENT_UPDATE_CHAT, room.users)
      }) */

      if (room) {
        if (!options && !reset) {
          await this.fetchMoreMessages(room)
        } else {
          this.messages = []
          this.messagesLoaded = false
          this.firstMessage = null
          this.currentUserId = room.instance.phone_number
          this.selectedInstance = room.instance
          this.currentPerson = room.person
          this.currentRoomId = room.id
          this.currentRoom = room
          this.currentContact = room.contact

          let chat = []
          const source = room.instance.phone_number
          const messages = room.contact.data_chats || []

          if (messages && messages.length == 0) {
            this.messagesLoaded = true
          }

          for (const detail of messages) {
            if (detail.message) {
              if (!detail.key.fromMe) {
                detail.source = `${detail.key.remoteJid}-${source}`
              } else {
                detail.source = `${source}@s.whatsapp.net-${source}`
              }

              const obj = this.setChatContactInbox(detail, room.instance, this.contactsPouch, this.selectedInstance)

              chat.push(obj)
            }
          }

          chat = _orderBy(chat, ['messageTimestamp'], ['asc'])
          chat = this.setMessageLabels(chat)

          // use timeout to imitate async server fetched data
          setTimeout(() => {
            this.messages = [...chat]
            this.firstMessage = this.messages[this.messages.length - 1]
          })

          if (!this.isReadUserRoom) {
            this.isReadUserRoom = true
            setTimeout(() => {
              this.isReadUserRoom = false
            }, 500)
            this.readRoom()
          }
        }
      }
    },
    async fetchMoreMessages(room) {
      const self = this

      /* await this.$nextTick(() => {
        this.$eventBus.$emit(process.env.VUE_APP_EVENT_UPDATE_CHAT, room.users)
      }) */
      if (self.firstMessage) {
        const message = self.firstMessage
        const owner = message.fromMe ? 1 : 0
        const source = room.instance.phone_number
        const endpoint = `${process.env.VUE_APP_WA_API_URL}chats/${self.selectedInstance.sub_id}-${self.selectedInstance.instance_id}/${self.currentPerson}/${message.data.key.id}/${owner}`

        self.$axiosLimit
          .get(endpoint)
          .then(response => {
            if (Object.keys(response.data).length) {
              const chat = []

              for (const data in response.data) {
                if (Array.isArray(response.data[data])) {
                  const noMessage = response.data[data].filter(item => item.message)

                  if (noMessage.length == 0) {
                    setTimeout(() => (self.messagesLoaded = true), 0)
                  } else {
                    for (const detail of response.data[data]) {
                      if (detail.message) {
                        if (!detail.key.fromMe) {
                          detail.source = `${detail.key.remoteJid}-${source}`
                        } else {
                          detail.source = `${source}@s.whatsapp.net-${source}`
                        }

                        const obj = self.setChatContactInbox(
                          detail,
                          self.selectedInstance,
                          self.contactsPouch,
                          self.selectedInstance,
                        )

                        chat.push(obj)
                      }
                    }
                  }
                }
              }

              self.messages = [...chat, ...self.messages]
              self.messages = self.setMessageLabels(self.messages)
              self.messages = self.messages.filter(
                (message, index, that) => index === that.findIndex(t => t._id === message._id),
              )
              self.firstMessage = self.messages[0]
            } else {
              setTimeout(() => (self.messagesLoaded = true), 0)
            }
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
    async sendMessage({ roomId, content, files, replyMessage, usersTag, quickReply }) {
      const self = this
      const contentOrigin = content

      let messageId = {
        id: customAlphabet(alphabet, 32)(),
        stanzaId: customAlphabet(alphabet, 20)(),
      }

      if (quickReply) {
        // parse message id to remove duplicate message
        messageId.id = `msgid${messageId.id}`

        this.putSavedReplies(messageId, quickReply)
      } else if (files && files.length > 0) {
        let urlType = ''
        const typesFile = []
        let isCaption = false

        // check type of files
        for (const file of files) {
          if (file.type.includes('image')) {
            typesFile.push('img')
          } else if (file.type.includes('video')) {
            typesFile.push('vid')
          } else if (file.type.includes('audio')) {
            typesFile.push('aud')
          } else {
            typesFile.push('doc')
          }
        }

        const checkerImg = arr => arr.every(v => v === 'img')

        // if content exists and all types is not img, send caption after attachments
        if (contentOrigin && !checkerImg(typesFile)) {
          isCaption = true
        }

        for (const [indexFile, file] of files.entries()) {
          messageId = {
            id: customAlphabet(alphabet, 32)(),
            stanzaId: customAlphabet(alphabet, 20)(),
          }

          // check type
          if (file.type.includes('image')) {
            urlType = 'img'
          } else if (file.type.includes('video')) {
            urlType = 'vid'

            // bypass format video to mp4
            file.type = 'video/mp4'

            // get thumbnail
            const videoFile = new File([file.blob], 'videoFile')
            const videoThumbnail = await this.generateVideoThumbnail(videoFile)

            if (videoThumbnail) {
              file.videoThumbnail = videoThumbnail
            }
          } else if (file.type.includes('audio')) {
            urlType = 'aud'
          } else {
            urlType = 'doc'
          }

          const endpoint = `${process.env.VUE_APP_WA_API_URL}upload/${self.selectedInstance.sub_id}-${self.selectedInstance.instance_id}/${urlType}`
          const filename = file.extension ? `${file.name}.${file.extension}` : file.name
          const fileObj = new File([file.blob], filename)
          const formData = new FormData()
          formData.append('file', fileObj)

          // add param type /upload
          if (urlType == 'img') {
            formData.append('type', 'image/png')
          }

          // replace content doc to filename
          if (urlType == 'doc') {
            content = filename
          }

          self.messageFile.push({
            id: messageId.id,
            name: filename,
            size: file.size,
            type: mime.extension(file.type),
            fileType: file.type,
            audio: file.audio ? file.audio : false,
            duration: file.duration ? file.duration : 0,
            url: '',
            preview: file.localUrl,
            previewVideo: false,
            progress: 0,
            indexFile,
            videoThumbnail: file.videoThumbnail ? file.videoThumbnail : '',
          })

          self.attachments.push({
            id: messageId.id,
            url: '',
            type: file.type,
            filename,
            indexFile,
          })

          const message = self.parseMessage(messageId, content, files, replyMessage, usersTag, isCaption)

          await self.$axios
            .post(endpoint, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
              onUploadProgress(progressEvent) {
                self.isUploadingAttachment = true
                self.uploadingAttachmentProgress = 0

                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                self.uploadingAttachmentProgress = percentCompleted
                const found = self.messages.find(function (item) {
                  return item._id == message._id
                })

                if (found) {
                  found.files[0].progress = percentCompleted
                  self.messages = [...self.messages]
                }
              },
            })
            .then(async response => {
              const data = {
                id: messageId.id,
                url: response.data.Url,
                mediaKey: response.data.MediaKey,
                fileEncSha256: response.data.FileEncSha256,
                fileSha256: response.data.FileSha256,
                fileLength: response.data.FileLength,
                jpegThumbnail: response.data.JpegThumbnail,
                urlType,
                indexFile,
              }

              if (file.videoThumbnail) {
                data.videoThumbnail = file.videoThumbnail
              }

              self.dataFile.push({
                ...data,
              })

              const url = self.actionFileBlob(data, true)

              if (self.attachments && self.attachments.length > 0 && self.messageFile && self.messageFile.length > 0) {
                const indexMessageFile = self.messageFile.findIndex(item => item.id == messageId.id)

                if (indexMessageFile >= 0) {
                  self.messageFile[indexMessageFile].url = url
                  delete self.messageFile[indexMessageFile].progress
                }

                const indexAttachments = self.attachments.findIndex(item => item.id == messageId.id)

                if (indexAttachments >= 0) {
                  self.attachments[indexAttachments].url = url
                }
              }

              const found = self.messages.find(function (item) {
                return item._id == message._id
              })

              if (found) {
                if (data.jpegThumbnail) {
                  const jpegThumbnail = `data:${file.type};base64, ${data.jpegThumbnail}`
                  found.files[0].preview = jpegThumbnail
                } else {
                  found.files[0].preview = url
                }

                found.attachment = url
                found.files[0].url = url
                found.paramBlob = {
                  urlType,
                  url: data.url,
                  fileLength: data.fileLength,
                  mediaKey: data.mediaKey,
                  mimeType: file.type,
                  directPath: null,
                  fileEncSha256: data.fileEncSha256,
                }
                delete found.files[0].progress
                self.messages = [...self.messages]
              }

              await self.axiosSendMessage(messageId, content, files, replyMessage, usersTag, message, null, isCaption)

              self.isUploadingAttachment = false
              self.uploadingAttachmentProgress = 0
            })
            .catch(error => {
              console.log(error)
              self.isUploadingAttachment = false
              self.uploadingAttachmentProgress = 0
              self.toastsErrorUploadAttachment()
            })
        }

        if (isCaption) {
          messageId = {
            id: customAlphabet(alphabet, 32)(),
            stanzaId: customAlphabet(alphabet, 20)(),
          }

          const message = self.parseMessage(messageId, contentOrigin, files, replyMessage, usersTag)
          delete message.replyMessage
          await self.axiosSendMessage(messageId, contentOrigin, [], null, usersTag, message)
        }
      } else {
        const message = self.parseMessage(messageId, content, files, replyMessage, usersTag)
        await self.axiosSendMessage(messageId, content, files, replyMessage, usersTag, message)
      }
    },
    async axiosSendMessage(
      messageId,
      content,
      files,
      replyMessage,
      usersTag,
      message,
      quickReply = null,
      isCaption = false,
    ) {
      const self = this

      if (self.currentRoomId) {
        const mentionedJid = []

        if (usersTag && usersTag.length > 0) {
          usersTag.forEach(user => {
            mentionedJid.push(`${user._id}@s.whatsapp.net`)
          })
        }

        // parse content includes <usertag></usertag>
        const usertagOpenRegex = /<usertag>/g
        const usertagCloseRegex = /<\/usertag>/g
        let msg = content.replace(usertagOpenRegex, '@')
        msg = msg.replace(usertagCloseRegex, '')

        const { id } = messageId
        const { stanzaId } = messageId
        const attachment = self.attachments.find(item => item.id == id)
        const messageFile = self.messageFile.find(item => item.id == id)
        const to = []

        if (self.currentPerson) {
          to.push(self.currentPerson)
        }

        let replyParticipant = ''

        if (replyMessage) {
          if (replyMessage.data.key.fromMe) {
            replyParticipant = `${replyMessage.channelPhoneNumber}@s.whatsapp.net`
          } else if (message.person.includes('-') || message.person.includes('@g')) {
            replyParticipant = replyMessage.participant
          } else {
            replyParticipant = message.person
          }
        }

        let body = {
          key: {
            remoteJid: self.currentPerson,
            fromMe: true,
            id,
          },
          message: {},
          messageTimestamp: self.$moment().unix(),
          status: 1,
        }

        if (attachment && messageFile) {
          const dataFile = self.dataFile.find(item => item.id == id)
          let key = ''

          if (attachment.type.includes('image')) {
            key = 'imageMessage'

            body.message = {
              imageMessage: {
                url: dataFile ? dataFile.url : '',
                mimetype: attachment.type,
                fileSha256: dataFile ? dataFile.fileSha256 : '',
                fileLength: dataFile ? dataFile.fileLength : '',
                height: 0,
                width: 0,
                mediaKey: dataFile ? dataFile.mediaKey : '',
                fileEncSha256: dataFile ? dataFile.fileEncSha256 : '',
                directPath: '',
                mediaKeyTimestamp: self.$moment().unix(),
                jpegThumbnail: dataFile ? (dataFile.jpegThumbnail ? dataFile.jpegThumbnail : '') : '',
              },
            }

            if (msg) {
              if (dataFile) {
                if (dataFile.indexFile == 0) {
                  if (!isCaption) {
                    body.message.imageMessage.caption = msg
                  }
                }
              }
            }
          } else if (attachment.type.includes('video')) {
            // check video thumbnail
            let jpegThumbnail = ''

            if (dataFile) {
              if (dataFile.jpegThumbnail) {
                jpegThumbnail = dataFile.jpegThumbnail
              } else if (dataFile.videoThumbnail) {
                jpegThumbnail = dataFile.videoThumbnail.replace('data:image/png;base64,', '')
              }
            }

            // bypass .mov quicktime
            if (attachment.type == 'video/quicktime') {
              attachment.type = 'video/mp4'
            }

            key = 'videoMessage'

            body.message = {
              videoMessage: {
                url: dataFile ? dataFile.url : '',
                mimetype: attachment.type,
                fileSha256: dataFile ? dataFile.fileSha256 : '',
                fileLength: dataFile ? dataFile.fileLength : '',
                seconds: 0,
                mediaKey: dataFile ? dataFile.mediaKey : '',
                height: 0,
                width: 0,
                fileEncSha256: dataFile ? dataFile.fileEncSha256 : '',
                directPath: '',
                mediaKeyTimestamp: self.$moment().unix(),
                jpegThumbnail,
                streamingSidecar: '',
              },
            }

            if (msg) {
              if (dataFile) {
                if (dataFile.indexFile == 0) {
                  if (!isCaption) {
                    body.message.videoMessage.caption = msg
                  }
                }
              }
            }
          } else if (attachment.type.includes('audio')) {
            key = 'audioMessage'

            body.message = {
              audioMessage: {
                url: dataFile ? dataFile.url : '',
                mimetype: messageFile.audio ? 'audio/ogg; codecs=opus' : attachment.type,
                fileSha256: dataFile ? dataFile.fileSha256 : '',
                fileLength: dataFile ? dataFile.fileLength : '',
                seconds: messageFile.duration ? Math.round(messageFile.duration) : 0,
                ptt: !!messageFile.audio,
                mediaKey: dataFile ? dataFile.mediaKey : '',
                fileEncSha256: dataFile ? dataFile.fileEncSha256 : '',
                directPath: '',
                mediaKeyTimestamp: self.$moment().unix(),
              },
            }
          } else {
            key = 'documentMessage'

            body.message = {
              documentMessage: {
                url: dataFile ? dataFile.url : '',
                mimetype: attachment.type,
                title: messageFile.name,
                fileSha256: dataFile ? dataFile.fileSha256 : '',
                fileLength: dataFile ? dataFile.fileLength : '',
                pageCount: 0,
                mediaKey: dataFile ? dataFile.mediaKey : '',
                fileName: messageFile.name,
                fileEncSha256: dataFile ? dataFile.fileEncSha256 : '',
                directPath: '',
                mediaKeyTimestamp: self.$moment().unix(),
                jpegThumbnail: '',
              },
            }
          }

          if (replyMessage) {
            body.message[key].contextInfo = {
              stanzaId,
              participant: replyParticipant,
              quotedMessage: replyMessage.data.message,
            }
          }

          if (mentionedJid && mentionedJid.length > 0) {
            if (body.message[key].contextInfo) {
              body.message[key].contextInfo.mentionedJid = mentionedJid
            } else {
              body.message[key].contextInfo = {
                mentionedJid,
              }
            }
          }
        } else {
          // eslint-disable-next-line no-lonely-if
          if (replyMessage) {
            body.message = {
              extendedTextMessage: {
                contextInfo: {
                  participant: replyParticipant,
                  quotedMessage: replyMessage.data.message,
                  stanzaId,
                },
                previewType: 0,
                text: msg,
              },
            }

            if (mentionedJid && mentionedJid.length > 0) {
              body.message.extendedTextMessage.contextInfo.mentionedJid = mentionedJid
            }
          } else {
            // eslint-disable-next-line no-lonely-if
            if (usersTag && usersTag.length > 0) {
              body.message = {
                extendedTextMessage: {
                  text: msg,
                  previewType: 0,
                },
              }

              if (mentionedJid && mentionedJid.length > 0) {
                body.message.extendedTextMessage.contextInfo = {
                  mentionedJid,
                }
              }
            } else {
              body.message = {
                conversation: msg,
              }
            }
          }
        }

        if (quickReply) {
          var endpoint = `${process.env.VUE_APP_WA_API_URL}send_batch/${self.selectedInstance.sub_id}-${self.selectedInstance.instance_id}`
          const quickReplyAttachment = self.attachments.filter(item => item.id == id)

          body = {
            to,
            post: {
              msg,
              attachment: quickReplyAttachment,
            },
          }

          if (quickReplyAttachment && quickReplyAttachment.length > 0) {
            if (quickReplyAttachment[0].indexFile > 0) {
              body.post.msg = ''
            }
          }
        } else {
          var endpoint = `${process.env.VUE_APP_WA_API_URL}send_json/${self.selectedInstance.sub_id}-${self.selectedInstance.instance_id}/0`
        }

        self.isUploadingAttachment = false
        self.uploadingAttachmentProgress = 0

        await self.$axios
          .post(endpoint, body)
          .then(async response => {
            await self.callbackSendMessage(message, to)

            // update current room
            // const lastMessage = {
            //   content,
            //   senderId: self.selectedInstance.phone_number,
            //   username: self.currentRoom.name,
            //   timestamp: time,
            //   saved: false,
            //   disbuted: false,
            //   seen: false,
            //   new: false,
            // }

            // const roomIndex = self.rooms.findIndex(room => room.id == self.currentRoomId)

            // if (roomIndex >= 0) {
            //   self.rooms[roomIndex].lastMessage = lastMessage
            //   self.rooms = [...self.rooms]
            // }

            if (message) {
              const indexMessage = self.messages.findIndex(item => item._id == message._id)

              if (indexMessage >= 0) {
                if (response.data.data) {
                  // response send_json
                  const responseMessage = response.data.data

                  self.messages[indexMessage]._id = responseMessage.key.id
                  self.messages[indexMessage].keyId = responseMessage.key.id
                  self.messages[indexMessage].indexId = responseMessage.key.id
                  self.messages[indexMessage].data.key = responseMessage.key
                  self.messages[indexMessage].data.message = responseMessage.message
                  self.messages[indexMessage].status = responseMessage.status
                  self.messages[indexMessage].saved = responseMessage.status <= 1
                  self.messages[indexMessage].send = responseMessage.status == 2
                  self.messages[indexMessage].distributed = responseMessage.status == 3
                  self.messages[indexMessage].seen = responseMessage.status >= 4
                  self.messages[indexMessage].messageTimestamp = responseMessage.messageTimestamp
                } else {
                  // response send_batch
                }
              }
            }
          })
          .catch(error => {
            if (message) {
              const indexMessage = this.messages.findIndex(item => item._id == message._id)

              if (indexMessage >= 0) {
                self.$store.dispatch('global/addMessageOffline', {
                  ...this.messages[indexMessage],
                  keyId: this.messages[indexMessage].keyId,
                  email: this.user.email,
                  sub_id: this.user.sub_id,
                  status: 5,
                  endpoint,
                  bodyAxios: body,
                  to,
                })

                // self.messages.splice(indexMessage, 1)
              }
            }

            self.toastsErrorSendMessage()
          })
      }
    },
    async sendOfflineMessages() {
      const self = this
      const offlineChats = this.$store.getters['global/getMessageOffline']
      offlineChats.forEach(async data => {
        await self.$axios
          .post(data.endpoint, data.bodyAxios)
          .then(async response => {
            await self.callbackSendMessage(data, data.to)
            this.$store.commit('global/spliceMessageOffline', data.keyId)

            // update current room
            // const lastMessage = {
            //   content,
            //   senderId: self.selectedInstance.phone_number,
            //   username: self.currentRoom.name,
            //   timestamp: time,
            //   saved: false,
            //   distributed: false,
            //   seen: false,
            //   new: false,
            // }

            // const roomIndex = self.rooms.findIndex(room => room.id == self.currentRoomId)

            // if (roomIndex >= 0) {
            //   self.rooms[roomIndex].lastMessage = lastMessage
            //   self.rooms = [...self.rooms]
            // }

            if (data) {
              const indexMessage = self.messages.findIndex(item => item._id == message._id)

              if (indexMessage >= 0) {
                if (response.data.data) {
                  //console.log('Berhasil Kirim')

                  // response send_json
                  const responseMessage = response.data.data

                  self.messages[indexMessage]._id = responseMessage.key.id
                  self.messages[indexMessage].keyId = responseMessage.key.id
                  self.messages[indexMessage].indexId = responseMessage.key.id
                  self.messages[indexMessage].data.key = responseMessage.key
                  self.messages[indexMessage].data.message = responseMessage.message
                  self.messages[indexMessage].status = responseMessage.status
                  self.messages[indexMessage].saved = responseMessage.status <= 1
                  self.messages[indexMessage].send = responseMessage.status == 2
                  self.messages[indexMessage].distributed = responseMessage.status == 3
                  self.messages[indexMessage].seen = responseMessage.status >= 4
                  self.messages[indexMessage].messageTimestamp = responseMessage.messageTimestamp
                } else {
                  // response send_batch
                }
              }
            }
          })
          .catch(error => {
            self.toastsErrorSendMessage()
          })
      })
    },
    parseMessage(messageId, content, files, replyMessage, usersTag, isCaption = false) {
      const self = this

      const date = this.$root.$i18n.locale == 'en' ? 'Today' : 'Hari ini'
      const time = self.$moment().format('HH:mm')
      const messageTimestamp = self.$moment().unix()
      const formattedMessageTimestamp = self.$moment().format()
      const nanoid = messageId.id
      const attachment = self.attachments.find(item => item.id == nanoid)

      const message = {
        contactName: self.currentRoom.name,
        contactPhoneNumber: self.currentRoom.person.substr(0, self.currentRoom.person.indexOf('@')),
        channelName: self.selectedInstance.label,
        channelPhoneNumber: self.selectedInstance.phone_number,
        attachment: attachment ? attachment.url : '',
        formattedMessageTimestamp,
        keyId: nanoid,
        person: self.currentRoom.person,
        avatar: self.currentRoom.avatar,
        caption: content,
        captionOrigin: content,
        time,
        messageTimestamp,
        sent: true,
        data: {
          key: { fromMe: true, id: nanoid, remoteJid: self.currentRoom.person },
          message: { conversation: content },
          messageTimestamp,
          source: self.currentRoom.roomId,
        },
        participant: '',
        quotedParticipant: '',
        quoted: '',
        quotedData: '',
        imgBase64: '',
        docBase64: '',
        type: '',
        quotedImage: '',
        quotedType: '',
        status: null,
        deleted: false,
        paramBlob: null,
        selected: false,
        _id: nanoid,
        indexId: nanoid,
        content,
        senderId: self.selectedInstance.phone_number,
        username: self.currentRoom.name,
        date,
        timestamp: time,
        system: false,
        saved: true,
        send: false,
        distributed: false,
        seen: false,
        disableActions: false,
        disableReactions: true,
      }

      if (replyMessage) {
        let contentReplyMessage = replyMessage.content

        const obj = self.setChatContactInbox(
          replyMessage.data,
          replyMessage.instance,
          this.contactsPouch,
          replyMessage.instance,
        )

        if (obj) {
          contentReplyMessage = obj.content
        }

        let replyParticipant = ''

        if (replyMessage.data.key.fromMe) {
          replyParticipant = replyMessage.channelPhoneNumber
        } else if (replyMessage.person.includes('-') || replyMessage.person.includes('@g')) {
          replyParticipant = replyMessage.participant.substr(0, replyMessage.participant.indexOf('@'))
        } else {
          replyParticipant = replyMessage.person.substr(0, replyMessage.person.indexOf('@'))
        }

        message.replyMessage = {
          content: contentReplyMessage,
          senderId: replyParticipant,
        }

        if (replyMessage.files) {
          message.replyMessage.files = replyMessage.files
        }
      }

      if (files && files.length > 0) {
        const messageFile = self.messageFile.filter(item => item.id == nanoid)
        message.files = messageFile

        if (messageFile && messageFile.length > 0) {
          // caption and quote/reply message only at first image
          if (
            messageFile[0].indexFile > 0 &&
            (messageFile[0].fileType.includes('image') || messageFile[0].fileType.includes('video'))
          ) {
            message.caption = ''
            message.captionOrigin = ''
            message.content = ''
          } else {
            // remove content image if is caption
            if (messageFile[0].fileType.includes('image') || messageFile[0].fileType.includes('video')) {
              if (isCaption) {
                message.caption = ''
                message.captionOrigin = ''
                message.content = ''
                delete message.replyMessage
              }
            }
          }
        }
      }

      // push to messages
      if (self.selectedInstance.status == 1) {
        self.messages.push(message)
        self.messages = _orderBy(self.messages, 'messageTimestamp', 'asc')
        self.messages = [...self.messages]
      }

      return message
    },
    async saveSavedReplies(channelId) {
      if (!this.savedRepliesTag || !this.savedRepliesName) {
        this.showSnackbarQuickReply = true
        this.quickReplyValidationMessage = this.$t('EOrdering.formValidationMessage')
      } else if (this.savedRepliesTag && this.savedRepliesName) {
        // let channelFilterId = this.$store.getters['global/getGlobalChannel'].value._id
        const channelFilterId = channelId.roomOwnerId
        if (this.activeSavedReplies) {
          const index = this.savedReplies.value.findIndex(item => item.id == this.activeSavedReplies.id)
          if (index >= 0) {
            this.savedReplies.value[index] = {
              id: this.activeSavedReplies.id,
              tag: this.savedRepliesTag,
              text: this.savedRepliesName,
              channelId: channelFilterId,
            }
          }
        } else {
          this.savedReplies.value.push({
            id: this.$nanoid(),
            tag: this.savedRepliesTag,
            text: this.savedRepliesName,
            channelId: channelFilterId,
          })
        }

        await this.updateSavedReplies()
      }
    },
    async saveSavedRepliesTemplates() {
      if (this.savedRepliesTemplate) {
        const index = this.messageTemplates.value.findIndex(item => item.id == this.savedRepliesTemplate.id)

        if (index >= 0) {
          this.messageTemplates.value[index].quick_reply = true
          this.messageTemplates.value[index].last_update = this.$moment().format('DD-MM-YYYYTHH:mm:ss')
        }

        //console.log(this.messageTemplates, ' == this.messageTemplates')

        await this.updateMessageTemplates()
      }
    },
    async deleteSavedReplies(id, messageTemplates = null) {
      if (messageTemplates) {
        const index = this.messageTemplates.value.findIndex(item => item.id == messageTemplates.id)

        if (index >= 0) {
          this.messageTemplates.value[index].quick_reply = false
        }

        await this.updateMessageTemplates()
        const data = this.savedReplies.value.filter(item => item.id !== id)
        this.$set(this.savedReplies, 'value', data)
      } else {
        let index = this.savedReplies.value.findIndex(item => item.id == id)

        if (index == -1) {
          index = 0
        }

        if (index !== -1) {
          this.savedReplies.value.splice(index, 1)
        }

        // this.savedReplies.value = this.savedReplies.value.filter(item => item.id !== id)

        // //console.log(`jalan filter`, this.savedReplies.value)

        await this.updateSavedReplies()
      }
    },
    runNotifRoom(room) {
      //console.log(this.room)
    },
    async updateSavedReplies() {
      // const savedReplies = this.savedReplies.value.filter(item => !item.message_templates)

      // this.$set(this.savedReplies, 'value', savedReplies)
      this.loadingQuickReplies = true

      // await this.updateSettings(this.savedReplies, async payload => {
      //   this.dialogSavedReplies = false
      //   this.savedRepliesTag = null
      //   this.savedRepliesName = null
      // })
      // //console.log(this.savedReplies.value, 'SAVED SEBELUM DI UPDATE')
      await this.$store.dispatch('global/updateSettingsByKey', {
        sub_id: this.user.sub_id,
        token: this.user.token,
        key: 'saved_replies',
        value: this.savedReplies.value,
      })

      this.dialogSavedReplies = false
      this.savedRepliesTag = null
      this.savedRepliesName = null

      // const settings = await this.loadSettings()
      // this.settingsPouch = settings

      // const messageTemplates = settings.find(item => item.key == 'message_templates')
      // this.messageTemplates = messageTemplates || []
      if (this.messageTemplates.value) {
        this.messageTemplates.value.forEach(messageTemplate => {
          if (messageTemplate.quick_reply) {
            this.savedReplies.value.push({
              id: messageTemplate.id,
              name: messageTemplate.message,
              message_templates: messageTemplate,
            })
          } else {
            // this.savedReplies.value = this.savedReplies.filter(item => item.id == messageTemplate.id)

            const index = this.savedReplies.value.findIndex(item => item.id == messageTemplate.id)
            if (index !== -1) {
              this.savedReplies.value.splice(index, 1)
            }
          }
        })

        // remove duplicate by quick reply id
        this.savedReplies.value = this.savedReplies.value.filter(
          (item, index, that) =>
            index === that.findIndex(t => t !== undefined && item !== undefined && t.id === item.id),
        )
      }
      this.loadingQuickReplies = false
    },
    async putSavedReplies(messageId, reply) {
      let content = ''
      const mustache = this.mustacheMessage(reply.name)

      if (mustache) {
        content = mustache
      }

      if (
        reply.message_templates &&
        reply.message_templates.attachments &&
        reply.message_templates.attachments.length > 0
      ) {
        for (const [indexFile, file] of reply.message_templates.attachments.entries()) {
          messageId = {
            id: `msgid${messageId.id}`,
            stanzaId: `msgid${messageId.stanzaId}`,
          }

          this.dataFile.push({
            id: messageId.id,
            url: file.url ? file.url : file.uploadURL,
            mediaKey: '',
            fileEncSha256: '',
            fileSha256: '',
            fileLength: 0,
            urlType: 'img',
            indexFile,
          })

          this.messageFile.push({
            id: messageId.id,
            name: file.name,
            size: file.size,
            type: file.extension,
            audio: false,
            duration: 0,
            url: file.url ? file.url : file.uploadURL,
            preview: file.url ? file.url : file.uploadURL,
            previewVideo: false,
            indexFile,
          })

          this.attachments.push({
            id: messageId.id,
            url: file.url ? file.url : file.uploadURL,
            type: file.type,
            filename: file.name,
            indexFile,
          })

          const message = this.parseMessage(messageId, content, reply.message_templates.attachments, null, null)
          await this.axiosSendMessage(
            messageId,
            content,
            reply.message_templates.attachments,
            null,
            null,
            message,
            true,
          )
        }
      } else {
        const message = this.parseMessage(messageId, content, [], null, null)
        await this.axiosSendMessage(messageId, content, [], null, null, message, true)
      }
    },
    async updateMessageTemplates() {
      this.loadingQuickReplies = true

      // await this.updateSettings(this.messageTemplates, async payload => {
      // })

      await this.$store.dispatch('global/updateSettingsByKey', {
        sub_id: this.user.sub_id,
        token: this.user.token,
        key: 'message_template',
        value: this.messageTemplates.value,
      })

      this.dialogSavedRepliesTemplates = false
      this.savedRepliesTemplate = null
      this.loadingQuickReplies = false
    },
    async updateNotes(data) {
      await this.updateContact(data, async payload => {
        const ctcsPouch = this.contactsPouch.find(x => x._id === data._id)
        if (ctcsPouch) {
          ctcsPouch.notes = data.notes
        }
        this.dialogNotes = false
        this.notesName = null
      })
    },
    async deleteNotes(id) {
      const room = this.rooms.find(item => item.roomId == this.selectedRoom)

      let index = room.notes.findIndex(item => item.id == id)

      if (index == -1) {
        index = 0
      }

      if (index !== -1) {
        room.notes.splice(index, 1)
      }

      // await this.updateDocumentByField(room.roomPath, 'notes', room.notes)
      await this.updateRoomCouchDb(room.subId, room.instance_id, room.roomId, { notes: room.notes })

      this.dialogNotes = false
      this.notesName = null
      this.activeNotes = null
    },
    async saveNotes() {
      const room = this.rooms.find(item => item.roomId == this.selectedRoom)

      if (this.activeNotes) {
        const index = room.notes.findIndex(item => item.id == this.activeNotes.id)

        if (index >= 0) {
          room.notes[index] = {
            id: this.activeNotes.id,
            name: this.notesName,
            sub_id: this.user.sub_id,
            created_at: this.activeNotes.created_at ? this.activeNotes.created_at : this.$moment().unix(),
            created_by: this.activeNotes.created_by ? this.activeNotes.created_by : this.user.email,
            updated_at: this.$moment().unix(),
            updated_by: this.user.email,
          }
        }
      } else {
        if (!room.notes) room.notes = []

        room.notes.push({
          id: this.$nanoid(),
          name: this.notesName,
          sub_id: this.user.sub_id,
          created_at: this.$moment().unix(),
          created_by: this.user.email,
          updated_at: this.$moment().unix(),
          updated_by: this.user.email,
        })
      }

      // await this.updateDocumentByField(room.roomPath, 'notes', room.notes)
      await this.updateRoomCouchDb(room.subId, room.instance_id, room.roomId, { notes: room.notes })

      this.dialogNotes = false
      this.notesName = null
      this.activeNotes = null
    },
    async archiveContactRoomMenu() {
      const room = this.rooms.find(item => item.roomId == this.selectedRoomRoomMenu)

      if (room) {
        if (!room.archived) {
          room.archived = true
        } else {
          room.archived = false
        }

        // await this.updateDocumentByField(room.roomPath, 'archived', room.archived)
        // //console.log(room, `INI ROOMNYA`)
        if (room.lastMessage && room.lastMessage.original_message) {
          const endpoint = `${process.env.VUE_APP_API_GENERAL}messages/archived-chat`
          const body = {
            subId: room.subId,
            roomId: room.roomId,
            last_message: room.lastMessage.original_message || room.lastMessage.original_message,
            remote_jid: room.lastMessage.original_message.key
              ? room.lastMessage.original_message.key.remoteJid
              : `${room.phone_number}@s.whatsapp.net`,
            archived: room.archived,
            server: 'wabailey',
          }

          this.$axios
            .post(endpoint, body)
            .then(response => {
              //console.log(response, ' == response api general')
            })
            .catch(error => {
              console.log(error)
            })
        }
        await this.updateRoomCouchDb(room.subId, room.instance_id, room.roomId, { archived: room.archived })
      }
    },
    async archiveContact() {
      const room = this.rooms.find(item => item.roomId == this.selectedRoom)

      if (room) {
        if (!room.archived) {
          room.archived = true
        } else {
          room.archived = false
        }

        if (room.lastMessage && room.lastMessage.original_message) {
          const endpoint = `${process.env.VUE_APP_API_GENERAL}messages/archived-chat`
          const body = {
            subId: room.subId,
            roomId: room.roomId,
            last_message: room.lastMessage.original_message || room.lastMessage.original_message,
            remote_jid: room.lastMessage.original_message.key
              ? room.lastMessage.original_message.key.remoteJid
              : `${room.phone_number}@s.whatsapp.net`,
            archived: room.archived,
            server: 'wabailey',
          }

          this.$axios
            .post(endpoint, body)
            .then(response => {
              //console.log(response, ' == response api general')
            })
            .catch(error => {
              console.log(error)
            })
        }
        await this.updateRoomCouchDb(room.subId, room.instance_id, room.roomId, { archived: room.archived })
      }
    },
    async assignContact(selectedOperator = null) {
      //console.log(selectedOperator, `INI SELECTED OP`)
      const roomList = JSON.parse(JSON.stringify(this.rooms))
      const room = roomList.find(item => item.roomId == this.selectedRoom)

      if (selectedOperator) {
        if (!room.last_interaction) room.last_interaction = selectedOperator
        else room.last_interaction = selectedOperator

        // if (!room.assign_to) room.assign_to = selectedOperator
        // else room.assign_to = selectedOperator
        room.assign_to = {
          email: selectedOperator.email,
          image: selectedOperator.image,
          name: selectedOperator.name,
          phone_number: selectedOperator.phone_number ? selectedOperator.phone_number : '',
          prof_pic: selectedOperator.prof_pic ? selectedOperator.prof_pic : '',
        }
      } else {
        if (!room.last_interaction) {
          room.last_interaction = {}
          room.last_interaction.phone_number = room.roomOwnerId
          room.last_interaction.email = this.user.email
          room.last_interaction.image = this.user.image
          room.last_interaction.prof_pic = this.user.image
          room.last_interaction.name = this.user.name
        } else if (room.last_interaction.email === this.user.email) {
          room.last_interaction.phone_number = room.roomOwnerId
          room.last_interaction.email = this.user.email
          room.last_interaction.image = this.user.image
          room.last_interaction.prof_pic = this.user.image
          room.last_interaction.name = this.user.name
        }

        if (!room.assign_to) {
          room.assign_to = {}
          room.assign_to.phone_number = room.roomOwnerId
          room.assign_to.email = this.user.email
          room.assign_to.image = this.user.image
          room.assign_to.prof_pic = this.user.image
          room.assign_to.name = this.user.name
          room.roomAssignedTo = this.user.email
        } else if (room.assign_to.email === this.user.email) {
          room.assign_to.phone_number = room.roomOwnerId
          room.assign_to.email = this.user.email
          room.assign_to.image = this.user.image
          room.assign_to.prof_pic = this.user.image
          room.assign_to.name = this.user.name
          room.roomAssignedTo = this.user.email
        }
      }

      // //console.log(room.assign_to, `INI DI MIXIN`)

      // await this.updateDocumentByField(room.roomPath, 'last_interaction', room.last_interaction)
      // await this.updateDocumentByField(room.roomPath, 'assign_to', room.assign_to)
      //console.log(room, 'ROOM SELECTed COY')

      await this.updateRoomCouchDb(room.subId, room.instance_id, room.roomId, {
        last_interaction: room.last_interaction,
      })

      await this.updateRoomCouchDb(room.subId, room.instance_id, room.roomId, { assign_to: room.assign_to })
      await this.$store.dispatch('rooms/changeOperator', {
        sub_id: this.user.sub_id,
        email: selectedOperator.email,
        phone_number: room.phone_number,
        token: this.user.token,
      })

      // //console.log('🚀 ~ file: inboxMixin.js ~ line 4034 ~ assignContact ~ res', res)
      this.rooms = [...roomList]

      // if (room) {
      // } else {
      //   //console.log('🚀 ~ file: inboxMixin.js ~ line 3969 ~ assignContact ~ room', this.currentRoom)

      //   // await this.$store.dispatch('rooms/insertRooms')
      // }
    },
    async changePinned() {
      const room = this.rooms.find(item => item.roomId == this.selectedRoom)

      if (room) {
        if (!room.pinned) {
          room.pinned = 1
        } else {
          room.pinned = 0
        }

        // await this.updateDocumentByField(room.roomPath, 'pinned', room.pinned)
        await this.updateRoomCouchDb(room.subId, room.instance_id, room.roomId, { pinned: room.pinned })
      }
    },
    mustacheMessage(formula) {
      const fields = []
      const pattern = /{{(.*?)}}/g
      let curMatch

      while ((curMatch = pattern.exec(formula))) {
        const split = curMatch[1].split(' | ')
        fields.push(split[0])
      }

      if (fields && fields.length > 0) {
        const obj = {}
        const phoneNumber = this.currentPerson ? this.currentPerson.substr(0, this.currentPerson.indexOf('@')) : ''
        const contact = this.contactsPouch.find(contact => contact.phone_number == phoneNumber)

        fields.forEach(field => {
          if (contact) {
            obj[field] = contact[field]
          } else {
            obj[field] = null
          }
        })

        var model = this.$Mustache.render(formula, obj)
      } else {
        var model = formula
      }

      return model
    },
    filterRooms(dataVuex = []) {
      let roomsOrigin = []
      if (dataVuex.length > 0) {
        roomsOrigin = JSON.parse(JSON.stringify(dataVuex))
      } else {
        roomsOrigin = JSON.parse(JSON.stringify(this.roomsOrigin))
      }

      let rooms = []
      let roomsFiltered = []

      const selectedInstance =
        this.$store.getters['inboxNavbar/getValueChannel'] && this.$store.getters['inboxNavbar/getValueChannel'].value
          ? this.$store.getters['inboxNavbar/getValueChannel'].value
          : this.selectedInstance
      if (dataVuex.length === 0 && selectedInstance) {
        if (selectedInstance.all_sender) {
          rooms = roomsOrigin
        } else {
          rooms = roomsOrigin.filter(room => room.instance.phone_number == selectedInstance.phone_number)
        }
      } else {
        rooms = roomsOrigin
      }

      if (this.selectedSettingFilter) {
        if (
          this.selectedSettingFilter.system &&
          (this.selectedSettingFilter.name === 'All Chat' || this.selectedSettingFilter.name === 'All Contacts')
        ) {
          roomsFiltered = rooms
        } else if (this.selectedSettingFilter.system && this.selectedSettingFilter.name === 'Unreplied') {
          roomsFiltered = rooms.filter(room => {
            if (room.contact.assign_to) {
              return (
                room.contact.assign_to.email === this.user.email &&
                room.contact.archived !== 1 &&
                room.contact.last_reply == room.contact.last_message
              )
            }

            return room.contact.archived !== 1 && room.contact.last_reply == room.contact.last_message
          })
        } else if (this.selectedSettingFilter.system && this.selectedSettingFilter.name === 'Unserved') {
          roomsFiltered = rooms.filter(room => {
            if (room.contact.last_interaction !== undefined) {
              return room.contact.last_interaction === '' && room.contact.archived !== 1
            }

            return false
          })
        } else if (this.selectedSettingFilter.system && this.selectedSettingFilter.name === 'Open') {
          roomsFiltered = rooms.filter(room => {
            if (room.contact.assign_to) {
              return room.contact.assign_to.email === this.user.email && room.contact.archived !== 1
            }

            return room.contact.archived !== 1
          })
        } else if (this.selectedSettingFilter.system && this.selectedSettingFilter.name === 'Group') {
          roomsFiltered = rooms.filter(
            room =>
              (room.contact.phone_number_show === 'Group' ||
                room.contact.phone_number_show.includes('-') ||
                room.contact.phone_number_show.length > 16) &&
              room.contact.archived !== 1,
          )
        } else if (this.selectedSettingFilter.system && this.selectedSettingFilter.name === 'Archived') {
          roomsFiltered = rooms.filter(room => room.contact.archived === 1)
        } else if (this.selectedSettingFilter.system && this.selectedSettingFilter.name === 'Closed') {
          roomsFiltered = rooms.filter(room => room.contact.archived === 1)
        } else if (this.selectedSettingFilter.system && this.selectedSettingFilter.name === 'Excluded') {
          roomsFiltered = rooms.filter(room => room.contact.archived === 1)
        } else if (this.selectedSettingFilter.system && this.selectedSettingFilter.name === 'Assigned') {
          roomsFiltered = rooms.filter(room => room.contact.assign_to)
        } else if (this.selectedSettingFilter.system && this.selectedSettingFilter.name === 'Unassigned') {
          roomsFiltered = rooms.filter(room => !room.contact.assign_to)
        }
      } else {
        roomsFiltered = rooms
      }

      // remove duplicate by room id
      roomsFiltered = roomsFiltered.filter(
        (room, index, that) =>
          index === that.findIndex(t => t !== undefined && room !== undefined && t.roomId === room.roomId),
      )

      if (!roomsFiltered.find(x => this.selectedRoom === x.roomId)) {
        this.selectedRoom = null
        this.currentContact = null
        this.currentRoom = null

        // this.resetRooms()
      }

      if ((this.user.role === 'Member' || this.user.role !== 'Admin') && this.user.view_assign_only) {
        roomsFiltered = roomsFiltered.filter(room => {
          if (room.contact.assign_to) {
            return room.contact.assign_to.email === this.user.email
          }

          return false
        })
      }
      if ((this.user.role === 'Member' || this.user.role !== 'Admin') && !this.user.view_assign_only) {
        roomsFiltered = roomsFiltered.filter(room => {
          if (room.contact.assign_to) {
            return room.contact.assign_to.email === this.user.email
          }
          if (!room.contact.assign_to) {
            return true
          }

          return false
        })
      }

      this.rooms = [...roomsFiltered]
    },
    async callbackSendMessage(message, to) {
      const contact = JSON.parse(JSON.stringify(this.contactDetail))
      const person = `${this.selectedInstance.phone_number}@s.whatsapp.net`

      if (contact.last_interaction) {
        contact.last_interaction.phone_number = person
        contact.last_interaction.email = this.user.email
        contact.last_interaction.image = this.user.image
        contact.last_interaction.prof_pic = this.user.image
        contact.last_interaction.name = this.user.name
      } else {
        contact.last_interaction = {}
        contact.last_interaction.phone_number = person
        contact.last_interaction.email = this.user.email
        contact.last_interaction.image = this.user.image
        contact.last_interaction.prof_pic = this.user.image
        contact.last_interaction.name = this.user.name
      }

      if (!contact.assign_to) {
        contact.assign_to = {}
        contact.assign_to.phone_number = person
        contact.assign_to.email = this.user.email
        contact.assign_to.image = this.user.image
        contact.assign_to.prof_pic = this.user.image
        contact.assign_to.name = this.user.name
      } else if (contact.assign_to.email === this.user.email) {
        contact.assign_to.phone_number = person
        contact.assign_to.email = this.user.email
        contact.assign_to.image = this.user.image
        contact.assign_to.prof_pic = this.user.image
        contact.assign_to.name = this.user.name
      }

      if (contact._id) {
        await this.updateContact(contact)
      }

      let replyTime = null
      const messages = JSON.parse(JSON.stringify(this.messages))
      const fromMe = messages.length > 1 ? messages[messages.length - 2].data.key.fromMe : false

      if (!fromMe) {
        replyTime = contact.last_reply ? Math.floor(new Date().getTime() / 1000) - contact.last_reply : 0

        let latestMessage = []

        if (messages.length > 5) {
          latestMessage.push(messages[messages.length - 1])
          latestMessage.push(messages[messages.length - 2])
          latestMessage.push(messages[messages.length - 3])
          latestMessage.push(messages[messages.length - 4])
          latestMessage.push(messages[messages.length - 5])
        } else {
          latestMessage = messages
        }

        const history = {
          email: this.user.email,
          name: this.user.name,
          image: this.user.image,
          chat: message,
          attachment: this.attachments,
          to: to[0],
          from: this.selectedInstance.phone_number,
          timestamp: this.$moment().unix(),
          reply_time: replyTime,
          latest_message: latestMessage,
          room: this.currentRoom,
        }

        await this.insertHistory(history)
      }

      if (this.selectedInstance && this.$store.getters['global/getBoldUnreplied']) {
        const endpoint = `${process.env.VUE_APP_WA_API_URL}read/${this.selectedInstance.sub_id}-${this.selectedInstance.instance_id}/${this.currentPerson}`

        this.$axios
          .get(endpoint)
          .then(async response => {})
          .catch(error => {
            console.log(error, ' == ERROR')
          })
      }
    },
    setLabels(roomId) {
      let labels = []

      //console.log('🚀 ~ file: inboxMixin.js ~ line 4274 ~ setLabels ~ this.label', this.label, this.labels, roomId)
      if (this.label && this.label.value && this.label.value.length > 0) {
        const label = this.label.value.filter(l => {
          const isLabelExists = this.labels.find(i => i.id == l.label.id)

          // //console.log(
          //   '🚀 ~ file: inboxMixin.js ~ line 4281 ~ setLabels ~ l.contact.roomId == roomId',
          //   l.contact,
          //   roomId,
          //   isLabelExists,
          //   l.contact.roomId == roomId,
          //   l.contact.roomId.split('-')[1] == roomId.split('-')[1],
          // )
          if (isLabelExists) {
            // const contactPhoneNumber = roomId.split('-')[1]
            // if (l.contact && !l.chat) {
            //   return l.contact.roomId.split('-')[1] == contactPhoneNumber
            // }
            return l.contact.roomId === roomId
          }
        })

        if (label && label.length > 0) {
          labels = label
        }
      }

      // //console.log('🚀 ~ file: inboxMixin.js ~ line 4292 ~ setLabels ~ labels', labels)

      return labels
    },
    setMessageLabels(message) {
      if (this.label && this.label.value && this.label.value.length > 0) {
        const labels = this.label.value.filter(
          label => label.id && label.chat !== null && label.chat.source_id === message.source_id,
        )

        if (labels && labels.length > 0) {
          message.labels = labels
        }
      }

      return message
    },
    async saveLabelsRoomMenu() {
      //console.log('<ADA DISINI')
      const label = []
      const room = this.rooms.find(item => item.roomId == this.selectedRoomRoomMenu)

      if (room) {
        const contact = {
          roomId: room.roomId,
          name: room.roomName,
          phone_number: room.phone_number,
          phone_number_show: room.phone_number_show,
          avatar: room.avatar,
          lastMessage: room.lastMessage,
          last_message: room.last_message,
        }

        const deletedLabel = this.label.value.filter(
          x => x.chat == null && x.contact && x.contact.roomId == room.roomId,
        )

        for (var data of deletedLabel) {
          const idx = this.label.value.findIndex(x => x.id === data.id)

          if (idx > -1) {
            this.label.value.splice(idx, 1)
          }
        }

        for (const selected of this.selectedLabels) {
          const obj = {
            id: this.$nanoid(),
            labelName: selected.label,
            label: selected,
            contact,
            chat: null,
          }

          this.label.value.push(obj)
          label.push(obj)
        }

        await this.$store.commit('inbox/setLabelValue', this.label.value)

        // const res = await this.$store.dispatch('global/updateSettingsByKey', {
        //   sub_id: this.user.sub_id,
        //   token: this.user.token,
        //   key: 'label_value',
        //   value: this.label.value,
        // })
        // if (res.status) {
        // }
        this.dialogLabelsRoomMenu = false
        this.selectedLabels = []
        this.updateRoomLabelsRoomMenu(label)

        // await this.updateSettings(this.label, async payload => {
        //   if (payload && payload.ok) {
        //     await this.updateRoomLabelsRoomMenu(label)
        //   }
        // })
      }
    },
    async saveLabels() {
      const label = []
      const room = this.rooms.find(item => item.roomId == this.selectedRoom)

      if (room) {
        const contact = {
          roomId: room.roomId,
          name: room.roomName,
          phone_number: room.phone_number,
          phone_number_show: room.phone_number_show,
          avatar: room.avatar,
          lastMessage: room.lastMessage,
          last_message: room.last_message,
        }

        if (this.currentAction == 'addLabel') {
          const deletedLabel = this.label.value.filter(
            x => x.chat == null && x.contact && x.contact.roomId == room.roomId,
          )

          for (var data of deletedLabel) {
            const idx = this.label.value.findIndex(x => x.id === data.id)

            if (idx > -1) {
              this.label.value.splice(idx, 1)
            }
          }
        } else if (this.currentAction == 'labelMessage') {
          const deletedLabel = this.label.value.filter(x => x.chat && x.chat.source_id == this.currentMessage.source_id)

          for (var data of deletedLabel) {
            const idx = this.label.value.findIndex(x => x.id === data.id)

            if (idx > -1) {
              this.label.value.splice(idx, 1)
            }
          }
        }

        for (const selected of this.selectedLabels) {
          if (this.currentAction == 'addLabel') {
            const obj = {
              id: this.$nanoid(),
              labelName: selected.label,
              label: selected,
              contact,
              chat: null,
            }

            this.label.value.push(obj)
            label.push(obj)
          } else if (this.currentAction == 'labelMessage') {
            const chatMessage = JSON.parse(JSON.stringify(this.currentMessage))
            delete chatMessage.labels

            const obj = {
              id: this.$nanoid(),
              labelName: selected.label,
              label: selected,
              contact,
              chat: chatMessage,
            }

            this.label.value.push(obj)
            label.push(obj)
          } else if (this.currentAction == 'labelMessages') {
            this.currentMessages.forEach(message => {
              const exists = this.label.value.find(
                item =>
                  item.id &&
                  item.chat !== null &&
                  item.chat.source_id === message.source_id &&
                  item.labelName === selected.label,
              )

              if (!exists) {
                const chatMessages = JSON.parse(JSON.stringify(message))
                delete chatMessages.labels

                const obj = {
                  id: this.$nanoid(),
                  labelName: selected.label,
                  label: selected,
                  contact,
                  chat: chatMessages,
                }

                this.label.value.push(obj)
                label.push(obj)
              } else {
                label.push(exists)
              }
            })
          }
        }

        await this.$store.commit('inbox/setLabelValue', this.label.value)

        // //console.log(this.label, ' == this.label')
        // const res = await this.$store.dispatch('global/updateSettingsByKey', {
        //   sub_id: this.user.sub_id,
        //   token: this.user.token,
        //   key: 'label_value',
        //   value: this.label.value,
        // })
        // if (res.status) {
        // }
        if (this.currentAction == 'addLabel') {
          await this.updateRoomLabels(label)
        } else if (this.currentAction == 'labelMessage') {
          await this.updateMessageLabels(label)
        } else if (this.currentAction == 'labelMessages') {
          await this.updateMessageLabels(label, true)
          this.showCheckbox = false
        }
        this.dialogLabels = false
        this.selectedLabels = []

        // await this.updateSettings(this.label, async payload => {
        //   if (payload && payload.ok) {
        //     if (this.currentAction == 'addLabel') {
        //       await this.updateRoomLabels(label)
        //     } else if (this.currentAction == 'labelMessage') {
        //       await this.updateMessageLabels(label)
        //     } else if (this.currentAction == 'labelMessages') {
        //       await this.updateMessageLabels(label, true)
        //       this.showCheckbox = false
        //     }
        //   }
        // })
      }
    },
    clearSelectedMessages() {
      this.messages.forEach(message => {
        message.selected = false
      })
    },
    async checkStatusInstances(interval = true) {
      // check status every 1 minutes
      const self = this
      const optionsInstances = JSON.parse(JSON.stringify(self.optionsInstances))

      if (interval) {
        self.statusInstancesInterval = setInterval(async () => {
          await self.getStatusInstancesFromDb(optionsInstances)
        }, 60000)
      } else {
        await self.getStatusInstancesFromApi(optionsInstances)
      }
    },
    async getStatusInstancesFromDb(optionsInstances) {
      const settings = await this.loadSettings()
      const instances = settings.find(item => item.key == 'instances')

      for (const instance of instances.value) {
        if (instance.phone_number) {
          const indexOptions = optionsInstances.findIndex(option => option.value._id == instance._id)

          if (indexOptions >= 0) {
            this.$set(this.optionsInstances[indexOptions], 'value', instance)
          }

          this.updateStatusInstancesList(instance.status, instance)

          if (this.selectedInstance && this.selectedInstance.phone_number) {
            if (this.selectedInstance._id == instance._id) {
              this.$set(this.selectedInstance, 'status', instance.status)
            }
          }
        }
      }
    },
    async getStatusInstancesFromApi(optionsInstances) {
      for (const instance of this.instances) {
        if (instance.phone_number) {
          await this.$store.dispatch('auth/checkStatus', `${instance.sub_id}-${instance.instance_id}`)

          const status = this.$store.getters['auth/getStatusInstance']
          const indexOptions = optionsInstances.findIndex(option => option.value._id == instance._id)

          if (indexOptions >= 0) {
            this.$set(this.optionsInstances[indexOptions], 'value', instance)
          }

          this.updateStatusInstancesList(status, instance)

          if (this.selectedInstance && this.selectedInstance.phone_number) {
            if (this.selectedInstance._id == instance._id) {
              this.$set(this.selectedInstance, 'status', status)
            }
          }
        }
      }
    },
    async saveContactList() {
      if (this.contactList && this.contactList.value && this.contactList.value.length > 0) {
        if (this.contactListName) {
          if (this.activeContactList) {
            const index = this.contactList.value.findIndex(item => item.id == this.activeContactList.id)

            if (index >= 0) {
              this.contactList.value[index].list_name = this.contactListName
              this.contactList.value[index].last_modified = new Date().getTime()

              await this.updateContactList()
            }
          }
        }
      }
    },
    async updateContactList() {
      await this.updateSettings(this.contactList, async payload => {
        this.dialogContactList = false
        this.contactListName = null
      })
    },
    showAlertDialog(title, text) {
      this.alertDialog = true
      this.alertTitle = title
      this.alertText = text
    },
    getContactsRoom() {
      const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/contact/get-list`

      this.$axios
        .post(endpoint, { subId: this.user.sub_id }, { headers: { Authorization: `Bearer ${this.user.token}` } })
        .then(response => {
          if (response.data.status) {
            this.contactsRoom = response.data.contacts

            this.contactsRoom.Whatsapp.forEach(contact => {
              if (contact.phone_number_show) {
                if (contact.phone_number_show.includes('-') || contact.phone_number_show.length > 16) {
                  contact.phone_number_show = 'Group'
                }
              }
            })

            this.tableContactsRoom = response.data.contacts.Whatsapp
            this.optionsContactListsRoom = response.data.contacts_list
            this.optionsContactListsRoom.unshift({
              id: 'whatsapp',
              list_name: 'Whatsapp',
            })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    changeContactListRoom(item) {
      this.tableContactsRoom = JSON.parse(JSON.stringify(item.contacts)).map(contact => {
        if (!contact._uid) {
          contact._uid = this.$nanoid()
        }

        if (contact.profile_picture) {
          const picture = contact.profile_picture.split('https://')
          if (picture.length > 1) {
            contact.profile_picture = `https://${picture[picture.length - 1].replace('//ppic', '/ppic')}`
          }
        }
        contact.profile_picture = contact.profile_picture
          ? contact.profile_picture
          : require('../plugins/demo/assets/avatar.png')

        return contact
      })

      // this.tableContactsRoom = this.contactListsRoom[item.list_id]
    },
    async actionNewChat(selected = null) {
      //console.log('🚀 ~ file: inboxMixin.js ~ line 4646 ~ actionNewChat ~ selected.item', selected)
      if (this.selectedInstanceRoom) {
        if (selected.value) {
          // from selected contact
          await this.openChatByPhoneNumber(selected.item, true)
        } else {
          // from enter phone number
          await this.openChatByPhoneNumber(selected.item)
        }
      } else {
        this.showAlertDialog('Oops...', this.$t('dialog.selectChannel'))
        this.selectedContactRoom = []
      }
    },
    async openChatByPhoneNumber(phoneNumber = null, fromContact = false) {
      this.loadingPhoneNumberContactRoom = true

      let isValid = false
      let bypass = false
      if (phoneNumber && phoneNumber.bypassValid) {
        // BYPASS BUKA DARI CHAT HEADER
        isValid = true
        bypass = true
      }
      if (!bypass) {
        // check format
        // phoneNumber.phone_number = this.countryCodeContactRoom + this.phoneNumberContactRoom

        // if (!phoneNumber.phone_number) {
        // }

        if (!fromContact) {
          phoneNumber.phone_number = this.countryCodeContactRoom + this.phoneNumberContactRoom
        }
        phoneNumber.phone_number = phoneNumber.phone_number.replace(/[a-zA-Z]/g, '')
        phoneNumber.phone_number = phoneNumber.phone_number.replace(/\D/g, '')
      }
      //console.log(phoneNumber, 'INIS ETELAH')

      // //console.log(
      //   '🚀 ~ file: inboxMixin.js ~ line 4685 ~ openChatByPhoneNumber ~ phoneNumber.phone_number',
      //   phoneNumber.phone_number,
      //   this.countryCodeContactRoom,
      //   this.phoneNumberContactRoom,
      // )

      // to do, international format checker
      if (/\d{11,13}/.test(phoneNumber.phone_number)) {
        if (phoneNumber.phone_number.length < 16) {
          isValid = true
        }
      }
      //console.log(this.selectedInstanceRoom, bypass, 'INIS ETELAH INSTANCE INI')

      if (isValid) {
        const channelName = bypass ? phoneNumber.instances.label : this.selectedInstanceRoom.value.label
        const channelPhoneNumber = bypass
          ? phoneNumber.instances.phone_number
          : this.selectedInstanceRoom.value.phone_number
        const subId = bypass ? phoneNumber.instances.sub_id : this.selectedInstanceRoom.value.sub_id
        const instanceId = bypass ? phoneNumber.instances._id : this.selectedInstanceRoom.value.instance_id
        const endpoint = `${process.env.VUE_APP_WA_SERVICES_URL}exists/${subId}-${instanceId}`
        //console.log(phoneNumber, 'ini phone')

        await this.$axiosLimit
          .post(endpoint, { phone_numbers: [phoneNumber.phone_number] })
          .then(async response => {
            if (response.data.success) {
              const phoneNumbers = response.data.data

              if (phoneNumbers[phoneNumber.phone_number]) {
                const roomId = `${instanceId}-${phoneNumber.phone_number}`
                const room = {
                  _id: roomId,
                  phone_number_show: phoneNumber.phone_number,
                  users: [
                    {
                      username: phoneNumber.phone_number,
                      _id: phoneNumber.phone_number,
                      avatar: require('../plugins/demo/assets/avatar.png'),
                      status: null,
                    },
                    {
                      _id: channelPhoneNumber,
                      status: null,
                      avatar: `${process.env.VUE_APP_WA_SERVICES_URL}/ppic/${subId}-${instanceId}/${channelPhoneNumber}/a.jpg`,
                      username: channelName,
                    },
                  ],
                  subId,
                  roomOwnerId: channelPhoneNumber,
                  lastUpdated: { seconds: this.$moment().unix(), nanoseconds: 0 },
                  avatar: phoneNumber.profile_picture
                    ? phoneNumber.profile_picture
                    : require('../plugins/demo/assets/avatar.png'),
                  name: phoneNumber.name || this.formatPhoneNumber(phoneNumber.phone_number),
                  roomName: phoneNumber.name || this.formatPhoneNumber(phoneNumber.phone_number),
                  roomStatus: '',
                  path: `chatRooms/${subId}/listRooms/${roomId}`,
                  archived: false,
                  pinned: 0,
                  last_reply: null,
                  last_message: null,
                  last_message_status: 0,
                  message_from_me: 0,
                  unread_count: 0,
                  unreadCount: 0,
                  roomOwnerName: channelName,
                  phone_number: phoneNumber.phone_number,
                  unreplied: false,
                  instanceId,
                  instance_id: instanceId,
                  assign_to: null,
                  last_interaction: null,
                  channelSource: 'wabailey',
                  roomPath: `chatRooms/${subId}/listRooms/${roomId}`,
                  typingUsers: [],
                  notes: [],
                  labels: [],
                  roomId,
                  index: this.$moment().unix(),
                  lastMessage: { content: '', timestamp: this.$moment().format('DD/MM/YYYY') },
                  instance: bypass ? phoneNumber.instances : this.selectedInstanceRoom.value,
                }

                this.dialogAddRoom = false
                this.roomId = roomId
                this.currentRoom = this.roomsOrigin.find(room => room.roomId == roomId)

                const foundRoom = this.rooms.find(room => room.roomId == roomId)

                if (!foundRoom) {
                  this.rooms.push(room)
                  this.addHashToLocation(roomId)
                  this.currentRoom = room
                  this.selectedRoom = roomId

                  //console.log(room, 'added room not found')
                }
                if (foundRoom) {
                  this.$store.commit('global/setSelectedChatHeader', { status: true, roomId })
                }
                if (bypass) {
                  await this.fetchMessages({ room, options: { reset: true } }, null)
                } else {
                  //console.log('ini room fetch2')
                  await this.fetchMessages({ room, options: { reset: true } }, null)
                }

                // await this.addDocument(`chatRooms/${subId}/listRooms`, room)
                // await this.addRoomApi(subId, instanceId, roomId, room)
                // await this.fetchRooms()
              } else {
                this.showAlertDialog('Oops...', this.$t('contacts.phoneNumberNotValid'))
              }
            } else {
              this.showAlertDialog('Oops...', this.$t('contacts.phoneNumberNotValid'))
            }
          })
          .catch(error => {
            console.log(error)
          })
      } else {
        this.showAlertDialog('Oops...', this.$t('contacts.phoneNumberNotValid'))
      }

      this.loadingPhoneNumberContactRoom = false
    },
    exportMessages(data) {
      const columns = [
        {
          label: 'Timestamp',
          field: 'formatted_timestamp',
        },
        {
          label: 'Contact Name',
          field: 'sender_name',
        },
        {
          label: 'Contact Phone Number',
          field: 'sender_id',
        },
        {
          label: 'Channel Name',
          field: 'instance_name',
        },
        {
          label: 'Channel Phone Number',
          field: 'instance_phone_number',
        },
        {
          label: 'Message',
          field: 'content',
        },
        {
          label: 'Attachment',
          field: 'attachment',
        },
      ]

      // naive encoding to csv format
      const csv = [columns.map(col => wrapCsvValue(col.label))]
        .concat(
          data.map(row =>
            columns
              .map(col =>
                wrapCsvValue(
                  typeof col.field === 'function' ? col.field(row) : row[col.field === void 0 ? col.name : col.field],
                  col.format,
                ),
              )
              .join(','),
          ),
        )
        .join('\r\n')

      const anchor = document.createElement('a')
      anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`
      anchor.target = '_blank'
      anchor.download = 'Export Messages.csv'
      anchor.click()
    },
    generateVideoThumbnail(file) {
      return new Promise(resolve => {
        const canvas = document.createElement('canvas')
        const video = document.createElement('video')

        // this is important
        video.autoplay = true
        video.muted = true
        video.src = URL.createObjectURL(file)

        video.onloadeddata = () => {
          const ctx = canvas.getContext('2d')

          canvas.width = video.videoWidth
          canvas.height = video.videoHeight

          ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight)
          video.pause()

          return resolve(canvas.toDataURL('image/png'))
        }
      })
    },
    async updateRoomCouchDb(subId, instanceId, roomId, room) {
      const endpoint = `${process.env.VUE_APP_API_GENERAL}room/update-room-couch`
      const body = {
        subid: this.user.sub_id,
        instanceId,
        roomId,
        roomData: room,
      }
      //console.log(body, 'DTA BODY')
      try {
        const res = await this.$axiosLimit.post(endpoint, body)

        return res
      } catch (err) {
        return err
      }
    },
    async shareRoomRoomMenu() {
      const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/rooms/share-room`
      const body = {
        roomId: this.roomIdRoomMenu,
        expiryTime: this.sharedRoomExpiryTime,
        type: this.sharedRoomType,
      }
      const config = {
        headers: {
          authorization: `Bearer ${this.user.token}`,
        },
      }

      await this.$axiosLimit
        .post(endpoint, body, config)
        .then(async response => {
          if (response.data.status) {
            this.sharedRoomUrl = `${window.location.protocol}//${window.location.host}/room/${response.data.data.id}`
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    async shareRoom() {
      const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/rooms/share-room`
      const body = {
        roomId: this.roomId,
        expiryTime: this.sharedRoomExpiryTime,
        type: this.sharedRoomType,
      }
      const config = {
        headers: {
          authorization: `Bearer ${this.user.token}`,
        },
      }

      await this.$axiosLimit
        .post(endpoint, body, config)
        .then(async response => {
          if (response.data.status) {
            this.sharedRoomUrl = `${window.location.protocol}//${window.location.host}/room/${response.data.data.id}`
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    async doCopy(message) {
      try {
        await navigator.clipboard.writeText(message)
      } catch ($e) {
        //console.log(e, 'Can not copy')
      }
    },
  },
}
