export function getShopListLink(state) {
  return state.shopListLink
}
export function getMessageListLink(state) {
  return state.messageListLink
}
export function getTrackerListLink(state) {
  return state.trackerListLink
}
export function getSendLinkBroadcast(state) {
  return state.sendLinkBroadcast
}
export function getSaveDataEform(state) {
  return state.saveDataEform
}
export function getopenStatisticEform(state) {
  return state.openStatisticEform
}
export function getCampaignId(state) {
  return state.CampaignId
}

