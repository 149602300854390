export function setListInstance(state, obj) {
  if (obj && obj.length) {
    obj.forEach(element => {
      if (element.instance_label_name && element.expand.instance_label_name.edited_name) {
        element.label = element.expand.instance_label_name.edited_name
        element.label_server = element.expand.instance_label_name.edited_name
      }
    })
  }
  state.listInstance = obj
}

export function setListWorkspaces(state, obj) {
  state.listWorkspaces = obj
}

export function setListUsers(state, obj) {
  state.listUsers = obj
}

export function setIsResetChannels(state, value) {
  state.isResetChannels = value
}

export function editListInstance(state, obj) {
  state.listInstance[state.listInstance.findIndex(x => x.id === obj.id)] = obj
}

export function removeInstance(state, idx) {
  state.listInstance.splice(idx, 1)
}

export function addNewInstance(state, obj) {
  state.listInstance.push(obj)
}

export function setSelectedInstance(state, obj) {
  state.selectedInstance = obj
}

export function setStatusInstance(state, obj) {
  state.statusInstance = obj
}

export function setInstanceName(state, obj) {
  state.instanceName = obj
}

export function setCurrentUid(state, obj) {
  state.curUid = obj
}

export function setListMeeting(state, obj) {
  state.listMeeting = obj
}

export function setLogin(state, obj) {
  state.isLoggedIn = obj
}

export function setUser(state, obj) {
  state.user = obj
}

export function setUserState(state, obj) {
  state.userState = obj
}

export function setSelectedWorkspace(state, obj) {
  state.selectedWorkspace = obj
}

export function setListInvoice(state, obj) {
  state.listInvoice = obj
}

export function setUserObj(state, { key, payload }) {
  state.user[key] = payload
}

export function setSuccessSync(state, obj) {
  state.successSync = obj
}

export function setInstanceVals(state, data) {
  state.instanceVals = data
}
