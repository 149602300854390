/* eslint-disable */
import store from '@/store'
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// const isAuth = store.dispatch('auth/checkAuth')
// const isAdmin = store.dispatch('auth/checkAdmin')
// const isSubscribe = store.dispatch('auth/checkSubscribe')
const user = store.getters['auth/getUser']

const routes = [
  {
    path: '/',
    redirect: '/inbox',
  },
  {
    path: '/channels',
    name: 'channels',
    component: () => import('@/views/ChannelsMD.vue'),
    meta: {
      layout: 'content',
      auth: true,
      admin: false,
      subscribe: true,
      basic: true,
      pro: true,
      business: true,
      business_plus: true,
      subMenu: 'Channels',
    },
  },
  {
    path: '/channels2',
    name: 'channels2',
    component: () => import('@/views/ChannelsMD2.vue'),
    meta: {
      layout: 'content',
      auth: true,
      admin: false,
      subscribe: true,
      basic: true,
      pro: true,
      business: true,
      business_plus: true,
      subMenu: 'Channels2',
    },
  },
  {
    path: '/channels/redirect-lazada',
    name: 'redirect-lazada',
    component: () => import('@/views/redirect/LazadaRedirect.vue'),
  },
  {
    path: '/channels/redirect-twitter',
    name: 'redirect-twitter',
    component: () => import('@/views/redirect/TwitterCloseRedirect.vue'),
    meta: {
      layout: 'blank',
      auth: true,
      admin: false,
      subscribe: true,
      basic: true,
      pro: true,
      business: true,
      business_plus: true,
      subMenu: 'Redirect',
    },
  },
  {
    path: '/tutorial',
    name: 'tutorial',
    component: () => import('@/views/Tutorial.vue'),
    meta: {
      layout: 'content',
      auth: true,
      admin: false,
      subscribe: true,
      basic: true,
      pro: true,
      business: true,
      business_plus: true,
      subMenu: 'Tutorial',
    },
  },
  {
    path: '/contact-us',
    name: 'call-center',
    component: () => import('@/views/HelpCenter.vue'),
    meta: {
      layout: 'content',
      auth: true,
      admin: false,
      subscribe: true,
      basic: true,
      pro: true,
      business: true,
      business_plus: true,
      subMenu: 'HelpCenter',
    },
  },
  {
    path: '/login/redirect',
    name: 'redirect',
    component: () => import('@/views/Redirect.vue'),
    meta: {
      layout: 'blank',
      auth: false,
      admin: false,
      subscribe: false,
      basic: false,
      pro: false,
      business: false,
      business_plus: false,
    },
  },
  {
    path: '/message-template',
    name: 'message-template',
    component: () => import('@/views/message-template'),
    meta: {
      layout: 'content',
      auth: true,
      admin: false,
      subscribe: true,
      basic: true,
      pro: true,
      business: true,
      business_plus: true,
      subMenu: 'Broadcasts',
    },
  },
  {
    path: '/broadcasts',
    name: 'broadcast-list',
    component: () => import('@/views/broadcast/BroadcastListNew.vue'),
    meta: {
      layout: 'content',
      auth: true,
      admin: false,
      subscribe: true,
      basic: true,
      pro: true,
      business: true,
      business_plus: true,
      subMenu: 'Broadcasts',
    },
  },
  // {
  //   path: '/broadcasts-new',
  //   name: 'broadcast-list-new',
  //   component: () => import('@/views/broadcast/BroadcastList.vue'),
  //   meta: {
  //     layout: 'content',
  //     auth: true,
  //     admin: false,
  //     subscribe: true,
  //     basic: true,
  //     pro: true,
  //     business: true,
  //     business_plus: true,
  //     subMenu: 'Broadcasts',
  //   },
  // },
  {
    path: '/broadcast-timeline',
    name: 'broadcast-scheduled',
    component: () => import('@/views/broadcast/BroadcastScheduled.vue'),
    meta: {
      layout: 'content',
      auth: true,
      admin: false,
      subscribe: true,
      basic: true,
      pro: true,
      business: true,
      business_plus: true,
      subMenu: 'Broadcasts',
    },
  },
  {
    path: '/broadcast-statistic',
    name: 'broadcast-statistic',
    component: () => import('@/views/broadcast/BroadcastStatistic.vue'),
    meta: {
      layout: 'content',
      auth: true,
      admin: false,
      subscribe: true,
      basic: true,
      pro: true,
      business: true,
      business_plus: true,
      subMenu: 'Broadcasts',
    },
  },
  {
    path: '/campaign-manager/:id/marketing-calendar',
    name: 'marketing-calendar',
    component: () =>
      import(
        /* webpackChunkName: "marketing-calendar" */ '@/views/campaign-manager/marketing-calendar/MarketingCalendar.vue'
      ),
    meta: {
      layout: 'content',
      auth: true,
      admin: false,
      subscribe: true,
      basic: true,
      pro: true,
      business: true,
      business_plus: true,
      subMenu: 'Marketing Calendar',
    },
  },
  {
    path: '/campaign-manager/:campaignId/statistic/',
    name: 'statistic',
    component: () => import('@/views/campaign-manager/campaign-manager/CampaignStatistic.vue'),
    meta: {
      layout: 'content',
      auth: true,
      admin: false,
      subscribe: true,
      basic: true,
      pro: true,
      business: true,
      business_plus: true,
      subMenu: 'Marketing Calendar',
    },
  },
  {
    path: '/campaign-manager/:campaignId/create-post/',
    name: 'create-post',
    component: () => import('@/views/campaign-manager/marketing-calendar/components/form/CreatePost.vue'),
    meta: {
      layout: 'content',
      auth: true,
      admin: false,
      subscribe: true,
      basic: true,
      pro: true,
      business: true,
      business_plus: true,
      subMenu: 'Marketing Calendar',
    },
  },
  {
    path: '/campaign-manager/:campaignId/edit-post/:postId',
    name: 'edit-post',
    component: () => import('@/views/campaign-manager/marketing-calendar/components/form/CreatePost.vue'),
    meta: {
      layout: 'content',
      auth: true,
      admin: false,
      subscribe: true,
      basic: true,
      pro: true,
      business: true,
      business_plus: true,
      subMenu: 'Marketing Calendar',
    },
  },
  // {
  //   path: '/list-marketing-calendar',
  //   name: 'list-marketing-calendar',
  //   component: () => import('@/views/campaign-manager/marketing-calendar/ListMarketingCalendar.vue'),
  //   meta: {
  //     layout: 'content',
  //     auth: true,
  //     admin: false,
  //     subscribe: true,
  //     basic: true,
  //     pro: true,
  //     business: true,
  //     business_plus: true,
  //     subMenu: 'Marketing Calendar',
  //   },
  // },
  {
    path: '/contacts',
    name: 'contacts',
    component: () => import('@/views/contacts/ContactList.vue'),
    meta: {
      layout: 'content',
      auth: true,
      admin: false,
      subscribe: true,
      basic: true,
      pro: true,
      business: true,
      business_plus: true,
      subMenu: 'Broadcasts',
    },
  },
  {
    path: '/contact/contacts-list/:idlist',
    name: 'contacts-list-custom',
    component: () => import('@/views/contacts/Contact.vue'),
    meta: {
      layout: 'content',
      auth: true,
      admin: false,
      subscribe: true,
      default: true,
      basic: true,
      pro: true,
      business: true,
      business_plus: true,
      subMenu: 'Broadcasts',
    },
  },
  {
    path: '/contact/contacts-list/:idlist/config',
    name: 'contacts-list-custom-config',
    component: () => import('@/views/contacts/Config.vue'),
    meta: {
      layout: 'content',
      auth: true,
      admin: false,
      subscribe: true,
      default: true,
      basic: true,
      pro: true,
      business: true,
      business_plus: true,
      subMenu: 'Broadcasts',
    },
  },
  {
    path: '/contact/contacts-list/:idlist/create-efrom',
    name: 'contacts-list-create-eform',
    component: () => import('@/views/contacts/CreateEform.vue'),
    meta: {
      layout: 'content',
      auth: true,
      admin: false,
      subscribe: true,
      default: true,
      basic: true,
      pro: true,
      business: true,
      business_plus: true,
      subMenu: 'Broadcasts',
    },
  },
  {
    path: '/contact/chat-data',
    name: 'contacts-list',
    component: () => import('@/views/contacts/contactData.vue'),
    meta: {
      layout: 'content',
      auth: true,
      admin: false,
      subscribe: true,
      default: true,
      basic: false,
      pro: true,
      business: true,
      business_plus: true,
      subMenu: 'Inbox',
    },
  },
  {
    path: '/contact/label-list',
    name: 'label-list',
    component: () => import('@/views/contacts/Label.vue'),
    meta: {
      layout: 'content',
      auth: true,
      admin: false,
      subscribe: true,
      basic: false,
      pro: true,
      business: true,
      business_plus: true,
      subMenu: 'Inbox',
    },
  },
  {
    path: '/inbox',
    name: 'inbox',
    component: () => import('@/views/chat/Chat.vue'),
    meta: {
      layout: 'content',
      auth: true,
      admin: false,
      subscribe: true,
      basic: false,
      pro: true,
      business: true,
      business_plus: true,
      subMenu: 'Inbox',
    },
  },
  {
    path: '/my-assets',
    name: 'assets',
    component: () => import('@/views/MyAssets/MyAsset.vue'),
    meta: {
      layout: 'content',
      auth: true,
      admin: false,
      subscribe: true,
      basic: true,
      pro: true,
      business: true,
      business_plus: true,
      subMenu: 'Broadcasts',
    },
  },
  // {
  //   path: '/inbox/:phoneNumber',
  //   name: 'inbox-phone-number',
  //   component: () => import('@/views/chat/AdvancedChatMDPostgres.vue'),
  //   meta: {
  //     layout: 'content',
  //     auth: true,
  //     admin: false,
  //     subscribe: true,
  //     basic: false,
  //     pro: true,
  //     business: true,
  //     business_plus: true,
  //     subMenu: 'Inbox',
  //   },
  // },
  {
    path: '/room/:id',
    name: 'room-id',
    component: () => import('@/views/chat/AdvancedChatMDSingle.vue'),
    meta: {
      layout: 'blank',
    },
  },
  // {
  //   path: '/inbox2',
  //   name: 'inbox2',
  //   component: () => import('@/views/chat/testGantiHeader.vue'),
  //   meta: {
  //     layout: 'content',
  //     auth: true,
  //     admin: false,
  //     subscribe: true,
  //     basic: false,
  //     pro: true,
  //     business: true,
  //     business_plus: true,
  //     subMenu: '',
  //   },
  // },
  // {
  //   path: '/inbox2/:phoneNumber',
  //   name: 'inbox-phone-number2',
  //   component: () => import('@/views/chat/testGantiHeader.vue'),
  //   meta: {
  //     layout: 'content',
  //     auth: true,
  //     admin: false,
  //     subscribe: true,
  //     basic: false,
  //     pro: true,
  //     business: true,
  //     business_plus: true,
  //     subMenu: 'Inbox',
  //   },
  // },
  {
    path: '/contact/inbox',
    name: 'contact-inbox',
    component: () => import('@/views/contacts/inbox/Chat.vue'),
    meta: {
      layout: 'content',
      auth: true,
      admin: false,
      subscribe: true,
      basic: false,
      pro: true,
      business: true,
      business_plus: true,
      subMenu: 'Inbox',
    },
  },
  // {
  //   path: '/subscriptions',
  //   name: 'subscriptions',
  //   component: () => import('@/views/subscriptions/Pricing 2.vue'),
  //   meta: {
  //     layout: 'blank',
  //     auth: true,
  //     admin: false,
  //     subscribe: false,
  //     basic: true,
  //     pro: true,
  //     business: true,
  //     business_plus: true,
  //     subMenu: 'Subscriptions',
  //   },
  // },
  // {
  //   path: '/subscriptions-plan',
  //   name: 'subscriptions-plan',
  //   component: () => import('@/views/subscriptions/PricingNonAuth.vue'),
  //   meta: {
  //     layout: 'blank',
  //     // auth: false,
  //     // admin: false,
  //     // subscribe: false,
  //     // basic: true,
  //     // pro: true,
  //     // business: true,
  //     // business_plus: true,
  //     // subMenu: 'Subscriptions',
  //   },
  // },
  // {
  //   path: '/settings',
  //   name: 'billings',
  //   component: () => import('@/views/subscriptions/account-settings/AccountSettings.vue'),
  //   meta: {
  //     layout: 'content',
  //     auth: true,
  //     admin: false,
  //     subscribe: true,
  //     basic: true,
  //     pro: true,
  //     business: true,
  //     business_plus: true,
  //     subMenu: 'Subscriptions',
  //   },
  // },
  {
    path: '/invoice',
    name: 'invoice',
    component: () => import('@/views/subscriptions/invoice-list/InvoiceList.vue'),
    meta: {
      layout: 'content',
      auth: true,
      admin: false,
      subscribe: true,
      basic: true,
      pro: true,
      business: true,
      business_plus: true,
      subMenu: 'Subscriptions',
    },
  },
  {
    path: '/redirect/:page',
    name: 'redirect',
    component: () => import('@/views/redirect/Index.vue'),
    meta: {
      layout: 'blank',
      auth: true,
      admin: false,
      subscribe: true,
      basic: true,
      pro: true,
      business: true,
      business_plus: true,
      subMenu: 'Redirect',
    },
  },

  {
    path: '/team/team-members',
    name: 'operators-list',
    props: { historyPage: false, listPage: true, statisticPage: false },
    component: () => import('@/views/operators'),
    meta: {
      layout: 'content',
      auth: false,
      admin: true,
      subscribe: false,
      basic: false,
      pro: false,
      business: true,
      business_plus: true,
      subMenu: 'Admin',
    },
  },
  {
    path: '/team/role-team',
    name: 'team-roles',
    component: () => import('@/views/operators/page/RoleTeam.vue'),
    meta: {
      layout: 'content',
      auth: false,
      admin: true,
      subscribe: false,
      basic: false,
      pro: false,
      business: true,
      business_plus: true,
      subMenu: 'Admin',
    },
  },
  {
    path: '/team/replies-history',
    name: 'replies-history',
    props: { historyPage: true, listPage: false, statisticPage: false },
    component: () => import('@/views/operators'),
    meta: {
      layout: 'content',
      auth: false,
      admin: false,
      subscribe: false,
      basic: false,
      pro: false,
      business: true,
      business_plus: true,
      subMenu: 'Operators',
    },
  },
  {
    path: '/team-statistic',
    name: 'operator-statistic',
    // props: { historyPage: false, listPage: false, statisticPage: true },
    component: () => import('@/views/operators/page/OperatorStatistic.vue'),
    meta: {
      layout: 'content',
      auth: false,
      admin: false,
      subscribe: false,
      basic: false,
      pro: false,
      business: true,
      business_plus: true,
      subMenu: 'Operators',
    },
  },
  {
    path: '/team/user-performance',
    name: 'user-performance',
    props: { historyPage: false, listPage: false, statisticPage: true },
    component: () => import('@/views/operators'),
    meta: {
      layout: 'content',
      auth: false,
      admin: true,
      subscribe: false,
      basic: false,
      pro: false,
      business: true,
      business_plus: true,
      subMenu: 'Admin',
    },
  },
  // {
  //   path: '/link-generator',
  //   name: 'link-generator',
  //   //belum terarah ke component yang tepat
  //   component: () => import('@/views/link-slug'),
  //   meta: {
  //     layout: 'content',
  //     auth: false,
  //     admin: false,
  //     subscribe: false,
  //     basic: false,
  //     pro: false,
  //     business: false,
  //     business_plus: true,
  //     subMenu: 'Widget',
  //   },
  // },
  // {
  //   path: '/live-chat-widget',
  //   name: 'live-chat-widget',
  //   component: () => import('@/views/LiveChatWidget'),
  //   meta: {
  //     layout: 'content',
  //     auth: true,
  //     admin: false,
  //     subscribe: true,
  //     basic: false,
  //     pro: true,
  //     business: true,
  //     business_plus: true,
  //     subMenu: 'Widget',
  //   },
  // },
  // {
  //   path: '/live-chat-widget/create',
  //   name: 'live-chat-widget-create',
  //   component: () => import('@/views/LiveChatWidget/create_widget'),
  //   meta: {
  //     layout: 'content',
  //     auth: true,
  //     admin: false,
  //     subscribe: true,
  //     basic: false,
  //     pro: true,
  //     business: true,
  //     business_plus: true,
  //     subMenu: 'Widget',
  //   },
  // },
  // {
  //   path: '/live-chat-widget/:idList',
  //   name: 'live-chat-widget-create-list',
  //   component: () => import('@/views/LiveChatWidget/create_widget'),
  //   meta: {
  //     layout: 'content',
  //     auth: true,
  //     admin: false,
  //     subscribe: true,
  //     basic: false,
  //     pro: true,
  //     business: true,
  //     business_plus: true,
  //     subMenu: 'Widget',
  //   },
  // },
  {
    path: '/logout',
    name: 'logout-meta',
    component: () => import('@/views/Logout.vue'),
    meta: {
      layout: 'content',
      auth: false,
      admin: false,
      subscribe: false,
      basic: false,
      pro: true,
      business: true,
      business_plus: true,
      subMenu: 'Widget',
    },
  },
  // {
  //   path: '/link-tracker',
  //   name: 'link-tracker',

  //   component: () => import('@/views/link-tracker'),
  //   meta: {
  //     layout: 'content',
  //     auth: false,
  //     admin: false,
  //     subscribe: false,
  //     basic: false,
  //     pro: false,
  //     business: false,
  //     business_plus: true,
  //     subMenu: 'Widget',
  //   },
  // },
  // {
  //   path: '/live-chat-widget',
  //   name: 'live-chat-widget',
  //   component: () => import('@/views/LiveChatWidget'),
  //   meta: {
  //     layout: 'content',
  //     auth: true,
  //     admin: false,
  //     subscribe: true,
  //     basic: false,
  //     pro: false,
  //     business: false,
  //     business_plus: true,
  //     subMenu: 'Widget',
  //   },
  // },
  // {
  //   path: '/live-chat-widget/create',
  //   name: 'live-chat-widget-create',
  //   component: () => import('@/views/LiveChatWidget/create_widget'),
  //   meta: {
  //     layout: 'content',
  //     auth: true,
  //     admin: false,
  //     subscribe: true,
  //     basic: false,
  //     pro: false,
  //     business: false,
  //     business_plus: true,
  //     subMenu: 'Widget',
  //   },
  // },
  // {
  //   path: '/live-chat-widget/:idList',
  //   name: 'live-chat-widget-create-list',
  //   component: () => import('@/views/LiveChatWidget/create_widget'),
  //   meta: {
  //     layout: 'content',
  //     auth: true,
  //     admin: false,
  //     subscribe: true,
  //     basic: false,
  //     pro: true,
  //     business: true,
  //     business_plus: true,
  //     subMenu: 'Widget',
  //   },
  // },

  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('@/views/Logout.vue'),
    meta: {
      layout: 'blank',
    },
  },
  // {
  //   path: '/landing',
  //   name: 'landing-page',
  //   component: () => import('@/views/LandingPage/LandingPage.vue'),
  //   meta: {
  //     layout: 'blank',
  //   },
  // },
  {
    path: '/track-link/:slug/:phone',
    name: 'track-page',
    component: () => import('@/views/trackPage'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/invoice/:id',
    name: 'invoice-page',
    component: () => import('@/views/subscriptions/invoice-list/invoice.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/app-settings',
    name: 'app-settings',
    component: () => import('@/views/app-settings/AppSettings.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/e-form',
    name: 'e-form',
    component: () => import('@/views/e-form/Index.vue'),
    meta: {
      layout: 'content',
      auth: true,
      admin: false,
      subscribe: true,
      basic: false,
      pro: false,
      business: false,
      business_plus: true,
      subMenu: 'Widget',
    },
  },
  {
    path: '/campaign-tracker',
    name: 'campaign-tracker',
    component: () => import('@/views/campaign-tracker/index.vue'),
    meta: {
      layout: 'content',
      auth: true,
      admin: false,
      subscribe: true,
      basic: false,
      pro: false,
      business: false,
      business_plus: true,
      subMenu: 'Widget',
    },
  },
  {
    path: '/e-form/create',
    name: 'e-form-create',
    component: () => import('@/views/e-form/Create.vue'),
    meta: {
      layout: 'content',
      auth: true,
      admin: false,
      subscribe: true,
      basic: false,
      pro: false,
      business: false,
      business_plus: true,
      subMenu: 'Widget',
    },
  },
  {
    path: '/e-form/edit',
    name: 'e-form-edit',
    props: { edit: true },
    component: () => import('@/views/e-form/Create.vue'),
    meta: {
      layout: 'content',
      auth: true,
      admin: false,
      subscribe: true,
      basic: false,
      pro: false,
      business: false,
      business_plus: true,
      subMenu: 'Widget',
    },
  },
  {
    path: '/e-form/survey/:id',
    name: 'e-form-survey',
    component: () => import('@/views/e-form/Survey.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/e-form/analytics/:id',
    name: 'e-form-analytics',
    component: () => import('@/views/e-form/AnalyticsTable.vue'),
    meta: {
      layout: 'content',
      auth: true,
      admin: false,
      subscribe: true,
      basic: false,
      pro: false,
      business: false,
      business_plus: true,
      subMenu: 'Widget',
    },
  },
  {
    path: '/e-shop',
    name: 'e-ordering',
    component: () => import('@/views/e-ordering'),
    meta: {
      layout: 'content',
      auth: true,
      admin: false,
      subscribe: true,
      basic: false,
      pro: false,
      business: false,
      business_plus: true,
      subMenu: 'Widget',
    },
  },
  {
    path: '/chatbot',
    name: 'chatbot',
    component: () => import('@/views/ChatBot/ChatbotList.vue'),
    meta: {
      layout: 'content',
      auth: true,
      admin: false,
      subscribe: true,
      basic: false,
      pro: false,
      business: false,
      business_plus: true,
      subMenu: 'Widget',
    },
  },
  {
    path: '/e-shop/order-list/:shopId',
    name: 'e-shop-order-list',
    component: () => import('@/views/e-ordering/orderList.vue'),
    props: true,
    meta: {
      layout: 'content',
      auth: true,
      admin: false,
      subscribe: true,
      basic: false,
      pro: false,
      business: false,
      business_plus: true,
      subMenu: 'Widget',
    },
  },

  {
    path: '/e-shop/sheet-editor/:id/:driveId/:name',
    name: 'sheet-editor',
    component: () => import('@/views/e-ordering/sheetEditor.vue'),
    meta: {
      layout: 'content',
      auth: true,
      admin: false,
      subscribe: true,
      basic: false,
      pro: false,
      business: false,
      business_plus: true,
      subMenu: 'Widget',
    },
  },
  {
    path: '/apps',
    name: 'apps-list',
    component: () => import('@/views/apps-list/index.vue'),
    meta: {
      layout: 'content',
      auth: true,
      admin: false,
      subscribe: true,
      basic: false,
      pro: false,
      business: false,
      business_plus: true,
      subMenu: 'Widget',
    },
  },
  {
    path: '/demo/:lang',
    name: 'demo-app',
    component: () => import('@/views/LandingPage/demoApp.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/wa-otp/:loginLinkId',
    name: 'login-by-wa',
    component: () => import('@/views/LoginByWa/LoginByWa.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/LoginByWa',
    name: 'LoginByWa',
    component: () => import('@/views/LoginByWa/Index.vue'),
    meta: {
      layout: 'content',
      auth: true,
      admin: false,
      subscribe: true,
      basic: false,
      pro: false,
      business: false,
      business_plus: true,
      subMenu: 'Widget',
    },
  },
  {
    path: '/campaign-manager',
    name: 'campaign-manager',
    component: () => import(/* webpackChunkName: "campaign-manager" */ '@/views/campaign-manager/CampaignManager.vue'),
    meta: {
      layout: 'content',
      auth: true,
      admin: false,
      subscribe: true,
      basic: false,
      pro: true,
      business: true,
      business_plus: true,
      // subMenu: 'Inbox',
    },
  },
  {
    path: '/campaign-manager/approval',
    name: 'campaign-manager-approval',
    component: () => import(/* webpackChunkName: "campaign-approval" */ '@/views/campaign-manager/Approval.vue'),
    meta: {
      layout: 'content',
      auth: true,
      admin: false,
      subscribe: true,
      basic: false,
      pro: true,
      business: true,
      business_plus: true,
      // subMenu: 'Inbox',
    },
  },
  {
    path: '/landing-page',
    name: 'landing-page',
    component: () => import(/* webpackChunkName: "landing-page" */ '@/views/build-landing-page/LandingPage.vue'),
    meta: {
      layout: 'content',
      auth: true,
      admin: false,
      subscribe: true,
      basic: false,
      pro: true,
      business: true,
      business_plus: true,
      // subMenu: 'Inbox',
    },
  },
  {
    path: '/landing-page/manage-domain',
    name: 'landing-page-manage-domain',
    component: () => import(/* webpackChunkName: "landing-page" */ '@/views/build-landing-page/ManageDomain.vue'),
    meta: {
      layout: 'content',
      auth: true,
      admin: false,
      subscribe: true,
      basic: false,
      pro: true,
      business: true,
      business_plus: true,
      // subMenu: 'Inbox',
    },
  },
  {
    path: '/landing-page/create',
    name: 'create-landing-page',
    component: () => import('@/views/build-landing-page/CreateLandingPage.vue'),
    meta: {
      layout: 'content',
      auth: true,
      admin: false,
      subscribe: true,
      basic: false,
      pro: true,
      business: true,
      business_plus: true,
      // subMenu: 'Inbox',
    },
  },
  {
    path: '/landing-page/editor',
    name: 'landing-page-editor',
    component: () => import('@/views/build-landing-page/LandingPageEditor.vue'),
    meta: {
      layout: 'blank',
      auth: true,
      admin: false,
      subscribe: true,
      basic: false,
      pro: true,
      business: true,
      business_plus: true,
      // subMenu: 'Inbox',
    },
  },
  {
    path: '/landing-page/preview',
    name: 'landing-page-preview',
    component: () => import('@/views/build-landing-page/LandingPagePreview.vue'),
    meta: {
      layout: 'blank',
      auth: true,
      admin: false,
      subscribe: true,
      basic: false,
      pro: true,
      business: true,
      business_plus: true,
      // subMenu: 'Inbox',
    },
  },
  {
    path: '/landing-page/checkout',
    name: 'landing-page-checkout',
    component: () => import('@/views/build-landing-page/FlowCheckout.vue'),
    meta: {
      layout: 'blank',
      auth: true,
      admin: false,
      subscribe: true,
      basic: false,
      pro: true,
      business: true,
      business_plus: true,
      // subMenu: 'Inbox',
    },
  },
  {
    path: '/manage-project',
    name: 'manage-project',
    component: () => import('@/views/finance/manage-project/ManageProject.vue'),
    meta: {
      layout: 'content',
      auth: true,
      admin: false,
      subscribe: true,
      basic: false,
      pro: true,
      business: true,
      business_plus: true,
    },
  },
  {
    path: '/manage-salary',
    name: 'manage-salary',
    component: () => import('@/views/finance/manage-salary/ManageSalary.vue'),
    meta: {
      layout: 'content',
      auth: true,
      admin: false,
      subscribe: true,
      basic: false,
      pro: true,
      business: true,
      business_plus: true,
    },
  },
  {
    path: '/manage-salary/employee-group/:id',
    name: 'employee-group',
    component: () => import('@/views/finance/manage-salary/EmployeeGroup.vue'),
    meta: {
      layout: 'content',
      auth: true,
      admin: false,
      subscribe: true,
      basic: false,
      pro: true,
      business: true,
      business_plus: true,
    },
  },
  {
    path: '/manage-salary/employee-group/:employeeGroupId/employee/:id',
    name: 'employee-detail',
    component: () => import('@/views/finance/manage-salary/EmployeeDetail.vue'),
    meta: {
      layout: 'content',
      auth: true,
      admin: false,
      subscribe: true,
      basic: false,
      pro: true,
      business: true,
      business_plus: true,
    },
  },
  {
    path: '/manage-salary/employee-group/:employeeGroupId/disbursement/:id',
    name: 'disbursement-detail',
    component: () => import('@/views/finance/manage-salary/DisbursementDetail.vue'),
    meta: {
      layout: 'content',
      auth: true,
      admin: false,
      subscribe: true,
      basic: false,
      pro: true,
      business: true,
      business_plus: true,
    },
  },
  {
    path: '/manage-salary/employee-group/:employeeGroupId/create-disbursement',
    name: 'create-disbursement',
    component: () => import('@/views/finance/manage-salary/CreateDisbursement.vue'),
    meta: {
      layout: 'content',
      auth: true,
      admin: false,
      subscribe: true,
      basic: false,
      pro: true,
      business: true,
      business_plus: true,
    },
  },
  {
    path: '/manage-loan',
    name: 'manage-loan',
    component: () => import('@/views/finance/manage-loan/ManageLoan.vue'),
    meta: {
      layout: 'content',
      auth: true,
      admin: false,
      subscribe: true,
      basic: false,
      pro: true,
      business: true,
      business_plus: true,
    },
  },
  {
    path: '/manage-loan/manage-billing/:idLoanSub',
    name: 'manage-billing',
    component: () => import('@/views/finance/manage-loan/billing/ManageBilling.vue'),
    meta: {
      layout: 'content',
      auth: true,
      admin: false,
      subscribe: true,
      basic: false,
      pro: true,
      business: true,
      business_plus: true,
    },
  },
  {
    path: '/manage-loan/manage-billing/detail-invoice-billing/:idBilling',
    name: 'detail-invoice-billing',
    component: () => import('@/views/finance/manage-loan/billing/components/DetailInvoice.vue'),
    meta: {
      layout: 'content',
      auth: true,
      admin: false,
      subscribe: true,
      basic: false,
      pro: true,
      business: true,
      business_plus: true,
    },
  },
  {
    path: '/manage-approval',
    name: 'manage-approval',
    component: () => import('@/views/finance/manage-approval/ManageApproval.vue'),
    meta: {
      layout: 'content',
      auth: true,
      admin: false,
      subscribe: true,
      basic: false,
      pro: true,
      business: true,
      business_plus: true,
    },
  },
  {
    path: '/manage-invoice',
    name: 'manage-invoice',
    component: () => import('@/views/finance/manage-invoice/ManageInvoice.vue'),
    meta: {
      layout: 'content',
      auth: true,
      admin: false,
      subscribe: true,
      basic: false,
      pro: true,
      business: true,
      business_plus: true,
    },
  },
  {
    path: '/detail-invoice',
    name: 'detail-invoice',
    component: () => import('@/views/finance/manage-invoice/components/DetailInvoice.vue'),
    meta: {
      layout: 'content',
      auth: true,
      admin: false,
      subscribe: true,
      basic: false,
      pro: true,
      business: true,
      business_plus: true,
    },
  },
  {
    path: '/manage-cashier/:sub_id_disbursement_id',
    name: 'manage-cashier',
    component: () => import('@/views/finance/manage-cashier/ListEmployee.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/manage-cashier/:sub_id_disbursement_id/confirm-with-face/:idEmployee',
    name: 'confirm-with-face',
    component: () => import('@/views/finance/manage-cashier/Camera.vue'),
    meta: {
      layout: 'blank',
    },
  },
  // calculator-cicilan (obsolete)
  // {
  //   path: '/calculator-cicilan/:idUnitType',
  //   name: 'calculator-cicilan',
  //   component: () => import('@/views/calculator-cicilan/CalculatorCicilan.vue'),
  //   meta: {
  //     layout: 'blank',
  //   },
  // },
  // calculator-cicilan (obsolete)
  {
    path: '/contacts/form/detail/:formId',
    name: 'formDetail',
    component: () => import('@/views/contacts/FormDetail.vue'),
    meta: {
      layout: 'content',
      auth: true,
      admin: false,
      subscribe: true,
      basic: false,
      pro: true,
      business: true,
      business_plus: true,
    },
  },
  {
    path: '/contacts/detail/:id',
    name: 'contactDetail',
    component: () => import('@/views/contacts/ContactDetail.vue'),
    meta: {
      layout: 'content',
      auth: true,
      admin: false,
      subscribe: true,
      basic: false,
      pro: true,
      business: true,
      business_plus: true,
    },
  },
  {
    path: '/templates/',
    name: 'templates',
    component: () => import('@/views/templates/Template.vue'),
    meta: {
      layout: 'content',
      auth: true,
      admin: false,
      subscribe: true,
      basic: false,
      pro: true,
      business: true,
      business_plus: true,
    },
  },
  {
    path: '/broadcast/statistic',
    name: 'broadcast-statistic',
    component: () => import('@/views/broadcast/BroadcastStatistic.vue'),
    meta: {
      layout: 'content',
      auth: true,
      admin: false,
      subscribe: true,
      basic: false,
      pro: true,
      business: true,
      business_plus: true,
    },
  },
  {
    path: '/chat/statistic',
    name: 'chat-statistic',
    component: () => import('@/views/chat/ChatStatistic.vue'),
    meta: {
      layout: 'content',
      auth: true,
      admin: false,
      subscribe: true,
      basic: false,
      pro: true,
      business: true,
      business_plus: true,
    },
  },
  // {
  //   path: '/chat/team-statistic',
  //   name: 'operator-statistic',
  //   component: () => import('@/views/operators/page/OperatorStatistic.vue'),
  //   meta: {
  //     layout: 'content',
  //     auth: true,
  //     admin: false,
  //     subscribe: true,
  //     basic: false,
  //     pro: true,
  //     business: true,
  //     business_plus: true,
  //   },
  // },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

router.beforeEach(async (to, from, next) => {
  const isOnlyInbox = await store.dispatch('auth/checkIsOnlyInbox')
  const isAuth = await store.dispatch('auth/checkAuth')
  const isAdmin = await store.dispatch('auth/checkAdmin')
  // const isSubscribe = await store.dispatch('auth/checkSubscribe')
  // const isFreeTrial = await store.dispatch('auth/checkFreeTrial')
  const isSubscribe = true
  const isFreeTrial = false
  // const isBasicOrMore = await store.dispatch('auth/isBasicOrMore')
  // const isProOrMore = await store.dispatch('auth/isProOrMore')
  // const isBusinessOrMore = await store.dispatch('auth/isBusinessOrMore')
  // const isBusinessPlusOrMore = await store.dispatch('auth/isBusinessPlusOrMore')
  const isBasicOrMore = false
  const isProOrMore = false
  const isBusinessOrMore = false
  const isBusinessPlusOrMore = true
  // const isOperatorHaveAccess = await store.dispatch('auth/isOperatorHaveAccess', to.meta.subMenu)
  const isOperatorHaveAccess = true

  let opts
  if (to.meta.auth && !isAuth) {
    opts = {
      name: 'login',
    }
  } else if (
    isOnlyInbox &&
    to.name !== 'inbox' &&
    to.name !== 'logout' &&
    to.name !== 'call-center' &&
    to.name !== 'billings' &&
    to.name !== 'subscriptions'
  ) {
    opts = {
      name: 'inbox',
    }
  } else if (!to.meta.auth && isAuth && to.name === 'login') {
    opts = {
      name: 'channels',
    }
  } else if (to.meta.subscribe && !isSubscribe) {
    opts = {
      name: 'subscriptions',
    }
  } else if (to.meta.admin && !isAdmin) {
    opts = {
      name: 'channels',
    }
  } else if (to.name === 'subscriptions' && isSubscribe && !isFreeTrial) {
    opts = {
      name: 'channels',
    }
  } else if (to.name === 'billings' && isSubscribe && isFreeTrial) {
    opts = {
      name: 'subscriptions',
    }
  } else if (to.name !== 'channels' && to.name !== 'subscriptions' && to.name !== 'landing') {
    if (!isBusinessPlusOrMore) {
      if (to.meta.business_plus && !to.meta.business) {
        //console.log(opts, ' == opts5')
        opts = {
          name: 'channels',
        }
      } else {
        if (!isBusinessOrMore) {
          if (to.meta.business && !to.meta.pro) {
            opts = {
              name: 'channels',
            }
          } else if (!isProOrMore) {
            if ((to.meta.business || to.meta.pro) && !to.meta.basic) {
              opts = {
                name: 'channels',
              }
            } else if (!isBasicOrMore) {
              if (to.meta.basic) {
                opts = {
                  name: 'channels',
                }
              }
            }
          }
        } else if (!isOperatorHaveAccess) {
          opts = {
            name: 'channels',
          }
        }
      }
    }
  }

  return next(opts)
})

export default router
