export function getChartStatistic(state) {
  return state.chartStatistic
}

export function getStatAgregate(state) {
  return state.statAgregate
}

export function getShopList(state) {
  return state.shopList
}

export function getProducts(state) {
  return state.products
}

export function getProvince(state) {
  return state.provinces
}

export function getCity(state) {
  return state.cities
}

export function getCost(state) {
  return state.cost
}

export function getLocalCouriers(state) {
  return state.localCouriers
}
export function getInterCouriers(state) {
  return state.interCouriers
}

export function getSheetData(state) {
  return state.sheetData
}

export function getCloseModal(state) {
  return state.closeModal
}

export function getCurrentShop(state) {
  return state.currentShop
}
