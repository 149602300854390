export function setShopListLink(state, obj) {
  state.shopListLink = obj
}

export function setMessageListLink(state, obj) {
  state.messageListLink = obj
}
export function setTrackerListLink(state, obj) {
  state.trackerListLink = obj
}
export function sendLinkToBroadcast(state, obj) {
  state.sendLinkBroadcast = obj
}
export function setDataEform(state, obj) {
  state.saveDataEform = obj
}
export function setopenStatisticEform(state, obj) {
  state.openStatisticEform = obj
}
export function setCampaignId(state, obj) {
  state.CampaignId = obj
}

