export function getLocale(state) {
  return state.locale
}
export function getNotifAllOrAssigned(state) {
  return state.notifAllOrAssigned
}
export function getAutoAssignChatOnReply(state) {
  return state.autoAssignChatOnReply
}
export function getAutoAssignNewChat(state) {
  return state.autoAssignNewChat
}
export function getAutoCloseChat(state) {
  return state.autoCloseChat
}
export function getGlobalChannel(state) {
  return state.globalChannel
}
export function getLoginState(state) {
  return state.loginState
}
export function getIsScanning(state) {
  return state.isScanning
}
export function getServerConnectionStatus(state) {
  return state.serverConnection
}
export function getContacts(state) {
  // map phone number show group
  state.contacts.forEach(contact => {
    if (contact.phone_number_show.includes('-') || contact.phone_number_show.length > 16) {
      contact.phone_number_show = 'Group'
    }
  })

  return state.contacts
}
export function getSettings(state) {
  return state.settings
}
export function getLink(state) {
  return state.link
}
export function getRooms(state) {
  return state.rooms
}
export function getSyncFromServer(state) {
  return state.syncFromServer
}
export function getFilterChat(state) {
  return state.filterChat
}
export function getFilterRoom(state) {
  return state.filterRoom
}
export function getSearch(state) {
  return state.search
}
export function getCurrentRoom(state) {
  return state.currentRoom
}
export function getLang(state) {
  return state.lang
}
export function getLastSeq(state) {
  return state.lastSeq
}
export function getReplicateStatus(state) {
  return state.replicateStatus
}
export function getStatusChannels(state) {
  return state.statusChannels
}
export function getMuteValue(state) {
  return state.muteValue
}
export function getBoldUnreplied(state) {
  return state.boldUnreplied
}
export function getVersion(state) {
  return state.version
}
export function getMessageOffline(state) {
  return state.messages_offline
}
export function getInitReplicateStatus(state) {
  return state.initReplicateStatus
}
export function getContactsLokiDb(state) {
  return state.contactsLokiDb
}
export function getChatsLokiDb(state) {
  return state.chatsLokiDb
}
export function getLokiDb(state) {
  return state.lokiDb
}
export function getNotifPayload(state) {
  return state.notifPayload
}
export function getLoadingMessages(state) {
  return state.loadingMessages
}
export function getCurrentScanned(state) {
  return state.currentScanned
}
export function getEnabledApps(state) {
  return state.enabledApps
}
export function getAllContactData(state) {
  return state.allContactData
}
export function getRoleSettings(state) {
  return state.roleSettings
}
export function getCustomerListContacts(state) {
  return state.customerListContacts
}
export function getCustomerGroups(state) {
  return state.customerGroups
}
export function getChatHeaderPayload(state) {
  return state.chatHeaderPayload
}
export function getSelectedChatHeader(state) {
  return state.selectedChatHeader
}
export function getMentionChatPayload(state) {
  return state.mentionChatPayload
}
export function getDefaultColumns(state) {
  const defaultColumns = [
    {
      id: 1,
      name: 'phone_number',
      show: true,
      text: 'Phone Number',
      type: 'text',
      align: 'left',
      field: 'phone_number',
      label: 'Phone Number',
      value: 'phone_number',
      editable: false,
      showable: true,
      sortable: true,
      deleteable: false,
      formatDate: '',
      isRequired: false,
      defaultValue: '',
      order_number: 1,
    },
    {
      id: 2,
      name: 'name',
      show: true,
      text: 'Name',
      type: 'text',
      align: 'left',
      field: 'name',
      label: 'Name',
      value: 'name',
      editable: false,
      showable: true,
      sortable: true,
      deleteable: false,
      formatDate: '',
      isRequired: false,
      defaultValue: '',
      order_number: 2,
    },
    {
      id: 3,
      name: 'created_by',
      show: true,
      text: 'Created By',
      type: 'text',
      align: 'left',
      field: 'created_by',
      label: 'Created By',
      value: 'created_by',
      editable: false,
      showable: true,
      sortable: true,
      deleteable: false,
      formatDate: '',
      isRequired: false,
      defaultValue: '',
      order_number: 3,
    },
    {
      id: 4,
      name: 'updated_by',
      show: true,
      text: 'Updated By',
      type: 'text',
      align: 'left',
      field: 'updated_by',
      label: 'Updated By',
      value: 'updated_by',
      editable: false,
      showable: true,
      sortable: true,
      deleteable: false,
      formatDate: '',
      isRequired: false,
      defaultValue: '',
      order_number: 4,
    },
    {
      id: 5,
      name: 'answerData',
      align: 'left',
      label: 'Answer Data',
      field: 'answerData',
      value: 'answerData',
      text: 'Answer Data',
      sortable: true,
      type: 'text',
      show: true,
      showable: true,
      formatDate: '',
      deleteable: false,
      isRequired: true,
      defaultValue: '',
      editable: true,
      order_number: 5,
    },
    {
      id: 6,
      name: 'created_at',
      align: 'center',
      label: 'Created At',
      field: 'created_at',
      value: 'created_at',
      text: 'Created At',
      sortable: true,
      type: 'text',
      show: true,
      showable: true,
      formatDate: '',
      deleteable: false,
      isRequired: false,
      defaultValue: '',
      editable: false,
      order_number: 6,
    },
    {
      id: 7,
      name: 'updated_at',
      align: 'center',
      label: 'Updated At',
      field: 'updated_at',
      value: 'updated_at',
      text: 'Updated At',
      sortable: true,
      type: 'text',
      show: true,
      showable: true,
      formatDate: '',
      deleteable: false,
      isRequired: false,
      defaultValue: '',
      editable: false,
      order_number: 7,
    },
  ]

  return defaultColumns
}
export function getIsCustomerGroupChanged(state) {
  return state.IsCustomerGroupChanged
}
export function getLastInserted(state) {
  return state.lastInserted
}
export function getStoreContacts(state) {
  return state.storeContacts
}
export function getMessageObj(state) {
  return state.messageObj
}
