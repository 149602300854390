/* eslint-disable */

export function getBroadcastListData(state) {
  return state.broadcastListData
}

export function getBroadcastScheduledData(state) {
  return state.broadcastScheduledData
}

export function getBroadcastStatistic(state) {
  return state.broadcastStatistic
}

export function getBroadcastContacts(state) {
  return state.broadcastContacts
}

export function getBroadcastContactsList(state) {
  return state.broadcastContactsList
}

export function getBroadcastExtraField(state) {
  return state.broadcastExtraField
}

// get channel
export function getChannel(state) {
  return state.channel
}
// get contact
export function getContact(state) {
  return state.filterContact
}
export function getValueChannel(state) {
  return state.valueChannel
}
