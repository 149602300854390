export default function () {
  return {
    selectedContacts: [],
    scoring: {
      whatsapp: {},
      facebook: {},
      instagram: {},
    },
    personalizedChannels: [],
    allCampaigns: [],
    specifiedCampaign: null,
    campaignApproval: [],
    ListIDHasAccessedMarketingCalendarBefore: [],
  }
}
