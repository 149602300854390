/* eslint-disable */
import axios from 'axios'

// axios limit
const MAX_REQUESTS_COUNT = 15
const INTERVAL_MS = 10
let PENDING_REQUESTS = 0

// create new axios instance
const conn = axios.create({})
conn.defaults.timeout = 20000
/**
 * Axios Request Interceptor
 */
conn.interceptors.request.use(function (config) {
  return new Promise((resolve, reject) => {
    let interval = setInterval(() => {
      if (PENDING_REQUESTS < MAX_REQUESTS_COUNT) {
        PENDING_REQUESTS++
        clearInterval(interval)
        resolve(config)
      }
    }, INTERVAL_MS)
  })
})

/**
 * Axios Response Interceptor
 */
conn.interceptors.response.use(
  function (response) {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1)
    return Promise.resolve(response)
  },
  function (error) {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1)
    return Promise.reject(error)
  },
)

export async function getBroadcasts({ commit, state }, user) {
  const endpoint = process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '') + 'superwa/broadcasts/get-broadcasts'

  return conn
    .post(endpoint, {
      db_name: user.db_name,
    })
    .then(async response => {
      if (response.data.status) {
        commit('setBroadcastListData', response.data.data)

        return {
          status: true,
          errorMessage: '',
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      return {
        status: false,
        errorMessage: error,
      }
    })
}

export async function insertBroadcasts({ commit, state }, obj) {
  const endpoint = process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '') + 'superwa/broadcasts/insert-broadcasts'

  return conn
    .post(endpoint, {
      db_name: obj.db_name,
      broadcasts: obj.data,
    })
    .then(async response => {
      if (response.data.status) {
        return {
          status: true,
          errorMessage: '',
        }
      }
      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      return {
        status: false,
        errorMessage: error,
      }
    })
}

export async function deleteBroadcasts({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '')}superwa/couchdb/broadcasts/delete`

  return conn
    .post(endpoint, {
      subId: obj.subId,
      _id: obj._id,
    })
    .then(async response => {
      return {
        status: response.data.status,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      return {
        status: false,
        errorMessage: error,
      }
    })
}

export async function fetchBroadcastStatistic({ commit, state }, obj) {
  const endpoint =
    process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '') + 'superwa/couchdb/broadcasts/broadcast_statistic'

  return conn
    .post(endpoint, {
      sub_id: obj.sub_id,
      start_date: obj.start_date,
      end_date: obj.end_date,
    })
    .then(async response => {
      if (response.data.status) {
        commit('setBroadcastStatistic', response.data.data)
        return {
          status: true,
          errorMessage: '',
        }
      }
      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      return {
        status: false,
        errorMessage: error,
      }
    })
}

export async function getListWidget({ commit, state }, obj) {
  return axios.post(
    `${process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '')}superwa/couchdb/widget/get-list

`,
    {
      sub_id: obj.sub_id,
    },
    {
      headers: {
        Authorization: `Bearer ${obj.token}`,
      },
    },
  )
}

export async function saveCreateWidget({ store, commit, state }, obj) {
  return axios.post(
    `${process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '')}superwa/couchdb/widget/create
`,
    obj.data,
    {
      headers: {
        Authorization: `Bearer ${obj.token}`,
      },
    },
  )
}
