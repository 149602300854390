<template>
  <v-menu
    offset-y
    bottom
    nudge-bottom="22"
    :elevation="$vuetify.theme.dark ? 9 : 8"
    content-class="list-style notification-menu-content"
  >
    <template v-slot:activator="{ on, attrs }">
      <span v-show="notifications.length > 0">{{ notifications.length }}</span>
      <v-icon
        v-bind="attrs"
        v-on="on"
      >
        {{ icons.mdiBellOutline }}
      </v-icon>
    </template>
    <v-card class="app-bar-notification-content-container">
      <perfect-scrollbar
        class="ps-user-notifications"
        :options="perfectScrollbarOptions"
      >
        <v-list class="py-0">
          <!-- Header -->
          <v-list-item
            class="d-flex"
            link
          >
            <div class="d-flex align-center justify-space-between flex-grow-1">
              <span class="font-weight-semibold">Notifications</span>
              <v-chip
                v-if="notifications.length > 0"
                class="v-chip-light-bg primary--text font-weight-semibold"
                small
              >
                {{ notifications.length }}
              </v-chip>
            </div>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item
            v-if="notifications.length > 0"
            key="read-all-btn"
            class="read-all-btn-list-item"
          >
            <v-btn
              block
              color="primary"
              @click="resetNotifications"
            >
              Read All Notifications
            </v-btn>
          </v-list-item>
          <!-- Notifications -->
          <template v-for="(notification, index) in notifications">
            <v-list-item
              :key="notification.id"
              link
              @click="gotoInbox(notification)"
            >
              <!-- Avatar -->
              <v-list-item-avatar
                :class="[{'v-avatar-light-bg primary--text justify-center': notification.user && !notification.user.avatar}]"
                size="38"
              >
                <v-img
                  v-if="notification.user && notification.user.avatar"
                  :src="notification.user.avatar"
                ></v-img>
                <span
                  v-else-if="notification.user && !notification.user.avatar"
                  class="text-lg"
                >{{ notification.user.name ? getInitialName(notification.user.name) : '' }}</span>
                <v-img
                  v-else
                  :src="notification.service.icon"
                ></v-img>
              </v-list-item-avatar>
              <!-- Content -->
              <v-list-item-content class="d-block">
                <v-list-item-title class="text-sm font-weight-semibold">
                  <div
                    v-if="notification.color"
                    class="state-circle"
                    style="display: inline-block;"
                    :style="{ 'background-color': notification.color }"
                  />
                  <div style="display: inline-block;">
                    {{ notification.title }}
                  </div>
                </v-list-item-title>
                <v-list-item-subtitle class="text-sm">
                  <v-icon
                    v-if="notification.mediaNotif"
                    size="18"
                  >
                    {{ getIconNotif(notification.mediaNotif) }}
                  </v-icon>
                  <!-- <span class="font-body-sneakbar"> -->
                  {{ truncateNotifMessage(notification.subtitle) }}
                  <!-- </span> -->
                  <!-- {{ notification.subtitle }} -->
                </v-list-item-subtitle>
              </v-list-item-content>
              <!-- Item Action/Time -->
              <v-list-item-action>
                <span class="text--secondary text-xs">{{ notification.time }}</span>
              </v-list-item-action>
            </v-list-item>
            <v-divider :key="index"></v-divider>
          </template>
          <v-list-item
            v-if="notifications.length == 0"
          >
            No notifications found
          </v-list-item>
        </v-list>
      </perfect-scrollbar>
    </v-card>
  </v-menu>
</template>

<script>
import { mdiBellOutline, mdiHeartBox, mdiVideo, mdiCamera, mdiMicrophone, mdiFileDocument } from '@mdi/js'
import { getInitialName } from '@core/utils'

// 3rd Party
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'

export default {
  components: {
    // 3rd Party
    PerfectScrollbar,
  },
  props: {
    // notifications: { type: Array, required: true },
  },
  computed: {
    notifications() {
      return this.$store.getters['inbox/getNotifications']
    },

    // mediaNotif() {
    //   return this.$store.getters['global/getMediaNotif']
    // },
  },
  setup() {
    const perfectScrollbarOptions = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      getInitialName,
      perfectScrollbarOptions,

      icons: {
        mdiBellOutline,
        mdiHeartBox,
        mdiVideo,
        mdiCamera,
        mdiMicrophone,
        mdiFileDocument,
      },
    }
  },
  methods: {
    getIconNotif(mediaNotif) {
      // //console.log(`media notif`, mediaNotif)
      if (mediaNotif === 'Sticker') {
        return this.icons.mdiHeartBox
      }
      if (mediaNotif === 'Video') {
        return this.icons.mdiVideo
      }
      if (mediaNotif === 'Photo') {
        return this.icons.mdiCamera
      }
      if (mediaNotif === 'Document') {
        return this.icons.mdiFileDocument
      }

      return this.icons.mdiMicrophone
    },
    resetNotifications() {
      let notifications = JSON.parse(JSON.stringify(this.$store.getters['inbox/getNotifications']))
      notifications = []
      this.$store.commit('inbox/setNotifications', notifications)

      this.$emit('reset-notifications')
    },
    truncateNotifMessage(notificationDetail) {
      if (this.$vuetify.breakpoint.xs) {
        if (notificationDetail.length <= 50) return notificationDetail.slice(0, 50)

        return `${notificationDetail.slice(0, 50)}...`
      }

      // //console.log(notificationDetail, `INI DETAIL`)

      return notificationDetail
    },
    gotoInbox(data) {
      //console.log(data.roomId, this.$route.params, `INI DATA`)
      if (this.$route.params.phoneNumber === data.roomId) {
        this.$emit('delete-notifications', data)
        let notifications = JSON.parse(JSON.stringify(this.$store.getters['inbox/getNotifications']))
        notifications = notifications.filter(notification => notification.roomId !== data.roomId)
        this.$store.commit('inbox/setNotifications', notifications)

        // this.$store.commit('global/setNotifPayload', data)
      } else {
        let notifications = JSON.parse(JSON.stringify(this.$store.getters['inbox/getNotifications']))
        notifications = notifications.filter(notification => notification.roomId !== data.roomId)
        this.$store.commit('inbox/setNotifications', notifications)
        this.$emit('delete-notifications', data)
        this.$store.commit('global/setNotifPayload', data)
        this.$router.push({
          name: 'inbox-phone-number',
          params: {
            phoneNumber: data.roomId,
          },
        })
      }

      // this.$router.push({
      //   name: 'inbox',
      //   params: {
      //     data,
      //   },
      // })
    },
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.app-bar-notification-content-container {
  .read-all-btn-list-item {
    padding-top: 14px;
    padding-bottom: 14px;
    min-height: unset;
  }
}

.ps-user-notifications {
  max-height: calc(var(--vh, 1vh) * 80);
}

.notification-menu-content {
  width: 30%;
  left: 66%;

  @media #{map-get($display-breakpoints, 'xs-only')} {
    min-width: calc(100vw - (1.5rem * 2)) !important;
    left: 50% !important;
    transform: translateX(-50%);
  }
}

.state-circle {
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: var(--chat-room-color-offline);
  margin-right: 6px;
  transition: 0.3s;
}
</style>
