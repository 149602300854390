export function setstatusOperator(state, obj) {
  state.statusOperator = obj
}
export function SET_FILTERED_ROOMS(state, obj) {
  // //console.log('SET_FILTERED_ROOMS is invoked')
  // state.filteredRooms = obj
  // //console.log('state.filteredRooms', state.filteredRooms)
}
export function SET_ALL_ROOMS(state, obj) {
  // //console.log('SET_ALL_ROOMS is invoked')
  // state.allRooms = obj.data
  // //console.log('state.allRooms', state.allRooms)
}
export function setFetchSearch(state, obj) {
  // //console.log('SET_ALL_ROOMS is invoked')
  state.fetchSearch = obj
  // //console.log('state.allRooms', state.allRooms)
}
