import MD5 from 'crypto-js/md5.js'
import Vue from 'vue'

function convertToMd5Hash(str) {
  const md5Hashed = window.btoa(hex2a(MD5(str))).replace(/[=+/%@#$&*!()|.,?_]/g, '')

  return md5Hashed.slice(-15)
}

function hex2a(hexx) {
  var hex = hexx.toString() //force conversion
  var str = ''
  for (var i = 0; i < hex.length; i += 2) str += String.fromCharCode(parseInt(hex.substr(i, 2), 16))
  return str
}

Vue.prototype.$Md5Hash = convertToMd5Hash
