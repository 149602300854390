export default {
  // menu "Broadcast Timeline","Template","Label","Chat Data"
  itemChat: 'Chat',
  [`itemSend Broadcast`]: 'Send Broadcast',
  [`itemContact List`]: 'Customer List',
  [`itemChannels`]: 'Channel Wa',
  [`itemInbox`]: 'Chat Setting',
  [`itemBroadcasts`]: 'Broadcasts',
  [`itemUsers`]: 'Team',
  [`itemBusiness+`]: 'Enterprise',
  [`itemStatistics`]: 'Statistik',
  [`itemBroadcast Timeline`]: 'Broadcast Timeline',
  [`itemTemplate`]: 'Template',
  [`itemLabel`]: 'Label',
  [`itemChat Data`]: 'Chat Data',
  [`itemMy Apps`]: 'My Apps',
  [`itemE-Shop`]: 'E-Shop',
  [`itemE-Form`]: 'E-Form',
  [`itemCampaign Tracker`]: 'Campaign Tracker',
  [`itemLogin by Whatsapp`]: 'Login by Whatsapp',
  [`itemBroadcast Statistic`]: 'Broadcast Statistic',
  [`itemUser Perfomance`]: 'User Perfomance',
  [`itemChat Statistic`]: 'Chat Statistic',

  // "Broadcast Statistic", "User Perfomance", "Chat Statistic"
  every: 'Setiap',
  none: 'Tidak sama sekali',
  needMoreHelpTextIg: 'Kunjungi instagram kita',
  needMoreHelpTextFb: 'Lihat kita di facebook',
  needMoreHelpTextEmail: 'Cara terbaik & tercepat mendapat jawaban ',
  needMoreHelpText: 'hubungi kami dengan kontak di bawah, kami akan membantu kamu secepat yang kami bisa',
  needMoreHelp: 'Perlu Bantuan?',
  HelpCenter: 'Pusat Bantuan',
  contactUs: 'Hubungi Kami',
  eOrdering: 'eOrdering',
  eForm: 'eForm',
  member: 'Anggota',
  loadingDialog: 'Memuat... Mohon Tunggu...',
  price: 'Harga',
  startConversation: 'Mulai Percakapan',
  customer: 'Pelanggan',
  notes: 'Catatan',
  note: 'Catatan',
  lastMessage: 'Pesan Terakhir',
  confirmDelete: 'Anda yakin ingin menghapus?',
  deleteTemplate: 'Anda yakin ingin menghapus template ini?',
  bulkDeleteTemplate: 'Anda yakin ingin menghapus semua template yang dipilih?',
  messageTemplatesRead: 'Templates Pesan',
  Channels: 'Channel',
  Inbox: 'Inbox',
  Broadcast: 'Broadcast',
  'Broadcast List': 'Daftar Broadcast',
  'Scheduled Broadcast': 'Jadwal Broadcast',
  'List Broadcast': 'Daftar Broadcast',
  'Broadcast Schedule': 'Jadwal Broadcast',
  'Marketing Calendar': 'Kalendar Marketing',
  titleCampaign: 'Judul Kampanye',
  createSchedulePost: 'Membuat Jadwal Post',
  createPost: 'Membuat Post',
  Statistics: 'Statistik',
  Contact: 'Kontak',
  Contacts: 'Contacts',
  Label: 'Label',
  Widget: 'Widget',
  Template: 'Template',
  Operators: 'Operator',
  'Operators List': 'Daftar Operator',
  'Replies History': 'Riwayat Balasan',
  'User Statistic': 'Statistik Operator',
  'Replies Statistic': 'Statistik Balasan',
  User: 'Pengguna',
  'User List': 'Daftar Pengguna',
  Subscriptions: 'Langganan',
  'Link Generator': 'Link Generator',
  Chat: 'Chat',
  'Chat Data': 'Chat Data',
  'Send Broadcast': 'Kirim Broadcast',
  'Live Chat Widget': 'Live Chat Widget',
  'Contact List': 'Contact List',
  contactGroup: 'Grup Pelanggan',
  group: 'Grup',
  Tutorial: 'Cara Penggunaan',
  'Help Center': 'Pusat Bantuan',
  'Contact Us': 'Hubungi Kami',
  'Business+': 'Business+',
  'Link Tracker': 'Link Tracker',
  'Statistik Balasan': 'Statistik Balasan',
  Settings: 'Pengaturan',

  // key
  pinned: 'Disematkan',
  version: 'Versi',
  whatsappContacts: 'Kontak WhatsApp',
  share: 'Bagikan',
  filter: 'Saring',
  sort: 'Urutan',
  tag: 'Tanda',
  text: 'Teks',
  phoneNumberInput: 'Ketik nomor telepon tanpa kode negara',
  notifFromAll: 'Notifikasi dari Semua Percakapan',
  notifFromChannel: 'Notifikasi dari Channel yang Aktif',
  notifFromAssigned: 'Notifikasi dari Percakapan yang Ditugaskan',
  notifUnreplied: 'Terima notifikasi dari',
  contact: 'kontak',
  downloadMessages: 'Unduh percakapan',
  freeTrialRemaining: 'Sisa waktu uji coba:',
  demoMessageSuccess: 'Yeay, pesan kamu sudah terkirim!',
  update: 'Perbarui',
  channel: 'channel',
  refresh: 'Refresh',
  message: 'pesan',
  Message: 'Pesan',
  List: 'Daftar',
  Import: 'Impor',
  scan: 'memindai',
  Fields: 'Fields',
  field: 'field',
  value: 'value',
  setLabel: 'Tetapkan Label',
  config: 'konfigurasi',
  logout: 'Keluar',
  ok: 'OK',
  new: 'Baru',
  create: 'Buat',
  add: 'Tambah',
  addTo: 'Tambah Ke',
  edit: 'Ubah',
  cancel: 'Batal',
  save: 'Simpan',
  warning: 'Peringatan',
  delete: 'Hapus',
  connectInstagram: 'Connect',
  deleteConfirmation: 'Apakah Anda yakin ingin menghapus',
  disconnectedConfirmation: 'Apakah Anda yakin ingin memutus koneksi channel ini',
  deleteConfirmationEorder:
    'Daftar Kontak ini terhubung dengan Eshop, jika ini di hapus, kamu tidak akan bisa melihat hasil eshop di daftar kontak',
  deleteConfirmationSurvey:
    'Daftar Kontak ini terhubung dengan eform survey, jika ini di hapus, kamu tidak akan bisa melihat hasil eform survey di daftar kontak',
  archive: 'Arsip',
  unarchived: 'Buka Arsip',
  archivedConfirmation: 'Apakah Anda yakin ingin mengarsipkan',
  unarchivedConfirmation: 'Apakah Anda yakin ingin membuka',
  deleteMessage: 'Apakah Anda yakin ingin menghapus data ini?',
  syncfromserver: 'Sinkronasi data dari server',
  search: 'Cari',
  select: 'Pilih',
  selectall: 'Pilih Semua',
  send: 'Kirim',
  submit: 'Kirim',
  next: 'Lanjut',
  previous: 'Sebelumnya',
  finish: 'Selesai',
  close: 'Tutup',
  open: 'Buka',
  from: 'Dari',
  clear: 'Bersihkan',
  rowsPerPageLabel: 'Data per halaman:',
  noDataLabel: 'Tidak ada data yang tersedia',
  loadingLabel: 'Memuat data...',
  phoneNumber: 'Nomor ponsel',
  notFound: 'tidak ditemukan',
  noResults: 'Tidak ada hasil',
  view: 'Lihat',
  preview: 'Pratinjau',
  tryAgain: 'Coba Lagi',
  column: 'Kolom',
  source: 'Sumber',
  lastReply: 'Terakhir Kali Membalas',
  favourite: 'Favorit',
  interaction: 'Interaksi',
  needReply: 'Perlu Dibalas',
  notificationNeedReplyOne: 'Saat ini terdapat',
  notificationNeedReplyTwo: 'percakapan yang perlu dibalas',
  reply: 'Balas',
  assignTo: 'Pasangkan Ke',
  messageStatus: 'Status Pesan',
  name: 'Nama',
  action: 'Aksi',
  continuousSync: 'Sinkronisasi Berkelanjutan',
  continuousSyncInfo: 'Terus sinkronkan kontak dengan sheet ini setiap 5 menit',
  sheetName: 'Nama Sheet',
  record: 'data',
  selectedOf: 'dipilih dari',
  you: 'Anda',
  or: 'atau',
  import: 'Impor',
  link: 'Tautan',
  total: 'Total',
  exportToCsv: 'Ekspor ke Excel',
  exportToVcf: 'Ekspor ke vCard',
  export: 'Ekspor',
  changeSelectedData: 'Ubah Data yang Dipilih',
  changeLabel: 'Ganti Label',
  change: 'Ganti',
  deleteSelectedData: 'Hapus Data yang Dipilih',
  sendyourfirstmessage: 'Kirim pesan pertama anda',
  warningfailedsync: 'Gagal sinkronisasi refresh untuk mengulang',
  warningactiveinvoice: 'Anda memiliki tagihan Xendit yang belum dibayar',
  warningpackageexpired: 'Workspace anda sudah expire',
  warningmaxchannel: 'Anda mencapai batas maksimal jumlah Channel, tingkatkan akun atau tambah ekstra Channel',
  warningmaxoperator: 'Anda mencapai batas maksimal jumlah Operator, tingkatkan akun atau tambah ekstra Operator',
  warningmaxcontacts: 'Anda mencapai batas maksimal jumlah Contacts, tingkatkan akun atau tambah ekstra Contacts',
  warningfailedsavedata: 'Gagal menyimpan data, mohon periksa koneksi anda',
  pay: 'Bayar',
  year: 'Tahun',
  years: 'tahun',
  month: 'Bulan',
  monthsInterval: 'Bulan',
  months: 'bulan',
  day: 'hari',
  days: 'hari',
  min: 'menit',
  mins: 'menit',
  minute: 'menit',
  minutes: 'menit',
  hour: 'jam',
  hours: 'jam',
  second: 'detik',
  seconds: 'detik',
  week: 'minggu',
  never: 'Tidak pernah',

  templates: {
    templates: 'Templates',
    templateName: 'Nama template',
    categories: 'Kategori',
    quickReplies: 'Balasan Cepat',
    lastUpdated: 'Terakhir diperbarui',
    actions: 'Aksi',
    addMessageTemplate: 'Tambahkan template message',
    addCategory: 'Tambahkan kategori',
    searchTemplate: 'Cari template',
  },

  // chat: 'Pesan',
  serverConnectionLost: 'Koneksi Ke Server Terputus',
  chat: {
    pressEnterToChat: 'Ketik disini untuk mencari',
    totalRooms: 'Jumlah room',
    newChat: 'Mulai chat baru',
    channels: 'kanal',
  },
  dialog: {
    invalidFile: 'Harap pilih file yang benar dan coba lagi',
    requiredMessage: 'Pesan diperlukan',
    channelRequired: 'Harap pilih channel yang akan diimport',
    mappingallfield: 'harap mapping kolom Name dan Phone Number',
    failedgetdata: 'Gagal mengambil data',
    notvalidsheet: 'Spreadsheet URL tidak Valid',
    pleaseselectone: 'Harap pilih kontak',
    isRequired: ' wajib diisi',
    errorSendMessage: 'Tidak dapat mengirim pesan, silakan coba lagi',
    errorUploadAttachment: 'Tidak dapat mengirim lampiran, silakan coba lagi',
    selectChannel: 'Mohon pilih Channel',
    selectGroupContact: 'Mohon Pilih Grup Pelanggan',
    errorImportUser: 'Terjadi Kesalahan Unduh Data User Group',
  },
  EOrdering: {
    chatBotActive: 'Hidup',
    AddPromo: 'Tambah Promo',
    addNewFaq: 'Tambah FAQ',
    serveTo: 'Pilih pengguna yang melayani',
    selectShop: 'Pilih Toko',
    greatingMsg: 'Pesan Sambutan',
    product: 'Produk',
    openChatbotSetting: 'Chatbot',
    chatbotSetting: 'Pengaturan Chatbot',
    keyword: { msg: 'Kata Kunci Pesan', detail: 'Kata Kunci Pesan' },
    greeting: { msg: 'Pesan Menyapa', detail: 'Pesan Menyapa Untuk Kustomer' },
    talktoCS: {
      msg: 'Pesan untuk Bicara dengan Operator',
      detail: 'cth : Terimakasih telah menghubungi kami. Tim Kami akan melayani anda sebentar lagi (antrian nomor: 2)',
    },
    choosenShop: { msg: 'E-Shop yang Terpilih', detail: 'Pilih E-Shop' },
    qna: { msg: 'Pesan Tanya Jawab', detail: 'Pesan Tanya Jawab untuk Kustomer' },
    closingMsg: {
      msg: 'Pesan Penutup (setelah 5 menit)',
      detail: 'cth: Terimakasih telah menghubungi Lavenir Studio, semoga hari anda menyenangkan.',
    },
    faqMsg: {
      qs: 'Masukan Pertanyaan',
    },
    additionalMenu: 'Menu Tambahan',
    shopAddress: 'Alamat Toko',
    canceledOrder: 'Dibatalkan',
    bulkUpdateStatus: 'Perbarui Semua Status',
    cancel: 'Dibatalkan',
    fullDelete: 'Hapus Semua',
    roomNo: 'Nomor Kamar',
    formValidationMessage: 'Semua Data yang Dibutuhkan Harus di Isi !',
    populatedSuccess: 'Data Sukses Termuat !',
    shop: 'Toko',
    customSheet: 'Kustom Sheet',
    phoneNumber: 'Nomor Telepon',
    shopType: 'Tipe Toko',
    shopTax: 'Pajak Toko',
    tax: 'Persentase Pajak :eg 10',
    taxlabel: 'Pajak',
    discountlabel: 'Diskon',
    includeTax: 'Masukan Pajak',
    calculateDeliveryPricing: 'Hitung Ongkos Kirim(ID)',
    calculateDeliveryPricingInter: 'Hitung Ongkos Kirim(ID ke Internasional)',
    availableCourier: 'Kurir yang Tersedia',
    province: 'Provinsi',
    city: 'Kota',
    subdistrict: 'Kecamatan',
    addressDetail: 'Detil Alamat',
    save: 'Simpan',
    customGSheet: 'Kustom Google Sheet',
    enableCash: 'Cash',
    enableXendit: 'Xendit',
    enablePaypal: 'Paypal',
    paymentMethod: 'Metode Pembayaran',
    addShop: 'Tambah Toko',
    deleteConfirm: 'Apa anda yakin ?',
    createShop: 'Buat Toko',
    deleteShop: 'Hapus Toko',
    editShop: 'Edit Toko',
    updateProduct: 'Perbarui Produk',
    addLink: 'Tambah Link',
    viewContact: 'Lihat Kontak',
    shopName: 'Nama Toko',
    shopNameLabel: 'Masukan Nama Toko',
    generatedLink: 'Link Yang Telah Dibuat',
    noLink: 'Belum Ada',
    copyLink: 'Kopi Link',
    editLink: 'Ubah Link',
    deleteLink: 'Hapus Link',
    broadcastLink: 'Broadcast Link',
    driveLink: 'Google Sheet Link',
    toDrive: 'Buka Google Sheet',
    address: 'Alamat',
    totalRevenue: 'Total Pendapatan',
    totalIncoming: 'Total Pesanan',
    totalPending: 'Total Tertunda',
    completedOrder: 'Pesanan Selesai',
    transaction: 'Transaksi',
    pending: 'Tertunda',
    onProgress: 'Dalam Pengerjaan',
    paid: 'Terbayar',
    done: 'Selesai',
    orderType: 'TIPE PESANAN',
    orderTime: 'WAKTU PESANAN',
    idOrder: 'ID PESANAN',
    name: 'NAMA',
    detailTable: 'Tabel Detail',
    price: 'HARGA',
    totalPrice: 'TOTAL HARGA',
    primaryColor: 'Warna Utama Toko',
    secondaryColor: 'Warna Kedua Toko',
    tertiaryColor: 'Warna Ketiga Toko',
    shopLogo: 'Logo Toko',
    gmaps: 'Pilih dari Peta',
    gmapsCurrentLoc: 'Pakai lokasi saat ini',
    gmapsCurrentLocInfo:
      'Jika lokasi tidak akurat, periksa koneksi jaringan anda, dan pastikan tidak sedang menggunakan Proxy atau VPN',
    calculatePricingInfo: 'Hanya untuk toko yang berlokasi di Indinesia',

    note: 'Catatan',
    subTotal: 'Sub Total',
    orderTypeDetail: 'Tipe Pesanan',
    deliveryPrice: 'Ongkos Kirim',
    courier: 'Kurir',
    source: 'Sumber',
    total: 'Total',
    coupon: 'Kupon',
    date: 'Tanggal',
    time: 'Waktu',
    cover: 'Cover',
    idTable: 'Id Meja',

    hero: {
      easiestWay: 'Buat Toko yang unik',
      moreSales: 'Ciptakan toko kamu dan kirim ke semua pelangganmu',
      isByAdding: 'Mereka bisa langsung memesan ke toko yang kamu',
      here: 'Buat',
    },
  },
  user: {
    edit: 'Edit User',
    delete: 'Hapus user',
  },
  channels: {
    socialMedia: 'Media Sosial',
    disconnectChannel: 'Putuskan Channel',
    noDataTitle: 'Integrasikan Chat Tokopedia',
    noDataSub: 'Integrasikan Chat Tokopedia Anda',
    noDataTitleFacebook: 'Integrasikan Facebook Pages',
    noDataSubFacebook: 'Integrasikan Facebook Pages Anda',
    noDataTitleInstagram: 'Koneksikan Akun Instagram Bisnis',
    noDataSubInstagram: 'Koneksikan Instagram Bisnis Akun anda',
    channel: 'Channel',
    label: 'Label',
    color: 'Warna',
    phoneNumber: 'Nomor Telepon',
    actions: 'Aksi',
    backgroundMessage: 'Pesan Latar Belakang Aktif',
    infoBackgroundMessage:
      'Untuk menghindari deteksi spam Whatsapp, Anda dapat mengaktifkan fitur ini untuk menambah jumlah obrolan masuk',
    syncSetting: 'Pengaturan Sinkronisasi',
    syncSettingOptionOne: 'Semua Pesan',
    syncSettingOptionTwo: 'Hanya Dari Kontak yang Diketahui',
    syncContact: 'Sinkronisasi Kontak',
    muteNotification: 'Mute Notifikasi',
    boldUnreplied: 'Jangan Kirim Pesan Terbaca',
    infoStealthMode: 'Hanya menandai chat terbaca jika membalas chat',
    connected: 'Terhubung',
    notConnected: 'Tidak Terhubung',
    disconnected: 'Terputus',
    preparing: 'Sedang Disiapkan',
    deviceOffline: 'Koneksi Terputus',
    howToUse: 'Cara Penggunaan',
    howToUseConnect: 'Cara Penggunaan',
    disclaimer:
      '<i><span class="text-subtitle-2">*Sebelum <span class="primary--text font-weight-bold">menghubungkan akun Whatsapp</span> ke Marketa Chat, <span class="primary--text font-weight-bold">pastikan</span> akun Anda sebelumnya telah <span class="primary--text font-weight-bold">bergabung dengan multi-device beta kemudian keluar dari multi-device beta,</span> untuk mengurangi <span class="primary--text font-weight-bold">kemungkinan gangguan </span> dari Whatsapp</span></i>',
    howToUseOne: '1. Buka <span class="font-weight-bold">Whatsapp</span> pada handphone anda',
    howToUseTwo: '2.  Klik <span class="font-weight-bold">3-titik</span> pada menu di kanan atas',
    howToUseThree: '3. Tap pada <span class="font-weight-bold">"Linked Devices"</span>',
    howToUseFour: `4. Klik <span class="font-weight-bold">"Join Beta"</span> jika belum`,
    howToUseFive: '5. Ketuk <span class="font-weight-bold">"Sambungkan Perangkat"</span>',
    howToUseSix: '6. Scan <span class="font-weight-bold">QR Code</span> yang ditunjukkan',
    howToUseFourBelow: '4. Scan <span class="font-weight-bold">QR Code</span> dibawah',
    howToUseOneConnect1: '1. Balas pesan pelanggan anda pada menu',
    howToUseTwoConnect1: '2. Buka menu',
    howToUseThreeConnect1: '3. Ketik pesan Anda atau gunakan template dari menu',
    howToUseFourConnect1: '4. Periksa riwayat pengiriman untuk aktivitas Broadcast Anda di menu',
    howToUseOneConnect2: '',
    howToUseTwoConnect2: 'dan pilih atau tambahkan kontak target',
    howToUseThreeConnect2: '',
    howToUseFourConnect2: '',

    // howToUseOne:
    //   '1. Pindai kode QR menggunakan aplikasi Whatsapp Anda. Jika Anda sudah memindainya, silakan pilih nomor telepon yang dipindai',
    // howToUseTwo: '2. Buka menu <span class="font-weight-bold">Broadcast</span> dan pilih atau tambahkan kontak target',
    // howToUseThree:
    //   '3. Ketik pesan Anda atau gunakan template dari menu <span class="font-weight-bold">Message Template</span>',
    // howToUseFour:
    //   '4. Periksa riwayat pengiriman untuk aktivitas Broadcast Anda di menu <span class="font-weight-bold">History</span>',
  },
  workspaces: {
    editHeader: 'Ubah Nama Workspace',
  },
  inbox: {
    autoAssignChatOnReply: 'Tetapkan Percakapan Saat Dibalas',
    autoAssignNewChat: 'Tetapkan Percakapan Baru',
    autoCloseChat: 'Tutup Percakapan Otomatis',
    uploading: 'Mengunggah...',
    noteMessage: 'Tambah ke catatan',
    unsendMessage: 'Hapus Pesan',
    selectMessages: 'Pilih pesan',
    attachment: 'Lampiran',
    typingPlaceholder: 'Tulis pesan',
    newChat: 'Percakapan Baru',
    enterPhoneNumber: 'Masukkan Nomor Ponsel',
    or: 'atau',
    quickReplies: 'Balas Cepat',
    quickRepliesNote: 'Kamu bisa menggunakan template untuk menambahkan gambar dan media ke balas cepat',
    quickRepliesAction: 'Balas cepat',
    selectFromExistingContact: 'Pilih Dari Kontak yang Ada',
    lastInteractWith: 'Berinteraksi terakhir dengan ',
    assignedTo: 'Ditugaskan untuk ',
    exportChat: 'Ekspor Percakapan',
    exportMessages: 'Ekspor pesan',
    labelMessages: 'Label pesan',
    infiniteLoadingErrorMessage: 'Tidak dapat memuat pesan',
    infoPhone: 'Pastikan ponsel Anda tetap terhubung',
    infoPhoneSub:
      'WhatsApp Web terhubung ke ponsel Anda untuk menyinkronkan pesan. Untuk mengurangi penggunaan data, sambungkan ponsel Anda ke Wi-Fi.',
    contactEmpty: 'Tidak ada kontak',
    noChatSelected: 'Mulai percakapan dengan mengklik sebuah ruangan chat',
    chatEmpty: 'Tidak ada pesan',
    archiveContact: 'Arsipkan kontak ini',
    deleteRoom: 'Hapus kontak ini',
    addToContactGroup: 'Tambah ke Grup Pelanggan',
    unarchiveContact: 'Batal arsip kontak ini',
    addedLabelContact: 'Semua label sudah ditambahkan pada kontak ini',
    addedLabelMessage: 'Semua label sudah ditambahkan pada pesan ini',
    shareRoom: 'Bagikan ruangan',
    sharedRoomExpiryTime: 'Waktu Kedaluwarsa',
    sharedRoomType: 'Tipe',
    sharedRoomUrl: 'Tautan Ruangan',
  },
  importContactPreview: {
    emptyFields: 'Harap pilih kolom dari field [name dan phone number]',
  },
  history: {
    headerMarkupTable: 'Riwayat Percakapan',
    operators: 'Operator',
    statistics: 'Statistik',
    replies: 'Riwayat Balasan',
    customerServed: 'Pelanggan Dilayani',
    totalReply: 'Total Balasan',
    avgReply: 'Rata-rata Balasan (Detik)',
    autoAssign: 'Menugaskan Otomatis',
    viewAssignOnly: 'Akses Terbatas untuk Contact yang Diberikan',
    channelList: 'List Channel Aktif',
    limitedChannel: 'Akses Terbatas untuk Channel yang Diberikan',
    enabledMenu: 'Menu Aktif',
    limitedMenu: 'Akses Terbatas untuk Menu yang Diberikan',
    preparing: 'Mempersiapkan',
    chatHistoryData: 'Tidak ada percakapan yang telah dilakukan',
    attachments: 'Lampiran',
    attachmentsData: 'Tidak ada ditemukan lampiran',
    to: 'Kepada',
    replyTime: 'Rata-rata Waktu Balasan',
    role: 'Peran',
    contact: 'Kontak',
    assign: 'Ditugaskan kepada',
    channel: 'Channel',
    duration: 'Durasi',
    rating: 'Nilai',
  },
  messageTemplates: {
    emptyList: `Anda belum mempunyai template`,
    emptyTemplate1: 'Sesuaikan konten pesan sesuai dengan kebutuhan Anda,',
    emptyTemplate2: 'dan kirimkan ke pelanggan Anda tanpa mengetik ulang',
    open: 'Buka Template',
    edit: 'Edit Template',
    delete: 'Hapus Template',
    templateName: 'Nama Template',
    message: 'Pesan',
    category: 'Kategori',
    lastUpdate: 'Terakhir Kali di Perbarui',

    // openingChat: 'Pesan Pembuka',
    openingChat: 'Isi Pesan',
    onReply: 'Pesan Balasan',
    fromTemplates: 'Gunakan Template',
  },
  broadcasts: {
    selectChannel: 'Pilih Channel',
    createBroadcast: 'Buat Broadcast Baru',
    channelFilter: 'Filter Channel',
    noTemplate: `Belum punya templatenya ?`,
    uploadFormat: 'Harap upload excel sesuai format yang diberikan',
    emptyList: `Anda belum memiliki riwayat broadcast`,
    linkAlert: 'Links harus dimulai dengan https://',
    deleteForMe: 'Hapus otomatis Broadcast (hanya untuk perangkat anda)',
    showBroadcastMessage: 'Perlihatkan Pesan Broadcast',
    hero: {
      easiestWay: 'Cara Termudah',
      moreSales: 'Untuk Menambah Penjualan Hari Ini',
      isByAdding: 'Adalah Dengan Menambah Daftar Broadcast',
      here: 'Disini',
    },
    templateHero: {
      easiestWay: 'Cara Termudah',
      moreSales: 'Untuk Menambah Penjualan Hari Ini',
      isByAdding: 'Adalah Dengan Menambah Template Pesan',
      here: 'Disini',
    },
    notesSendingBroadcasts:
      '* Mohon pastikan <span class="primary--text">channel anda terkoneksi</span> saat <span class="primary--text">broadcast sedang dikirim</span>',
    loginExpired: 'Sesi Login Telah Habis, Dialihkan Ke Login Page',
    sendingSpeed: 'Kecepatan Pengiriman',
    stepOne: 'Broadcast Baru - Langkah 1: Pilih atau Tambah Message Template',
    stepTwo: 'Broadcast Baru - Langkah 2: Pilih Dari atau Upload Daftar Kontak',
    stepThree: 'Broadcast Baru - Langkah 3: Tinjauan Broadcast',
    broadcastName: 'Judul Broadcast',
    broadcastList: 'Broadcast',
    broadcastHistory: 'Riwayat Broadcast',
    broadcastTime: 'Waktu Broadcast',
    broadcastTimeline: 'Lini Waktu Broadcast',
    broadcastScheduled: 'Jadwal Broadcast',
    broadcastFrom: 'Broadcast dari kontak Anda',
    broadcastFromInfo: 'Unduh contoh format untuk mengunggah kontak',
    broadcastFromExcel: 'Broadcast dari file excel yang diimpor',
    broadcastFromExcelInfo: 'Pilih file untuk mengunggah daftar penerima',
    channel: 'Pengirim',
    messageTemplate: 'Template Pesan',
    executionType: 'Tipe Eksekusi',
    immediately: 'Langsung',
    scheduled: 'Terjadwal',
    date: 'Tanggal',
    time: 'Waktu',
    numberContacts: 'Jumlah Kontak',
    executionDatetime: 'Tanggal/Waktu Eksekusi',
    totalContacts: 'Total Kontak',
    recipients: 'Penerima',
    processing: 'Diproses',
    queued: 'Antrian',
    sending: 'Terkirim',
    failed: 'Failed',
    sent: 'Terkirim',
    delivered: 'Terkirim',
    read: 'Dibaca',
    replied: 'Dibalas',
    received: 'Diterima',
    unsend: 'Diproses',
    progress: 'Progress',
    unknown: 'Tidak Diketahui',
    statistics: 'Statistik',
    totalBroadcasts: 'Jumlah Broadcast',
    broadcastStatistic: 'Broadcast Statistik',
    linkClicked: 'Tautan Diklik',
    clickedAt: 'Diklik pada',
    today: 'Hari ini',
    day: 'Hari',
    week: 'Minggu',
    month: 'Bulan',
    four_day: '4 Hari',
    mustFilled: 'Harus di Isi',
    alertSelectContact: 'Mohon Pilih Minimal 1 Kontak',
    stepOneSubtitle: 'Pilih Kontak untuk Broadcast',
    stepOneTitle: 'Pengirim & Kontak',
    stepTwoSubtitle: 'Pilih Pesan untuk Broadcast',
    stepTwoTitle: 'Template Pesan',
    stepThreeSubtitle: 'Cek Data Broadcast',
    stepThreeTitle: 'Tinjau & Kirim',
    selected: 'Dipilih',
    search: 'Cari',
    chooseTemplateFirst: 'Choose Template First',
    custom: 'Custom',
    datetime: 'Custom',
    today10am: 'Pagi Jam 10',
    today08am: 'Pagi Jam 8',
    today09am: 'Pagi Jam 9',
    noon01pm: 'Siang Jam 1',
    afternoon04pm: 'Sore Jam 4',
    tonight7pm: 'Malam Jam 7',
    editBroadcast: 'Mengubah Broadcast',
    deleteBroadcast: 'Menghapus Broadcast',
    detailBroadcast: 'Detail Broadcast',
    statisticBroadcast: 'Statistik Broadcast',
    pauseBroadcast: 'Menghentikan Broadcast',
    resumeBroadcast: 'Melanjutkan Broadcast',
    sendPreview: 'Kirim Pratinjau',
    saveDraft: 'Simpan Draft',
    send: 'Kirim',
    customMessage: 'Pesan Kustom',
    noLink: 'Tidak Ada Link Tersedia',
    linkOff: 'Mati',
    linkOn: 'Hidup',
    getContacts: 'Memuat Kontak',
    customDelay: 'Atur Penundaan Pengiriman',
    delayInterval: 'Jarak Penundaan',
    seconds: 'Detik',
    delayFrom: 'Dari',
    delayTo: 'Hingga',
    restMode: 'Mode Istirahat',
    additionalDelay: 'Selama (Detik)',
    stopAfter: 'Berhenti Kirim Setelah (Pesan) ',
    pleaseSelect: 'Mohon Pilih',
    contactSource: 'Sumber Kontak',
    chooseFrom: 'Pilih Dari',
    customFrom: 'Pilih Dari',
    otherList: 'Daftar Kontak',
    thereAre: 'Terdapat',
    duplicatePhone: 'Nomor Telepon Duplikat',
    unsubscribePhone: 'Nomor Telepon yang tidak berlangganan',
    confirmDelete: 'Apakah anda yakin ingin menghapus',
    confirmPause: 'Apakah anda yakin ingin menghentikan pengiriman broadacast',
    confirmResume: 'Apakah anda yakin ingin melanjutkan pengiriman broadacast',
    confirm: 'Konfirmasi',
    notAllField: 'Tidak Semua Field',
    inOpeningChat: 'di Pesan "Opening Chat", tersedia pada',
    onReplyChat: 'di Pesan "On Reply", tersedia pada',
    contactListSelected: 'Daftar Kontak Terpilih',
    allChannelDisconnected: 'Semua Channel Terputus',
    checkChannels: 'Mengecek Status Channel',
    uploadNotes: 'Unggah Hanya File Gambar',
    deleteFailedNotCreated: 'Tidak bisa didelete, pesan broadcast sedang di buat',
    broadcastModeAggresive: 'Kirim Broadcast ke semua contact terpilih',
    broadcastModeSafe: 'Jangan mengirim broadcast ke kontak tanpa interaksi',
  },
  contacts: {
    search: 'Cari',
    batchActions: 'Aksi kumpulan',
    channelFilter: 'Filter channel',
    allChannels: 'Semua kanal',
    contacts: 'Kontak',
    tabs: {
      contacts: 'Kontak',
      labels: 'Label',
      form: 'Formulir',
      campaign: 'Kampanye',
    },
    headers: {
      user: 'Pengguna',
      assignTo: 'Diampu oleh',
      email: 'Surel',
      labels: 'Label',
      campaign: 'Kampanye',
      action: 'Aksi',
    },
    selectLabelDialog: {
      selectLabels: 'Pilih label',
      cancel: 'Batalkan',
      add: 'Tambah',
      save: 'Simpan',
    },
    addContactDialog: {
      name: 'Nama',
      avatarURL: 'URL avatar',
      nickname: 'Nama panggilan',
      phoneNumber: 'Nomor telepon',
      email: 'Surel',
      addLabels: 'Tambah label',
      errorMessage: 'Baris ini harus diisi',
    },
    labels: {
      export: 'Ekspor',
      labelConfig: 'Atur label',
      searchUser: 'Cari pengguna',
      filter: 'Filter',
      headers: {
        user: 'Pengguna',
        email: 'Surel',
        message: 'Pesan',
        assignDate: 'Tanggal ditetapkan',
        label: 'Label',
      },
      pleaseSelectContactToExport: 'Tolong pilih kontak untuk diekspor terlebih dahulu',
      configLabelDialog: {
        configLabel: 'Atur Label',
        variant: 'Varian',
        channel: 'Kanal',
      },
    },
    form: {
      addForm: 'Tambah Formulir',
      editForm: 'Sunting Formulir',
      headers: {
        formName: 'Nama Form',
        contentAmount: 'Jumlah konten',
        label: 'Label',
        createdAt: 'Awal dibuat',
        lastUpdated: 'Terakhir diperbarui',
        actions: 'Aksi',
      },
    },
    statistic: {
      statisticCampaignAll: 'Statistik Kampanye Semua Data',
      headers: {
        user: 'Pengguna',
        message: 'Pesan',
        channel: 'Kanal',
        date: 'Tanggal',
      },
    },
    campaign: 'Kampanye',
    addContact: 'Tambah kontak',
    emptyList: `Anda belum mempunyai daftar kontak`,
    contact: 'Kontak',
    additional: ' Kolom Tambahan ',
    noAdditionalField: `Anda tidak mempunyai kolom tambahan untuk daftar kontak ini.`,
    contactList: 'Kontak',
    createContactGroupList: 'Buat Daftar Kontak',
    hero: {
      easiestWay: 'Cara Termudah',
      moreSales: 'Untuk Menambah Penjualan Hari Ini',
      isByAdding: 'Adalah Dengan Menambah Daftar Kontak',
      here: 'Disini',
    },
    importContact: 'Impor Kontak',
    importContactSub: 'Impor kontak dari berbagai sumber',
    contactName: 'Nama Kontak',
    name: 'Nama',
    phoneNumber: 'Nomor Ponsel',
    source: 'Sumber',
    profilePicture: 'Foto Profil',
    currentSetting: 'Pengaturan Saat Ini',
    currentSettingName: 'Nama Pengaturan',
    attribute: 'Atribut',
    listManage: 'Kelola Daftar',
    listName: 'Daftar Nama',
    fromSystem: 'Dari Sistem',
    lastModified: 'Terakhir di Ubah',
    showChat: 'Tampilkan Chat',
    newLabel: 'Label Baru',
    color: 'Warna',
    value: 'Nilai',
    field: 'Kolom',
    action: 'Aksi',
    config: 'Konfigurasi',
    columnName: 'Nama Kolom',
    defaultValue: 'Nilai Default',
    showField: 'Tampilkan Kolom',
    required: 'Dibutuhkan',
    chooseCsv: 'Pilih File CSV',
    chooseExcel: 'Pilih File Excel',
    chooseVcf: 'Pilih File vCard',
    scanQr: 'Pindai jika Anda ingin mengimpor dari nomor baru',
    hintSheet: 'Pastikan setelan spreadsheet Anda dibagikan dengan siapa saja yang memiliki tautan ini',
    deleteSelectedContact: 'Hapus Kontak yang Dipilih',
    changeSelectedContact: 'Ubah Kontak yang Dipilih',
    broadcastSelectedContact: 'Broadcast Kontak yang Dipilih',
    setLabelSelectedContact: 'Atur Label Kontak yang Dipilih',
    deleteSelectedSetting: 'Hapus Pengaturan yang Dipilih',
    deleteSelectedLabel: 'Hapus Label yang Dipilih',
    columnNameRequired: 'Nama kolom wajib diisi',
    contactsNameRequired: 'Nama kontak wajib diisi',
    phoneNumberRequired: 'Nama Telp wajib diisi',
    nicknameRequired: 'Nama Panggilan wajib diisi',
    phoneNumberNotValid: 'Nomor telp tidak valid',
    phoneNumberExist: 'Nomor telp sudah terdaftar',
    answerData: 'Data Jawaban',
    contactType: 'Tipe Contact',
    nickName: 'Nama Panggilan',
    showLabel: 'Tampilkan Label',
  },
  subscriptions: {
    subscriptionPlan: 'Paket Langganan',
    maxOperators: 'Maksimal Operator',
    monthly: 'Bulanan',
    annually: 'Tahunan',
    package: 'Paket',
    coupon: 'Kupon',
    action: 'Aksi',
    extra: 'Ekstra',
    maxChannels: 'Maksimal Channel',
    invoiceHistory: 'Riwayat Faktur',
    CreatedAt: 'Dibuat pada',
    currentPlan: 'Plan Saat Ini',
    ExpiredAt: 'Berakhir pada',
    TotalPrice: 'Total Harga',
    PayWith: 'Bayar dengan',
    yourCurrentPersonalWorkspacePlan: 'Plan Workspace Milik Anda Saat Ini',
    detail: 'Semua rencana termasuk 40+ peralatan canggih dan fitur-fitur untuk meningkatkan produk Anda.',
  },
  widget: {
    hero: {
      easiestWay: 'Cara Termudah',
      moreSales: 'Untuk Menambah Penjualan Hari Ini',
      isByAdding: 'Adalah Dengan Menambah Widget',
      here: 'Disini',
    },
    showWidget: 'TAMPILKAN WIDGET',
    lastModified: 'TERAKHIR DIUBAH',
  },
  LinkGenerator: {
    hero: {
      easiestWay: 'Buat link chat untuk pelanggan mu',
      moreSales: ',pelanggan akan jadi lebih mudah',
      isByAdding: 'dalam menghubungimu dengan membuat direct link',
      here: 'dengan pesan yang ditunjukan ke kamu',
    },
    trackerHero: {
      easiestWay: 'Ketahui jumlah pengunjung di linkmu',
      moreSales: 'dan lihat bagaimana efektifnya',
      isByAdding: 'usahamu menggait perhatian',
      here: 'Pelangganmu',
    },
    createNewLink: 'Buat Link Baru',
    slugIsAvailability: 'Slug bisa dipakai',
    slugIsNotAvailability: 'Slug tidak bisa dipakai',
    copySuccess: 'Copy Berhasil dilakukan',
    copyUnsupported: 'Fitur copy tidak tersedia di perangkat/browser ini.',
    settings: 'Pengaturan',
    alertSlug: 'Tolong Check Ketersedian Slug',
    alertChannel: 'Tolong Pilih Minimal 1 Channel',
    confirmDeleteLink: 'Anda Yakin Ingin Menghapus Link Generate ini?',
    tutorialSlugOne: 'Slug link dipakai untuk menghindari kesamaan link yang di tuju dengan user lain',
    tutorialSlugTwo: 'Anda dapat menentukan Pesan yang akan user kirimkan kepada Anda',
    tutorialSlugThree: 'Anda dapat menentukan Operator yang akan mendapatkan pesan dari link yang di klik oleh User',
    tutorialSlugFour: 'Anda Memiliki Google Tag? paste disini untuk melakukan tracking pada Google Tag Manager Anda',
    tutorialSlugFive:
      'Anda Juga Dapat Menentukan Loading saat user melakukan klik terhadap link yang anda buat dan memaksimalkan Google Tag Manager',
  },
  OperatorsTranslate: {
    confirmWant: 'Anda Yakin ingin Menghapus',
    meaningAction: 'sebagai Operator?',
  },
  coupons: {
    coupon: 'Kupon',
  },
  landing: {
    hero: {
      sendManyMsg: 'Kirim Banyak Pesan WhatsApp Hanya Dengan',
      oneClick: 'Satu Klik',
      increaseSales:
        'Tingkatkan Penjualanmu Hingga 300% dengan berinteraksi langsung dengan pelangganmu menggunakan WhatsApp.',
      tryNow: 'Coba Sekarang',
      sendMessage: 'Kirim Pesan',
    },
    howItWork: {
      title: 'Cara Kerja',
      headline: 'Bagaimana Cara Kerjanya ?',
      stepOne: {
        title: '1. Hubungkan Nomor WhatsApp Anda',
        detail: 'Scan QR Code yang telah kami siapkan, setelah itu, Anda akan terhubung dengan server kami.',
      },
      stepTwo: {
        title: '2. Pilih Kontak Penerima Broadcast Anda',
        detail: 'Silahkan pilih nomor kontak penerima yang ingin Anda kirimkan pesan tersebut.',
      },
      stepThree: {
        title: '3. Pengiriman Pesan Otomatis Dimulai',
        detail: 'Jika sudah, biarkan sistem kami yang bekerja. Anda boleh duduk manis sambil minum kopi.',
      },
    },
    features: {
      title: 'Fitur',
      headline: 'Apa Saja Fitur Canggih di Marketa Chat ?',
      description:
        'Marketa Chat adalah tool berbasis web untuk Anda sebagai pebisnis online yang dapat membantu penjualan di bisnis Anda jadi jauh lebih efektif & efisien.',
      featureOne: {
        title: 'Kirim Pesan Tanpa Batas',
        body: 'Kirim pesan ke ratusan kontak WhatsApp hanya dengan sekali klik, tanpa harus simpan nomor.',
      },
      featureTwo: {
        title: 'WhatsApp Web Multi Pengguna',
        body: 'Satu akses WhatsApp Web yang bisa digunakan oleh banyak CS Anda.',
      },
      featureThree: {
        title: 'Kustomisasi Penuh Pesan',
        body: 'Buat isi pesan Anda menjadi dinamis dengan menambahkan nama customer dan emoji.',
      },
      featureFour: {
        title: 'Sisipkan Gambar pada Pesan Broadcast',
        body: 'Buat isi pesan Anda lebih menarik lagi dengan melampirkan gambar.',
      },
      featureFive: {
        title: 'Kustomisasi Jadwal Broadcast',
        body: `Rencanakan broadcast Anda dengan langkah yang mudah. Broadcast kan pesan Anda kapanpun Anda inginkan.`,
      },
      featureSix: {
        title: 'Singkronkan Kontak dari WhatsApp',
        body: 'Hanya dengan satu klik, Anda bisa dengan mudah mengimpor ataupun mengekspor semua kontak Anda dari WhatsApp.',
      },
      featureSeven: {
        title: 'Berikan Penanda pada Customer Anda',
        body: `Kelompokkan customer Anda berdasarkan penanda yang telah dibuat.`,
      },
      featureEight: {
        title: 'Impor dan Ekspor Kontak',
        body: 'Baik secara manual maupun secara otomatis, Anda bisa memasukan kontak hany dengan sekali klik.',
      },
      featureNine: {
        title: 'Pantau Kinerja Tim Anda',
        body: 'Pantau kinerja dan kecepatan balas tim secara harian, mingguan dan bulanan.',
      },
      featureTen: {
        title: 'Penanda Pesan',
        body: 'Tandai chat untuk setiap proses pesanan Anda.',
      },
    },
    demo: {
      title: 'Demo',
      headline: 'Demo Marketa Chat',
      subTitle: 'Pindai dengan WA dan kirim pesan pertama anda',
      subTitlesuccess:
        'Pesan pertama anda telah berhasil terkirim, coba aplikasi kami untuk melakukan broadcast dan lebih banyak lagi',
    },
    demoLanding: {
      stepOne: {
        android: { open: 'Buka', desc: 'di HP Anda', moreOpt: `Ketuk 'Opsi Lainnya'` },
        ios: {
          open: 'Buka',
          desc: 'di HP Anda',
          moreOpt: 'Ke Menu Pengaturan',
          moreOpt2: 'WhatsApp',
        },
      },
      stepTwo: {
        android: {
          tap: 'Ketuk',
          linkDevice: 'TAUTKAN PERANGKAT',
          desc: 'Ikuti petunjuk yang ditampilkan di layar jika perangkat Anda memiliki fitur autentikasi biometrik. Jika tidak mengaktifkan autentikasi biometrik, Anda akan diminta untuk memasukkan PIN yang digunakan untuk membuka kunci telepon.',
        },
        ios: {
          desc: `Di iOS 14 dan yang lebih baru, gunakan Touch ID atau Face ID untuk membuka kunci. Jika tidak mengaktifkan autentikasi biometrik, Anda akan diminta untuk memasukkan PIN yang digunakan untuk membuka kunci telepon.`,
        },
      },
      stepThree: {
        android: {
          select: 'Ceklis opsi',
          keepSigned: 'Tetap Masuk',
          desc: {
            head: 'Pilih kotak centang di sebelah',
            body: 'di layar yang menampilkan kode QR Anda pada komputer atau Portal untuk tetap masuk pada perangkat ini.',
          },
        },
      },
      stepFour: {
        android: {
          scan: 'Pindai',
          qrCode: 'Kode QR',
          below: 'di bawah ini',
        },
      },
      note: {
        headline: 'CATATAN:',
        body: `"Jika Anda tidak bisa memindai kode QR, pastikan Anda  sedang mengikuti WhatsApp multi device beta. Anda bisa melihat bagaimana untuk mengikuti multi device beta`,
        link: `disini`,
      },
    },
    testimonials: {
      title: 'Testimoni',
      headline: 'Testimoni',
      testimonialOneDesc:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      testimonialTwoDesc:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    },
    faq: {
      title: 'Tanya Jawab',
      subTitle: 'Kami bantu jawab pertanyaan yang banyak ditanyakan.',
      faqOne: {
        question: 'Apa itu Marketa Chat ?',
        answer:
          'Marketa Chat adalah bagian dari Marketa SuperApp untuk layanan Multi Chat dan Broadcast. Marketa Chat membawa semua customer anda lebih dekat dan cepat ke bisnis anda dengan mudah dan efisien.',
      },
      faqTwo: {
        question: 'Apa itu Marketa Chat App ?',
        answer:
          'Marketa SuperApp adalah platform untuk mengelola seluruh aktivitas Digital Marketing di multi channel seperti Google ads, Facebook ads, Instagram, Whatsapp dan Tiktok. Marketa hadir untuk mempermudah pebisnis mengembangkan bisnis di aspek digital marketing dan customer service.',
      },
      faqThree: {
        question: 'Mengapa Saya Butuh Marketa Chat ?',
        answer:
          'Di Marketa Chat anda dapat melakukan hal-hal yang tidak dapat anda lakukan di WA Bisnis. Layanan seperti Unlimited Broadcast, Smart Replay (Chat Bot), Statistic User dan Broadcast serta banyak fitur lainnya terintegrasi dalam satu tool. Dengan Marketa Chat anda akan memiliki akses ke fitur dan insight yang dapat membantu bisnis anda berkembang lebih cepat.',
      },
      faqFour: {
        question: 'Apakah Marketa Chat Perlu Diinstal di Gadget Saya ?',
        answer:
          'Tidak, Marketa adalah software/tool yang berbasis web yang hanya perlu Anda temukan di mesin pencari seperti Google saja, jadi Anda tidak perlu melakukan instalasi apapun di perangkat Anda.',
      },
      faqFive: {
        question: 'Saya hanya pemilik UMKM dan sedikit Gaptek, apakah Saya bisa mengoperasikan Marketa Chat ?',
        answer: 'BISA! Marketa Chat bisa Anda pakai semudah memakai WA personal Anda.',
      },
      faqSix: {
        question: 'Apakah Marketa Chat berbayar atau gratis ?',
        answer:
          'Anda memiliki kesempatan besar untuk mencoba GRATIS tool kami untuk merasakan manfaatnya, selanjutnya Anda bisa memilih harga yang cocok dengan kebutuhan bisnis Anda.',
      },

      // faqSeven: {
      //   question: 'Apakah ada garansi uang kembali / refund ?',
      //   answer: 'TIDAK ADA',
      // },
      // faqEight: {
      //   question: 'Jika nomor saya diblokir, Apakah bisa refund ?',
      //   answer:
      //     'Tidak ada tool pihak ketiga manapun yang 100% aman dari blokir WhatsApp. Pengguna aktif Marketa Chat ada 1.000+ orang setiap harinya. Jika Anda diblokir oleh pihak WhatsApp, tidak bisa refund.',
      // },
    },
  },
  eform: {
    addTo: 'Tambahkan ke',
    copy: 'Kopi E-form',
    add: 'Memulai formulir baru',
    contact: 'Kontak',
    broadcast: 'Broadcast',
    action: 'Aksi',
    answerCount: 'Jumlah Jawaban',
    selectedFields: 'Kolom Yang Dipilih',
    addToSurvey: 'Tunjukkan Di Menu Chat',
    addToQuickReplay: 'Tunjukkan Di Menu Balas Cepat',
    createdAt: 'Terbuat pada',
    updatedAt: 'Diperbarui pada',
    notFound: 'Belum ada formulir',
    notFoundAction: 'Buat formulir kustom untuk bisnis anda, pendapat, dan survey atau penilaian?',
    notFoundAction2: 'Buat semua itu mudah, buat dan bagikan kepada semua pelanggan anda',
    title: 'Judul Formulir',
    titlePlaceholder: 'Formulir tanpa judul',
    titleEmpty: 'Judul wajib diisi.',
    description: 'Deskripsi Formulir',
    descriptionPlaceholder: 'Deskripsi formulir',
    discard: 'Batalkan formulir ini?',
    notFoundById: 'eForm tidak ditemukan',
    copyUrlSurvey: 'Salin Tautan E-form',
    broadcastForm: 'Broadcast E-form',
    viewContact: 'Lihat Kontak',
    openStatistic: 'Buka Statistik',
    editEform: 'Edit E-form',
    deleteEform: 'Hapus E-form',
    openSurvey: 'Buka E-form',
    surveyAnalytics: 'Analitik Survey',
    savePdfSurvey: 'Simpan PDF E-form',
    deleteEformConfirm: 'Kamu yakin menghapus E-form ini?',
    deleteEformConfirm2: 'Hapus Penuh untuk menghapus hingga contact list yang tersambung',
  },
  label: {
    emptyList: `Anda Belum mempunyai label`,
    emptyLabel1: 'Buat penanda label untuk memudahkan',
    emptyLabel2: 'menyortir konten pesan, ruangan yang dipilih, bahkan status pesanan',
  },
  campaignTracker: {
    description: 'Buat link kustom, dan buat tim anda lebih mudah untuk menangani customer.',
  },
  waLogin: {
    description: 'Deskripsi Login by Wa',
  },
}
