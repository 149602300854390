export function getListInstance(state) {
  return state.listInstance
}

export function getListWorkspaces(state) {
  return state.listWorkspaces
}

export function getListUsers(state) {
  // return state.listUsers
  const noNullData = state.listUsers.filter(e => !!e)
  const noStringsData = noNullData.filter(e => typeof e === 'object')

  return noStringsData
}

export function isResetChannels(state) {
  return state.isResetChannels
}

export function getSelectedWorkspace(state) {
  return state.selectedWorkspace
}

export function getUserState(state) {
  return state.userState
}

export function getSelectedInstance(state) {
  return state.selectedInstance
}

export function getStatusInstance(state) {
  return state.statusInstance
}

export function getInstanceName(state) {
  return state.instanceName
}

export function getCurUid(state) {
  return state.curUid
}

export function getLoggedIn(state) {
  return state.isLoggedIn
}

export function getUser(state) {
  return state.user
}

export function getListInvoice(state) {
  return state.listInvoice
}

export function isSuccessSync(state) {
  return state.successSync
}

export function getListMeeting(state) {
  return state.listMeeting
}

export function getInstanceVals(state) {
  return state.instanceVals
}
