import conn from '@/plugins/axios-limit'
import { default as clientPocketBase } from '@/helpers/pocketbase'
import store from '@/store'

export async function checkStatusConnection({ commit, state }, id) {
  const endpoint = `${process.env.VUE_APP_WA_API_URL}status/${id}`

  return conn
    .get(endpoint)
    .then(async response => response.data.Success === 1)
    .catch(error => false)
}

export async function checkStatus({ commit, state }, id) {
  const endpoint = `${process.env.VUE_APP_WA_API_URL}status/${id}`

  return conn
    .get(endpoint)
    .then(async response => {
      commit('setInstanceName', '')
      var connectionStatus = 3

      if (response.data.Success && response.data.ConnectedNum) {
        commit('setInstanceName', response.data.Name)
        var connectionStatus = 1
      } else if (response.data.Msg.includes('Not Login')) {
        commit('setInstanceName', '')
        var connectionStatus = 0
      }

      commit('setStatusInstance', connectionStatus)
    })
    .catch(error => {
      commit('setInstanceName', '')
      commit('setStatusInstance', 3)
    })
}

export async function checkStatusMd({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_WA_SERVICES_URL}sessions/status/${obj.id}`

  return conn
    .get(endpoint)
    .then(async response => {
      //console.log('🚀 ~ file: actions.js ~ line 75 ~ checkStatusMd ~ response', response.data)

      // //console.log(response.data, obj, `INI OBJ`)
      if (response.data.success && response.data.data.status === 'authenticated') {
        commit('setInstanceName', response.data.name)
        commit('setStatusInstance', 1)
      } else {
        commit('setInstanceName', '')
        commit('setStatusInstance', 3)
      }

      // if (obj.ready) {
      //   commit('setInstanceName', '')
      //   let connectionStatus = 3

      //   if (response.data.message === 'CONNECTED') {
      //     connectionStatus = 1
      //     commit('setInstanceName', response.data.name)
      //   }

      //   commit('setStatusInstance', connectionStatus)
      // } else {
      //   commit('setStatusInstance', 3)

      //   if (response.data.message === 'DISCONNECTED') {
      //     commit('setStatusInstance', 3)
      //   }
      // }
    })
    .catch(error => {
      // console.log(error.response.data, obj, `INI ERR`)
      commit('setInstanceName', '')
      commit('setStatusInstance', 1)
    })
}

export async function deleteInstancePostgres({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}delete-channels/${obj.id}`

  return conn
    .get(endpoint)
    .then(async response => {
      // //console.log(response, ' == response')
    })
    .catch(error => {
      // console.log(error, obj, `INI ERR`)
      console.log(error, ' == error')
    })
}

export async function startConnection({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_WA_SERVICES_URL}start-connection/${obj.id}`

  return conn
    .get(endpoint)
    .then(async response => {
      // //console.log(response, ' == response')
    })
    .catch(error => {
      // console.log(error, obj, `INI ERR`)
      console.log(error, ' == error')
    })
}

export async function deleteChannelMd({ commit, state }, id) {
  const endpoint = `${process.env.VUE_APP_WA_SERVICES_URL}cancel-register/${id}`

  return conn
    .get(endpoint)
    .then(async response => {})
    .catch(error => {})
}

export async function checkChannelReady({ commit, state }, id) {
  const endpoint = `${process.env.VUE_APP_WA_SERVICES_URL}ready/${id}`

  try {
    const res = await conn.get(endpoint)
    if (res.data && res.data.success) {
      return true
    }
  } catch (err) {
    return false
  }

  return false
}

export async function resetStatus({ commit, state }, id) {
  const endpoint = `${process.env.VUE_APP_WA_API_URL}reset/${id}/0`

  return conn
    .get(endpoint)
    .then(async response => {})
    .catch(error => {
      console.log(error)
    })
}

export async function getVersions({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/settings/get-version`

  return conn
    .post(endpoint, obj, { headers: { Authorization: `Bearer ${obj.token}` } })
    .then(async response => {
      if (response.data.status) {
        return {
          status: true,
          data: response.data.data,
          errorMessage: '',
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error =>
      // to do check auth
      ({
        status: false,
        errorMessage: error,
      }),
    )
}

export async function deleteBulkInstancesById({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/delete-bulk-instances-by-id`

  return conn
    .post(endpoint, {
      db_name: obj.db_name,
      ids: obj.ids,
    })
    .then(async response => {
      if (response.data.status) {
        return {
          status: true,
          errorMessage: '',
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error =>
      // to do check auth
      ({
        status: false,
        errorMessage: error,
      }),
    )
}

export async function getAllInstances({ commit, state }) {
  // const endpoint = process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '') + 'superwa/couchdb/contact/all-contactData'
  let endpoint = `${process.env.VUE_APP_WA_SERVICES_URL}chats/allInstance`
  let instances = []
  const user = store.getters['auth/getUser']
  // try {
  return conn
    .post(endpoint, {
      email: user.email,
      subId: user.sub_id,
    })
    .then(response => {
      if (response.data.status) {
        instances = response.data.data
        const instancesData = {
          value: instances,
        }
        commit('setListInstance', instancesData)
        // console.log(instances, '<<ini test')

        return {
          status: true,
          errorMessage: '',
          value: instances,
        }
      } else {
        return {
          status: false,
          errorMessage: response.data.message,
        }
      }
    })
    .catch(error =>
      // to do check auth
      ({
        status: false,
        errorMessage: error,
      }),
    )
  // } catch (err) {
  //   console.log(err)
  // }
  // console.log(instances, endpoint, '<<ini test')
}
export async function getListInstances({ commit, state }, user) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/get-instances`
  commit('setListInstance', [])

  return conn
    .post(endpoint, {
      db_name: user.db_name,
    })
    .then(async response => {
      if (response.data.status) {
        commit('setListInstance', response.data.data)

        return {
          status: true,
          errorMessage: '',
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error =>
      // to do check auth
      ({
        status: false,
        errorMessage: error,
      }),
    )
}

export async function registerTokopediaShop({ commit, state }, payload) {
  const endpoint = `${process.env.VUE_APP_DB_TOKOPEDIA_URL}superwa/couchdb/tokopedia/register-tokopedia-shop`

  return conn
    .post(endpoint, payload)
    .then(async response => {
      return response.data
    })
    .catch(error =>
      // to do check auth
      ({
        status: false,
        errorMessage: error,
      }),
    )
}

export async function getContactList({ commit, state }, payload) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/postgres/contact-list/get-contact-lists`

  return conn
    .post(endpoint, payload)
    .then(async response => {
      return response.data
    })
    .catch(error => ({
      status: false,
      errorMessage: error,
    }))
}

// export async function createLoanTypes({ commit, state }, payload) {
//   const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/loan/create-loan-type`

//   return conn
//     .post(endpoint, payload)
//     .then(async response => {
//       return response.data
//     })
//     .catch(error => ({
//       status: false,
//       errorMessage: error,
//     }))
// }

// export async function getLoanTypes({ commit, state }, payload) {
//   const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/loan/list-loan-type`

//   return conn
//     .post(endpoint, payload)
//     .then(async response => {
//       return response.data
//     })
//     .catch(error => ({
//       status: false,
//       errorMessage: error,
//     }))
// }

// export async function deleteLoanType({ commit, state }, payload) {
//   const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/loan/delete-loan-type`

//   return conn
//     .post(endpoint, payload)
//     .then(async response => {
//       return response.data
//     })
//     .catch(error => ({
//       status: false,
//       errorMessage: error,
//     }))
// }

// export async function getLoanSubmissions({ commit, state }, payload) {
//   const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/loan/list-loan-submission`

//   return conn
//     .post(endpoint, payload)
//     .then(async response => {
//       return response.data
//     })
//     .catch(error => ({
//       status: false,
//       errorMessage: error,
//     }))
// }

// export async function createLoanSubmissions({ commit, state }, payload) {
//   const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/loan/create-loan-submission`

//   return conn
//     .post(endpoint, payload)
//     .then(async response => {
//       return response.data
//     })
//     .catch(error => ({
//       status: false,
//       errorMessage: error,
//     }))
// }

// export async function deleteLoanSubmissions({ commit, state }, payload) {
//   const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/loan/delete-loan-submission`

//   return conn
//     .post(endpoint, payload)
//     .then(async response => {
//       return response.data
//     })
//     .catch(error => ({
//       status: false,
//       errorMessage: error,
//     }))
// }

export async function registerFacebookPage({ commit, state }, payload) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/facebook/register-fb-page`

  return conn
    .post(endpoint, payload)
    .then(async response => {
      //console.log(response, 'ini res')

      return response.data
    })
    .catch(error =>
      // to do check auth
      ({
        status: false,
        errorMessage: error,
      }),
    )
}
export async function removerFacebookPage({ commit, state }, payload) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/facebook/remover-fb-page`

  return conn
    .post(endpoint, payload)
    .then(async response => {
      return response.data
    })
    .catch(error =>
      // to do check auth
      ({
        status: false,
        errorMessage: error,
      }),
    )
}
export async function deleteTokopediaShop({ commit, state }, payload) {
  const endpoint = `${process.env.VUE_APP_DB_TOKOPEDIA_URL}superwa/couchdb/tokopedia/delete-tokopedia-shop`

  return conn
    .post(endpoint, payload)
    .then(async response => {
      return response.data
    })
    .catch(error =>
      // to do check auth
      ({
        status: false,
        errorMessage: error,
      }),
    )
}

export async function sendTokopediaChat({ commit, state }, payload) {
  const endpoint = `${process.env.VUE_APP_DB_TOKOPEDIA_URL}superwa/couchdb/tokopedia/send-tokopedia-chat`

  return conn
    .post(endpoint, payload)
    .then(async response => {
      return response.data
    })
    .catch(error =>
      // to do check auth
      ({
        status: false,
        errorMessage: error,
      }),
    )
}

export async function sendFacebookChat({ commit, state }, payload) {
  const endpoint = `${process.env.VUE_APP_FB_SERVICES_URL}webhook/send-message`

  return conn
    .post(endpoint, payload.body, {
      params: payload.params,
    })
    .then(async response => {
      return response.data
    })
    .catch(err => {
      console.log(err, '<<< ADA ERROR SEND MESSAGE')

      return err
    })
}

export async function sendTwitterChat({ commit, state }, payload) {
  // //console.log('payload in actions :', payload)
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/twitter/send-message`

  return conn
    .post(endpoint, payload)
    .then(async response => {
      return response.data
    })
    .catch(error =>
      // to do check auth
      ({
        status: false,
        errorMessage: error,
      }),
    )
}

export async function sendWaCloudChat({ commit, state }, payload) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/wa-cloud/messages`

  return conn
    .post(endpoint, payload)
    .then(async response => {
      return response.data
    })
    .catch(error => console.log(error))
}

export async function sendAttachedWaCloud({ commit, state }, payload) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/wa-cloud/messages/attached`

  return conn
    .post(endpoint, payload)
    .then(async response => {
      return response.data
    })
    .catch(error => console.log(error))
}

export async function updateTokopediaShop({ commit, state }, payload) {
  const endpoint = `${process.env.VUE_APP_DB_TOKOPEDIA_URL}superwa/couchdb/tokopedia/update-tokopedia-shop`

  return conn
    .post(endpoint, payload)
    .then(async response => {
      return response.data
    })
    .catch(error =>
      // to do check auth
      ({
        status: false,
        errorMessage: error,
      }),
    )
}

export async function getTokopediaRoom({ commit, state }, payload) {
  const endpoint = `${process.env.VUE_APP_DB_TOKOPEDIA_URL}superwa/couchdb/tokopedia/get-tokopedia-room`

  return conn
    .post(endpoint, payload)
    .then(async response => {
      return response.data
    })
    .catch(error =>
      // to do check auth
      ({
        status: false,
        errorMessage: error,
      }),
    )
}

export async function getTokopediaChat({ commit, state }, payload) {
  const endpoint = `${process.env.VUE_APP_DB_TOKOPEDIA_URL}superwa/couchdb/tokopedia/get-tokopedia-chat`

  return conn
    .post(endpoint, payload)
    .then(async response => {
      return response.data
    })
    .catch(error =>
      // to do check auth
      ({
        status: false,
        errorMessage: error,
      }),
    )
}

export async function getListChatTokopedia({ commit, state }, payload) {
  const endpoint = `${process.env.VUE_APP_DB_TOKOPEDIA_URL}superwa/couchdb/tokopedia/get-list-chat`

  return conn
    .post(endpoint, payload)
    .then(async response => {
      return response.data
    })
    .catch(error =>
      // to do check auth
      ({
        status: false,
        errorMessage: error,
      }),
    )
}

export async function deleteInstance({ commit, state }, data) {
  const endpoint = `${process.env.VUE_APP_WA_SERVICES_URL}sessions/delete/${data}`

  return conn.delete(endpoint)
}

export async function deleteChats({ commit, state }, data) {
  const endpoint = `${process.env.VUE_APP_WA_SERVICES_URL}sessions/delete/chats/${data}`

  return conn.delete(endpoint)
}

export async function editNewInstance({ commit, state }, data) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/insert-instances`

  return conn
    .post(endpoint, {
      db_name: data.db_name,
      instances: data.instances,
    })
    .then(async response => {
      if (response.data.status) {
        commit('setSelectedInstance', data.instances)

        return {
          status: true,
          errorMessage: '',
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error =>
      // to do check auth
      ({
        status: false,
        errorMessage: error,
      }),
    )
}

export async function removeInstance({ commit, state }, idx) {
  commit('removeInstance', idx)
  commit('setSelectedInstance', null)
}

export async function saveListInstance({ commit, state }, listInstance) {
  commit('setListInstance', listInstance)
}

export async function addNewInstance({ commit, state }, selectedInstance) {
  if (!state.listInstance.find(x => x.id === selectedInstance.id)) {
    commit('addNewInstance', selectedInstance)
  }
  commit('setSelectedInstance', selectedInstance)

  const endpoint = `${process.env.VUE_APP_WA_API_URL}status/${selectedInstance.id}`

  return conn
    .get(endpoint)
    .then(async response => {
      const connectionStatus = response.data.Success === 1
      commit('setStatusInstance', connectionStatus)
      commit('setCurrentUid', uid())
    })
    .catch(error => {
      commit('setStatusInstance', false)
      commit('setCurrentUid', uid())
    })
}

export async function checkIsOnlyInbox({ commit, state }) {
  // to do check auth
  return state.user.role !== 'Admin' && state.user.view_assign_only
}

export async function checkAuth({ commit, state }) {
  // to do check auth
  return state.isLoggedIn
}

export async function checkAdmin({ commit, state }) {
  // to do check auth
  return state.user.role === 'Admin'
}

export async function checkSubscribe({ commit, state }) {
  // to do check auth
  const isSubscribe = state.user.package && state.user.expire_date && new Date().getTime() < state.user.expire_date

  // if (!isSubscribe) {
  // update is free trial
  //   const endpoint = `${process.env.VUE_APP_WA_API_URL}superwa/couchdb/subscriptions/update-is-free-trial`
  //   const body = {
  //     sub_id: state.user.sub_id,
  //     is_free_trial: false,
  //   }

  //   conn
  //     .post(endpoint, body)
  //     .then(async response => {})
  //     .catch(error => {
  //       console.log(error)
  //     })
  // }

  return isSubscribe
}

export async function checkFreeTrial({ commit, state }) {
  // to do check auth
  return state.user.is_free_trial
}

export async function isBasicOrMore({ state }) {
  if (state.user.package)
    return state.user.package === 'pro' || state.user.package === 'basic' || state.user.package === 'business'

  return false
}

export async function isProOrMore({ state }) {
  if (state.user.package) return state.user.package === 'pro' || state.user.package === 'business'

  return false
}

export async function isBusinessOrMore({ state }) {
  if (state.user.package) return state.user.package === 'business'

  return false
}

export async function isBusinessPlusOrMore({ state }) {
  if (state.user.package) return state.user.package === 'business_plus'

  return false
}

export async function isOperatorHaveAccess({ state }, subMenu) {
  if (state.user.limited_menu) {
    return state.user.enabled_menu && state.user.enabled_menu.includes(subMenu)
  }

  return true
}

export async function saveLogin({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/login`

  return conn
    .post(endpoint, obj)
    .then(async response => {
      if (response.data.status) {
        //console.log(response.data.data)
        commit('setLogin', true)
        commit('setUser', response.data.data)

        return {
          status: true,
          exist: response.data.isUserExists,
          errorMessage: '',
        }
      }
      commit('setLogin', false)

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      // to do check auth
      commit('setLogin', false)

      return {
        status: false,
        errorMessage: error,
      }
    })
}

export async function fetchUserWorkspace({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/get-list-sub-id`

  commit('setListWorkspaces', [])

  return conn
    .post(endpoint, obj)
    .then(async response => {
      if (response.data.status) {
        commit('setListWorkspaces', response.data.data)

        return {
          data: response.data.data,
          status: true,
          errorMessage: '',
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error =>
      // to do check auth
      ({
        status: false,
        errorMessage: error,
      }),
    )
}

export async function requestAutoAssign({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/settings/request-auto-assign`

  return conn
    .post(endpoint, obj, { headers: { Authorization: `Bearer ${obj.token}` } })
    .then(async response => {
      if (response.data.status) {
        return {
          status: true,
          errorMessage: '',
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error =>
      // to do check auth
      ({
        status: false,
        errorMessage: error,
      }),
    )
}

export async function setOnlineStatus({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/settings/online-status`

  return conn
    .post(endpoint, obj, { headers: { Authorization: `Bearer ${obj.token}` } })
    .then(async response => {
      if (response.data.status) {
        return {
          status: true,
          errorMessage: '',
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error =>
      // to do check auth
      ({
        status: false,
        errorMessage: error,
      }),
    )
}

export async function editUser({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/edit-workspace-name`

  return conn
    .post(endpoint, obj)
    .then(async response => {
      if (response.data.status) {
        return {
          status: true,
          errorMessage: '',
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error =>
      // to do check auth
      ({
        status: false,
        errorMessage: error,
      }),
    )
}

export async function editUserSubscription({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/edit-subscription-email`

  return conn
    .post(endpoint, obj)
    .then(async response => {
      if (response.data.status) {
        return {
          status: true,
          errorMessage: '',
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error =>
      // to do check auth
      ({
        status: false,
        errorMessage: error,
      }),
    )
}
export async function addNewRole({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/insert-role-settings`

  return conn
    .post(endpoint, obj)
    .then(async response => {
      if (response.data.status) {
        return {
          status: true,
          errorMessage: '',
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error =>
      // to do check auth
      ({
        status: false,
        errorMessage: error,
      }),
    )
}
export async function editRole({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/edit-role-settings`

  return conn
    .post(endpoint, obj)
    .then(async response => {
      if (response.data.status) {
        return {
          status: true,
          errorMessage: '',
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error =>
      // to do check auth
      ({
        status: false,
        errorMessage: error,
      }),
    )
}
export async function deleteRole({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/delete-role-settings`

  return conn
    .post(endpoint, obj)
    .then(async response => {
      if (response.data.status) {
        return {
          status: true,
          errorMessage: '',
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error =>
      // to do check auth
      ({
        status: false,
        errorMessage: error,
      }),
    )
}

export async function getChannelAccess({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/get-channels-access`

  return conn
    .post(endpoint, obj, { headers: { Authorization: `Bearer ${obj.token}` } })
    .then(async response => {
      if (response.data.status) {
        commit('setUserObj', {
          key: 'auto_assign',
          payload: response.data.data.auto_assign,
        })
        commit('setUserObj', {
          key: 'view_assign_only',
          payload: response.data.data.view_assign_only,
        })
        commit('setUserObj', {
          key: 'auto_assign_new_chat_setting',
          payload: response.data.data.auto_assign_new_chat_setting,
        })
        commit('setUserObj', {
          key: 'limited_channel',
          payload: response.data.data.limited_channel,
        })
        // commit('setUserObj', {
        //   key: 'chat_mode',
        //   payload: response.data.data.chat_mode,
        // })
        commit('setUserObj', {
          key: 'enabled_channel',
          payload: response.data.data.enabled_channel,
        })
        commit('setUserObj', {
          key: 'enabled_menu',
          payload: response.data.data.enabled_menu,
        })
        commit('setUserObj', {
          key: 'limited_menu',
          payload: response.data.data.limited_menu,
        })
        commit('setUserObj', {
          key: 'package',
          payload: response.data.data.package,
        })
        commit('setUserObj', {
          key: 'max_channel',
          payload: response.data.data.max_channel,
        })
        commit('setUserObj', {
          key: 'max_operator',
          payload: response.data.data.max_operator,
        })
        commit('setUserObj', {
          key: 'max_contact',
          payload: response.data.data.max_contact,
        })
        commit('setUserObj', {
          key: 'expire_date',
          payload: response.data.data.expire_date,
        })
        commit('setUserObj', {
          key: 'token',
          payload: response.data.data.token,
        })

        // commit('setUserObj', {
        //   key: 'email',
        //   payload: 'sisilmanurung@realtegic.co.id',
        // })

        return {
          status: true,
          errorMessage: '',
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error =>
      // to do check auth
      ({
        status: false,
        errorMessage: error,
      }),
    )
}

export async function addUser({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/add-subscription-email`

  return conn
    .post(endpoint, obj)
    .then(async response => {
      if (response.data.status) {
        return {
          status: true,
          errorMessage: '',
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error =>
      // to do check auth
      ({
        status: false,
        errorMessage: error,
      }),
    )
}

export async function deleteUser({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/delete-subscription-email`

  return conn
    .post(endpoint, obj)
    .then(async response => {
      if (response.data.status) {
        return {
          status: true,
          errorMessage: '',
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error =>
      // to do check auth
      ({
        status: false,
        errorMessage: error,
      }),
    )
}

export async function sendMessage({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/send-message-landing`
  const res = await conn.post(endpoint, obj)

  return res
}

export async function checkUserDemo({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/check-demo-user`
  const res = await conn.post(endpoint, obj)

  return res
}

export async function insertNewMeeting({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/meeting/insert`
  const res = await conn.post(endpoint, obj, { headers: { Authorization: `Bearer ${obj.token}` } })

  return res
}

export async function deleteMeeting({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/meeting/delete`
  const res = await conn.post(endpoint, obj, { headers: { Authorization: `Bearer ${obj.token}` } })

  return res
}

export async function getMeeting({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/meeting/get`
  const res = await conn.post(endpoint, obj, { headers: { Authorization: `Bearer ${obj.token}` } })

  if (res.data && res.data.status && res.data.data) {
    commit('setListMeeting', res.data.data.docs)
  }
}

export async function sendInvitationMeeting({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/meeting/send-invitation`
  const res = await conn.post(endpoint, obj, { headers: { Authorization: `Bearer ${obj.token}` } })

  return res
}

export async function fetchListUser({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/get-list-email`

  commit('setListUsers', [])

  return conn
    .post(endpoint, obj)
    .then(async response => {
      if (response.data.status) {
        commit('setListUsers', response.data.data)

        return {
          status: true,
          errorMessage: '',
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error =>
      // to do check auth
      ({
        status: false,
        errorMessage: error,
      }),
    )
}

export async function saveLoginRethink({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/login`

  return conn
    .post(endpoint, obj)
    .then(async response => {
      if (response.data.status) {
        commit('setLogin', true)
        commit('setUser', response.data.data)

        return {
          status: true,
          errorMessage: '',
        }
      }
      commit('setLogin', false)

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      // to do check auth
      commit('setLogin', false)

      return {
        status: false,
        errorMessage: error,
      }
    })
}

export async function logout({ commit, state }) {
  // to do check auth
  commit('setLogin', false)
  commit('setUser', {
    sub_id: null,
    name: null,
    email: null,
    image: null,
    role: null,
    package: null,
    limited_menu: false,
    limited_channel: false,
    enabled_channel: null,
    enabled_menu: null,
    max_operator: null,
    max_channel: null,
    max_contact: null,
    access: {
      channel: false,
      inbox: false,
      contacts: false,
      broadcast: false,
      templates: false,
      operators: false,
    },
    token: null,
    is_free_trial: false,
  })
  localStorage.clear()
  // localStorage.removeItem('vuex')
}

export async function importListContact({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_WA_API_URL}contacts/${obj.id}`

  return conn
    .get(endpoint)
    .then(async response => {
      if (response.data.Success === 0) {
        return {
          data: [],
          status: false,
        }
      }
      const contacts = []
      for (const res in response.data) {
        if (response.data[res].Notify || response.data[res].Name || response.data[res].Short) {
          let name = ''

          if (response.data[res].Name) {
            name = response.data[res].Name
          } else if (response.data[res].Notify) {
            name = response.data[res].Notify
          }
          if (name) {
            const phoneNumber = response.data[res].Jid
              ? response.data[res].Jid.substr(0, response.data[res].Jid.indexOf('@'))
              : ''
            contacts.push({
              _uid: obj.idlist + response.data[res].Jid,
              _id: obj.idlist + phoneNumber,
              id: obj.idlist + response.data[res].Jid,
              name,
              phone_number: phoneNumber,
              phone_number_show: phoneNumber.includes('-') || phoneNumber.length > 16 ? 'Group' : phoneNumber,
              status: 'Valid',
              last_reply: '',
              last_message: '',
              profile_picture: `${process.env.VUE_APP_WA_API_URL}ppic/${obj.id}/${phoneNumber}/a.jpg`,
            })
          }
        }
      }

      return {
        data: contacts,
        status: true,
      }
    })
    .catch(error => {
      console.log(error)

      return {
        data: [],
        status: false,
      }
    })
}

export async function importContact({ commit, state }, id) {
  const endpoint = `${process.env.VUE_APP_WA_API_URL}contacts/${id}`

  return conn
    .get(endpoint)
    .then(async response => {
      if (response.data.Success === 0) {
        return {
          data: [],
          status: false,
        }
      }
      const contacts = []
      for (const res in response.data) {
        if (response.data[res].Notify || response.data[res].Name || response.data[res].Short) {
          let name = ''

          if (response.data[res].Name) {
            name = response.data[res].Name
          } else if (response.data[res].Notify) {
            name = response.data[res].Notify
          }
          if (name) {
            const phoneNumber = response.data[res].Jid
              ? response.data[res].Jid.substr(0, response.data[res].Jid.indexOf('@'))
              : ''
            contacts.push({
              _uid: response.data[res].Jid,
              _id: phoneNumber,
              id: response.data[res].Jid,
              name,
              phone_number: phoneNumber,
              phone_number_show: phoneNumber.includes('-') || phoneNumber.length > 16 ? 'Group' : phoneNumber,
              status: 'Valid',
              last_reply: '',
              last_message: '',
              profile_picture: `${process.env.VUE_APP_WA_API_URL}ppic/${id}/${phoneNumber}/a.jpg`,
            })
          }
        }
      }

      return {
        data: contacts,
        status: true,
      }
    })
    .catch(error => {
      console.log(error)

      return {
        data: [],
        status: false,
      }
    })
}

export async function checkTokenLife({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/check-token`

  return conn
    .post(endpoint, {}, { headers: { Authorization: `Bearer ${obj.token}` } })
    .then(async response => ({
      statusCode: response.status,
      status: false,
      errorMessage: response.data.message,
    }))
    .catch(error => {
      if (error.response) {
        return {
          statusCode: error.response.status,
          status: false,
          errorMessage: error,
        }
      }

      return {
        statusCode: 500,
        status: false,
        errorMessage: 'internal server error',
      }
    })
}

export async function createOtpCode({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/otp-login/create-code`

  // const endpoint = `https://4911-103-138-42-137.ngrok.io/superwa/couchdb/otp-login/send-code`

  // const endpoint = `https://4911-103-138-42-137.ngrok.io/superwa/couchdb/otp-login/send-code`
  return conn
    .post(endpoint, obj)
    .then(async response => {
      if (response.data.status) {
        return response
      }
    })
    .catch(async err => {
      return err
    })
}

export async function verifyCodeBeforeSend({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/otp-login/verify-code`

  // const endpoint = `https://4911-103-138-42-137.ngrok.io/superwa/couchdb/otp-login/send-code`
  return conn
    .post(endpoint, obj)
    .then(async response => {
      if (response.data.status) {
        return response
      }
    })
    .catch(async err => {
      return err
    })
}

export async function verifyOtpCode({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/otp-login/verification`

  // const endpoint = `https://4911-103-138-42-137.ngrok.io/superwa/couchdb/otp-login/verification`

  return conn
    .post(endpoint, obj)
    .then(async response => {
      if (response.data.status) {
        return response
      }
    })
    .catch(async err => {
      return err
    })
}

export async function sendOtpMessage({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/otp-login/send-otp-message`

  return conn
    .post(endpoint, obj)
    .then(async response => {
      if (response.data.status) {
        return response
      }
    })
    .catch(async err => {
      return err
    })
}
