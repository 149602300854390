/* eslint-disable */
import conn from '@/plugins/axios-limit'

// Loan submission
export async function getLoanSubmissions({ commit }, payload) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/loan/list-loan-submission`

  return conn
    .post(endpoint, payload)
    .then(async response => {
      commit('setLoanSubs', response.data.data)

      return response.data
    })
    .catch(error => ({
      status: false,
      errorMessage: error,
    }))
}
export async function getEmployeeLoanSubmissionsWithNetOff({ commit, state }, payload) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/loan/list-employee-loan-submissions-with-net-on`

  return conn
    .post(endpoint, payload)
    .then(async response => {
      //console.log(response)

      return response.data
    })
    .catch(error => ({
      status: false,
      errorMessage: error,
    }))
}
export async function createLoanSubmissions({ commit, state }, payload) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/loan/create-loan-submission`
  //console.log(payload, 'payloadpayload')

  return conn
    .post(endpoint, payload)
    .then(response => {
      //console.log(response, 'daya')
      commit('setLoanSubs', [...state.loanSubs, response.data.loanSub])

      return response.data
    })
    .catch(error => ({
      status: false,
      errorMessage: error,
    }))
}
export async function editLoanSubmissions(_, payload) {
  //console.log(payload, 'idLoanSub')
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/loan/update-loan-submission`
  const body = { sub_id: payload.sub_id, input_data: payload.input_data }

  try {
    const { data: res } = await conn.post(endpoint, body)
    if (!res.status) {
      throw res
    }

    return
  } catch (error) {
    console.log(error)
  }
}
export async function deleteLoanSubmissions({ commit, state }, payload) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/loan/delete-loan-submission`

  return conn
    .post(endpoint, payload)
    .then(async response => {
      return response.data
    })
    .catch(error => ({
      status: false,
      errorMessage: error,
    }))
}
export async function getLoanSubmissionsNeedMyApproval({ commit }, payload) {
  //console.log('entering getLoanSubmissionsNeedMyApproval')
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/loan/list-loan-submission-need-my-approval`

  return conn
    .post(endpoint, payload)
    .then(response => {
      commit('setLoanSubsNeedMyApproval', response.data.data)
    })
    .catch(error => ({
      status: false,
      errorMessage: error,
    }))
}
export async function getLoanSubById({ commit }, payload) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/loan/get-loan-submission`

  return conn
    .post(endpoint, payload)
    .then(async response => {
      commit('setLoanSub', response.data.data)

      return response.data
    })
    .catch(error => ({
      status: false,
      errorMessage: error,
    }))
}

// Loan type start
export async function createLoanTypes({ commit, state }, payload) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/loan/create-loan-type`

  return conn
    .post(endpoint, payload)
    .then(async response => {
      commit('setLoanTypes', [...state.loanTypes, response.data.loanType])

      return response.data
    })
    .catch(error => ({
      status: false,
      errorMessage: error,
    }))
}

export async function updateLoanTypes({ commit, state }, payload) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/loan/update-loan-type`

  const body = { sub_id: payload.sub_id, input_data: payload.input_data }
  //console.log(payload, 'payload')
  try {
    const { data: res } = await conn.post(endpoint, body)
    if (!res.status) {
      throw res
    }

    return
  } catch (error) {
    console.log(error)
  }
}

export async function getLoanTypes({ commit, state }, payload) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/loan/list-loan-type`

  return conn
    .post(endpoint, payload)
    .then(async response => {
      commit('setLoanTypes', response.data.loanType)

      return response.data
    })
    .catch(error => ({
      status: false,
      errorMessage: error,
    }))
}

export async function getLoanTypeByID({ commit }, payload) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/loan/get-loan-type`

  return conn
    .post(endpoint, payload)
    .then(async response => {
      // commit('setLoanSubs', response.data.data)

      return response.data
    })
    .catch(error => ({
      status: false,
      errorMessage: error,
    }))
}

export async function deleteLoanType({ commit, state }, payload) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/loan/delete-loan-type`

  return conn
    .post(endpoint, payload)
    .then(async response => {
      return response.data
    })
    .catch(error => ({
      status: false,
      errorMessage: error,
    }))
}

export async function getBillingByLoanId({ commit, state }, payload) {
  try {
    const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/loan/billings/byIdLoan/${payload.loanId}`
    const { data: res } = await conn.get(endpoint)
    commit('setBillingByLoanId', res.billings)
  } catch (error) {
    console.log(error, 'error')
  }
}

export async function getBillingById({ commit, state }, payload) {
  try {
    const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/loan/billings/byIdBilling/${payload.idBilling}`
    const { data: res } = await conn.get(endpoint)
    commit('setBillingById', res.billing)
  } catch (error) {
    console.log(error, 'error')
  }
}

export async function createBilling({ commit, state }, payload) {
  try {
    //console.log(payload, 'payload')
    const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/loan/billings-create`
    const body = payload.data
    const { data: res } = await conn.post(endpoint, body)
    commit('setBillingByLoanId', [...state.billingByLoanId, res.createdBilling])
  } catch (error) {
    console.log(error, 'error')
  }
}

export async function getUserOrderingById({ commit, state }, payload) {
  //console.log(payload, 'payload')
  try {
    const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/happy-home-directus/get-transaction-byId/${payload.transactionId}`
    const { data: res } = await conn.post(endpoint)
    //console.log(res, 'ressss')
    commit('setTransaction', res.order)
  } catch (error) {
    console.log(error)
  }
}
