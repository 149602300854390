export const SET_SELECTED_CONTACTS = (state, payload) => {
  state.selectedContacts = payload
}
export const SET_PERSONALIZED_CHANNELS_FROM_DATABASE = (state, payload) => {
  state.personalizedChannels = payload
}

export const SET_ALL_CAMPAIGNS = (state, payload) => {
  state.allCampaigns = payload.data
}

export const SET_CAMPAIGNS_APPROVAL = (state, payload) => {
  state.campaignApproval = payload
}

export const SET_SPECIFIED_CAMPAIGNS = (state, payload) => {
  state.specifiedCampaign = payload.data
}

export const SET_ID_HAS_ACCESSED_MARKETING_CALENDAR_BEFORE = (state, payload) => {
  state.ListIDHasAccessedMarketingCalendarBefore.push(payload)
}

export const setScoring = (state, payload) => {
  state.scoring = payload
}
