export function getEmployeeGroups(state) {
  return state.employeeGroups
}
export function getCurrentEmployeeGroup(state) {
  return state.currentEmployeeGroup
}
export function getEmployeesFromEmployeeGroup(state) {
  return state.employeesFromEmployeeGroup
}
export function getCurrentEmployee(state) {
  return state.currentEmployee
}
export function getDisbursementList(state) {
  return state.disbursementList
}
export function getCurrentDisbursement(state) {
  return state.currentDisbursement
}
export function getCurrentDisbursementDetail(state) {
  return state.currentDisbursementDetail
}
export function getCurrentProject(state) {
  return state.currentProject
}
export function getEmployeeDetails(state) {
  return state.employeeDetails
}
