<template>
  <v-app
    :class="[
      { 'nav-drawer-mini': menuIsVerticalNavMini },
      { 'nav-drawer-mini-mobile': menuIsVerticalNavMini && $vuetify.breakpoint.smAndDown },
      { 'content-full': appContentWidth === 'full' },
    ]"
    class="content-layout vertical-nav"
  >
    <pre style="display: none">{{ allRooms }}</pre>
    <v-navigation-drawer
      v-if="!menuIsMenuHidden"
      ref="layoutDrawer"
      v-model="isVerticalNavMenuActive"
      app
      :right="$vuetify.rtl"
      mini-variant-width="68"
      :expand-on-hover="menuIsVerticalNavMini"
      width="260"
      class="app-navigation-menu"
      style="z-index: 11"
    >
      <vertical-nav-menu :nav-menu-items="navMenuItems" @close-nav-menu="isVerticalNavMenuActive = false" />
    </v-navigation-drawer>

    <!-- TODO: All elevations are to bottom and footer requires shadow on top -->
    <v-app-bar
      v-if="appBarType !== 'hidden'"
      ref="refAppBar"
      app
      :absolute="appBarType === 'static'"
      flat
      :elevate-on-scroll="appBarType !== 'static'"
      :elevation="appBarType !== 'static' ? ($vuetify.theme.isDark ? 4 : 3) : 0"
      class="mx-auto app-bar-static height-custom-layout"
      :class="[
        { 'px-5 app-bar-shinked': appBarType === 'fixed' && scrollY },
        { 'bg-blur': appBarIsBlurred && appBarType === 'fixed' && scrollY },
      ]"
    >
      <slot
        name="navbar"
        :isVerticalNavMenuActive="isVerticalNavMenuActive"
        :toggleVerticalNavMenuActive="toggleVerticalNavMenuActive"
      ></slot>
    </v-app-bar>
    <pre style="display: none">{{ currentRouteName }}</pre>
    <div
      v-if="statusBanner && currentRouteName !== 'inbox' && currentRouteName !== 'inbox-phone-number'"
      :style="{
        height: height,
      }"
    ></div>
    <!-- <div
      v-if="statusChannelsBanner && currentRouteName !== 'inbox' && currentRouteName !== 'inbox-phone-number' && currentRouteName !== 'subscriptions' && currentRouteName !== 'call-center' && currentRouteName !== 'message-template' && currentRouteName !== 'broadcast-statistic' && currentRouteName !== 'label-list' && currentRouteName !== 'subscriptions' && currentRouteName !== 'subscriptions-plan' && currentRouteName !== 'billings' && currentRouteName !== 'invoice' && currentRouteName !== 'operators-list' && currentRouteName !== 'replies-history' && currentRouteName !== 'operator-statistic' && currentRouteName !== 'invoice-page' && currentRouteName !== 'app-settings' && currentRouteName !== 'sheet-editor'"
      :style="{
        height: height,
      }"
    ></div> -->
    <div
      v-if="statusServerConnection && currentRouteName !== 'inbox' && currentRouteName !== 'inbox-phone-number'"
      :style="{
        height: height,
      }"
    ></div>

    <!-- <slot name="v-app-content"></slot> -->

    <v-main>
      <pre style="display: none">{{ currentRouteName }}</pre>
      <app-content-container
        v-if="
          currentRouteName !== 'inbox' &&
          currentRouteName !== 'chat-single' &&
          currentRouteName !== 'inbox-phone-number' &&
          currentRouteName !== 'e-ordering' &&
          currentRouteName !== 'e-shop-order-list'
        "
      >
        <div :class="`mobile-check-header ${showHeader === false ? 'active' : ''}`" style="">
          <slot> </slot>
        </div>
      </app-content-container>
      <!-- :style="`background: linear-gradient(180deg, ${currentShop.primaryColor} 22.67%, ${$vuetify.theme.isDark === true ? '#696969' : 'white'} 100%);`" -->

      <app-content-container
        v-if="
          (currentRouteName !== 'inbox' &&
            currentRouteName !== 'inbox-phone-number' &&
            currentRouteName !== 'chat-single' &&
            currentRouteName === 'e-ordering') ||
          currentRouteName === 'e-shop-order-list'
        "
        :style="$vuetify.theme.isDark === true ? `background: #222222;` : `background: #F8F9F9;`"
      >
        <div :class="`mobile-check-header ${showHeader === false ? 'active' : ''}`" style="">
          <slot> </slot>
        </div>
      </app-content-container>

      <div
        v-if="
          currentRouteName === 'inbox' ||
          (currentRouteName === 'inbox-phone-number' && currentRouteName !== 'chat-single')
        "
        :class="`mobile-check-header ${showHeader === false ? 'active' : ''}`"
        style=""
      >
        <slot> </slot>
      </div>

      <div
        v-if="currentRouteName === 'chat-single'"
        :class="`mobile-check-header mt-2 ${showHeader === false ? 'active' : ''}`"
        style=""
      >
        <slot> </slot>
      </div>
    </v-main>
    <pre style="display: none">{{ currentRouteName }}</pre>
    <!-- <div
      v-if="currentRouteName !== 'inbox'"
      :class="isExpanded ? 'floating-chat-expanded' : 'floating-chat'"
      class="d-flex justify-end mb-8 me-8"
    >
      <v-card width="420px">
        <v-card-title @click="expandChat">
          <v-row>
            <v-col class="mt-n2" cols="3">
              <v-btn class="justify-end" dense icon :ripple="false">
                <v-icon>
                  {{ isExpanded ? icons.mdiChevronDown : icons.mdiChevronUp }}
                </v-icon>
              </v-btn>
            </v-col>
            <v-col class="mt-0 my-auto" cols="5">
              <div class="ms-14">
    <span class="my-auto text-h6 font-weight-bold"> Chat </span>
              </div>
            </v-col>
            <v-col cols="2">
              <v-icon v-if="isNewChat" color="primary" size="32">
                {{ icons.mdiAlertBox }}
              </v-icon>
            </v-col>
            <v-col cols="2" class="d-flex mt-n2 justify-end">
              <v-btn class="" dense icon :ripple="false" @click="openFullChat">
                <v-icon>
                  {{ icons.mdiArrowExpandAll }}
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <div v-show="isExpanded" class="x-chat-window-padding">
            <FloatingChat :on-chat-menu="currentRouteName === 'inbox' ? true : false" />
          </div>
        </v-card-text>
      </v-card>
    </div> -->
    <v-overlay
      :value="$store.state.app.shallContentShowOverlay"
      z-index="4"
      absolute
      class="content-overlay"
    ></v-overlay>
    <div
      v-if="
        currentRouteName !== 'inbox' && currentRouteName !== 'chat-single' && currentRouteName !== 'inbox-phone-number'
      "
    >
      <v-footer
        v-if="footerType !== 'hidden'"
        app
        inset
        :absolute="footerType === 'static'"
        padless
        :class="{ 'mx-auto': appContentWidth !== 'full' }"
        :color="footerType === 'static' ? 'transparent' : null"
      >
        <div :class="{ 'px-5': footerType === 'fixed' }" class="py-4 w-full">
          <slot name="footer"></slot>
        </div>
      </v-footer>
    </div>
  </v-app>
</template>

<script>
import { getVuetify } from '@/@core/utils'

// import { db } from '@/helpers/db'
// import FloatingChat from '@/views/chat/FloatingChat.vue'
import useAppConfig from '@core/@app-config/useAppConfig'
import AppContentContainer from '@core/layouts/components/app-content-container/AppContentContainer.vue'
import VerticalNavMenu from '@core/layouts/components/vertical-nav-menu/VerticalNavMenu.vue'
import { mdiAlertBox, mdiArrowExpandAll, mdiChevronDown, mdiChevronUp } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'
import { useWindowScroll } from '@vueuse/core'

// import { useObservable } from '@vueuse/rxjs'
// import { liveQuery } from 'dexie'

export default {
  components: {
    AppContentContainer,
    VerticalNavMenu,
    // FloatingChat,
  },
  props: {
    navMenuItems: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      height: 0,
      isExpanded: false,
      icons: { mdiChevronDown, mdiAlertBox, mdiChevronUp, mdiArrowExpandAll },
      showedRooms: [],
      instances: [],
    }
  },
  computed: {
    allRooms() {
      if (this.showedRooms.length) {
        // //console.log(
        //   '🚀 ~ file: LayoutContentVerticalNavCustom.vue ~ line 276 ~ allRooms ~ allRooms',
        //   this.showedRooms.value.filter(room => {
        //     if (this.user.chat_mode === 'Read and Write to assigned only') {
        //       if (
        //         (!room.assign_to || room.assign_to.email === this.user.email) &&
        //         room.last_message > 0 &&
        //         room.unread_count > 0 &&
        //         this.instances.findIndex(instance => instance._id === room.instance_id) > -1
        //       ) {
        //         //console.log('🚀 ~ file: LayoutContentVerticalNavCustom.vue ~ line 290 ~ allRooms ~ true', room)

        //         return true
        //       }

        //       return false
        //     }

        //     if (
        //       this.user.chat_mode !== 'Read and Write to assigned Only' &&
        //       room.last_message > 0 &&
        //       room.unread_count > 0 &&
        //       this.instances.findIndex(instance => instance._id === room.instance_id) > -1
        //     ) {
        //       return true
        //     }

        //     return false
        //   }),
        // )

        // //console.log(this.showedRooms, 'lah lu nanya')

        return this.showedRooms.filter(room => {
          // //console.log(
          //   room.instance,
          //   this.instances,
          //   this.instances.findIndex(instance => this.$Md5Hash(instance._id) === room.instance),
          //   'ini instancesssea',
          // )
          if (this.user.chat_mode === 'Read and Write to assigned only') {
            if (
              (!room.assign_to || room.assign_to.email === this.user.email) &&
              new Date(room.last_message).getTime() > 0 &&
              room.unread_count > 0 &&
              (this.instances.findIndex(instance => this.$Md5Hash(instance._id) === room.instance) > -1 ||
                this.instances.findIndex(instance => instance.id === room.instance) > -1)
            ) {
              // //console.log('🚀 ~ file: LayoutContentVerticalNavCustom.vue ~ line 290 ~ allRooms ~ true', room)

              return true
            }

            return false
          }

          if (
            (this.user.chat_mode !== 'Read and Write to assigned Only' &&
              new Date(room.last_message).getTime() > 0 &&
              room.unread_count > 0) ||
            this.instances.findIndex(instance => this.$Md5Hash(instance._id) === room.instance) > -1 ||
            this.instances.findIndex(instance => instance.id === room.instance) > -1
          ) {
            //console.log('masuk yea')

            return true
          }

          return false
        }).length
      }

      return 0
    },
    isNewChat() {
      return this.$store.getters['inbox/getNewChat']
    },
    currentShop() {
      return this.$store.getters['eOrdering/getCurrentShop']
    },
    getStyleHeight() {
      return this.$store.getters['inbox/getStyleHeight']
    },
    statusBanner() {
      return !this.$store.getters['auth/isSuccessSync']
    },
    user() {
      return this.$store.getters['auth/getUser']
    },
    statusChannelsBanner() {
      return this.$store.getters['global/getStatusChannels']
    },
    statusServerConnection() {
      return !this.$store.getters['global/getServerConnectionStatus']
    },
    currentRouteName() {
      // //console.log(this.$route.name, 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaa')

      return this.$route.name
    },
    showRoomsList() {
      return this.$store.getters['inbox/getShowRoomsList']
    },
    showHeader() {
      if (this.$vuetify.breakpoint.mdAndDown === true && this.showRoomsList === false) {
        this.$eventBus.$emit('mobile-check-header', false)

        return false
      }

      // //console.log(`ini break`, this.$vuetify.breakpoint.mdAndDown, `ini show room`, this.showRoomsList)
      this.$eventBus.$emit('mobile-check-header', true)

      return true
    },
    // instances() {
    //   if (this.$store.getters['auth/getInstances'].length !== 0) {
    //     return this.$store.getters['auth/getInstances'].value
    //   }

    //   return []

    //   // return this.$store.getters['auth/getListInstance']
    // },
  },
  watch: {
    async isNewChat(val) {
      // //console.log(val, this.isExpanded, 'ini ketrigger gak<<<')
      try {
        let queryInstance = ''
        // for (let i = 0; i < this.instances.length; i++) {
        //   const instance = this.instances[i]
        //   if (i === 0) {
        //     if (instance.type === 'chatwoot') {
        //       queryInstance += `instance = '${instance.id}' && inbox_id = '${instance.inbox_id}'`
        //     } else {
        //       queryInstance += `instance = '${instance.id}'`
        //     }
        //   } else if (instance.type === 'chatwoot') {
        //     queryInstance += `|| instance = '${instance.id}' && inbox_id = '${instance.inbox_id}'`
        //   } else {
        //     queryInstance += `|| instance = '${instance.id}'`
        //   }
        // }
        // this.showedRooms = await clientPocketBase.collection('crm_contact').getFullList(200, {
        //   filter: `type ~ 'rooms' && unread_count > 0 && subId = '${this.user.sub_id}' && (${queryInstance}) `,
        //   $autoCancel: false,
        // })
        // console.log(this.showedRooms, 'ini dia<<<')
      } catch (err) {
        console.log(err)
      }
      if (val && this.isExpanded) {
        this.$store.commit('inbox/setNewChat', false)
      }

      // else if (val && !this.isExpanded) {this.$store.commit('inbox/setNewChat', true)}
    },
    getStyleHeight(data) {
      this.height = `${data}px`
    },
  },
  async mounted() {
    // client.realtime.subscribe('contact', e => this.eventUpdatePocketBase(e))

    const data = this.$store.getters['inbox/getStyleHeight']
    this.height = `${data}px`
    try {
      const instances = await this.$store.dispatch('auth/getAllInstances')
      this.instances = instances?.value || []
      // await clientPocketBase.admins.authViaEmail(process.env.VUE_APP_POCKETBASE_USER, process.env.VUE_APP_POCKETBASE_PASS)
      // try {

      // } catch (err) {
      //   console.log(err,'error auth sumber')
      // }
      let queryInstance = ''

      ///matiin dulu gatau apaan ini
      // for (let i = 0; i < this.instances.length; i++) {
      //   const instance = this.instances[i]
      //   if (i === 0) {
      //     if (instance.type === 'chatwoot') {
      //       queryInstance += `instance = '${instance.id}' && inbox_id = '${instance.inbox_id}'`
      //     } else {
      //       queryInstance += `instance = '${instance.id}'`
      //     }
      //   } else if (instance.type === 'chatwoot') {
      //     queryInstance += `|| instance = '${instance.id}' && inbox_id = '${instance.inbox_id}'`
      //   } else {
      //     queryInstance += `|| instance = '${instance.id}'`
      //   }
      // }
      // this.showedRooms = await clientPocketBase.collection('crm_contact').getFullList(200, {
      //   filter: `type ~ 'rooms' && unread_count > 0 && subId = '${this.user.sub_id}' && (${queryInstance}) `,
      //   $autoCancel: false,
      // })
    } catch (error) {
      console.log(error.isAbort, error.status, error.url, 'failed sumber create mounted')
    }

    // this.instances.forEach(instance => {
    //   //console.log(this.$Md5Hash(instance._id))
    // })
    // //console.log(
    //   '🚀 ~ file: LayoutContentVerticalNavCustom.vue ~ line 410 ~ mounted ~ this.showedRooms',
    //   this.showedRooms,
    // )
    // window.addEventListener('resize', () => {
    //   // doc = document.getElementsByClassName('height-costum-alert-inbox')[0]

    //   // if (doc) {
    //   //   this.$store.commit('inbox/setStyleHeight2', doc.clientHeight)
    //   // }
    //   this.$store.commit('inbox/setBannerAlert', this.statusChannelsBanner)
    //   this.setAlertChannelText()
    // })
    // this.$store.commit('inbox/setBannerAlert', this.statusChannelsBanner)
    // this.setAlertChannelText()
  },
  methods: {
    openFullChat() {
      this.$router.push({ name: 'inbox' })
    },
    expandChat() {
      this.$store.commit('inbox/setNewChat', false)

      if (!this.isExpanded) this.isExpanded = true
      else this.isExpanded = false
    },
  },
  setup() {
    /* eslint-disable object-curly-newline, operator-linebreak */
    const { menuIsVerticalNavMini, menuIsMenuHidden, appBarType, appBarIsBlurred, footerType, appContentWidth } =
      useAppConfig()
    const $vuetify = getVuetify()
    const isVerticalNavMenuActive = ref(true)

    // TODO: Check do we need below watch
    watch(
      () => $vuetify?.breakpoint.mdAndDown,
      value => {
        isVerticalNavMenuActive.value = !value
      },
      {
        immediate: true,
      },
    )

    const toggleVerticalNavMenuActive = () => {
      isVerticalNavMenuActive.value = !isVerticalNavMenuActive.value
    }

    const { y: scrollY } = useWindowScroll()

    return {
      isVerticalNavMenuActive,
      toggleVerticalNavMenuActive,
      menuIsVerticalNavMini,
      menuIsMenuHidden,
      appBarType,
      appBarIsBlurred,
      footerType,
      appContentWidth,
      scrollY,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/layouts/styles/_variables';

.app-content-container {
  padding: $content-padding-vertical-navigation-menu;
}

.x-chat-window-padding {
  margin-left: -20px;
  margin-right: -20px;
}

.floating-chat-expanded {
  position: fixed;
  width: 420px;
  height: 85vh;
  bottom: 400px;
  right: -9px;
  top: 16vh;
  z-index: 10000;
  cursor: pointer;
}

.floating-chat {
  position: fixed;
  width: 420px;
  height: 8vh;
  bottom: 400px;
  right: -9px;
  top: 92.5vh;
  z-index: 999999999;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .floating-chat {
    position: fixed;
    width: 100vw;
    height: 8vh;
    bottom: 400px;
    /* right: 232vh; */
    top: 92.5vh;
    z-index: 999999999;
    cursor: pointer;
    left: 0vh;
  }

  .floating-chat-expanded {
    position: fixed;
    width: 100vw;
    height: 95vh;
    bottom: 400px;
    /* right: -9px; */
    top: 7vh;
    z-index: 1000;
    cursor: pointer;
    left: 0vh;
  }
}

@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, 'background');
}

// Vuetify Fix
// https://github.com/vuetifyjs/vuetify/issues/13327
$nav-drawer-mini-width: 68px;
.height-custom-layout {
  height: auto !important;
  min-height: 64px !important;
  //display: flex;
  //flex-flow: row wrap;
}
.v-application {
  .v-main,
  .v-footer {
    transition-duration: 0.3s;
  }

  .v-footer {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  @include theme(v-footer) using ($material) {
    // Elevation 3 with -y
    &.v-footer--fixed {
      box-shadow: 0 -4px 8px -4px rgba(map-deep-get($material, 'shadow-color'), 0.42) !important;
    }
  }

  &.nav-drawer-mini {
    .v-main {
      // We haven't used `ltr` & `rtl` mixin because those doesn't work with top-level selectors: https://github.com/vuetifyjs/vuetify/issues/13987
      @at-root {
        .nav-drawer-mini {
          &.v-application--is-ltr {
            .v-main {
              padding-left: $nav-drawer-mini-width !important;
            }
          }
          &.v-application--is-rtl {
            .v-main {
              padding-right: $nav-drawer-mini-width !important;
            }
          }
        }
      }
    }
    @media #{map-get($display-breakpoints, 'lg-and-up')} {
      .app-navigation-menu ~ .v-footer,
      .app-navigation-menu + .v-app-bar {
        left: $nav-drawer-mini-width !important;
      }
    }
  }

  &.nav-drawer-mini-mobile {
    .v-main {
      // We haven't used `ltr` & `rtl` mixin because those doesn't work with top-level selectors: https://github.com/vuetifyjs/vuetify/issues/13987
      @at-root {
        .nav-drawer-mini-mobile {
          &.v-application--is-ltr {
            .v-main {
              padding-left: 0 !important;
            }
          }
          &.v-application--is-rtl {
            .v-main {
              padding-right: 0 !important;
            }
          }
        }
      }
    }
    @media #{map-get($display-breakpoints, 'lg-and-up')} {
      .app-navigation-menu ~ .v-footer,
      .app-navigation-menu + .v-app-bar {
        left: $nav-drawer-mini-width !important;
      }
    }
  }

  .v-app-bar,
  .v-footer {
    max-width: calc(1440px - (1.5rem * 2));
    @media screen and (max-width: 1456px) {
      margin-left: 1.5rem !important;
      margin-right: 1.5rem !important;
    }

    @at-root .v-application {
      &.content-full {
        .v-footer,
        .v-app-bar {
          max-width: unset;
          margin-left: $content-padding-vertical-navigation-menu !important;
          margin-right: $content-padding-vertical-navigation-menu !important;
        }
      }

      &:not(.nav-drawer-mini) {
        @media screen and (max-width: 1711px) {
          .app-navigation-menu ~ .v-footer,
          .app-navigation-menu + .v-app-bar {
            margin-left: 1.5rem !important;
            margin-right: 1.5rem !important;
          }
        }
      }
      &.nav-drawer-mini {
        @media screen and (max-width: 1523px) {
          .v-footer,
          .v-app-bar {
            margin-left: 1.5rem !important;
            margin-right: 1.5rem !important;
          }
        }
      }
    }
  }

  .v-app-bar {
    border-radius: 0 0 10px 10px !important;
    z-index: 5;

    &.v-toolbar:not(.app-bar-shinked) {
      background-color: transparent;
    }

    &.app-bar-static {
      will-change: padding, background-color;
      transition: padding 0.2s ease, background-color 0.18s ease, left 0.3s ease;

      &.v-toolbar.v-sheet:not(.v-app-bar--is-scrolled) {
        box-shadow: none !important;
      }

      ::v-deep > .v-toolbar__content {
        padding: 0;
        height: auto !important;
        min-height: 64px;
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
      }
    }
  }
}

@include theme(v-app-bar) using ($material) {
  &.v-toolbar.app-bar-shinked {
    background-color: map-deep-get($material, 'cards');
  }
}

.v-application.content-layout {
  @media #{map-get($display-breakpoints, 'md-and-down')} {
    .v-main,
    .v-footer,
    .v-app-bar {
      max-width: unset;
      left: 0 !important;
      @include ltr() {
        padding-left: 0 !important;
      }
      @include rtl() {
        padding-right: 0 !important;
      }
    }
  }
}
.mobile-check-header {
  position: relative;
  width: 100%;
  &.active {
    position: fixed;
    top: 0;
    z-index: 5;
  }
}
.shop-bg-dark {
  background: linear-gradient(#0d6efd, black) !important;
}
.shop-bg-light {
  background: linear-gradient(#0d6efd, white) !important;
}
</style>
