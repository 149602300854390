var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{staticClass:"content-layout vertical-nav",class:[
    { 'nav-drawer-mini': _vm.menuIsVerticalNavMini },
    { 'nav-drawer-mini-mobile': _vm.menuIsVerticalNavMini && _vm.$vuetify.breakpoint.smAndDown },
    { 'content-full': _vm.appContentWidth === 'full' } ],staticStyle:{"position":"fixed","width":"100%","height":"100%"}},[(!_vm.menuIsMenuHidden)?_c('v-navigation-drawer',{ref:"layoutDrawer",staticClass:"app-navigation-menu",attrs:{"app":"","right":_vm.$vuetify.rtl,"mini-variant-width":"68","expand-on-hover":_vm.menuIsVerticalNavMini,"width":"260"},model:{value:(_vm.isVerticalNavMenuActive),callback:function ($$v) {_vm.isVerticalNavMenuActive=$$v},expression:"isVerticalNavMenuActive"}},[_c('vertical-nav-menu',{attrs:{"nav-menu-items":_vm.navMenuItems},on:{"close-nav-menu":function($event){_vm.isVerticalNavMenuActive = false}}})],1):_vm._e(),(_vm.appBarType !== 'hidden')?_c('v-app-bar',{ref:"refAppBar",staticClass:"mx-auto app-bar-static height-custom-layout",class:[
      { 'px-5 app-bar-shinked': _vm.appBarType === 'fixed' && _vm.scrollY },
      { 'bg-blur': _vm.appBarIsBlurred && _vm.appBarType === 'fixed' && _vm.scrollY } ],attrs:{"app":"","absolute":_vm.appBarType === 'static',"flat":"","elevate-on-scroll":_vm.appBarType !== 'static',"elevation":_vm.appBarType !== 'static' ? (_vm.$vuetify.theme.isDark ? 4 : 3) : 0}},[_vm._t("navbar",null,{"isVerticalNavMenuActive":_vm.isVerticalNavMenuActive,"toggleVerticalNavMenuActive":_vm.toggleVerticalNavMenuActive})],2):_vm._e(),_c('div',{style:({
      marginTop: _vm.height,
    })}),_vm._t("v-app-content"),_c('v-main',[_c('app-content-container',[_vm._t("default")],2)],1),_c('v-overlay',{staticClass:"content-overlay",attrs:{"value":_vm.$store.state.app.shallContentShowOverlay,"z-index":"4","absolute":""}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }