/* eslint-disable */
export default function () {
  return {
    broadcastListData: [],
    broadcastScheduledData: [],
    broadcastStatistic: {
      failed: 0,
      broadcast: 0,
      delivered_count: 0,
      read: 0,
      received: 0,
      replied: 0,
      sent: 0,
      chart: {},
    },
    broadcastContacts: { Whatsapp: [] },
    broadcastContactsList: [],
    broadcastExtraField: { global: [], Whatsapp: [] },

    // get channel
    channel: null,
    valueChannel: null,
    filterContact: null,
    valueContact: null,
    screen: {
      height: 0,
      width: 0,
    },
  }
}
