var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{staticClass:"content-layout vertical-nav",class:[
    { 'nav-drawer-mini': _vm.menuIsVerticalNavMini },
    { 'nav-drawer-mini-mobile': _vm.menuIsVerticalNavMini && _vm.$vuetify.breakpoint.smAndDown },
    { 'content-full': _vm.appContentWidth === 'full' } ]},[_c('pre',{staticStyle:{"display":"none"}},[_vm._v(_vm._s(_vm.allRooms))]),(!_vm.menuIsMenuHidden)?_c('v-navigation-drawer',{ref:"layoutDrawer",staticClass:"app-navigation-menu",staticStyle:{"z-index":"11"},attrs:{"app":"","right":_vm.$vuetify.rtl,"mini-variant-width":"68","expand-on-hover":_vm.menuIsVerticalNavMini,"width":"260"},model:{value:(_vm.isVerticalNavMenuActive),callback:function ($$v) {_vm.isVerticalNavMenuActive=$$v},expression:"isVerticalNavMenuActive"}},[_c('vertical-nav-menu',{attrs:{"nav-menu-items":_vm.navMenuItems},on:{"close-nav-menu":function($event){_vm.isVerticalNavMenuActive = false}}})],1):_vm._e(),(_vm.appBarType !== 'hidden')?_c('v-app-bar',{ref:"refAppBar",staticClass:"mx-auto app-bar-static height-custom-layout",class:[
      { 'px-5 app-bar-shinked': _vm.appBarType === 'fixed' && _vm.scrollY },
      { 'bg-blur': _vm.appBarIsBlurred && _vm.appBarType === 'fixed' && _vm.scrollY } ],attrs:{"app":"","absolute":_vm.appBarType === 'static',"flat":"","elevate-on-scroll":_vm.appBarType !== 'static',"elevation":_vm.appBarType !== 'static' ? (_vm.$vuetify.theme.isDark ? 4 : 3) : 0}},[_vm._t("navbar",null,{"isVerticalNavMenuActive":_vm.isVerticalNavMenuActive,"toggleVerticalNavMenuActive":_vm.toggleVerticalNavMenuActive})],2):_vm._e(),_c('pre',{staticStyle:{"display":"none"}},[_vm._v(_vm._s(_vm.currentRouteName))]),(_vm.statusBanner && _vm.currentRouteName !== 'inbox' && _vm.currentRouteName !== 'inbox-phone-number')?_c('div',{style:({
      height: _vm.height,
    })}):_vm._e(),(_vm.statusServerConnection && _vm.currentRouteName !== 'inbox' && _vm.currentRouteName !== 'inbox-phone-number')?_c('div',{style:({
      height: _vm.height,
    })}):_vm._e(),_c('v-main',[_c('pre',{staticStyle:{"display":"none"}},[_vm._v(_vm._s(_vm.currentRouteName))]),(
        _vm.currentRouteName !== 'inbox' &&
        _vm.currentRouteName !== 'chat-single' &&
        _vm.currentRouteName !== 'inbox-phone-number' &&
        _vm.currentRouteName !== 'e-ordering' &&
        _vm.currentRouteName !== 'e-shop-order-list'
      )?_c('app-content-container',[_c('div',{class:("mobile-check-header " + (_vm.showHeader === false ? 'active' : ''))},[_vm._t("default")],2)]):_vm._e(),(
        (_vm.currentRouteName !== 'inbox' &&
          _vm.currentRouteName !== 'inbox-phone-number' &&
          _vm.currentRouteName !== 'chat-single' &&
          _vm.currentRouteName === 'e-ordering') ||
        _vm.currentRouteName === 'e-shop-order-list'
      )?_c('app-content-container',{style:(_vm.$vuetify.theme.isDark === true ? "background: #222222;" : "background: #F8F9F9;")},[_c('div',{class:("mobile-check-header " + (_vm.showHeader === false ? 'active' : ''))},[_vm._t("default")],2)]):_vm._e(),(
        _vm.currentRouteName === 'inbox' ||
        (_vm.currentRouteName === 'inbox-phone-number' && _vm.currentRouteName !== 'chat-single')
      )?_c('div',{class:("mobile-check-header " + (_vm.showHeader === false ? 'active' : ''))},[_vm._t("default")],2):_vm._e(),(_vm.currentRouteName === 'chat-single')?_c('div',{class:("mobile-check-header mt-2 " + (_vm.showHeader === false ? 'active' : ''))},[_vm._t("default")],2):_vm._e()],1),_c('pre',{staticStyle:{"display":"none"}},[_vm._v(_vm._s(_vm.currentRouteName))]),_c('v-overlay',{staticClass:"content-overlay",attrs:{"value":_vm.$store.state.app.shallContentShowOverlay,"z-index":"4","absolute":""}}),(
      _vm.currentRouteName !== 'inbox' && _vm.currentRouteName !== 'chat-single' && _vm.currentRouteName !== 'inbox-phone-number'
    )?_c('div',[(_vm.footerType !== 'hidden')?_c('v-footer',{class:{ 'mx-auto': _vm.appContentWidth !== 'full' },attrs:{"app":"","inset":"","absolute":_vm.footerType === 'static',"padless":"","color":_vm.footerType === 'static' ? 'transparent' : null}},[_c('div',{staticClass:"py-4 w-full",class:{ 'px-5': _vm.footerType === 'fixed' }},[_vm._t("footer")],2)]):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }