<template>
  <!-- dialog config -->
  <v-dialog v-if="dialogAddContact" v-model="dialogAddContact" width="600px">
    <v-card>
      <v-card-actions>
        <h3 class="font-weight-bold">
          {{ 'Group Name' }}
        </h3>
        <v-spacer></v-spacer>
      </v-card-actions>
      <v-divider />

      <v-card-text class="my-3">
        <v-text-field class="my-0 py-0" v-model="name" outlined :label="`${$t('name')}`" />
        <v-checkbox
          v-if="fromCustGroup"
          v-model="use_color"
          class="mt-0 mb-5 py-0"
          hide-details
          :label="`${$t('contacts.showLabel')}`"
          :disabled="!serverConnectionStatus"
        ></v-checkbox>
        <v-row>
          <v-col>
            <span>{{ $t('channels.color') }}</span>
            <v-color-picker
              v-model="instanceColor"
              dot-size="25"
              hide-mode-switch
              mode="hexa"
              swatches-max-height="200"
            ></v-color-picker>
          </v-col>
        </v-row>
      <!-- <v-card-text>
        <v-text-field class="mt-3 " v-model="name" outlined :label="`${$t('name')}`" />
        <v-color-picker
            v-if="fromCustGroup"
            v-model="instanceColor"
            dot-size="25"
            hide-mode-switch
            mode="hexa"
            swatches-max-height="200"
        ></v-color-picker> -->
      </v-card-text>
      <v-divider />
      <v-card-actions class="text-end">
        <v-spacer></v-spacer>
        <v-btn color="#ffeeee" class="mr-3" @click="cancel">
          <span class="error--text">{{ $t('cancel') }}</span>
        </v-btn>
        <v-btn color="success" class="" @click="agree">
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['serverConnectionStatus'],
  data: () => ({
    instanceColor: '',
    use_color: false,
    dialogAddContact: false,
    resolve: null,
    reject: null,
    name: '',
    dialogAddWorkspace: false,
    fromCustGroup: false,
  }),
  mounted() {},
  methods: {
    open(name, color, use_color, fromCustomerGroup = null) {
    // open(name, color, fromCustomerGroup = null) {
      if(fromCustomerGroup) {
        this.fromCustGroup = true
      }
      this.name = name
      this.use_color = use_color;
      if (color) {
        this.instanceColor = color
      } else {
        let colors = Math.floor(Math.random() * 16777215).toString(16)

        while (colors.length < 6) {
          colors = Math.floor(Math.random() * 16777215).toString(16)
        }
        this.instanceColor = '#' + colors
      }
      this.dialogAddContact = true

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    add() {
      if (color) {
        this.instanceColor = color
      } else {
        let colors = Math.floor(Math.random() * 16777215).toString(16)

        while (colors.length < 6) {
          colors = Math.floor(Math.random() * 16777215).toString(16)
        }
        this.instanceColor = '#' + colors
      }
      this.dialogAddWorkspace = true

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    agree() {
      this.resolve({
        status: true,
        name: this.name,
        label_color: this.instanceColor,
        use_color: this.use_color,
      })
      this.dialogAddContact = false
      this.$store.commit('workspaces/setIsWorkspaceEdited', true)
    },
    cancel() {
      this.resolve({
        status: false,
        name: '',
      })
      this.dialogAddContact = false
    },
  },
}
</script>
<style scoped>
.bottom-action {
  display: none;
}
@media (max-width: 536px) {
  .hide-buton {
    display: none;
  }
  .bottom-action {
    display: inherit;
  }
}
</style>
