import Vue from 'vue'

const Mustache = require('mustache')
const Wax = require('@jvitela/mustache-wax')

Wax(Mustache)

Mustache.Formatters = {
  uppercase: function(string) {
    var string = string ? string : ''
    return string.toUpperCase()
  },
  lowercase: function(string) {
    var string = string ? string : ''
    return string.toLowerCase()
  },
  substring: function(string, start, end = null) {
    var string = string ? string : ''
    return end ? string.substring(start, end) : string.substring(start)
  },
  substr: function(string, start, length = null) {
    var string = string ? string : ''
    return length ? string.substr(start, length) : string.substr(start)
  },
  regexreplace: function(string, regex, substitute) {
    var string = string ? string : ''
    var regex = new RegExp(regex, 'g')
    return string.replace(regex, substitute)
  },
}

Vue.prototype.$Mustache = Mustache
