import {
  mdiCellphoneMessage,
  mdiForumOutline,
  mdiHomeOutline,
  mdiLabel,
  mdiMessageTextOutline,
  mdiMonitorCellphone,
} from '@mdi/js'
import store from '../../store'

const user = store.getters['auth/getUser']

export default [
  {
    title: 'Channels',
    icon: mdiHomeOutline,
    to: 'channels',
  },
  {
    title: 'Inbox',
    icon: mdiMessageTextOutline,
    children: [
      {
        icon: mdiForumOutline,
        title: 'chat',
        to: 'inbox',
      },
      {
        icon: mdiCellphoneMessage,
        title: 'Chat Data',
        to: 'contacts-list',
      },
      {
        icon: mdiLabel,
        title: 'Label',
        to: 'label-list',
      },
    ],
  },
  // {
  //   title: 'Broadcast',
  //   icon: mdiBroadcast,
  //   children: [
  //     {
  //       icon: mdiCardAccountMailOutline,
  //       title: 'contacts.contactList',
  //       to: 'contacts-group-list',
  //     },
  //     {
  //       icon: mdiFormatListBulleted,
  //       title: 'List Broadcast',
  //       to: 'broadcast-list',
  //     },
  //     {
  //       icon: mdiAlarm,
  //       title: 'Broadcast Schedule',
  //       to: 'broadcast-scheduled',
  //     },
  //     {
  //       icon: mdiChartAreaspline,
  //       title: 'Statistic',
  //       to: 'broadcast-statistic',
  //     },
  //     {
  //       title: 'Template',
  //       icon: mdiMessage,
  //       to: 'message-template',
  //     },
  //   ],
  // },
  // {
  //   title: 'Operators',
  //   icon: mdiChartBubble,
  //   children: [
  //     {
  //       icon: mdiAccountMultiple,
  //       title: 'Operators List',
  //       to: 'operators-list',
  //     },
  //     {
  //       icon: mdiAccountClock,
  //       title: 'Replies History',
  //       to: 'replies-history',
  //     },
  //     {
  //       icon: mdiChartHistogram,
  //       title: 'User Statistic',
  //       to: 'operator-statistic',
  //     },
  //   ],
  // },

  // {
  //   title: 'Widget',
  //   icon: mdiWidgets,
  //   children: [
  //     {
  //       title: 'Link Generator',
  //       icon: mdiLink,
  //       to: 'link-generator',
  //     },
  //     {
  //       title: 'Live Chat Widget',
  //       icon: mdiChat,
  //       to: 'live-chat-widget',
  //     },
  //   ],
  // },
  {
    title: 'Subscriptions',
    icon: mdiMonitorCellphone,
    to: user.package === '' ? 'subscriptions' : 'billings',
  },
  // {
  //   title: 'Marketing Calendar',
  //   icon: mdiMonitorCellphone,
  //   to: 'marketing-calendar',
  // },

  // {
  //   title: 'Link Generator',
  //   icon: mdiLink,
  //   to: 'link-generator',
  // },
  // {
  //   title: 'Live Chat Widget',
  //   icon: mdiChat,
  //   to: 'live-chat-widget',
  // },

  // {
  //   title: 'Test',
  //   icon: mdiChartBubble,
  //   children: [
  //     {
  //       icon: mdiAccountMultiple,
  //       title: 'b',
  //       to: 'test-b',
  //     },
  //     {
  //       icon: mdiAccountClock,
  //       title: 'a',
  //       to: 'test-a',
  //     },
  //   ],
  // },
]
