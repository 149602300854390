/* eslint-disable */
import axios from 'axios'

// axios limit
const MAX_REQUESTS_COUNT = 15
const INTERVAL_MS = 10
let PENDING_REQUESTS = 0

// create new axios instance
const conn = axios.create({})
conn.defaults.timeout = 20 * 60 * 1000

/**
 * Axios Request Interceptor
 */
conn.interceptors.request.use(function (config) {
  return new Promise((resolve, reject) => {
    const interval = setInterval(() => {
      if (PENDING_REQUESTS < MAX_REQUESTS_COUNT) {
        PENDING_REQUESTS++
        clearInterval(interval)
        resolve(config)
      }
    }, INTERVAL_MS)
  })
})

/**
 * Axios Response Interceptor
 */
conn.interceptors.response.use(
  function (response) {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1)

    return Promise.resolve(response)
  },
  function (error) {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1)

    return Promise.reject(error)
  },
)

export const getScoring = async ({ commit, state }, payload) => {
  const endpoint = `${process.env.VUE_APP_CAMPAIGN_LANDING_SERVICE_API_URL}campaign-manager/scoring/get`
  const body = { sub_id: payload.sub_id }
  const config = { headers: { authorization: `Bearer ${payload.token}` } }

  try {
    const { data: res } = await conn.post(endpoint, body, config)
    if (res.data[0]) {
      commit('setScoring', res.data[0])
      return true
    } else {
      return false
    }
  } catch (error) {
    console.log(error)
  }
}

export const createScoring = async ({ commit }, payload) => {
  const endpoint = `${process.env.VUE_APP_CAMPAIGN_LANDING_SERVICE_API_URL}campaign-manager/scoring/create`
  const body = { sub_id: payload.sub_id }
  if (payload.data) {
    body.data = payload.data
  }
  const config = { headers: { authorization: `Bearer ${payload.token}` } }

  try {
    const { data: res } = await conn.post(endpoint, body, config)
    commit('setScoring', res.data)
  } catch (error) {
    console.log(error)
  }
}

export const updateScoring = async ({ commit }, payload) => {
  const endpoint = `${process.env.VUE_APP_CAMPAIGN_LANDING_SERVICE_API_URL}campaign-manager/scoring/update`
  const body = { sub_id: payload.sub_id, data: payload.data }
  const config = { headers: { authorization: `Bearer ${payload.token}` } }

  try {
    const { data: res } = await conn.put(endpoint, body, config)
    commit('setScoring', res.data)
  } catch (error) {
    console.log(error)
  }
}

export const createChannel = async ({ commit }, payload) => {
  const endpoint = `${process.env.VUE_APP_CAMPAIGN_LANDING_SERVICE_API_URL}campaign-manager/channels`
  //console.log(endpoint)
  try {
    const body = { payload, sub_id: payload.sub_id }
    const config = { headers: { authorization: `Bearer ${payload.token}` } }
    const { data: response } = await conn.post(endpoint, body, config)
    //console.log(response)
    // if (response.data[0]) {
    //   commit('SET_SELECTED_CONTACTS', res.data[0])
    //   return true
    // } else {
    //   return false
    // }
  } catch (error) {
    console.log(error)
  }
}

export async function readAllChannel(context, payload) {
  try {
    const subId = context.rootGetters['auth/getUser'].sub_id
    const { data } = await axios({
      method: 'GET',
      url: process.env.VUE_APP_CAMPAIGN_LANDING_SERVICE_API_URL + 'campaign-manager/channels',
      params: { subId },
    })
    return data
  } catch (error) {
    console.log(error)
  }
}

export async function deleteChannel(context, payload) {
  try {
    const subId = context.rootGetters['auth/getUser'].sub_id
    const { data } = await axios({
      method: 'DELETE',
      url: process.env.VUE_APP_CAMPAIGN_LANDING_SERVICE_API_URL + 'campaign-manager/channels',
      data: { idDeletion: payload, subId },
    })
    return data
  } catch (error) {
    console.log(error)
  }
}

export async function createCampaign(context, payload) {
  try {
    const subId = context.rootGetters['auth/getUser'].sub_id
    //console.log(payload)
    const { data } = await axios({
      method: 'POST',
      url: process.env.VUE_APP_CAMPAIGN_LANDING_SERVICE_API_URL + 'campaign-manager/campaigns',
      data: { payload, subId },
    })
    //console.log(data, '<< data action')
    return data.data
  } catch (error) {
    console.log(error)
  }
}

export async function fetchAllCampaigns(context, payload) {
  try {
    const subId = context.rootGetters['auth/getUser'].sub_id
    const { data } = await axios({
      method: 'GET',
      url: process.env.VUE_APP_CAMPAIGN_LANDING_SERVICE_API_URL + 'campaign-manager/campaigns',
      params: { subId },
    })
    context.commit('SET_ALL_CAMPAIGNS', data)
  } catch (error) {
    console.log(error)
  }
}

// export async function fetchAllCampaignsApproval(context, payload) {
//   try {
//     const { data } = await axios({
//       method: 'POST',
//       url: process.env.VUE_APP_CAMPAIGN_LANDING_SERVICE_API_URL + 'campaign-manager/campaigns/readCampaignByAllSubId',
//     })
//     context.commit('SET_CAMPAIGNS_APPROVAL', data.result)
//   } catch (error) {
//     console.log(error)
//   }
// }

export async function fetchSpecifiedCampaign(context, payload) {
  try {
    //console.log('fetchSpecifiedCampaign is invoked')
    const subId = context.rootGetters['auth/getUser'].sub_id
    const { ID_for_fetching } = payload
    const { data } = await axios({
      method: 'GET',
      url: process.env.VUE_APP_CAMPAIGN_LANDING_SERVICE_API_URL + `campaign-manager/campaigns/${ID_for_fetching}`,
      params: { subId, ID_for_fetching },
    })
    context.commit('SET_SPECIFIED_CAMPAIGNS', data)
    return data
  } catch (error) {
    console.log(error)
  }
}

export const editCampaign = async (context, payload) => {
  try {
    const sub_ID = context.rootGetters['auth/getUser'].sub_id
    await axios({
      method: 'PUT',
      url: process.env.VUE_APP_CAMPAIGN_LANDING_SERVICE_API_URL + 'campaign-manager/campaigns',
      data: { payload, sub_ID },
    })
  } catch (error) {
    console.log(error)
  }
}

export const deleteCampaign = async (context, payload) => {
  try {
    const sub_ID = context.rootGetters['auth/getUser'].sub_id
    const { data } = await axios({
      method: 'DELETE',
      url: process.env.VUE_APP_CAMPAIGN_LANDING_SERVICE_API_URL + 'campaign-manager/campaigns',
      params: { sub_ID, ID_for_deletion: payload.id_for_deletion },
    })
  } catch (error) {
    console.log(error)
  }
}

export const createGif = async (context, payload) => {
  try {
    //console.log(payload)
    const sub_ID = context.rootGetters['auth/getUser'].sub_id
    const data = await axios.post(
      process.env.VUE_APP_CAMPAIGN_LANDING_SERVICE_API_URL + 'editly',
      payload,
      // {
      //   headers: { 'Content-Type': 'multipart/form-data' },
      // }
    )
    //console.log(data)
  } catch (error) {
    console.log(error)
  }
}
