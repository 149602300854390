export const setEmployeeGroups = (state, payload) => {
  state.employeeGroups = payload
}
export const setCurrentEmployeeGroup = (state, payload) => {
  state.currentEmployeeGroup = payload
}
export const setEmployeesFromEmployeeGroup = (state, payload) => {
  state.employeesFromEmployeeGroup = payload
}
export const setCurrentEmployee = (state, payload) => {
  state.currentEmployee = payload
}
export const setDisbursementList = (state, payload) => {
  state.disbursementList = payload
}
export const setCurrentDisbursement = (state, payload) => {
  state.currentDisbursement = payload
}
export const setCurrentDisbursementDetail = (state, payload) => {
  state.currentDisbursementDetail = payload
}
export const setCurrentProject = (state, payload) => {
  state.currentProject = payload
}
export const setEmployeeDetails = (state, payload) => {
  state.employeeDetails = payload
}
