/* eslint-disable */
import axios from 'axios'

// axios limit
const MAX_REQUESTS_COUNT = 15
const INTERVAL_MS = 10
let PENDING_REQUESTS = 0

// create new axios instance
const conn = axios.create({})
conn.defaults.timeout = 20 * 60 * 1000

/**
 * Axios Request Interceptor
 */
conn.interceptors.request.use(function (config) {
  return new Promise((resolve, reject) => {
    const interval = setInterval(() => {
      if (PENDING_REQUESTS < MAX_REQUESTS_COUNT) {
        PENDING_REQUESTS++
        clearInterval(interval)
        resolve(config)
      }
    }, INTERVAL_MS)
  })
})

/**
 * Axios Response Interceptor
 */
conn.interceptors.response.use(
  function (response) {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1)

    return Promise.resolve(response)
  },
  function (error) {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1)

    return Promise.reject(error)
  },
)

export const getProjects = async ({ commit, state }, payload) => {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/projects`
  const body = { sub_id: payload.sub_id }

  try {
    const { data: res } = await conn.post(endpoint, body)
    if (!res.status) {
      throw res
    }
    commit('setProjects', res.data)
    return res.data
  } catch (error) {
    console.log(error)
  }
}

export const getProjectById = async ({ commit, state }, payload) => {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/projects/${payload.id}`
  const body = { sub_id: payload.sub_id }

  try {
    const { data: res } = await conn.post(endpoint, body)
    if (!res.status) {
      throw res
    }
    commit('setCurrentProject', res.data)
    return res.data
  } catch (error) {
    console.log(error)
  }
}

export const addProject = async ({ commit, state }, payload) => {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/projects/create`
  const body = { sub_id: payload.sub_id, input_data: payload.input_data }

  try {
    const { data: res } = await conn.post(endpoint, body)
    if (!res.status) {
      throw res
    }
    commit('setProjects', [...state.projects, res.data])
    return res
  } catch (error) {
    console.log(error)
    return error
  }
}

export const editProject = async ({ commit, state }, payload) => {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/projects/${payload.id}`
  const body = { sub_id: payload.sub_id, input_data: payload.input_data }

  try {
    const { data: res } = await conn.put(endpoint, body)
    if (!res.status) {
      throw res
    }
    return res
  } catch (error) {
    console.log(error)
    return error
  }
}

export const deleteProject = async ({ commit, state }, payload) => {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/projects/${payload.id}`
  const body = { sub_id: payload.sub_id }

  try {
    const { data: res } = await conn.delete(endpoint, { data: body })
    if (!res.status) {
      throw res
    }
    return
  } catch (error) {
    console.log(error)
  }
}
