<template>
  <div>
    <div :id="`pick-files-${dataIndex || 0}`"></div>
  </div>
</template>
<script>
import useAppConfig from '@core/@app-config/useAppConfig'

// import GoogleDrive from '@uppy/google-drive'
import AwsS3 from '@uppy/aws-s3'
import Uppy from '@uppy/core'
import '@uppy/core/dist/style.css'
import Dashboard from '@uppy/dashboard'
import '@uppy/dashboard/dist/style.css'
import ImageEditor from '@uppy/image-editor'
import '@uppy/image-editor/dist/style.css'
import English from '@uppy/locales/lib/en_US'
import Indonesia from '@uppy/locales/lib/id_ID'
import Url from '@uppy/url'

// const Tus = require('@uppy/tus')

const ImageCompressor = require('uppy-plugin-image-compressor')

export default {
  name: 'UppyUploader',
  emits: ['uppy-file'],
  props: {
    dataUppy: {
      type: Object,
      default: () => {},
    },
    status: {
      type: String,
      default: 'add',
    },
    dataIndex: {
      type: String,
      default: '0',
    },
    config: {
      type: Object,
      default: () => {},
    },
    dashboardConfig: {
      type: Object,
      default: () => {},
    },
    onFileAdded: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      uppy: null,
      theme: false,
    }
  },
  computed: {
    locale() {
      return this.$i18n.locale
    },
    user() {
      return this.$store.getters['auth/getUser']
    },
  },
  watch: {
    dataUppy: {
      handler(val) {
        if (this.$route.name === 'manage-project') {
          this.uppy.reset()
          // eslint-disable-next-line no-unused-expressions, array-callback-return
          val?.attachments.map(d => {
            const blob = this.convertUrlToBlob(d.uploadURL)
            blob.then(r => {
              //console.log(d, 'checkBlob')
              this.uppy.addFile({
                name: d.name, // file name
                type: d.type, // file type
                data: r, // file blob
                // when using companion in combination with Instagram.
              })
            })
          })
        }
      },
      deep: true,
    },
  },
  mounted() {
    const { isDark } = useAppConfig()
    this.theme = isDark ? 'dark' : 'light'
    this.newUppy()
  },
  methods: {
    newUppy() {
      let localLang = English
      switch (this.locale) {
        case 'id':
          localLang = Indonesia
          break

        default:
          localLang = English
          break
      }

      this.uppy = new Uppy({
        autoProceed: true,
        locale: localLang,
        infoTimeout: 5000,
        ...(this.config || {}),
      })
        .use(Dashboard, {
          inline: true,
          target: `#pick-files-${this.dataIndex || 0}`,
          replaceTargetContent: true,
          theme: this.theme,
          disabled: true,
          height: 250,
          showRemoveButtonAfterComplete: true,
          doneButtonHandler: null,
          ...(this.dashboardConfig || {}),
        })

        // .use(GoogleDrive, {
        //   target: Dashboard,
        //   companionUrl: process.env.VUE_APP_UPPY_COMPANION_URL.replace(/['"]+/g, ''),
        // })
        .use(Url, {
          target: Dashboard,
          companionUrl: process.env.VUE_APP_UPPY_COMPANION_URL.replace(/['"]+/g, ''),
        })
        .use(AwsS3, {
          companionUrl: `${process.env.VUE_APP_UPPY_COMPANION_URL.replace(/['"]+/g, '')}/`,
        })

        // .use(Tus, {
        //   endpoint: process.env.VUE_APP_TUS_API_URL.replace(/['"]+/g, ''),
        //   resume: true,
        //   retryDelays: [0, 1000, 3000, 5000],
        //   limit: 10,
        // })
        .use(ImageCompressor, {
          quality: 0.6,
        })
        .use(ImageEditor, {
          target: Dashboard,
          cropperOptions: {
            viewMode: 0,
            autoCrop: true,
            autoCropArea: 1,
            aspectRatio: 1,
          },
        })

      const self = this
      this.uppy.on('upload-success', (file, data) => {
        //console.log('upload success, ', file, data)
        const newUploadURL = data.uploadURL.replace(
          `https://${process.env.VUE_APP_UPPY_S3_SERVER}/${process.env.VUE_APP_UPPY_S3_SERVER}`,
          `https://${process.env.VUE_APP_UPPY_S3_SERVER}`,
        )
        self.uppy.setFileState(file.id, { uploadURL: newUploadURL, preview: newUploadURL })
        this.$emit('uppy-file', newUploadURL)
      })

      this.uppy.on('file-removed', () => {
        this.$emit('uppy-file', '')
      })

      this.uppy.on('file-added', file => {
        if (this.$route.name === 'create-post' || this.$route.name === 'edit-post') {
          this.checkImageSize(file)
        } else if (this.$route.name === 'manage-project') {
          this.checkLogoSize(file)
        }
      })

      this.uppy.on('file-editor:complete', updatedFile => {
        if (this.$route.name === 'create-post' || this.$route.name === 'edit-post') {
          this.checkImageSize(updatedFile)
        } else if (this.$route.name === 'manage-project') {
          this.checkLogoSize(updatedFile)
        }
      })

      // eslint-disable-next-line no-unused-expressions, array-callback-return
      this.dataUppy?.attachments.map(d => {
        const blob = this.convertUrlToBlob(d.uploadURL)
        blob.then(r => {
          //console.log(d, 'checkBlob')
          this.uppy.addFile({
            name: d.name, // file name
            type: d.type, // file type
            data: r, // file blob
            // when using companion in combination with Instagram.
          })
        })
      })
      this.uppy.getFiles().map(d =>
        this.uppy.setFileState(d.id, {
          progress: { uploadComplete: true },
        }),
      )
      const dashboard = this.uppy.getPlugin('Dashboard')
      dashboard.setOptions({ disabled: false })
      if (this.dashboardConfig.disabled) {
        //console.log(this.dashboardConfig.disabled, 'this.dashboardConfig.disabled')
        dashboard.setOptions({ disabled: true })
      }
    },
    convertUrlToBlob(data = null) {
      //console.log('convertUrlToBlob is invoked')

      return new Promise(resolve => {
        const xhr = new XMLHttpRequest()
        xhr.open('GET', data)
        xhr.responseType = 'blob'
        xhr.send()
        xhr.onload = () => {
          resolve(xhr.response)
        }
      })
    },
    checkImageSize(file) {
      const { data } = file // is a Blob instance
      const url = URL.createObjectURL(data)
      const image = new Image()
      image.src = url
      image.onload = () => {
        const aspectRatio = image.width / image.height
        if (image.width < 320) {
          this.uppy.info("Image's width must be greater than 320 pixels, please choose another Photo.")
          this.uppy.removeFile(file.id)
        } else if (aspectRatio < 0.8 || aspectRatio > 1.91) {
          this.uppy.info(
            `The image aspect ratio must be between 0.8 and 1.91. Current image aspect ratio: ${aspectRatio.toFixed(
              3,
            )}`,
          )
          this.uppy.info('Please edit the image first by clicking the edit button')
        } else if (image.width > 1440) {
          this.uppy.info(`Image's width must be between 320 and 1440 pixels, current width: ${image.width}.`)
          this.uppy.info('Please edit the image first by clicking the edit button')
        } else {
          this.uppy.upload()
        }
      }
    },
    checkLogoSize(file) {
      let url

      if (file.source === 'Url') {
        url = file.remote.body.url
      } else {
        const { data } = file // is a Blob instance
        url = URL.createObjectURL(data)
      }

      const image = new Image()
      image.src = url
      image.onload = () => {
        if (image.width > 500 || image.height > 500) {
          this.uppy.info('Max logo size is 500 x 500 pixels')
          this.uppy.info(`Current image width: ${image.width}, height: ${image.height}`)
          if (file.source !== 'Url') {
            this.uppy.info('Crop the image by clicking the edit button')
          }
        } else {
          this.uppy.upload()
        }
      }
    },
  },
}
</script>
