export default {
  methods: {
    async syncContactsWa(channel) {
      const endpoint = `${process.env.VUE_APP_WA_API_URL}sync_contact/${channel.sub_id}-${channel.instance_id}`
      this.axiosContactsWa(endpoint)
    },
    async axiosContactsWa(endpoint) {
      await this.$axiosLimit
        .get(endpoint)
        .then(async response => {
          //console.log('sync contact complete')
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
