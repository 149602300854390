/* eslint-disable import/prefer-default-export */
export const getScoringData = state => {
  return state.scoring
}

// Campaigns
export const getAllCampaignsData = state => {
  return state.allCampaigns
}

export const getSpecifiedCampaignsData = state => {
  return state.specifiedCampaign
}

export const getCampaignApproval = state => {
  return state.campaignApproval
}

export const getListIDHasAccessedMarketingCalendarBefore = state => {
  const newList = [...new Set(state.ListIDHasAccessedMarketingCalendarBefore)]

  return newList
}

/// //////////////////////////////////////////////////////
