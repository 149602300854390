/* eslint-disable */
import axios from 'axios'
// axios limit
const MAX_REQUESTS_COUNT = 15
const INTERVAL_MS = 10
let PENDING_REQUESTS = 0

// create new axios instance
const conn = axios.create({})
conn.defaults.timeout = 20000
/**
 * Axios Request Interceptor
 */
conn.interceptors.request.use(function (config) {
  return new Promise((resolve, reject) => {
    let interval = setInterval(() => {
      if (PENDING_REQUESTS < MAX_REQUESTS_COUNT) {
        PENDING_REQUESTS++
        clearInterval(interval)
        resolve(config)
      }
    }, INTERVAL_MS)
  })
})

/**
 * Axios Response Interceptor
 */
conn.interceptors.response.use(
  function (response) {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1)
    return Promise.resolve(response)
  },
  function (error) {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1)
    return Promise.reject(error)
  },
)

export function setContacts({ commit }, contacts) {
  commit('setContacts', contacts)
}
export function setContactsById({ commit }, contact) {
  commit('setContactsById', contact)
}
export function setSettingsById({ commit }, settings) {
  commit('setSettingsById', settings)
}
export function setSettings({ commit }, settings) {
  commit('setSettings', settings)
}

export function setLink({ commit }, settings) {
  commit('setLink', settings)
}
export function setRooms({ commit }, rooms) {
  commit('setRooms', rooms)
}
export function setSyncFromServer({ commit }, syncFromServer) {
  commit('setSyncFromServer', syncFromServer)
}
export function setFilterChat({ commit }, filterChat) {
  if (!filterChat) {
    filterChat = 'all'
  }

  commit('setFilterChat', filterChat)
}
export function setLang({ commit }, lang) {
  commit('setLang', lang)
}
export function setLastSeq({ commit }, lastSeq) {
  commit('setLastSeq', lastSeq)
}
export function updateContacts({ commit }, obj) {
  commit('updateContacts', obj)
}
export function addNewContacts({ commit }, contacts) {
  commit('addNewContacts', contacts)
}
export function updateReplicateStatus({ commit }, status) {
  commit('setReplicateStatus', status)
}
export function setStatusChannels({ commit }, statusChannels) {
  commit('setStatusChannels', statusChannels)
}
export function setMuteValue({ commit }, muteValue) {
  commit('setMuteValue', muteValue)
}
export function setBoldUnreplied({ commit }, boldUnreplied) {
  commit('setBoldUnreplied', boldUnreplied)
}
export function setVersion({ commit }, version) {
  commit('setVersion', version)
}
export function addMessageOffline({ commit }, message) {
  commit('addMessageOffline', message)
}
export function setInitReplicateStatus({ commit }, status) {
  commit('setInitReplicateStatus', status)
}
export function setNotifPayload({ commit }, type) {
  commit('setNotifPayload', type)
}
export async function deleteContactList({ commit, state }, obj) {
  const endpoint =
    process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '') + 'superwa/postgres/contact-list/delete-contact-lists'

  const config = {
    headers: {
      authorization: `Bearer ${obj.token}`,
    },
  }

  return conn
    .post(endpoint, obj, config)
    .then(async response => {
      if (response && response.data && response.data.status) {
        return {
          status: true,
          errorMessage: '',
          data: response.data.data,
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      return {
        status: false,
        errorMessage: error,
      }
    })
}
export async function insertOrUpdateContactList({ commit, state }, obj) {
  const endpoint =
    process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '') +
    'superwa/postgres/contact-list/insert-or-update-contact-lists'

  const config = {
    headers: {
      authorization: `Bearer ${obj.token}`,
    },
  }

  return conn
    .post(endpoint, obj, config)
    .then(async response => {
      if (response && response.data && response.data.status) {
        return {
          status: true,
          errorMessage: '',
          data: response.data.data,
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      return {
        status: false,
        errorMessage: error,
      }
    })
}
export async function updateContactData({ commit, state }, obj) {
  const endpoint = process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '') + 'superwa/couchdb/contact/add-to-contactData'

  const config = {
    headers: {
      authorization: `Bearer ${obj.token}`,
    },
  }

  return conn
    .post(endpoint, obj, config)
    .then(async response => {
      if (response && response.data && response.data.status) {
        return {
          status: true,
          errorMessage: '',
          data: response.data.data,
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      return {
        status: false,
        errorMessage: error,
      }
    })
}
export async function getContactLists({ commit, state }, obj) {
  const endpoint =
    process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '') + 'superwa/postgres/contact-list/get-contact-lists'

  const config = {
    headers: {
      authorization: `Bearer ${obj.token}`,
    },
  }

  return conn
    .post(endpoint, obj, config)
    .then(async response => {
      if (response && response.data && response.data.status) {
        return {
          status: true,
          errorMessage: '',
          data: response.data.data,
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      return {
        status: false,
        errorMessage: error,
      }
    })
}
export async function findAllContactData({ commit, state }, obj) {
  // const endpoint = process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '') + 'superwa/couchdb/contact/all-contactData'
  const endpoint = process.env.VUE_APP_WA_SERVICES_URL.replace(/['"]+/g, '') + 'chats/allContact'
  // //console.log(endpoint, 'ini enpdoin')

  const config = {
    headers: {
      authorization: `Bearer ${obj.token}`,
    },
  }

  return conn
    .post(endpoint, obj, config)
    .then(async response => {
      // //console.log(response,'ini responz')
      if (response && response.data && response.data.status) {
        // //console.log(response, 'ini dia response all')
        commit('setAllContactData', response.data.data)
        // //console.log(response.data.data, '---------------')
        return {
          status: true,
          errorMessage: '',
          data: response.data.data,
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      return {
        status: false,
        errorMessage: error,
      }
    })
}

export async function findStoreContacts({ commit, state }, obj) {
  // const endpoint = process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '') + 'superwa/couchdb/contact/all-contactData'
  const endpoint = process.env.VUE_APP_WA_SERVICES_URL.replace(/['"]+/g, '') + 'chats/storeContacts'

  const config = {
    headers: {
      authorization: `Bearer ${obj.token}`,
    },
  }

  return conn
    .post(endpoint, obj, config)
    .then(async response => {
      // //console.log(response,'ini responz')
      if (response && response.data && response.data.status) {
        // //console.log(response.data.data, '---------------')
        commit('setStoreContacts', response.data.data)
        return {
          status: true,
          errorMessage: '',
          data: response.data.data,
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      return {
        status: false,
        errorMessage: error,
      }
    })
}
export async function syncDb({ commit, state }, obj) {
  const endpoint = process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '') + 'superwa/couchdb/sync-db'

  const config = {
    headers: {
      authorization: `Bearer ${obj.token}`,
    },
  }

  return conn
    .post(endpoint, obj, config)
    .then(async response => {
      if (response && response.data && response.data.status) {
        // commit('setAllContactData', response.data.data)
        // //console.log(response.data.data, '---------------')
        return {
          status: true,
          errorMessage: '',
          data: response.data.data,
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      return {
        status: false,
        errorMessage: error,
      }
    })
}
export async function getSettingsByKey({ commit, state }, obj) {
  const endpoint = process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '') + 'superwa/couchdb/settings/get-settings-by-key'

  const config = {
    headers: {
      authorization: `Bearer ${obj.token}`,
    },
  }

  return {
    status: true,
    errorMessage: '',
    data: [],
  }
  // conn
  //   .post(endpoint, obj, config)
  //   .then(async response => {
  //     if (response && response.data && response.data.status) {
  //       return {
  //         status: true,
  //         errorMessage: '',
  //         data: response.data.data,
  //       }
  //     }
  //     return {
  //       status: false,
  //       errorMessage: response.data.message,
  //     }
  //   })
  //   .catch(error => {
  //     return {
  //       status: false,
  //       errorMessage: error,
  //     }
  //   })
}
export async function updateSettingsByKey({ commit, state }, obj) {
  const endpoint =
    process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '') + 'superwa/couchdb/settings/insert-settings-by-key'

  const config = {
    headers: {
      authorization: `Bearer ${obj.token}`,
    },
  }

  return conn
    .post(endpoint, obj, config)
    .then(async response => {
      if (response && response.data && response.data.status) {
        return {
          status: true,
          errorMessage: '',
          data: response.data.data,
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      return {
        status: false,
        errorMessage: error,
      }
    })
}
export async function getMarketaPrivateKey(context, payload) {
  const endpoint = `${process.env.VUE_APP_CAMPAIGN_LANDING_SERVICE_API_URL}generate-key`
  try {
    const config = {
      headers: {
        authorization: `Bearer ${payload.token}`,
      },
    }
    const body = { sub_id: payload.sub_id }
    return await conn.post(endpoint, body, config)
  } catch (error) {
    console.log(error, 'errr')
  }
}
export async function createProfileAyrshare({ context, state }, payload) {
  const endpoint = `${process.env.VUE_APP_CAMPAIGN_LANDING_SERVICE_API_URL}ayrshares`
  try {
    const config = {
      headers: {
        authorization: `Bearer ${payload.token}`,
      },
    }
    const body = { data: payload.data }
    return await conn.post(endpoint, body, config)
  } catch (error) {
    console.log(error)
  }
}
export async function fetchProfileAyrshareBysubid({ context, state }, payload) {
  const endpoint = `${process.env.VUE_APP_CAMPAIGN_LANDING_SERVICE_API_URL}ayrshares/${payload.sub_id}`
  try {
    const config = {
      headers: {
        authorization: `Bearer ${payload.token}`,
      },
    }
    const body = { data: payload.data }
    const { data: data } = await conn.get(endpoint, body, config)
    return data
  } catch (error) {
    console.log(error)
  }
}
export function deleteProfileAyrshare({ context, state }, payload) {
  const endpoint = `${process.env.VUE_APP_CAMPAIGN_LANDING_SERVICE_API_URL}ayrshares/delete`
  const config = {
    headers: {
      authorization: `Bearer ${payload.token}`,
    },
  }
  const body = { data: payload.profileKey }
  return conn.post(endpoint, body, config)
}
export async function unlinkSosmed({ context, state }, payload) {
  const API_KEY = process.env.VUE_APP_AYRSHARE_KEY
  const profileKey = payload.profileKey

  try {
    return fetch('https://app.ayrshare.com/api/profiles/social', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${API_KEY}`,
      },
      body: JSON.stringify({ platform: payload.platform, profileKey }),
    })
      .then(res => res.json())
      .then(json => {
        // //console.log(json, 'json')
        return json
      })
      .catch(console.error)
  } catch (error) {
    //console.log(erorr)
  }
}
export function deleteProfileOnAyrshare({ context, state }, payload) {
  const API_KEY = process.env.VUE_APP_AYRSHARE_KEY
  const profileKey = payload.profileKey

  return fetch('https://app.ayrshare.com/api/profiles/profile', {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${API_KEY}`,
    },
    body: JSON.stringify({ profileKey }),
  })
    .then(res => res.json())
    .then(json => {
      // //console.log(json, 'json')
    })
    .catch(console.error)
}
