/* eslint-disable */
import axios from 'axios'

// axios limit
const MAX_REQUESTS_COUNT = 15
const INTERVAL_MS = 10
let PENDING_REQUESTS = 0

// create new axios instance
const conn = axios.create({})
conn.defaults.timeout = 20 * 60 * 1000

/**
 * Axios Request Interceptor
 */
conn.interceptors.request.use(function (config) {
  return new Promise((resolve, reject) => {
    const interval = setInterval(() => {
      if (PENDING_REQUESTS < MAX_REQUESTS_COUNT) {
        PENDING_REQUESTS++
        clearInterval(interval)
        resolve(config)
      }
    }, INTERVAL_MS)
  })
})

/**
 * Axios Response Interceptor
 */
conn.interceptors.response.use(
  function (response) {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1)

    return Promise.resolve(response)
  },
  function (error) {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1)

    return Promise.reject(error)
  },
)

export const getEmployeeGroups = async ({ commit, state }, payload) => {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/employees/employeeGroup`
  const body = { sub_id: payload.sub_id }

  try {
    const { data: res } = await conn.post(endpoint, body)
    if (!res.status) {
      throw res
    }
    commit('setEmployeeGroups', res.data)
  } catch (error) {
    console.log(error)
  }
}

export const getEmployeeGroupById = async ({ commit, state }, payload) => {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/employees/employeeGroup/${payload.id}`
  const body = { sub_id: payload.sub_id }

  try {
    const { data: res } = await conn.post(endpoint, body)
    if (!res.status) {
      throw res
    }
    commit('setCurrentEmployeeGroup', res.data)
    return res.data
  } catch (error) {
    console.log(error)
  }
}

export const createEmployeeGroup = async ({ commit, state }, payload) => {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/employees/createEmployeeGroup`
  const body = { sub_id: payload.sub_id, employee_group_data: payload.employee_group_data }

  try {
    const { data: res } = await conn.post(endpoint, body)
    if (!res.status) {
      throw res
    }
    commit('setEmployeeGroups', [...state.employeeGroups, res.data])
    return res.data
  } catch (error) {
    console.log(error)
  }
}

export const editEmployeeGroup = async ({ commit, state }, payload) => {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/employees/employeeGroup`
  const body = {
    sub_id: payload.sub_id,
    ID_employeeGroup: payload.id,
    employee_group_data: payload.employee_group_data,
    isCashierEnabled: payload.isCashierEnabled,
  }

  try {
    const { data: res } = await conn.put(endpoint, body)
    if (!res.status) {
      throw res
    }
    return
  } catch (error) {
    console.log(error)
  }
}

export const deleteEmployeeGroup = async ({ commit, state }, payload) => {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/employees/employeeGroup`
  const body = { sub_id: payload.sub_id, ID_employeeGroup: payload.id }

  try {
    const { data: res } = await conn.delete(endpoint, { data: body })
    if (!res.status) {
      throw res
    }
    return
  } catch (error) {
    console.log(error)
  }
}

export const createEmployee = async ({ commit, state }, payload) => {
  //console.log(`entering createEmployee`)
  //console.log(payload)
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/employees/createEmployee`
  const body = { sub_id: payload.sub_id, employee_data: payload.employee_data }

  try {
    const { data: res } = await conn.post(endpoint, body)
    if (!res.status) {
      throw res
    }
  } catch (error) {
    console.log(error)
  }
}

export const bulkCreateEmployee = async ({ commit, state }, payload) => {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/employees/bulk-create-employee`
  const body = {
    sub_id: payload.sub_id,
    employeeGroupId: payload.employeeGroupId,
    employeeListData: payload.employeeListData,
  }

  try {
    const { data: res } = await conn.post(endpoint, body)
    if (!res.status) {
      throw res
    }
  } catch (error) {
    console.log(error)
  }
}

export const editEmployee = async ({ commit, state }, payload) => {
  //console.log(`entering editEmployee`)
  //console.log(payload)
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/employees/editEmployee`
  const body = {
    sub_id: payload.sub_id,
    employee_data: payload.employee_data,
    employeeGroupId: payload.employeeGroupId,
  }

  try {
    const { data: res } = await conn.post(endpoint, body)
    if (!res.status) {
      throw res
    }
  } catch (error) {
    console.log(error)
  }
}

export const deleteEmployee = async ({ commit, state }, payload) => {
  //console.log(`entering deleteEmployee`)
  //console.log(payload)
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/employees/deleteEmployee`
  const body = { sub_id: payload.sub_id, ID_employee: payload.ID_employee, employeeGroupId: payload.employeeGroupId }

  try {
    const { data: res } = await conn.post(endpoint, body)
    if (!res.status) {
      throw res
    }
  } catch (error) {
    console.log(error)
  }
}

export const getEmployeesByEmployeeGroupID = async ({ commit, state }, payload) => {
  //console.log(`entering getEmployeesByEmployeeGroupID`)
  //console.log(payload)
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/employees/getEmployeesFromEmployeeGroup`
  const body = { sub_id: payload.sub_id, ID_employeeGroup: payload.ID_employeeGroup }
  try {
    const { data: res } = await conn.post(endpoint, body)
    //console.log(res, 'resres')
    if (!res.status) {
      throw res
    }
    //console.log(res)
    commit('setEmployeesFromEmployeeGroup', res.data)
  } catch (error) {
    console.log(error)
  }
}

export const getEmployeeByID = async ({ commit, state }, payload) => {
  //console.log(`entering getEmployeesByID`)
  //console.log(payload)
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/employees/getEmployeeByID`
  const body = { sub_id: payload.sub_id, ID_employee: payload.ID_employee }

  try {
    const { data: res } = await conn.post(endpoint, body)
    if (!res.status) {
      throw res
    }
    //console.log(res)
    commit('setCurrentEmployee', res.data)
  } catch (error) {
    console.log(error)
  }
}

export const createDisbursement = async ({ commit, state }, payload) => {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/disbursement/create-disbursement`
  const body = payload

  try {
    const { data: res } = await conn.post(endpoint, body)
    if (!res.status) {
      throw res
    }
    commit('setDisbursementList', [...state.disbursementList, res.data])
    return res.data
  } catch (error) {
    console.log(error)
  }
}

export const getDisbursementList = async ({ commit, state }, payload) => {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/disbursement/list-disbursement`
  const body = { sub_id: payload.sub_id, employeeGroupId: payload.employeeGroupId }

  try {
    const { data: res } = await conn.post(endpoint, body)
    if (!res.status) {
      throw res
    }
    //console.log(res.data)
    commit('setDisbursementList', res.data)
  } catch (error) {
    console.log(error)
  }
}

export const getDisbursementById = async ({ commit, state }, payload) => {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/disbursement/detail-disbursement/${payload.id}`
  const body = { sub_id: payload.sub_id }

  try {
    const { data: res } = await conn.post(endpoint, body)
    if (!res.status) {
      throw res
    }
    commit('setCurrentDisbursement', res.data)
    return res.data
  } catch (error) {
    console.log(error)
  }
}

export const getDisbursementDetailById = async ({ commit, state }, payload) => {
  //console.log(payload, 'payloadpayloadpayload')
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/disbursement/detail-disbursement-byId/${payload.id}`
  const body = { sub_id: payload.sub_id }
  try {
    return await conn.post(endpoint, body)
  } catch (error) {
    console.log(error)
  }
}

export const validateIfPhoneNumberAlreadyRegistered = async ({ commit, state }, payload) => {
  // //console.log(payload)
  const instanceId = `${payload.sub_id}-${payload.instance_ID}`
  const endpoint = `${process.env.VUE_APP_WA_SERVICES_URL}chats/exists/${instanceId}/${payload.phoneNumber}`

  try {
    return await conn.get(endpoint)
  } catch (error) {
    console.log(error)
  }
}

export const getEmployeeDetails = async ({ commit, state }, payload) => {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/disbursement/employee/${payload.id}`
  const body = { sub_id: payload.sub_id }

  try {
    const { data: res } = await conn.post(endpoint, body)
    if (!res.status) {
      throw res
    }
    commit('setEmployeeDetails', res.data)
    return res.data
  } catch (error) {
    console.log(error)
  }
}

// export const getAllEmployeeLoanWithNetOff = async ({ _ }, payload) => {
//   const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/disbursement/employee/${payload.id}/with-net-off`
//   const body = { sub_id: payload.sub_id }
//   try {
//     const { data: res } = await conn.post(endpoint, body)
//     if (!res.status) {
//       throw res
//     }
//     //console.log(res.data)
//     return res.data
//   } catch (error) {
//     console.log(error)
//   }
// }

//verification pin detail disbursement
export const checkPinDisbursement = async ({ commit, state }, payload) => {
  //console.log(payload, 'payload')
  try {
    const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/disbursement/verification-pin-disbursement`
    const body = {
      sub_id: payload.sub_id,
      pin: payload.pin,
      detailDisbursementId: payload.id,
    }
    const response = await conn.post(endpoint, body)
    return response.data
  } catch ({ response }) {
    return response.data
  }
}

export const updateDisbursmentToPaid = async (context, payload) => {
  try {
    const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/disbursement/update-disbursement-to-paid-by-cashier/${payload.id}`
    const body = {
      sub_id: payload.sub_id,
      employeeId: payload.employeeId ? payload.employeeId : null,
      disbursementId: payload.disbursementId ? payload.disbursementId : null,
    }
    return await conn.post(endpoint, body)
  } catch (error) {
    console.log(error)
  }
}

export const getDisbursementDetailWithPaymentMethodCashier = async ({ commit, state }, payload) => {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/disbursement/get-detail-disbursement-with-payment-method-cashier`
  const body = { sub_id: payload.sub_id, disbursementId: +payload.disbursementId }
  try {
    const { data: response } = await conn.post(endpoint, body)
    return response
  } catch (error) {
    console.log(error)
  }
}
