export default function () {
  return {
    loanSubs: [],
    loanSub: {},
    loanTypes: [],
    loanType: {},
    loanSubsNeedMyApproval: [],
    disbursementsNeedApproval: [],
  }
}
