export function setChartStatistic(state, obj) {
  state.chartStatistic = obj
}

export function setShopList(state, obj) {
  state.shopList = obj
}

export function setStatAgregate(state, obj) {
  state.statAgregate = obj
}

export function setProducts(state, obj) {
  state.products = obj
}

export function setProvince(state, obj) {
  state.provinces = obj
}

export function setCity(state, obj) {
  state.cities = obj
}

export function setCost(state, obj) {
  state.cost = obj
}

export function setLocalCouriers(state, obj) {
  state.localCouriers = obj
}
export function setInterCouriers(state, obj) {
  state.interCouriers = obj
}

export function setSheetData(state, obj) {
  state.sheetData = obj
}

export function setCloseModal(state, data) {
  state.closeModal = data
}

export function setCurrentShop(state, data) {
  state.currentShop = data
}
