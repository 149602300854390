export default {
  isResetChannels: false,
  listInstance: [],
  listWorkspaces: [],
  listUsers: [],
  selectedWorkspace: '',
  selectedInstance: null,
  statusInstance: false,
  instanceName: '',
  curUid: '',
  isLoggedIn: false,
  user: {
    sub_id: null,
    name: null,
    email: null,
    image: null,
    role: null,
    package: null,
    limited_menu: false,
    limited_channel: false,
    enabled_channel: null,
    enabled_menu: null,
    max_operator: null,
    max_channel: null,
    max_contact: null,
    access: {
      channel: false,
      inbox: false,
      contacts: false,
      broadcast: false,
      templates: false,
      operators: false,
    },
    token: null,
    is_free_trial: false,
  },
  listInvoice: [],
  successSync: true,
  listMeeting: [],

  // =============================================================================
  allRooms: [],
  filteredRooms: [],
  instanceVals: false,
  userState: null,
}
