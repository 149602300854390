import appConfigStoreModule from '@core/@app-config/appConfigStoreModule'

// import SecureLS from 'secure-ls'
import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import app from './app'
import auth from './auth'
import broadcast from './broadcast'
import campaign_manager from './campaign_manager'
import marketingCalendar from './campaign_manager/marketing-calendar'
import eOrdering from './e-ordering'
import firestore from './firestore'
import global from './global'
import happyhome from './happyhome'
import inbox from './inbox'
import inboxNavbar from './inboxNavbar'
import link from './link'
import manageLoan from './manage-loan'
import manageProject from './manage-project'
import manageSalary from './manage-salary'
import rooms from './rooms'
import subscriptions from './subscriptions'
import widget from './widget'
import workspaces from './workspaces'
import manageApproval from './manage-approval'

Vue.use(Vuex)

// const ls = new SecureLS({ isCompression: false })

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    appConfig: appConfigStoreModule,
    app,
    auth,
    broadcast,
    campaign_manager,
    marketingCalendar,
    global,
    workspaces,
    subscriptions,
    inboxNavbar,
    link,
    widget,
    inbox,
    eOrdering,
    firestore,
    rooms,
    manageProject,
    manageSalary,
    manageApproval,
    happyhome,
    manageLoan,
  },
  plugins: [
    createPersistedState(),

    // {
    // storage: {
    //   getItem: key => ls.get(key),
    //   setItem: (key, value) => ls.set(key, value),
    //   removeItem: key => ls.remove(key),
    // },
    // }
  ],
})

// export default function (/* { ssrContext } */) {
//   const Store = new Vuex.Store({
//     state: {},
//     mutations: {},
//     actions: {},
//     modules: {
//       appConfig: appConfigStoreModule,
//       app,
//       auth,
//     },

//     // enable strict mode (adds overhead!)
//     // for dev mode only
//     strict: process.env.DEBUGGING,
//     plugins: [createPersistedState()],
//   })

//   return Store
// }
