import conn from '@/plugins/axios-limit'

export async function editSetting({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/firestore/insert-settings`

  try {
    const res = await conn.post(endpoint, obj)
    if (res.data.status) {
      return true
    }

    return false
  } catch (error) {
    return false
  }
}

export async function createContactList({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/firestore/create-contact-list`

  try {
    const res = await conn.post(endpoint, obj)
    if (res.data.status) {
      return {
        status: true,
        id: res.data.data.id,
      }
    }

    return {
      status: false,
    }
  } catch (error) {
    return {
      status: false,
    }
  }
}

export async function updateContactList({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/firestore/update-contact-list`

  try {
    const res = await conn.post(endpoint, obj)
    if (res.data.status) {
      return true
    }

    return false
  } catch (error) {
    return false
  }
}

export async function deleteContactList({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/firestore/delete-contact-list`

  try {
    const res = await conn.post(endpoint, obj)
    if (res.data.status) {
      return true
    }

    return false
  } catch (error) {
    return false
  }
}

export async function deleteBatchContactList({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/firestore/delete-batch-contact-list`

  try {
    const res = await conn.post(endpoint, obj)
    if (res.data.status) {
      return true
    }

    return false
  } catch (error) {
    return false
  }
}

export async function updateContacts({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/firestore/update-contact-list-value`

  try {
    const res = await conn.post(endpoint, obj)
    if (res.data.status) {
      return true
    }

    return false
  } catch (error) {
    return false
  }
}
