export function setAutoCloseChat(state, autoCloseChat) {
  state.autoCloseChat = autoCloseChat
}
export function setNotifAllOrAssigned(state, notifAllOrAssigned) {
  state.notifAllOrAssigned = notifAllOrAssigned
}
export function setAutoAssignChatOnReply(state, autoAssignChatOnReply) {
  state.autoAssignChatOnReply = autoAssignChatOnReply
}
export function setAutoAssignNewChat(state, autoAssignNewChat) {
  state.autoAssignNewChat = autoAssignNewChat
}
export function setGlobalChannel(state, globalChannel) {
  state.globalChannel = globalChannel
}
export function setLocale(state, locale) {
  state.locale = locale
}
export function setServerConnectionStatus(state, status) {
  state.serverConnection = status
}
export function setContacts(state, contacts) {
  state.contacts = contacts
}
export function setFilterRoom(state, filterRoom) {
  state.filterRoom = filterRoom
}
export function setCurrentRoom(state, currentRoom) {
  state.currentRoom = currentRoom
}
export function setSearch(state, search) {
  state.search = search
}
export function setIsScanning(state, isScanning) {
  state.isScanning = isScanning
}
export function setLoginState(state, loginState) {
  state.loginState = loginState
}
export function updateContacts(state, obj) {
  state.contacts[obj.idx] = obj.data
}
export function addNewContacts(state, contacts) {
  state.contacts.splice(contacts.length - 1, 1)
  state.contacts.push(contacts)
}
export function setContactsById(state, contact) {
  const index = state.contacts ? state.contacts.findIndex(item => item._id === contact.id) : -1

  if (index >= 0) {
    if (contact.deleted) {
      state.contacts.splice(index, 1)
    } else {
      state.contacts[index] = contact.doc
    }
  } else {
    state.contacts.push(contact.doc)
  }
}
export function setSettingsById(state, settings) {
  const index = state.settings ? state.settings.findIndex(item => item._id === settings.id) : -1

  if (index >= 0) {
    if (settings.deleted) {
      state.settings.splice(index, 1)
    } else {
      state.settings[index] = settings.doc
    }
  } else {
    state.settings.push(settings.doc)
  }
}
export function setSettings(state, settings) {
  state.settings = settings
}
export function setLink(state, settings) {
  state.link = settings
}
export function setRooms(state, rooms) {
  state.rooms = rooms
}
export function setSyncFromServer(state, syncFromServer) {
  state.syncFromServer = syncFromServer
}
export function setFilterChat(state, filterChat) {
  state.filterChat = filterChat
}
export function setLang(state, lang) {
  state.lang = lang
}
export function setLastSeq(state, lastSeq) {
  state.lastSeq = lastSeq
}
export function setReplicateStatus(state, status) {
  state.replicateStatus = { ...state.replicateStatus, ...status }
}
export function setStatusChannels(state, statusChannels) {
  state.statusChannels = statusChannels
}
export function setMuteValue(state, muteValue) {
  state.muteValue = muteValue
}
export function setBoldUnreplied(state, boldUnreplied) {
  state.boldUnreplied = boldUnreplied
}
export function setVersion(state, version) {
  state.version = version
}
export function setMessageOffline(state, message) {
  state.messages_offline = message
}
export function addMessageOffline(state, message) {
  state.messages_offline.push(message)
}
export function spliceMessageOffline(state, keyId) {
  const idx = state.messages_offline.findIndex(x => x.keyId === keyId)
  if (idx > -1) state.messages_offline.splice(idx, 1)
}

export function setInitReplicateStatus(state, status) {
  state.initReplicateStatus = { ...state.initReplicateStatus, ...status }
}

export function setContactsLokiDb(state, db) {
  state.contactsLokiDb = db
}

export function setChatsLokiDb(state, db) {
  state.chatsLokiDb = db
}

export function setLokiDb(state, db) {
  state.lokiDb = db
}

export function setNotifPayload(state, db) {
  state.notifPayload = db
}

export function setLoadingMessages(state, data) {
  state.loadingMessages = data
}

export function setCurrentScanned(state, data) {
  state.currentScanned = data
}

export function setEnabledApps(state, data) {
  state.enabledApps = data
}
export function setAllContactData(state, data) {
  // //console.log(data, 'masuk mutation')
  state.allContactData = data
}
export function setRoleSettings(state, data) {
  // //console.log(data, 'masuk mutation')
  state.roleSettings = data
}

export function SET_CUSTOMER_LIST_CONTACTS(state, data) {
  state.customerListContacts = data
}

export function SET_CUSTOMER_GROUPS(state, data) {
  state.customerGroups = data
}

export function setChatHeaderPayload(state, data) {
  state.chatHeaderPayload = data
}

export function setSelectedChatHeader(state, data) {
  state.selectedChatHeader = data
}

export function setMentionChatPayload(state, data) {
  state.mentionChatPayload = data
}

export function setIsCustomerGroupChanged(state, data) {
  state.IsCustomerGroupChanged = data
}

export function setLastInserted(state, data) {
  state.lastInserted = data
}

export function setStoreContacts(state, data) {
  // //console.log(data, 'masuk mutation')
  state.storeContacts = data
}

export function setMessageObj(state, { key, payload }) {
  state.messageObj[key] = payload
}

export function resetMessageObj(state, payload) {
  state.messageObj = payload
}
