export const setSchedules = (state, payload) => {
  state.schedules = payload.schedules
}
export const setSchedule = (state, payload) => {
  state.schedule = payload.schedule
}
export const setPostTemplates = (state, payload) => {
  state.postTemplates = payload
}
export const setPostTemplate = (state, payload) => {
  state.postTemplate = payload
}
export const setPostApproval = (state, payload) => {
  state.postApproval = payload
}
