import { mdiFileOutline, mdiHomeOutline } from '@mdi/js'

/* eslint-disable */
// prettier-ignore
export default [
{ header: 'Pages' },

{ title: 'Home', icon: mdiHomeOutline, to: 'home' },
{ title: 'Second Page', icon: mdiFileOutline, to: 'home' },
]
