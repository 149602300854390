export default {
  chartStatistic: [],
  shopList: [],
  statAgregate: {},
  products: [],
  provinces: [],
  cities: [],
  cost: [],
  interCouriers: [],
  localCouriers: [],
  sheetData: [],
  currentShop: null,
  closeModal: false,
}
