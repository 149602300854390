import axios from 'axios'

// axios limit
const MAX_REQUESTS_COUNT = 15
const INTERVAL_MS = 10
let PENDING_REQUESTS = 0

// create new axios instance
const conn = axios.create({})
conn.defaults.timeout = 20000
/**
 * Axios Request Interceptor
 */
conn.interceptors.request.use(function(config) {
  return new Promise((resolve, reject) => {
    let interval = setInterval(() => {
      if (PENDING_REQUESTS < MAX_REQUESTS_COUNT) {
        PENDING_REQUESTS++
        clearInterval(interval)
        resolve(config)
      }
    }, INTERVAL_MS)
  })
})

/**
 * Axios Response Interceptor
 */
conn.interceptors.response.use(
  function(response) {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1)
    return Promise.resolve(response)
  },
  function(error) {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1)
    return Promise.reject(error)
  },
)

export async function fetchChartStatistic({ commit, state }, obj) {
  const endpoint =
    process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '') + 'superwa/couchdb/history/get-operator-statistic'

  return conn
    .post(endpoint, {
      sub_id: obj.sub_id,
      end_date: obj.end_date,
      start_date: obj.start_date,
      email: obj.email,
    })
    .then(async response => {
      if (response.data.status) {
        commit('setChartStatistic', response.data.data)
        commit('setStatAgregate', response.data.stat)
        return {
          status: true,
          errorMessage: '',
        }
      }
      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      // to do check auth
      return {
        status: false,
        errorMessage: error,
      }
    })
}

export async function editGenerateLink({ commit, state }, obj) {
  const endpoint = process.env.DB_API_URL.replace(/['"]+/g, '') + 'superwa/couchdb/link/update-shop-data'

  return conn
    .post(endpoint, {
      sub_id: obj.sub_id,
      link_id: obj.link_id,
      shop_data: {
        name: obj.shop_data.name,
        alamat: obj.shop_data.alamat,
        shop_open: obj.shop_data.shop_open,
        shop_banner: obj.shop_data.shop_banner,
        currency: obj.shop_data.currency,
        currency_symbol: obj.shop_data.currency_symbol,
        menu: obj.shop_data.menu,
        open: obj.shop_data.open,
        close: obj.shop_data.close,
      },
    })
    .then(async response => {
      if (response.data.status) {
        return {
          status: true,
          errorMessage: '',
        }
      }
      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      // to do check auth
      return {
        status: false,
        errorMessage: error,
      }
    })
}

export async function saveGenerateLink({ commit, state }, obj) {
  const endpoint = process.env.DB_API_URL.replace(/['"]+/g, '') + 'superwa/couchdb/link/generate-order-link'

  return conn
    .post(endpoint, {
      sub_id: obj.sub_id,
      instance_id: obj.instance_id,
      shop_data: {
        name: obj.shop_data.name,
        alamat: obj.shop_data.alamat,
        shop_open: obj.shop_data.shop_open,
        shop_banner: obj.shop_data.shop_banner,
        currency: obj.shop_data.currency,
        currency_symbol: obj.shop_data.currency_symbol,
        menu: obj.shop_data.menu,
        open: obj.shop_data.open,
        close: obj.shop_data.close,
      },
    })
    .then(async response => {
      if (response.data.status) {
        return {
          status: true,
          errorMessage: '',
        }
      }
      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      // to do check auth
      return {
        status: false,
        errorMessage: error,
      }
    })
}

export async function updateEnabledMessageLink({ commit, state }, obj) {
  const endpoint = process.env.DB_API_URL.replace(/['"]+/g, '') + 'superwa/couchdb/link/toggle-chat-link'

  return conn
    .post(endpoint, {
      sub_id: obj.sub_id,
      link_id: obj.link_id,
    })
    .then(async response => {
      if (response.data.status) {
        return {
          status: true,
          errorMessage: '',
        }
      }
      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      // to do check auth
      return {
        status: false,
        errorMessage: error,
      }
    })
}

export async function updateGenerateMessageLink({ commit, state }, obj) {
  const endpoint = process.env.DB_API_URL.replace(/['"]+/g, '') + 'superwa/couchdb/link/update-chat-data'

  return conn
    .post(endpoint, {
      sub_id: obj.sub_id,
      link_id: obj.link_id,
      message: obj.message,
      reply_message: obj.reply_message,
      geo_loc_accuracy: obj.geoAccuracy,
    })
    .then(async response => {
      if (response.data.status) {
        return {
          status: true,
          errorMessage: '',
        }
      }
      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      // to do check auth
      return {
        status: false,
        errorMessage: error,
      }
    })
}

export async function saveGenerateMessageLink({ commit, state }, obj) {
  const endpoint = process.env.DB_API_URL.replace(/['"]+/g, '') + 'superwa/couchdb/link/generate-chat-link'

  return conn
    .post(endpoint, {
      sub_id: obj.sub_id,
      instance_id: obj.instance_id,
      message: obj.message,
      reply_message: obj.reply_message,
      geo_loc_accuracy: obj.geoAccuracy,
    })
    .then(async response => {
      if (response.data.status) {
        return {
          status: true,
          errorMessage: '',
        }
      }
      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      // to do check auth
      return {
        status: false,
        errorMessage: error,
      }
    })
}

export async function deleteShopLink({ commit, state }, obj) {
  const endpoint = process.env.DB_API_URL.replace(/['"]+/g, '') + 'superwa/couchdb/link/delete-shop-data'

  return conn
    .post(endpoint, {
      sub_id: obj.sub_id,
      link_id: obj.link_id,
    })
    .then(async response => {
      if (response.data.status) {
        return {
          status: true,
          errorMessage: '',
        }
      }
      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      // to do check auth
      return {
        status: false,
        errorMessage: error,
      }
    })
}

export async function deleteMessageLink({ commit, state }, obj) {
  const endpoint = process.env.DB_API_URL.replace(/['"]+/g, '') + 'superwa/couchdb/link/delete-chat-data'

  return conn
    .post(endpoint, {
      sub_id: obj.sub_id,
      link_id: obj.link_id,
    })
    .then(async response => {
      if (response.data.status) {
        return {
          status: true,
          errorMessage: '',
        }
      }
      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error => {
      // to do check auth
      return {
        status: false,
        errorMessage: error,
      }
    })
}

export async function addWorkspace({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/add-workspace`
  return conn
    .post(endpoint, obj)
    .then(async response => {
      if (response.data.status) {
        return {
          status: true,
          errorMessage: '',
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error =>
      // to do check auth
      ({
        status: false,
        errorMessage: error,
      }),
    )
}

export async function deleteWorkspace({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/delete-workspace`
  return conn
    .post(endpoint, obj)
    .then(async response => {
      if (response.data.status) {
        return {
          status: true,
          errorMessage: '',
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error =>
      // to do check auth
      ({
        status: false,
        errorMessage: error,
      }),
    )
}
