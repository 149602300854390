/* eslint-disable */

export function setBroadcastListData(state, obj) {
  state.broadcastListData = obj
}

export function setBroadcastStatistic(state, obj) {
  state.broadcastStatistic = obj
}

export function setBroadcastContacts(state, obj) {
  state.broadcastContacts = obj.contacts
  state.broadcastContactsList = obj.contacts_list
  state.broadcastExtraField = obj.extra_field
}
