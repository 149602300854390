import axios from 'axios'

export const verifyAccountUserHappyhome = async ({}, phoneNumber) => {
  let status = false
  let message = ''
  try {
    const { data } = await axios.post(
      process.env.VUE_APP_DB_API_URL + 'superwa/couchdb/auth-happyhome/verify-account',
      {
        phoneNumber,
      },
    )

    status = data.status
    message = data.message
  } catch ({ response }) {
    //console.log(response)
    status = response.data.status
    message = response.data.message
  }

  return {
    status,
    message,
  }
}

export const rejectDataVerificationAccount = async ({}, obj) => {
  let status = false
  let message = ''
  try {
    const { data } = await axios.post(
      process.env.VUE_APP_DB_API_URL + 'superwa/couchdb/auth-happyhome/reject-verification-account',
      obj,
    )

    status = data.status
    message = data.message
  } catch ({ response }) {
    //console.log(response)
    status = response.data.status
    message = response.data.message
  }

  return {
    status,
    message,
  }
}

// calculator-cicilan (obsolete)
// export const getPropertyType = async ({}, id) => {
//   let status = false
//   let message = ''
//   let value = null
//   try {
//     const { data } = await axios(
//       process.env.VUE_APP_DB_API_URL + 'superwa/couchdb/happy-home-directus/collection-propertyTypes/' + id
//     )
//     status = true
//     message = data.message
//     value = data.value
//   } catch ({response}) {
//     //console.log(response)
//     status = false
//     message = response.data.message
//   }

//   return {
//     status,
//     message,
//     value,
//   }
// }
// calculator-cicilan (obsolete)
