/* eslint-disable no-unused-vars */
/* eslint-disable import/prefer-default-export */
import conn from '@/plugins/axios-limit'
// eslint-disable-next-line import/no-cycle
import store from '..'

export async function getFreeTrialLength({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/subscriptions/get-free-trial-length`

  return conn
    .get(endpoint)
    .then(async response => {
      if (response.data.status) {
        return {
          status: true,
          data: response.data,
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error =>
      // to do check auth
      ({
        status: false,
        errorMessage: error,
      }),
    )
}

export async function getUserFreeTrial({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/subscriptions/get-user-free-trial`

  return conn
    .post(endpoint, obj)
    .then(async response => {
      if (response.data.status) {
        return {
          status: true,
          data: response.data,
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error =>
      // to do check auth
      ({
        status: false,
        errorMessage: error,
      }),
    )
}

export async function startFreeTrial({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/subscriptions/start-free-trial`
  const { token } = store.getters['auth/getUser']
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  }

  return conn
    .post(endpoint, obj, config)
    .then(async response => {
      if (response.data.status) {
        return {
          status: true,
          data: response.data,
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error =>
      // to do check auth
      ({
        status: false,
        errorMessage: error,
      }),
    )
}

export async function updateIsFreeTrial({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/subscriptions/update-is-free-trial`
  const { token } = store.getters['auth/getUser']
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  }

  return conn
    .post(endpoint, obj, config)
    .then(async response => {
      if (response.data.status) {
        return {
          status: true,
          data: response.data,
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error =>
      // to do check auth
      ({
        status: false,
        errorMessage: error,
      }),
    )
}

export async function getCouponAvailability({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/check-coupon-availability`

  return conn
    .post(endpoint, obj)
    .then(async response => {
      if (response.data.status) {
        return {
          status: true,
          data: response.data,
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error =>
      // to do check auth
      ({
        status: false,
        errorMessage: error,
      }),
    )
}
// eslint-disable-next-line no-unused-vars
export async function fetchListInvoice({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/invoices/list`

  commit('setListInvoice', [])

  return conn
    .post(endpoint, obj)
    .then(async response => {
      if (response.data.status) {
        response.data.data.forEach(invoice => {
          if (!invoice.coupon) {
            // eslint-disable-next-line no-param-reassign
            invoice.coupon = 'No Coupon'
          }
          if (!invoice.extra) {
            // eslint-disable-next-line no-param-reassign
            invoice.extra = 'No Extra'
          }
        })
        commit('setListInvoice', response.data.data)

        return {
          data: response.data.data,
          status: true,
          errorMessage: '',
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error =>
      // to do check auth
      ({
        status: false,
        errorMessage: error,
      }),
    )
}

export async function fetchSubscriptions({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/xendit/get-subscriptions`

  return conn
    .post(endpoint, obj)
    .then(async response => {
      if (response.data.status) {
        return {
          status: true,
          data: response.data.data,
          errorMessage: '',
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error =>
      // to do check auth
      ({
        status: false,
        errorMessage: error,
      }),
    )
}

export async function fetchListActiveInvoiceXendit({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/xendit/check-invoice-xendit`

  commit('setActiveInvoice', [])

  return conn
    .post(endpoint, obj)
    .then(async response => {
      if (response.data.status) {
        commit('setActiveInvoice', response.data.data)

        return {
          data: response.data.data,
          status: true,
          errorMessage: '',
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error =>
      // to do check auth
      ({
        status: false,
        errorMessage: error,
      }),
    )
}

export async function getUserInfo({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/get-list-sub-id`

  commit('setUserInfo', {})

  return conn
    .post(endpoint, obj)
    .then(async response => {
      if (response.data.status) {
        response.data.data.forEach(userInfo => {
          commit('setUserInfo', userInfo)
        })

        return {
          status: true,
          errorMessage: '',
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error =>
      // to do check auth
      ({
        status: false,
        errorMessage: error,
      }),
    )
}

export async function createInvoiceXendit({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '')}superwa/couchdb/xendit/create-invoice`

  return conn
    .post(endpoint, {
      email: obj.email,
      sub_id: obj.sub_id,
      package_name: obj.package_name,
      extra_package: obj.extra_package,
      coupon: obj.coupon,
      upgrade: obj.upgrade,
      month: obj.month,
      amount: obj.amount,
    })
    .then(async response => {
      if (response.data.status) {
        return {
          status: true,
          data: response.data.data,
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error =>
      // to do check auth
      ({
        status: false,
        errorMessage: error,
      }),
    )
}

export async function updatePackage({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL.replace(/['"]+/g, '')}superwa/couchdb/paypal/payments-success`

  return conn
    .post(endpoint, {
      email: obj.email,
      sub_id: obj.sub_id,
      package: obj.package,
      extra_package: obj.extra_package,
      invoice_detail: obj.invoice_detail,
      upgrade: obj.upgrade,
      month: obj.month,
    })
    .then(async response => {
      if (response.data.status) {
        //console.log(response.data)

        return response
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error =>
      // to do check auth
      ({
        status: false,
        errorMessage: error,
      }),
    )
}

export async function getSubscriptionPlan({ commit, state }) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/pricing-plan?currency=IDR`

  commit('setSubscriptionPlan', [])

  return conn
    .get(endpoint)
    .then(async response => {
      if (response.data.status) {
        commit('setSubscriptionPlan', response.data.data)

        return {
          status: true,
          errorMessage: '',
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error =>
      // to do check auth
      ({
        status: false,
        errorMessage: error,
      }),
    )
}

export async function subscribeWithXendit({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/xendit/subscription`

  return conn
    .post(endpoint, obj)
    .then(async response => {
      if (response.data.status) {
        return {
          invoiceUrl: response.data.invoice_url,
          status: true,
          errorMessage: '',
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error =>
      // to do check auth
      ({
        status: false,
        errorMessage: error,
      }),
    )
}

export async function upgradeWithXendit({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/xendit/upgrade`
  const { token } = store.getters['auth/getUser']
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
    },
  }

  return conn
    .put(endpoint, obj, config)
    .then(async response => {
      if (response.data.status) {
        return {
          invoiceUrl: response.data.invoice_url,
          status: true,
          errorMessage: '',
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error =>
      // to do check auth
      ({
        status: false,
        errorMessage: error,
      }),
    )
}

export async function checkCoupon({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/check-coupon-availability`

  return conn
    .post(endpoint, obj)
    .then(async response => {
      if (response.data.status) {
        return {
          data: response.data,
          status: true,
          errorMessage: '',
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error =>
      // to do check auth
      ({
        status: false,
        errorMessage: error,
      }),
    )
}

export async function getSubscriptionInfo({ commit, dispatch }, obj) {
  try {
    let param = {}

    if (obj.email) {
      param = { email: obj.email }
    } else {
      param = { email: obj }
    }

    const workspaceInfo = await dispatch('auth/fetchUserWorkspace', param, { root: true })
    const userPayload = {}

    workspaceInfo.data.forEach(workspace => {
      if (obj.activeWorkspace) {
        if (obj.activeWorkspace.sub_id === workspace.sub_id) {
          userPayload.email = workspace.email
          userPayload.sub_id = workspace.sub_id
        }
      } else if (workspace.role === 'Admin') {
        userPayload.email = workspace.email
        userPayload.sub_id = workspace.sub_id
      }
    })

    const subsInfo = await dispatch('fetchSubscriptions', userPayload)
    //console.log(subsInfo,'ini dia subs')
    subsInfo.data.email = userPayload.email
    commit('setSubscriptionInfo', subsInfo.data)

    return {
      data: subsInfo,
      status: true,
      errorMessage: '',
    }
  } catch (error) {
    return {
      status: false,
      errorMessage: error,
    }
  }
}

export async function getPlansList({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/paypal/list-plans?size=20&page=1`

  return conn
    .get(endpoint)
    .then(async response => {
      if (response.data) {
        let planId = ''
        response.data.plans.forEach(plan => {
          if (plan.name.includes(obj.plan)) {
            planId = plan.id
          }
        })

        return {
          planId,
          status: true,
          errorMessage: '',
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error =>
      // to do check auth
      ({
        status: false,
        errorMessage: error,
      }),
    )
}

export async function subscribeWithPaypal({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/paypal/create-subs`

  return conn
    .post(endpoint, obj)
    .then(async response => {
      if (response.data.status) {
        return {
          invoiceUrl: response.data.links[0].href,
          status: true,
          errorMessage: '',
        }
      }

      return {
        status: false,
        errorMessage: response.data.message,
      }
    })
    .catch(error =>
      // to do check auth
      ({
        status: false,
        errorMessage: error,
      }),
    )
}

export async function getApiInvoice({ commit, state }, obj) {
  const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/invoices/detail/${obj}`

  return conn
    .get(endpoint)
    .then(async response => {
      return response
    })
    .catch(error =>
      // to do check auth
      ({
        status: false,
        errorMessage: error,
      }),
    )
}

export async function getLocation({ commit, state }) {
  const endpoint = `https://chat.superwa.io/ip`

  return conn
    .get(endpoint)
    .then(async response => {
      commit('setCurrentLang', response.data.country)

      return {
        status: true,
        data: response.data.country,
        message: 'Success',
      }
    })
    .catch(error =>
      // to do check auth
      ({
        status: false,
        errorMessage: error,
      }),
    )
}
