<template>
  <v-menu
    offset-y
    left
    nudge-bottom="14"
    min-width="230"
    z-index="9999999999"
    content-class="user-profile-menu-content"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-badge bottom color="success" overlap offset-x="12" offset-y="12" class="ms-4" dot>
        <v-avatar size="40px" v-bind="attrs" color="primary" class="v-avatar-light-bg primary--text" v-on="on">
          <img v-if="imgerror === false" :src="appLogo" @error="imgerror = true" />
          <span v-else class="white--text text-h5">user</span>
        </v-avatar>
      </v-badge>
    </template>
    <v-list>
      <div class="pb-3 pt-2">
        <v-badge bottom color="success" overlap offset-x="12" offset-y="12" class="ms-4" dot>
          <v-avatar size="40px" color="primary" class="v-avatar-light-bg primary--text">
            <img v-if="imgerror === false" :src="appLogo" @error="imgerror = true" />
            <span v-else class="white--text text-h5">user</span>
          </v-avatar>
        </v-badge>
        <div class="d-inline-flex flex-column justify-center ms-3 me-3" style="vertical-align: middle">
          <span class="text--primary font-weight-semibold mb-n1">
            {{ user.name }}
          </span>
          <small class="text--disabled text-capitalize">{{ user.role }}</small>
        </div>
      </div>

      <v-divider></v-divider>

      <!-- Logout -->
      <!-- <v-list-item @click="toLoanApprovalPage">
        <v-list-item-content>
          <v-list-item-title> 
            <v-chip
              v-if="totalNotifications > 0"
              small
              color="error"
              class="mr-1"
            >
              {{ totalNotifications }}
            </v-chip> {{ $t('Approval') }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->
      <v-list-item @click="logout">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiLogoutVariant }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('logout') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import store from '@/store'
import {
  mdiAccountOutline,
  mdiChatOutline,
  mdiCheckboxMarkedOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiEmailOutline,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
} from '@mdi/js'
import themeConfig from '@themeConfig'

export default {
  data() {
    return {
      imgerror: false,
    }
  },
  setup() {
    return {
      icons: {
        mdiAccountOutline,
        mdiEmailOutline,
        mdiCheckboxMarkedOutline,
        mdiChatOutline,
        mdiCogOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
      },
      appLogo: themeConfig.app.logo,
    }
  },
  computed: {
    user() {
      return store.getters['auth/getUser']
    },

    loanSubmissionsNeedApproval() {
      return this.$store.getters['manageLoan/getLoanSubsNeedMyApproval']
    },
    disbursementsNeedApproval() {
      return this.$store.getters['manageApproval/getDisbursementsNeedApproval']
    },
    totalNotifications() {
      const total = +this.loanSubmissionsNeedApproval.length + +this.disbursementsNeedApproval.length

      return !total ? 0 : total
    },
  },
  methods: {
    async toLoanApprovalPage() {
      await this.$store.dispatch('manageLoan/getLoanSubmissionsNeedMyApproval', {
        sub_id: this.user.sub_id,
        input_data: { my_user_data: this.user },
      })
      this.$router.push('/manage-approval')
    },
    logout() {
      // this.$store.dispatch('auth/setOnlineStatus', {
      //   token: this.user.token,
      //   subId: this.user.sub_id,
      //   email: this.user.email,
      //   online: false,
      // })
      store.dispatch('auth/logout')
      this.$router.push('/login')
      setTimeout(function () {
        window.location.reload()
      }, 500)
    },
  },
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
