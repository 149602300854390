/* eslint-disable */
import axios from 'axios'
/* eslint-disable */

// axios limit
const MAX_REQUESTS_COUNT = 15
const INTERVAL_MS = 10
let PENDING_REQUESTS = 0

// create new axios instance
const conn = axios.create({})
conn.defaults.timeout = 20 * 60 * 1000

/**
 * Axios Request Interceptor
 */
conn.interceptors.request.use(function (config) {
  return new Promise((resolve, reject) => {
    const interval = setInterval(() => {
      if (PENDING_REQUESTS < MAX_REQUESTS_COUNT) {
        PENDING_REQUESTS++
        clearInterval(interval)
        resolve(config)
      }
    }, INTERVAL_MS)
  })
})

/**
 * Axios Response Interceptor
 */
conn.interceptors.response.use(
  function (response) {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1)

    return Promise.resolve(response)
  },
  function (error) {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1)

    return Promise.reject(error)
  },
)

// Start Post
export async function createPost(context, payload) {
  //console.log(payload, 'payload create post')

  const endpoint = `${process.env.VUE_APP_CAMPAIGN_LANDING_SERVICE_API_URL}posts/create`
  try {
    const body = { sub_id: payload.sub_id }
    const config = { headers: { authorization: `Bearer ${payload.token}` } }
    if (payload.data) {
      body.data = {
        post_name: payload.data.post_name,
        description: payload.data.description,
        isDraft: payload.data.isDraft ? payload.data.isDraft : false,
        idCampaign: payload.data.idCampaign,
        approvement: payload.data.approvement,
        message_template: payload.data.message_template,
        post_creator: payload.data.post_creator,
        statusApproval: payload.data.statusApproval,
        broadcastMode: payload.data.broadcastMode,
      }
    }

    const { data } = await conn.post(endpoint, body, config)

    //console.log(data, 'data create post')
  } catch (error) {
    console.log(error)
  }
}
export async function fetchAllPostTemplates({ commit }, payload) {
  const endpoint = `${process.env.VUE_APP_CAMPAIGN_LANDING_SERVICE_API_URL}posts`
  try {
    const body = { sub_id: payload.sub_id }
    const config = { headers: { authorization: `Bearer ${payload.token}` } }
    const { data } = await conn.post(endpoint, body, config)
    commit('setPostApproval', data.posts)
  } catch (error) {
    console.log(error)
  }
}
export async function fetchPostTemplates({ commit }, payload) {
  const endpoint = `${process.env.VUE_APP_CAMPAIGN_LANDING_SERVICE_API_URL}posts/getByCampaignId`
  try {
    const config = { headers: { authorization: `Bearer ${payload.token}` } }
    const body = { sub_id: payload.sub_id }
    if (payload.data) {
      body.data = payload.data
    }
    const { data: res } = await conn.post(endpoint, body, config)
    commit('setPostTemplates', res.data)
  } catch (error) {
    console.log(error)
  }
}
export async function fetchPostTemplate({ commit }, payload) {
  const endpoint = `${process.env.VUE_APP_CAMPAIGN_LANDING_SERVICE_API_URL}posts/getById`
  try {
    const body = { sub_id: payload.sub_id, id: payload.data.postId }
    const config = { headers: { authorization: `Bearer ${payload.token}` } }
    const { data: res } = await axios.post(endpoint, body, config)
    commit('setPostTemplate', res.data)
  } catch (error) {
    console.log(error)
  }
}
export async function updatePostTemplate(_, payload) {
  const endpoint = `${process.env.VUE_APP_CAMPAIGN_LANDING_SERVICE_API_URL}posts/${payload.data.id}`
  try {
    const body = {
      sub_id: payload.sub_id,
      data: {
        post_name: payload.data.post_name,
        description: payload.data.description,
        idCampaign: payload.data.idCampaign,
        message_template: payload.data.message_template,
        isDraft: payload.data.isDraft,
        post_creator: payload.data.post_creator,
        broadcastMode: payload.data.broadcastMode,
      },
    }
    const config = { headers: { authorization: `Bearer ${payload.token}` } }
    await axios.put(endpoint, body, config)
  } catch (error) {
    console.log(error)
  }
}
export async function updateStatus({ commit }, payload) {
  const endpoint = `${process.env.VUE_APP_CAMPAIGN_LANDING_SERVICE_API_URL}posts/updateStatus/${payload.id}`
  try {
    //console.log(payload, 'mintak payload')
    const config = { headers: { authorization: `Bearer ${payload.token}` } }
    const body = { sub_id: payload.sub_id, approvement: payload.approvement, totalApprover: payload.totalApprover }
    const { data } = await conn.post(endpoint, body, config)
    return data
  } catch (error) {
    console.log(error)
  }
}
export async function updateManyStatus({ commit }, payload) {
  const endpoint = `${process.env.VUE_APP_CAMPAIGN_LANDING_SERVICE_API_URL}posts/updateManyStatus`
  try {
    const config = { headers: { authorization: `Bearer ${payload.token}` } }
    const body = { sub_id: payload.sub_id, data: payload.data }
    //console.log(body, 'bodyyy')
    const { data } = await conn.post(endpoint, body, config)
    return data
  } catch (error) {
    console.log(error)
  }
}
export async function deletePost({ commit, state }, payload) {
  const endpoint = `${process.env.VUE_APP_CAMPAIGN_LANDING_SERVICE_API_URL}posts/delete/${payload.postId}`
  try {
    const config = { headers: { authorization: `Bearer ${payload.token}` } }
    const body = { sub_id: payload.sub_id, postId: payload.postId }
    await conn.post(endpoint, body, config)
    commit(
      'setPostTemplates',
      state.postTemplates.filter(post => post.id !== payload.postId),
    )
    return
  } catch (error) {
    console.log(error)
  }
}

// Start Schedules
export async function fetchSchedules(context, payload) {
  //console.log(`yoooooooooooo`)
  const endpoint = `${process.env.VUE_APP_CAMPAIGN_LANDING_SERVICE_API_URL}schedules`
  try {
    const body = { sub_id: payload.sub_id }
    const config = { headers: { authorization: `Bearer ${payload.token}` } }
    if (payload.data) {
      body.data = payload.data
    }
    const { data } = await conn.post(endpoint, body, config)
    context.commit('setSchedules', data)
  } catch (error) {
    console.log(error)
  }
}

export async function fetchSchedule(context, payload) {
  const endpoint = `${process.env.VUE_APP_CAMPAIGN_LANDING_SERVICE_API_URL}schedules/getById`
  try {
    const body = { sub_id: payload.sub_id }
    const config = { headers: { authorization: `Bearer ${payload.token}` } }
    if (payload.data) {
      body.data = payload.data
    }
    const { data } = await conn.post(endpoint, body, config)
    context.commit('setSchedule', data)
    return data
  } catch (error) {
    console.log(error)
  }
}

export async function fetchSchedulesByIdCampaign(context, payload) {
  const endpoint = `${process.env.VUE_APP_CAMPAIGN_LANDING_SERVICE_API_URL}schedules/getByIdCampaign`
  try {
    const body = { sub_id: payload.sub_id }
    const config = { headers: { authorization: `Bearer ${payload.token}` } }
    if (payload.data) {
      body.data = payload.data
    }
    const { data } = await conn.post(endpoint, body, config)
    context.commit('setSchedules', data)
  } catch (error) {
    console.log(error)
  }
}

export async function postScheduleToSosmed(context, payload) {
  const API_KEY = process.env.VUE_APP_AYRSHARE_KEY
  try {
    let formattedChannel = []
    payload.channels?.forEach(element => {
      if (element !== 'WhatsApp') {
        formattedChannel.push(element)
      }
    })
    const { data } = payload
    //console.log(data.schedulePostData.data.id, 'datakokoasasasako')
    return fetch('https://app.ayrshare.com/api/post', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${API_KEY}`,
      },
      body: JSON.stringify({
        post: payload.schedule, // required
        platforms: formattedChannel, // required
        profileKeys: payload.profileKey, // required for client posting
        mediaUrls: payload.images, //optional
      }),
    })
      .then(res => res.json())
      .then(async json => {
        if (json.status !== 'error') {
          const endpoint = `${process.env.VUE_APP_CAMPAIGN_LANDING_SERVICE_API_URL}schedules/updateDataFromAyrshare`
          //console.log(payload, 'payloadayshare')
          const body = { sub_id: data.sub_id, dataFromAyrshare: json.posts, idSchedule: data.schedulePostData.data.id }
          const config = { headers: { authorization: `Bearer ${data.token}` } }
          const apa = await conn.post(endpoint, body, config)
        }
        //console.log(json, 'json post')
      })
      .catch(console.error)
  } catch (error) {
    console.log(error)
  }
}

export async function createSchedule(context, payload) {
  const endpoint = `${process.env.VUE_APP_CAMPAIGN_LANDING_SERVICE_API_URL}schedules/create`
  try {
    const body = { sub_id: payload.sub_id }
    const config = { headers: { authorization: `Bearer ${payload.token}` } }
    if (payload.data) {
      body.data = payload.data
    }
    const { data: res } = await conn.post(endpoint, body, config)
    context.commit('setSchedules', { schedules: [...context.state.schedules, res.data] })
    return res
  } catch (error) {
    console.log(error)
  }
}

export async function updateSchedule(context, payload) {
  const endpoint = `${process.env.VUE_APP_CAMPAIGN_LANDING_SERVICE_API_URL}schedules/${payload.id}`
  try {
    const body = { sub_id: payload.sub_id }
    const config = { headers: { authorization: `Bearer ${payload.token}` } }
    if (payload.data) {
      body.data = payload.data
    }
    await conn.put(endpoint, body, config)
  } catch (error) {
    console.log(error)
  }
}
export function fetchAnalytics(context, payload) {
  const API_KEY = process.env.VUE_APP_AYRSHARE_KEY
  fetch('https://app.ayrshare.com/api/analytics/post', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${API_KEY}`,
    },
    body: JSON.stringify({
      id: payload.idScheduleAyrshare, // required
      platforms: payload.platforms, // required
    }),
  })
    .then(res => res.json())
    .then(json => console.log(json, 'payload'))
    .catch(console.error)
}
export async function updateDataFromAyrshare(context, payload) {
  const endpoint = `${process.env.VUE_APP_CAMPAIGN_LANDING_SERVICE_API_URL}updateDataFromAyrshare/${payload.id}`
  try {
    const body = { sub_id: payload.sub_id }
    const config = { headers: { authorization: `Bearer ${payload.token}` } }
    if (payload.data) {
      body.data = payload.data
    }
    await conn.patch(endpoint, body, config)
  } catch (error) {
    console.log(error)
  }
}
