export default function () {
  return {
    employeeGroups: [],
    currentEmployeeGroup: {
      name: '',
      ID_project: '',
      setting: {
        approver: {
          type: '',
          list: [],
        },
        disbursement_type: 'manual',
        cashier: {
          status: false,
          phone_number: '',
          account_number: '',
        },
      },
    },
    employeesFromEmployeeGroup: [],
    currentEmployee: {
      name: '',
      phoneNumber: '',
      email: '',
      bankAccount: '',
      bankCode: '',
      accountNumber: '',
      totalLoan: 0,
    },
    disbursementList: [],
    currentDisbursement: {},
    currentProject: {},
    employeeDetails: {},
    currentDisbursementDetail: {},
  }
}
