<template>
  <v-card>
    <dialogAddListContact ref="dialogAddListContact"></dialogAddListContact>
    <!-- <pre>{{ form }}</pre> -->
    <v-snackbar
      v-model="showSnackbarWarning"
      color="error"
      :timeout="2000"
      :top="true"
      style="z-index: 5 !important"
    >
      {{ formValidationMessage }}
      <template #action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="closeSnackbarWarning()"
        >
          {{ $t('close') }}
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
      v-model="showSnackbarSuccess"
      color="success"
      :timeout="2000"
      :top="true"
      style="z-index: 5 !important"
    >
      {{ $t('EOrdering.populatedSuccess') }}
      <template #action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="showSnackbarSuccess = false"
        >
          {{ $t('close') }}
        </v-btn>
      </template>
    </v-snackbar>

    <v-card-title class="mobile-header">
      <div class="text-h5">
        {{ shopData ? $t('edit') : $t('create') }} {{ $t('shop') }}
      </div>
    </v-card-title>
    <v-divider />
    <v-card elevation="0">
      <v-card-text class="height-dialog">
        <v-form
          ref="form-add-template"
          v-model="valid"
          class=""
          lazy-validation
        >
          <!-- Shop Name Field -->

          <v-row class="mb-n8">
            <v-col
              cols="12"
              md="1"
              class="flex  mobile-col"
            ></v-col>
            <v-col
              cols="12"
              md="3"
              sm="4"
              class="flex "
            >
              <p class="order-text me-3 mt-2">
                {{ $t('EOrdering.shopName') }} *<span class="mobile-col-show ms-3"> : </span>
              </p>
            </v-col>
            <v-col
              cols="12"
              md="7"
              sm="8"
              class="d-flex col-margin-top-mobile"
            >
              <span class="order-text me-3 mb-3 mobile-span"> : </span>
              <v-skeleton-loader
                v-if="edit && form.name === ''"
                v-bind="attrs"
                class="mx-auto"
                max-width="2000"
                type="list-item"
              ></v-skeleton-loader>
              <v-text-field
                v-else
                v-model="form.name"
                outlined
                hide-details
                :rules="formValidation.name"
                required
                :label="$t('EOrdering.shopNameLabel')"
                class="mb-3 order-text"
                dense
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="1"
              class="flex  mobile-col"
            ></v-col>
          </v-row>
          <!-- End Shop Name Field -->
          <!-- Phone Number Field -->
          <v-row class="mb-n8">
            <v-col
              cols="12"
              md="1"
              class="flex  mobile-col"
            ></v-col>
            <v-col
              cols="12"
              md="3"
              sm="4"
              class="flex "
            >
              <p class="order-text me-3 mt-2">
                {{ $t('EOrdering.phoneNumber') }} * <span class="mobile-col-show ms-3"> : </span>
              </p>
            </v-col>
            <v-col
              cols="12"
              md="7"
              sm="8"
              class="d-flex col-margin-top-mobile"
            >
              <span class="order-text me-3 mb-3 mobile-span"> : </span>
              <v-skeleton-loader
                v-if="edit && form.phoneNumber === ''"
                v-bind="attrs"
                class="mx-auto"
                max-width="2000"
                type="list-item"
              ></v-skeleton-loader>
              <v-select
                v-else
                v-model="form.phoneNumber"
                :items="instances"
                :rules="formValidation.phoneNumber"
                item-text="label"
                item-value="value"
                outlined
                :label="$t('channels.channel')"
                hide-details
                required
                class="mb-3"
                dense
                attach
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              md="1"
              class="flex  mobile-col"
            ></v-col>
          </v-row>
          <!-- End Phone Number Field -->
          <!-- Shop Type Field -->
          <v-row class="mb-n8">
            <v-col
              cols="12"
              md="1"
              class="flex  mobile-col"
            ></v-col>
            <v-col
              cols="12"
              md="3"
              sm="4"
              class="flex "
            >
              <p class="order-text me-3 mt-2">
                {{ $t('EOrdering.shopType') }} * <span class="mobile-col-show ms-3"> : </span>
              </p>
            </v-col>
            <v-col
              cols="12"
              md="7"
              sm="8"
              class="d-flex col-margin-top-mobile"
            >
              <span class="order-text me-3 mb-3 mobile-span"> : </span>
              <v-skeleton-loader
                v-if="edit && Object.keys(form.shopType).length === 0"
                v-bind="attrs"
                class="mx-auto"
                max-width="2000"
                type="list-item"
              ></v-skeleton-loader>
              <v-select
                v-else
                v-model="form.shopType"
                :items="shopTypes"
                :rules="formValidation.shopType"
                outlined
                :label="$t('EOrdering.shopType')"
                hide-details
                return-object
                required
                class="mb-3"
                dense
                attach
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              md="1"
              class="flex  mobile-col"
            ></v-col>
          </v-row>
          <!-- End Shop Type Field -->
          <!-- Payment Method Field -->
          <v-row class="mb-n8">
            <v-col
              cols="12"
              md="1"
              class="flex  mobile-col"
            ></v-col>
            <v-col
              cols="12"
              md="3"
              sm="4"
              class="flex "
            >
              <p class="order-text me-3 mt-2">
                {{ $t('EOrdering.paymentMethod') }} *
                <v-tooltip
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="ms-1"

                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ icons.mdiInformation }}
                    </v-icon>
                  </template>
                  <span>Minimum Select One Payment Method</span>
                </v-tooltip>
                <span class="mobile-col-show ms-3"> : </span>
              </p>
            </v-col>
            <v-col
              cols="12"
              md="7"
              sm="8"
              class="d-flex col-margin-top-mobile"
            >
              <span class="order-text me-3 mb-3 mobile-span"> : </span>
              <v-checkbox
                v-model="form.pricing.enableCash"
                :label="$t('EOrdering.enableCash')"
                hide-details
                style="margin-top: 0"
              ></v-checkbox>
            </v-col>
            <v-col
              cols="12"
              md="1"
              class="flex  mobile-col"
            ></v-col>
          </v-row>
          <v-row class="mb-n8">
            <v-col
              cols="12"
              md="1"
              class="flex  mobile-col"
            ></v-col>
            <v-col
              cols="5"
              md="3"
              sm="4"
              class="flex "
            >
            </v-col>
            <v-col
              cols="12"
              md="2"
              sm="2"
              class="d-flex col-margin-top-mobile"
            >
              <v-checkbox
                v-model="form.pricing.enableXendit"
                :label="$t('EOrdering.enableXendit')"
                hide-details
                class="margin-start4"
                style="margin-top: 0"
              ></v-checkbox>
            </v-col>
            <v-col
              cols="5"
              md="1"
              class="flex  mobile-col-show"
            ></v-col>
            <v-col
              cols="12"
              md="5"
              sm="6"
              class="d-flex col-margin-top-mobile"
            >
              <v-skeleton-loader
                v-if="edit && form.pricing.keyXendit === ''"
                v-bind="attrs"
                class="mx-auto"
                max-width="2000"
                type="list-item"
              ></v-skeleton-loader>
              <v-text-field
                v-else
                id="password2"
                v-model="form.pricing.keyXendit"
                style="-webkit-text-security: none;"
                outlined
                hide-details
                required
                label="Xendit Key"
                class="mb-2"
                dense
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="1"
              class="flex  mobile-col"
            ></v-col>
          </v-row>


          <v-row class="mb-n8">
            <v-col
              cols="12"
              md="1"
              class="flex  mobile-col"
            ></v-col>
            <v-col
              cols="5"
              md="3"
              sm="4"
              class="flex "
            >
            </v-col>
            <v-col
              cols="12"
              md="2"
              sm="2"
              class="d-flex col-margin-top-mobile"
            >
              <v-checkbox
                v-model="form.pricing.enablePaypal"
                :label="$t('EOrdering.enablePaypal')"
                hide-details
                class="margin-start4"
                style="margin-top: 0"
              ></v-checkbox>
            </v-col>
            <v-col
              cols="5"
              md="1"
              class="flex  mobile-col-show"
            ></v-col>
            <v-col
              cols="12"
              md="5"
              sm="6"
              class="d-flex col-margin-top-mobile"
            >
              <v-skeleton-loader
                v-if="edit && form.pricing.keyPaypal === ''"
                v-bind="attrs"
                class="mx-auto"
                max-width="2000"
                type="list-item"
              ></v-skeleton-loader>
              <v-text-field
                v-else
                id="password"
                v-model="form.pricing.keyPaypal"
                outlined
                hide-details
                required
                class="mb-2"
                style="-webkit-text-security: none;"
                label="Paypal Key"
                dense
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="1"
              class="flex  mobile-col"
            ></v-col>
          </v-row>

          <v-row class="mb-n8">
            <v-col
              cols="12"
              md="1"
              class="flex  mobile-col"
            ></v-col>
            <v-col
              cols="5"
              md="3"
              sm="4"
              class="flex "
            >
            </v-col>
            <v-col
              cols="12"
              md="2"
              sm="2"
              class="d-flex col-margin-top-mobile"
            >
              <v-checkbox
                v-model="form.enableTax"
                :label="$t('EOrdering.includeTax')"
                hide-details
                class="margin-start4"
                style="margin-top: 0"
              ></v-checkbox>
            </v-col>
            <v-col
              cols="5"
              md="1"
              class="flex  mobile-col-show"
            ></v-col>
            <v-col
              cols="12"
              md="5"
              sm="6"
              class="d-flex col-margin-top-mobile"
            >
              <v-skeleton-loader
                v-if="edit && form.tax === ''"
                v-bind="attrs"
                class="mx-auto"
                max-width="2000"
                type="list-item"
              ></v-skeleton-loader>
              <v-text-field
                v-else
                v-model="taxValidator"
                outlined
                type="number"
                :rules="[formValidation.tax]"
                required
                :label="$t('EOrdering.tax')"
                class="mb-2"
                dense
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="1"
              class="flex  mobile-col"
            ></v-col>
          </v-row>
          <!-- End Payment Method Field -->
          <!-- Enable Delivery Pricing Field -->
          <v-row class="mb-n8">
            <v-col
              cols="12"
              md="1"
              class="flex  mobile-col"
            ></v-col>
            <v-col
              cols="112"
              md="7"
              sm="8"
              class="d-flex "
            >
              <v-checkbox
                v-model="form.address.enableDeliveryPricing"
                :label="$t('EOrdering.calculateDeliveryPricing')"
                hide-details
                style="margin-top: 0"
              ></v-checkbox>
              <v-tooltip
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="ms-1"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ icons.mdiInformation }}
                  </v-icon>
                </template>
                <span>{{ $t('EOrdering.calculatePricingInfo') }}</span>
              </v-tooltip>
            </v-col>
            <v-col
              cols="2"
              md="3"
              sm="4"
              class="d-flex"
            >
            </v-col>
            <v-col
              cols="12"
              md="1"
              class="flex  mobile-col"
            ></v-col>
          </v-row>

          <!-- End Enable Delivery Pricing Field -->

          <!-- Available Courier Field -->
          <v-row
            v-if="form.address.enableDeliveryPricing"
            class="mb-n6"
          >
            <v-col
              cols="12"
              md="1"
              class="flex  mobile-col"
            ></v-col>
            <v-col
              cols="12"
              md="3"
              sm="4"
              class="flex "
            >
              <p class="order-text me-3 mt-2">
                {{ $t('EOrdering.availableCourier') }} * <span class="mobile-col-show ms-3"> : </span>
              </p>
            </v-col>
            <v-col
              cols="12"
              md="7"
              sm="8"
              class="d-flex col-margin-top-mobile"
            >
              <span class="order-text me-3 mb-3 mobile-span"> : </span>
              <v-skeleton-loader
                v-if="
                  edit && form.address.availableCourier.length === 0 && !form.address.enableDeliveryPricing
                "
                v-bind="attrs"
                class="mx-auto"
                max-width="2000"
                type="list-item"
              ></v-skeleton-loader>
              <v-select
                v-else
                v-model="form.address.availableCourier"
                attach
                :items="localCouriers"
                :label="$t('EOrdering.availableCourier')"
                :rules="[v => Boolean(Object.keys(v || {})[0]) || 'Courier is required']"
                multiple
                hide-details
                required
                dense
                outlined
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              md="1"
              class="flex  mobile-col"
            ></v-col>
          </v-row>
          <!-- End Available Courier Field -->
          <!-- Province Field -->
          <v-row
            v-if="form.address.enableDeliveryPricing"
            class="mb-n6"
          >
            <v-col
              cols="12"
              md="1"
              class="flex  mobile-col"
            ></v-col>
            <v-col
              cols="12"
              md="3"
              sm="4"
              class="flex "
            >
              <p class="order-text me-3 mt-2">
                {{ $t('EOrdering.province') }} *<span class="mobile-col-show ms-3"> : </span>
              </p>
            </v-col>
            <v-col
              cols="12"
              md="7"
              sm="8"
              class="d-flex col-margin-top-mobile"
            >
              <span class="order-text me-3 mb-3 mobile-span"> : </span>
              <v-select
                v-model="form.address.province"
                :items="provinces.data"
                :label="$t('EOrdering.province')"
                :rules="[v => Boolean(Object.keys(v || {})[0]) || 'Province is required']"
                item-text="province"
                return-object
                hide-details
                required
                dense
                outlined
                attach
                item-value="value"
                @change="onChangeProvince()"
                @click="onChangeProvince()"
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              md="1"
              class="flex  mobile-col"
            ></v-col>
          </v-row>
          <!-- End Province Field -->
          <!-- City Field -->
          <v-row
            v-if="form.address.enableDeliveryPricing"
            class="mb-n11"
          >
            <v-col
              cols="12"
              md="1"
              class="flex  mobile-col"
            ></v-col>
            <v-col
              cols="12"
              md="3"
              sm="4"
              class="flex "
            >
              <p class="order-text me-3 mt-2">
                {{ $t('EOrdering.city') }} *<span class="mobile-col-show ms-3"> : </span>
              </p>
            </v-col>
            <v-col
              cols="12"
              md="7"
              sm="8"
              class="d-flex col-margin-top-mobile"
            >
              <span class="order-text me-3 mb-3 mt-n3 mobile-span"> : </span>
              <v-select
                v-model="form.address.city"
                :disabled="cityData.length === 0"
                :items="cityData"
                :label="$t('EOrdering.city')"
                item-text="text"
                return-object
                :rules="[v => Boolean(Object.keys(v || {})[0]) || 'City is required']"
                required
                hide-details
                dense
                outlined
                attach
                item-value="value"
                @change="getSubdistrict()"
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              md="1"
              class="flex  mobile-col"
            ></v-col>
          </v-row>
          <!-- End City Field -->
          <!-- Subdistrict Field -->
          <v-row
            v-if="form.address.enableDeliveryPricing"
            class="mb-n8"
          >
            <v-col
              cols="12"
              md="1"
              class="flex  mobile-col"
            ></v-col>
            <v-col
              cols="12"
              md="3"
              sm="4"
              class="flex "
            >
              <p class="order-text me-3 mt-2">
                {{ $t('EOrdering.subdistrict') }} *<span class="mobile-col-show ms-3"> : </span>
              </p>
            </v-col>
            <v-col
              cols="12"
              md="7"
              sm="8"
              class="d-flex col-margin-top-mobile"
            >
              <span class="order-text me-3 mb-3 mobile-span"> : </span>
              <v-select
                v-model="form.address.subdistrict"
                :disabled="subdistricts.length === 0"
                :items="subdistricts"
                :label="$t('EOrdering.subdistrict')"
                item-text="subdistrict_name"
                return-object
                :rules="[v => Boolean(Object.keys(v || {})[0]) || 'Subsdistrict is required']"
                hide-details
                required
                dense
                outlined
                attach
                item-value="value"
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              md="1"
              class="flex  mobile-col"
            ></v-col>
          </v-row>
          <!-- End Subdistrict Field -->
          <!-- Enable Delivery Inter -->
          <v-row class="mb-n6">
            <v-col
              cols="12"
              md="1"
              class="flex  mobile-col"
            ></v-col>
            <v-col
              cols="12"
              md="7"
              sm="8"
              class="d-flex "
            >
              <v-checkbox
                v-model="form.address.enableDeliveryPricingInter"
                :label="$t('EOrdering.calculateDeliveryPricingInter')"
                hide-details
                style="margin-top: 0"
              ></v-checkbox>
              <v-tooltip
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="ms-1"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ icons.mdiInformation }}
                  </v-icon>
                </template>
                <span>{{ $t('EOrdering.calculatePricingInfo') }}</span>
              </v-tooltip>
            </v-col>
            <v-col
              cols="2"
              md="3"
              sm="4"
              class="d-flex"
            >
            </v-col>
            <v-col
              cols="12"
              md="1"
              class="flex  mobile-col"
            ></v-col>
          </v-row>
          <!-- End Enable Delivery Inter -->

          <!-- Available Courier Field Inter-->
          <v-row
            v-if="form.address.enableDeliveryPricingInter"
            class="mb-n6"
          >
            <v-col
              cols="12"
              md="1"
              class="flex  mobile-col"
            ></v-col>
            <v-col
              cols="12"
              md="3"
              sm="4"
              class="flex "
            >
              <p class="order-text me-3 mt-2">
                {{ $t('EOrdering.availableCourier') }} *<span class="mobile-col-show ms-3"> : </span>
              </p>
            </v-col>
            <v-col
              cols="12"
              md="7"
              sm="8"
              class="d-flex col-margin-top-mobile"
            >
              <span class="order-text me-3 mb-3 mobile-span"> : </span>

              <v-select
                v-model="form.address.availableCourierInter"
                attach
                :items="interCouriers"
                :label="$t('EOrdering.availableCourier')"
                :rules="[v => Boolean(Object.keys(v || {})[0]) || 'Courier is required']"
                multiple
                hide-details
                required
                dense
                outlined
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              md="1"
              class="flex  mobile-col"
            ></v-col>
          </v-row>
          <!-- End Available Courier Field Inter-->
          <!-- Pos Field -->
          <v-row
            v-if="form.address.enableDeliveryPricingInter"
            class="mb-n8"
          >
            <v-col
              cols="12"
              md="1"
              class="flex  mobile-col"
            ></v-col>
            <v-col
              cols="12"
              md="3"
              sm="4"
              class="flex "
            >
              <p class="order-text me-3 mt-2">
                Pos Code *<span class="mobile-col-show ms-3"> : </span>
              </p>
            </v-col>
            <v-col
              cols="12"
              md="7"
              sm="8"
              class="d-flex col-margin-top-mobile"
            >
              <span class="order-text me-3 mb-3 mobile-span"> : </span>

              <v-text-field
                v-model="form.address.pos"
                :rules="[v => !!v || 'POS Code is required']"
                outlined
                hide-details
                required
                label="Pos Code"
                class="mb-3"
                dense
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="1"
              class="flex  mobile-col"
            ></v-col>
          </v-row>
          <!-- End Pos Field -->

          <!-- Enable Devlivery Inter Field -->
          <v-row
            v-if="form.address.enableDeliveryPricingInter"
            class="mb-n8"
          >
            <v-col
              cols="12"
              md="1"
              class="flex  mobile-col"
            ></v-col>
            <v-col
              cols="12"
              md="3"
              sm="4"
              class="flex "
            >
              <p class="order-text me-3 mt-2">
                Origin *<span class="mobile-col-show ms-3"> : </span>
              </p>
            </v-col>
            <v-col
              cols="12"
              md="7"
              sm="8"
              class="d-flex col-margin-top-mobile"
            >
              <span class="order-text me-3 mb-3 mobile-span"> : </span>

              <v-select
                v-model="form.address.origin"
                :items="raySpeedorigin"
                :rules="[v => Boolean(Object.keys(v || {})[0]) || 'Origin is required']"
                item-text="name"
                return-object
                outlined
                label="origin"
                hide-details
                required
                class="mb-3"
                dense
                attach
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              md="1"
              class="flex  mobile-col"
            ></v-col>
          </v-row>
          <!-- End Enable Devlivery Inter Field -->
          <!-- Address Field -->
          <v-row class="mb-n8">
            <v-col
              cols="12"
              md="1"
              class="flex  mobile-col"
            ></v-col>
            <v-col
              cols="12"
              md="3"
              sm="4"
              class="flex "
            >
              <p class="order-text me-3 mt-2">
                {{ $t('EOrdering.addressDetail') }} *<span class="mobile-col-show ms-3"> : </span>
              </p>
            </v-col>
            <v-col
              cols="12"
              md="7"
              sm="8"
              class="d-flex col-margin-top-mobile"
            >
              <span class="order-text me-3 mb-3 mobile-span"> : </span>
              <v-skeleton-loader
                v-if="edit && form.address.detail === ''"
                v-bind="attrs"
                class="mx-auto"
                max-width="2000"
                type="list-item"
              ></v-skeleton-loader>
              <v-text-field
                v-else
                v-model="form.address.detail"
                outlined
                hide-details
                required
                :label="$t('EOrdering.addressDetail')"
                :rules="formValidation.address"
                class="mb-3"
                dense
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="1"
              class="flex  mobile-col"
            ></v-col>
          </v-row>
          <!-- End Address Field -->
          <!-- GMAPS -->
          <v-row class="mb-n8">
            <v-col
              cols="12"
              md="1"
              class="flex  mobile-col"
            ></v-col>
            <v-col
              cols="12"
              md="3"
              sm="4"
              class="flex "
            >
              <p class="order-text me-3 mt-2">
                {{ $t('EOrdering.gmaps') }} * <span class="mobile-col-show ms-3"> : </span>
              </p>
              <!-- <v-btn class="instructions-action" @click="getLocation">
        Move to my location
      </v-btn> -->
            </v-col>
            <v-col
              cols="12"
              md="7"
              sm="8"
              class="d-flex col-margin-top-mobile"
            >
              <span class="order-text me-3 mb-3 mobile-span"> :

              </span>
              <v-checkbox
                v-model="setCurrentLocation"
                :label="$t('EOrdering.gmapsCurrentLoc')"
                hide-details
                style="margin-top: 0"
                @click="getLocation"
              ></v-checkbox>
            </v-col>
            <v-col
              cols="12"
              md="1"
              class="flex  mobile-col"
            ></v-col>
          </v-row>

          <div class="wrapper">
            <div class="map">
              <gmaps-map
                :options="mapOptions"
                @click="addMarker"
              >
                <gmaps-marker
                  v-for="(m, i) in markers"
                  :key="i"
                  :options="m"
                  @click="removeMarker(i)"
                />
              </gmaps-map>
            </div>
          </div>
          <!-- End GMAPS -->
          <!-- Primary Color Field -->
          <v-row class="mb-n8">
            <v-col
              cols="12"
              md="1"
              class="flex  mobile-col"
            ></v-col>
            <v-col
              cols="12"
              md="3"
              sm="4"
              class="flex "
            >
              <p class="order-text me-3 mt-2">
                {{ $t('EOrdering.primaryColor') }} <span class="mobile-col-show ms-3"> : </span>
              </p>
            </v-col>
            <v-col
              cols="10"
              md="6"
              sm="7"
              class="d-flex col-margin-top-mobile"
            >
              <span class="order-text me-3 mb-3 mobile-span"> : </span>
              <!-- <v-skeleton-loader
                    v-if="edit && form.address.detail === ''"
                    v-bind="attrs"
                    class="mx-auto"
                    max-width="2000"
                    type="list-item"
                  ></v-skeleton-loader> -->
              <v-menu
                v-model="menuPrimaryColor"
                offset-y
                max-width="300"
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="form.primaryColor"
                    outlined
                    hide-details
                    required
                    :label="$t('EOrdering.primaryColor')"
                    class="mb-3"
                    dense
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-card>
                  <v-color-picker
                    v-model="form.primaryColor"
                    class="ma-2 mb-2"
                    mode="hexa"
                    hide-inputs
                  >
                  </v-color-picker>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="#ffeeee"
                      text
                      @click="menuPrimaryColor = false"
                    >
                      <span class="error--text">{{ $t('cancel') }}</span>
                    </v-btn>
                    <v-btn
                      color="primary"
                      text
                      @click="menuPrimaryColor = false"
                    >
                      {{ $t('EOrdering.save') }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </v-col>
            <v-col
              cols="2"
              md="1"
              sm="1"
              class="flex col-margin-top-mobile"
            >
              <span
                class="dot"
                :style="`background-color: ${form.primaryColor}`"
              ></span>
            </v-col>
          </v-row>
          <!-- End Primary Color Field -->
          <!-- Secondary Color Field -->
          <v-row class="mb-n8">
            <v-col
              cols="12"
              md="1"
              class="flex  mobile-col"
            ></v-col>
            <v-col
              cols="12"
              md="3"
              sm="4"
              class="flex "
            >
              <p class="order-text me-3 mt-2">
                {{ $t('EOrdering.secondaryColor') }} <span class="mobile-col-show ms-3"> : </span>
              </p>
            </v-col>
            <v-col
              cols="10"
              md="6"
              sm="7"
              class="d-flex col-margin-top-mobile"
            >
              <span class="order-text me-3 mb-3 mobile-span"> : </span>
              <!-- <v-skeleton-loader
                    v-if="edit && form.address.detail === ''"
                    v-bind="attrs"
                    class="mx-auto"
                    max-width="2000"
                    type="list-item"
                  ></v-skeleton-loader> -->
              <v-menu
                v-model="menuSecondaryColor"
                offset-y
                max-width="300"
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="form.secondaryColor"
                    outlined
                    hide-details
                    required
                    :label="$t('EOrdering.secondaryColor')"
                    class="mb-3"
                    dense
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-card>
                  <v-color-picker
                    v-model="form.secondaryColor"
                    class="ma-2 mb-2"
                    mode="hexa"
                    hide-inputs
                  >
                  </v-color-picker>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="#ffeeee"
                      text
                      @click="menuSecondaryColor = false"
                    >
                      <span class="error--text">{{ $t('cancel') }}</span>
                    </v-btn>
                    <v-btn
                      color="primary"
                      text
                      @click="menuSecondaryColor = false"
                    >
                      {{ $t('EOrdering.save') }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </v-col>
            <v-col
              cols="2"
              md="1"
              sm="1"
              class="flex col-margin-top-mobile"
            >
              <span
                class="dot"
                :style="`background-color: ${form.secondaryColor}`"
              ></span>
            </v-col>
          </v-row>
          <!-- End Secondary Color Field -->
          <!-- Tertiary Color Field -->
          <v-row class="mb-n8">
            <v-col
              cols="12"
              md="1"
              class="flex  mobile-col"
            ></v-col>
            <v-col
              cols="12"
              md="3"
              sm="4"
              class="flex "
            >
              <p class="order-text me-3 mt-2">
                {{ $t('EOrdering.tertiaryColor') }} <span class="mobile-col-show ms-3"> : </span>
              </p>
            </v-col>
            <v-col
              cols="10"
              md="6"
              sm="7"
              class="d-flex col-margin-top-mobile"
            >
              <span class="order-text me-3 mb-3 mobile-span"> : </span>
              <!-- <v-skeleton-loader
                    v-if="edit && form.address.detail === ''"
                    v-bind="attrs"
                    class="mx-auto"
                    max-width="2000"
                    type="list-item"
                  ></v-skeleton-loader> -->
              <v-menu
                v-model="menuTertiaryColor"
                offset-y
                max-width="300"
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="form.tertiaryColor"
                    outlined
                    hide-details
                    required
                    :label="$t('EOrdering.tertiaryColor')"
                    class="mb-3"
                    dense
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-card>
                  <v-color-picker
                    v-model="form.tertiaryColor"
                    class="ma-2 mb-2"
                    mode="hexa"
                    hide-inputs
                  >
                  </v-color-picker>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="#ffeeee"
                      text
                      @click="menuTertiaryColor = false"
                    >
                      <span class="error--text">{{ $t('cancel') }}</span>
                    </v-btn>
                    <v-btn
                      color="primary"
                      text
                      @click="menuTertiaryColor = false"
                    >
                      {{ $t('EOrdering.save') }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </v-col>
            <v-col
              cols="2"
              md="1"
              sm="1"
              class="flex col-margin-top-mobile"
            >
              <span
                class="dot"
                :style="`background-color: ${form.tertiaryColor}`"
              ></span>
            </v-col>
          </v-row>
          <!-- End Tertiary Color Field -->

          <!-- Shop Logo Field -->
          <v-row class="mb-n8">
            <v-col
              cols="12"
              md="1"
              class="flex  mobile-col"
            ></v-col>
            <v-col
              cols="12"
              md="3"
              sm="4"
              class="flex "
            >
              <p class="order-text me-3 mt-2">
                {{ $t('EOrdering.shopLogo') }} * <span class="mobile-col-show ms-3"> : </span>
              </p>
            </v-col>
            <v-col
              cols="12"
              md="7"
              sm="8"
              class="col-margin-top-mobile"
            >
              <span class="order-text me-3 mb-3 mobile-span"> : </span>
              <!-- <uppyUploader
              class="margin-start4"
                ref="uppy-data"
                :key="'shop-' + dataIndex"
                :data-index="'shop-' + dataIndex"
                :status="'add'"
                :data-uppy="propsUppyUploader"
                :config="uppySettings"
                :dashboard-config="uppyDashboardSettings"
              /> -->
            </v-col>
            <v-col
              cols="12"
              md="1"
              class="flex  mobile-col"
            ></v-col>
          </v-row>
          <!-- End Shop Logo Field -->

          <!-- Shop Logo Field -->
          <v-row class="mb-n8">
            <v-col
              cols="12"
              lg="3"
              md="2"
              class="flex  mobile-col"
            ></v-col>
            <!-- <v-col
              cols="12"
              md="3"
              sm="4"
              class="flex "
            >
              <p class="order-text me-3 mt-2">
                {{ $t('EOrdering.shopLogo') }} <span class="mobile-col-show ms-3"> : </span>
              </p>
            </v-col> -->
            <v-col
              cols="12"
              lg="6"
              md="8"
              sm="12"
            >
              <!-- <span class="order-text me-3 mb-3 mobile-span"> : </span> -->
              <uppyUploader
                v-if="uppyShow"
                ref="uppy-data"
                :key="'shop-' + dataIndex"
                class="margin-start4"
                :data-index="'shop-' + dataIndex"
                :status="'add'"
                :data-uppy="propsUppyUploader"
                :config="uppySettings"
                :dashboard-config="uppyDashboardSettings"
                :rules="formValidation.logo"
              />
            </v-col>
            <v-col
              cols="12"
              lg="3"
              md="2"
              class="flex mobile-col"
            ></v-col>
          </v-row>
          <!-- End Shop Logo Field -->

          <!-- <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-card>
                <v-card-text>
                  <v-switch
                    v-model="customGsheet"
                    :label="$t('EOrdering.customGSheet')"
                    hide-details
                  ></v-switch>
                </v-card-text>

                <div v-if="customGsheet">
                  <v-card-title class="align-start">
                    <span class="text-lg font-weight-semibold me-3">{{ $t('EOrdering.customSheet') }}</span>
                    <v-icon>
                      {{ icons.mdiTableAccount }}
                    </v-icon>
                  </v-card-title>
                  <v-card-text>
                    <v-text-field
                      v-model="form.sheetId"
                      outlined
                      hide-details
                      :rules="formValidation.name"
                      required
                      label="Sheet Id"
                      class="mb-3"
                      :prepend-inner-icon="icons.mdiIdCard"
                      dense
                    ></v-text-field>
                  </v-card-text>
                </div>


                <v-card-title class="align-start">
                  <span class="text-lg font-weight-semibold me-3">{{ $t('EOrdering.phoneNumber') }}</span>
                  <v-icon>
                    {{ icons.mdiAccountMultiple }}
                  </v-icon>
                </v-card-title>
                <v-card-text>
                  <v-skeleton-loader
                    v-if="edit && form.phoneNumber === ''"
                    v-bind="attrs"
                    class="mx-auto"
                    max-width="2000"
                    type="list-item"
                  ></v-skeleton-loader>
                  <v-select
                    v-else
                    v-model="form.phoneNumber"
                    :items="instances"
                    item-text="label"
                    item-value="value"
                    :prepend-inner-icon="icons.mdiCardAccountDetails"
                    outlined
                    :label="$t('channels.channel')"
                    hide-details
                    :rules="formValidation.phoneNumber"
                    required
                    class="mb-3"
                    dense
                    attach
                  ></v-select>
                </v-card-text>

                <v-card>
                  <v-card-title class="align-start">
                    <span class="text-lg font-weight-semibold me-3">{{ $t('EOrdering.shopType') }}</span>
                    <v-icon>
                      {{ icons.mdiShoppingSearch }}
                    </v-icon>
                  </v-card-title>
                  <v-card-text>
                    <v-skeleton-loader
                      v-if="edit && Object.keys(form.shopType).length === 0"
                      v-bind="attrs"
                      class="mx-auto"
                      max-width="2000"
                      type="list-item"
                    ></v-skeleton-loader>
                    <v-select
                      v-else
                      v-model="form.shopType"
                      :items="shopTypes"
                      outlined
                      :label="$t('EOrdering.shopType')"
                      hide-details
                      :prepend-inner-icon="icons.mdiRenameBox"
                      return-object
                      :rules="formValidation.phoneNumber"
                      required
                      class="mb-3"
                      dense
                      attach
                    ></v-select>
                  </v-card-text>
                  <v-card-text>
                    <v-switch
                      v-model="form.pricing.enableCash"
                      :label="$t('EOrdering.enableCash')"
                      hide-details
                    ></v-switch>
                  </v-card-text>
                  <v-card-text>
                    <v-switch
                      v-model="form.pricing.enableXendit"
                      :label="$t('EOrdering.enableXendit')"
                      hide-details
                    ></v-switch>
                  </v-card-text>

                  <div v-if="form.pricing.enableXendit">
                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3">Xendit Api Key</span>
                      <v-icon>
                        {{ icons.mdiCreditCardOutline }}
                      </v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-skeleton-loader
                        v-if="edit && form.pricing.keyXendit === ''"
                        v-bind="attrs"
                        class="mx-auto"
                        max-width="2000"
                        type="list-item"
                      ></v-skeleton-loader>
                      <v-text-field
                        v-else
                        v-model="form.pricing.keyXendit"
                        :type="'password'"
                        :prepend-inner-icon="icons.mdiTableKey"
                        outlined
                        hide-details
                        type="password"
                        :rules="formValidation.pricing"
                        required
                        label="Xendit Key"
                        class="mb-3"
                        dense
                      ></v-text-field>
                    </v-card-text>
                  </div>

                  <v-card-text>
                    <v-switch
                      v-model="form.pricing.enablePaypal"
                      :label="$t('EOrdering.enablePaypal')"
                      hide-details
                    ></v-switch>
                  </v-card-text>

                  <div v-if="form.pricing.enablePaypal">
                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3">Paypal Client ID</span>
                      <v-icon>
                        {{ icons.mdiHexagonOutline }}
                      </v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-skeleton-loader
                        v-if="edit && form.pricing.keyPaypal === ''"
                        v-bind="attrs"
                        class="mx-auto"
                        max-width="2000"
                        type="list-item"
                      ></v-skeleton-loader>
                      <v-text-field
                        v-else
                        v-model="form.pricing.keyPaypal"
                        :type="'password'"
                        :prepend-inner-icon="icons.mdiTableKey"
                        outlined
                        hide-details
                        :rules="formValidation.pricing"
                        required
                        type="password"
                        label="Paypal Key"
                        class="mb-3"
                        dense
                      ></v-text-field>
                    </v-card-text>
                  </div>

                  <v-card-text>
                    <v-switch
                      v-model="form.enableTax"
                      :label="$t('EOrdering.includeTax')"
                      hide-details
                    ></v-switch>
                  </v-card-text>

                  <div v-if="form.enableTax">
                    <v-card-title class="align-start">
                      <span class="text-lg font-weight-semibold me-3">{{ $t('EOrdering.shopTax') }}</span>
                      <v-icon>
                        {{ icons.mdiBank }}
                      </v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-text-field
                        v-model="form.tax"
                        outlined
                        type="number"
                        min="0"
                        hide-details
                        :rules="formValidation.name"
                        required
                        :prepend-inner-icon="icons.mdiPercent"
                        :label="$t('EOrdering.tax')"
                        class="mb-3"
                        dense
                      ></v-text-field>
                    </v-card-text>
                  </div>

                  <v-card-text>
                    <v-switch
                      v-model="form.address.enableDeliveryPricing"
                      :label="$t('EOrdering.calculateDeliveryPricing')"
                      hide-details
                    ></v-switch>
                  </v-card-text>

                  <v-card-title
                    v-if="form.address.enableDeliveryPricing"
                    class="align-start"
                  >
                    <span class="text-lg font-weight-semibold me-3">{{ $t('EOrdering.availableCourier') }}</span>
                    <v-icon>
                      {{ icons.mdiTruckFast }}
                    </v-icon>
                  </v-card-title>
                  <v-card-text v-if="form.address.enableDeliveryPricing">
                    <v-row>
                      <v-col
                        cols="12"
                        md="12"
                      >
                        <v-skeleton-loader
                          v-if="
                            edit && form.address.availableCourier.length === 0 && !form.address.enableDeliveryPricing
                          "
                          v-bind="attrs"
                          class="mx-auto"
                          max-width="2000"
                          type="list-item"
                        ></v-skeleton-loader>
                        <v-select
                          v-else
                          v-model="form.address.availableCourier"
                          attach
                          :items="localCouriers"
                          :label="$t('EOrdering.availableCourier')"
                          multiple
                          hide-details
                          :rules="formValidation.address"
                          required
                          :prepend-inner-icon="icons.mdiAccountGroup"
                          class="mb-3"
                          dense
                          outlined
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-card>
            </v-col> -->

            <!-- <v-col
              cols="12"
              md="6"
            >
              <v-card>
                <v-card-title
                  v-if="form.address.enableDeliveryPricing"
                  class="align-start"
                >
                  <span class="text-lg font-weight-semibold me-3">{{ $t('EOrdering.province') }}</span>
                  <v-icon>
                    {{ icons.mdiMapMarker }}
                  </v-icon>
                </v-card-title>
                <v-card-text v-if="form.address.enableDeliveryPricing">
                  <v-row>
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <v-select
                        v-model="form.address.province"
                        :items="provinces.data"
                        :label="$t('EOrdering.province')"
                        item-text="province"
                        return-object
                        hide-details
                        :rules="[v => !!v || 'Item is required']"
                        required
                        class="mb-3"
                        dense
                        outlined
                        :prepend-inner-icon="icons.mdiMapMarkerCheck"
                        attach
                        item-value="value"
                        @change="onChangeProvince()"
                        @click="onChangeProvince()"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-title
                  v-if="form.address.enableDeliveryPricing"
                  class="align-start"
                >
                  <span class="text-lg font-weight-semibold me-3">{{ $t('EOrdering.city') }}</span>
                  <v-icon>
                    {{ icons.mdiMapMarker }}
                  </v-icon>
                </v-card-title>
                <v-card-text v-if="form.address.enableDeliveryPricing">
                  <v-row>
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <v-select
                        v-model="form.address.city"
                        :disabled="cityData.length === 0"
                        :items="cityData"
                        :label="$t('EOrdering.city')"
                        item-text="text"
                        return-object
                        :rules="[v => !!v || 'Item is required']"
                        required
                        dense
                        :prepend-inner-icon="icons.mdiMapMarkerCheck"
                        outlined
                        attach
                        item-value="value"
                        @change="getSubdistrict()"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-title
                  v-if="form.address.enableDeliveryPricing"
                  class="align-start"
                >
                  <span class="text-lg font-weight-semibold me-3">{{ $t('EOrdering.subdistrict') }}</span>
                  <v-icon>
                    {{ icons.mdiMapMarker }}
                  </v-icon>
                </v-card-title>
                <v-card-text v-if="form.address.enableDeliveryPricing">
                  <v-row>
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <v-select
                        v-model="form.address.subdistrict"
                        :disabled="subdistricts.length === 0"
                        :items="subdistricts"
                        :label="$t('EOrdering.subdistrict')"
                        item-text="subdistrict_name"
                        return-object
                        :rules="[v => !!v || 'Item is required']"
                        required
                        :prepend-inner-icon="icons.mdiMapMarkerCheck"
                        dense
                        outlined
                        attach
                        item-value="value"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-title class="align-start">
                  <span class="text-lg font-weight-semibold me-3">{{ $t('EOrdering.addressDetail') }}</span>
                  <v-icon>
                    {{ icons.mdiStoreMarker }}
                  </v-icon>
                </v-card-title>
                <v-card-text>
                  <v-skeleton-loader
                    v-if="edit && form.address.detail === ''"
                    v-bind="attrs"
                    class="mx-auto"
                    max-width="2000"
                    type="list-item"
                  ></v-skeleton-loader>
                  <v-text-field
                    v-else
                    v-model="form.address.detail"
                    :prepend-inner-icon="icons.mdiArchiveMarker"
                    outlined
                    hide-details
                    :rules="formValidation.address"
                    required
                    :label="$t('EOrdering.addressDetail')"
                    class="mb-3"
                    dense
                  ></v-text-field>
                </v-card-text>

              </v-card>
            </v-col> -->
          </v-row>
        </v-form>
      </v-card-text>
      <!-- <v-card-text>
        <v-alert
          v-model="validateSlug"
          color="warning"
          text
          transition="slide-y-transition"
        >
          {{ $t('LinkGenerator.alertSlug') }}
        </v-alert>
        <v-alert
          v-model="validateChannel"
          color="error"
          text
          transition="slide-y-transition"
        >
          {{ $t('LinkGenerator.alertChannel') }}
        </v-alert>
      </v-card-text> -->
    </v-card>
    <v-divider />
    <v-card-actions>
      <v-spacer></v-spacer>

      <v-btn
        color="#ffeeee"
        :disabled="loadingSave === true"
        @click="closeDialog"
      >
        <span class="error--text">{{ $t('cancel') }}</span>
      </v-btn>

      <v-btn
        color="success"
        :disabled="loadingSave === true"
        @click="saveGenerateLink()"
      >
        {{ $t('EOrdering.save') }}
        <v-progress-circular
          v-if="loadingSave === true"
          indeterminate
          class="ms-2"
          :size="16"
          color="primary"
        ></v-progress-circular>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import uppyUploader from '@/components/UppyUploader.vue'
import eOrderingMixin from '@/mixins/e-orderingMixin'
import {
  mdiAccountGroup,
  mdiAccountMultiple,
  mdiArchiveMarker,
  mdiArrowLeft,
  mdiBank,
  mdiCardAccountDetails,
  mdiChartBar,
  mdiCheckCircle,
  mdiClipboardList,
  mdiCloseCircle,
  mdiCodeTags,
  mdiContentCopy,
  mdiCounter,
  mdiCreditCardOutline,
  mdiDeleteOutline,
  mdiDomain,
  mdiExport,
  mdiEyeOutline,
  mdiGestureDoubleTap,
  mdiHexagonOutline,
  mdiIdCard,
  mdiImageFilterCenterFocusWeak,
  mdiInformation,
  mdiLink,
  mdiLinkBoxOutline,
  mdiMagnify,
  mdiMapMarker,
  mdiMapMarkerCheck,
  mdiMessageTextOutline,
  mdiPencil,
  mdiPercent,
  mdiPlusCircle,
  mdiPoundBoxOutline,
  mdiRadioTower,
  mdiRenameBox,
  mdiShopping,
  mdiShoppingSearch,
  mdiStoreMarker,
  mdiTable,
  mdiTableAccount,
  mdiTableKey,
  mdiTruckFast,
} from '@mdi/js'
import PouchDB from 'pouchdb-browser'
import { gmapsMap, gmapsMarker } from 'x5-gmaps'
import XLSX from 'xlsx'
import contactMixin from '../../mixins/contactMixin'
import pouchDbMixin from '../../mixins/pouchDbMixin'
import dialogAddListContact from '../component/DialogAddListContact.vue'

PouchDB.adapter('worker', require('worker-pouch'))

export default {
  name: 'CreateShop',

  setup() {
    return {
      icons: {
        mdiCodeTags,
        mdiImageFilterCenterFocusWeak,
        mdiRadioTower,
        mdiDomain,
        mdiLink,
        mdiPercent,
        mdiContentCopy,
        mdiPencil,
        mdiCheckCircle,
        mdiMagnify,
        mdiDeleteOutline,
        mdiPlusCircle,
        mdiExport,
        mdiChartBar,
        mdiEyeOutline,
        mdiCloseCircle,
        mdiLinkBoxOutline,
        mdiAccountMultiple,
        mdiMessageTextOutline,
        mdiCounter,
        mdiPoundBoxOutline,
        mdiGestureDoubleTap,
        mdiTable,
        mdiArrowLeft,
        mdiBank,
        mdiShopping,
        mdiRenameBox,
        mdiTableAccount,
        mdiIdCard,
        mdiCardAccountDetails,
        mdiShoppingSearch,
        mdiClipboardList,
        mdiTruckFast,
        mdiAccountGroup,
        mdiMapMarker,
        mdiMapMarkerCheck,
        mdiStoreMarker,
        mdiArchiveMarker,
        mdiCreditCardOutline,
        mdiHexagonOutline,
        mdiTableKey,
        mdiInformation,
      },
    }
  },
  components: { dialogAddListContact, uppyUploader, gmapsMap, gmapsMarker },

  mixins: [eOrderingMixin, pouchDbMixin, contactMixin],
  props: ['shopData'],
  data() {
    return {
      raySpeedorigin: [
        {
          name: 'Bandung',
          code: 'JKT',
        },
        {
          name: 'Bogor',
          code: 'JKT',
        },
        {
          name: 'Cikarang',
          code: 'JKT',
        },
        {
          name: 'Depok',
          code: 'JKT',
        },
        {
          name: 'Jakarta',
          code: 'JKT',
        },
        {
          name: 'Malang',
          code: 'SBY',
        },
        {
          name: 'Medan',
          code: 'MES',
        },
        {
          name: 'Semarang',
          code: 'SBY',
        },
        {
          name: 'Surabaya',
          code: 'SBY',
        },
        {
          name: 'Tangerang',
          code: 'JKT',
        },
        {
          name: 'Yogyakarta',
          code: 'SBY',
        },
      ],
      taxValidatorMsg: '',
      mapOptions: {
        center: { lat: 0.3, lng: 118 },
        zoom: 4,
        fullscreenControl: false,
        mapTypeControl: false,
        rotateControl: false,
        scaleControl: false,
        streetViewControl: false,
        zoomControl: false,
      },
      markers: [],
      subdistricts: [],
      contactListTable: [],
      listContactValue: [],
      labelData: [],
      contactListSetting: null,
      contactListSelected: {},

      // enableTax: false,
      attrs: {},
      courier: ['jne', 'tiki', 'pos'],
      loadingSave: false,
      optionsInstances: [],
      addNewLink: false,
      deleteData: {},
      domain: [
        {
          label: 'https://wa-link.deploy.cbs.co.id/',
        },
      ],

      dialogSnipet: false,
      snipet: '',

      confirmDeleteMessage: false,
      showSnackbarSuccess: false,
      customGsheet: false,

      // Form

      slug: '',
      channel: [],
      slugAvailability: null,
      message: '',
      google: {
        tag: '',
      },
      facebook_pixel: { id: '' },
      airtable: {
        id: '',
        key: '',
      },
      shopTypes: [
        {
          id: 1,
          text: 'FnB',
          value: {
            templateId: '1ow6fYM_k8nhOavYtYmufeBYbQDrjTVsBtivRjs6-JQU',
            orderTypes: [
              { id: 1, name: 'Dine In' },
              { id: 2, name: 'Take Away' },
              { id: 3, name: 'Delivery' },
              { id: 4, name: 'Reservation' },
            ],
          },
        },
        {
          id: 2,
          text: 'Retail',
          value: {
            templateId: '15XXeV6GrBIchPtkm8nGSbjW8ch4cvSua48qiNk1gt2c',
            orderTypes: [
              { id: 1, name: 'Take Away' },
              { id: 2, name: 'Delivery' },
            ],
          },
        },
        {
          id: 3,
          text: 'Hotel',
          value: {
            templateId: '1EtIe_Ql40ZRZZX6YqrtXttmvAXDGY1OpI97FTlhLKRM',
            orderTypes: [
              { id: 1, name: 'Room Order' },
              { id: 2, name: 'Reservation' },
            ],
          },
        },
        {
          id: 4,
          text: 'Other',
          value: {
            templateId: '1DWwNsHUSQ1mZIqF1vZQJIgI8w_LyzOfKUQCrPTAicwU',
            orderTypes: [
              { id: 1, name: 'Delivery' },
              { id: 2, name: 'Take Away' },
              { id: 3, name: 'Reservation' },
            ],
          },
        },
      ],

      // city data
      cityData: [],
      slect_domain: '',
      payloadLoading: true,
      rotation: 'random',
      enabled: true,

      slugEdit: '',
      slugPayload: {
        slug: '',
        airtableDb: '',
        shopDb: '',
        sub_id: '',
      },

      edit: false,
      currentEditData: {},

      timeout: null,

      // validation
      validateSlug: false,
      validateChannel: false,

      // ERRMASSAGE
      errorMessage: '',
      dialogErrorMessage: false,

      showSnackbarWarning: false,
      formValidationMessage: '',

      show5History: false,
      showChat: '',
      form: {
        name: '',
        address: {
          enableDeliveryPricing: false,
          enableDeliveryPricingInter: false,
          city: {},
          province: {},
          subdistrict: {},
          detail: '',
          availableCourier: [],
          pos: '',
          origin: {},
          availableCourierInter: [],
        },
        urlGenerate: [],
        shopType: {},
        tax: '',
        enableTax: false,
        excelId: '',
        selectedContactList: {},
        pricing: {
          enableCash: true,
          enableXendit: false,
          enablePaypal: false,
          keyXendit: '',
          keyPaypal: '',
        },
        driveInfo: {
          kind: '',
          id: '',
          name: '',
          mimeType: '',
          link: '',
        },
        shopLogo: null,
        primaryColor: '#C5DBA4FF',
        secondaryColor: '#ACD4CBFF',
        tertiaryColor: '#C99358FF',
        shopLocation: [],
      },
      setCurrentLocation: false,
      excelTempData: {},
      formValidation: {
        name: [v => !!v || 'Name is required'],
        address: [v => !!v || 'Address is required'],
        shopType: [v => Boolean(Object.keys(v || {})[0]) || 'Shop Type is required'],
        phoneNumber: [v => Boolean(Object.keys(v || {})[0]) || 'Phone Number is required'],
        tax: v => {
          if (Number(v) > 100) {
            return `Tax can not exceed 100`
          }
          if (Number(v) < 0) {
            return `Tax cannot be below 0`
          }
        },
        logo: [v => !!v || 'File is required', v => (v && v.size > 0) || 'File is required'],
      },
      valid: false,
      menuPrimaryColor: false,
      menuSecondaryColor: false,
      menuTertiaryColor: false,

      dataIndex: Math.round(Math.random() * 100000),

      // dipakai ketika edit
      propsUppyUploader: null,

      // propsUppyUploader: {
      //   attachments: [
      //     {

      //       type: 'image/png',

      //       uploadURL:
      //         'https://sgp1.digitaloceanspaces.com/static11/uploads%2Fimages%2F344018772d6f49affffcdb27e62b1475-64f0f549fe9220281487c429c82891e0eaebf1a2eedf705e682159e70fd314c7ed0f0f482f13c53f99ba6551c35a31cf266addc57b8cf9ca53bd774df1ca12b83588e65bd99426c3bda57626b6300d0a4e2e0940ada853e3fe8a271a9e63d27d192683c0.png',

      //     },
      //   ],
      // },
      uppySettings: {
        restrictions: {
          maxFileSize: 100 * 1024 * 1024,
          allowedFileTypes: ['.jpg', '.jpeg', '.png'],
          maxNumberOfFiles: 1,
        },
      },
      uppyDashboardSettings: {
        note: ' Image Only',

        // dipakai ketika edit
        // disabled: false,
      },
      uppyShow: false,

      //  validation
    }
  },
  computed: {
    input: {
      get() {
        return this.slug
      },
      set(val) {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.slug = val
          this.checkAvailability()
        }, 2000)
      },
    },
    taxValidator: {
      get() {
        return this.form.tax
      },
      set(val) {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.form.tax = ''

          // this.checkAvailabilityEdit()
          //console.log(+val, `INI VAL`)
          if (Number(val) > 100) {
            this.form.tax = '100'
            this.taxValidatorMsg = 'Tax cannot exceed 100'
          }
          if (Number(val) < 0) {
            this.form.tax = ''
            this.taxValidatorMsg = 'Tax cannot be below 0'
          }

          if (Number(val) >= 0 && Number(val) <= 100) {
            this.form.tax = val
          }
        }, 2000)
      },
    },
    allSettings() {
      return this.$store.getters['global/getSettings']
    },
    dataListContact2() {
      return this.listContactValue
    },
    headers() {
      return [
        { text: 'URL', value: 'url', sortable: true },
        { text: 'Slug', value: 'slug', sortable: true },
        { text: 'Click', value: 'click', sortable: true },

        // { text: 'Chat', value: 'chat', sortable: true },
        { text: 'Enabled', value: 'enabled', sortable: true },

        // { text: this.$t('message'), value: 'message', sortable: true },
        { text: '', value: 'action', sortable: false },
      ]
    },

    dataShopLink() {
      const initData = this.$store.getters['link/getMessageListLink']
      const retData = initData.map(el => {
        el.slug_redirect = `${el.domain}${el.slug}`

        return el
      })

      return retData
    },
    instances() {
      const listInstance = []
      this.$store.getters['auth/getListInstance'].value.forEach(instance => {
        listInstance.push({
          label: `${
            instance.label.startsWith('-') || instance.label.includes('Default') || instance.label.includes('62')
              ? instance.label_server
              : instance.label
          } - (${instance.phone_number})`,
          value: instance.phone_number,
        })
      })

      return listInstance
    },
    user() {
      return this.$store.getters['auth/getUser']
    },
    provinces() {
      return this.$store.getters['eOrdering/getProvince']
    },
    cities() {
      return this.$store.getters['eOrdering/getCity']
    },
    localCouriers() {
      return this.$store.getters['eOrdering/getLocalCouriers'].map(el => el.toUpperCase())
    },
    interCouriers() {
      return this.$store.getters['eOrdering/getInterCouriers'].map(el => el.toUpperCase())
    },
  },

  watch: {
    async shopData(data) {
      const shopData = data

      // //console.log('shopData', shopData)
      if (shopData !== null) {
        // //console.log(shopData.shopLogo, `INI DI WATCH`)
        if (shopData.shopLogo) {
          this.propsUppyUploader = {
            attachments: [shopData.shopLogo],
          }
          this.uppyDashboardSettings.disabled = false
        }

        if (this.shopData.shopLocation) {
          this.markers = this.shopData.shopLocation
          this.mapOptions = {
            ...this.mapOptions,
            center: {
              lat: this.shopData?.shopLocation[0]?.position?.lat,
              lng: this.shopData?.shopLocation[0]?.position?.lng,
            },
            zoom: 15,
          }
        }

        this.form = {
          name: shopData ? shopData.name : '',
          tax: shopData ? shopData.tax : 0,
          enableTax: shopData ? shopData.enableTax : false,
          address: shopData
            ? shopData.address
            : {
                enableDeliveryPricing: false,
                enableDeliveryPricingInter: false,
                city: {},
                province: {},
                subdistrict: {},
                detail: '',
                availableCourier: [],
                pos: '',
                origin: {},
                availableCourierInter: [],
              },
          urlGenerate: shopData ? shopData.urlGenerate : [],
          shopType: shopData ? shopData.shopType : {},
          phoneNumber: shopData ? shopData.phoneNumber : '',
          excelId: shopData ? shopData.excelId : '',
          selectedContactList: shopData ? shopData.selectedContactList : {},
          pricing: shopData
            ? shopData.pricing
            : {
                enableCash: true,
                enableXendit: false,
                enablePaypal: false,
                keyXendit: '',
                keyPaypal: '',
              },
          driveInfo: shopData
            ? shopData.driveInfo
            : {
                kind: '',
                id: '',
                name: '',
                mimeType: '',
                link: '',
              },
          shopLogo: shopData ? shopData.shopLogo : null,
          primaryColor: shopData ? shopData.primaryColor : '#C5DBA4FF',
          secondaryColor: shopData ? shopData.secondaryColor : '#ACD4CBFF',
          tertiaryColor: shopData ? shopData.tertiaryColor : '#C99358FF',
          shopLocation: shopData ? shopData.shopLocation : [],
        }

        // this.edit = true
      } else {
        this.form = {
          name: '',
          tax: 0,
          address: {
            enableDeliveryPricing: false,
            enableDeliveryPricingInter: false,
            city: {},
            province: {},
            detail: '',
            availableCourier: [],
            subdistrict: {},
            pos: '',
            origin: {},
            availableCourierInter: [],
          },
          urlGenerate: [],
          shopType: {},
          phoneNumber: '',
          enableTax: false,
          selectedContactList: {},
          pricing: {
            enableCash: true,
            enableXendit: false,
            enablePaypal: false,
            keyXendit: '',
            keyPaypal: '',
          },
          driveInfo: {
            kind: '',
            id: '',
            name: '',
            mimeType: '',
            link: '',
          },
          shopLogo: null,
          primaryColor: '#C5DBA4FF',
          secondaryColor: '#ACD4CBFF',
          tertiaryColor: '#C99358FF',
          shopLocation: [],
        }

        // this.edit = false

        // await this.connectSettings(true)
        // await this.getEOrdering()
      }
    },
  },
  async mounted() {
    // //console.log(this.shopData, `INI SHOPDATA`)

    // await this.loadSetting(true)
    // await this.loadTable()
    if (this.shopData) {
      if (this.shopData.shopLogo) {
        this.propsUppyUploader = {
          attachments: [this.shopData.shopLogo],
        }
        this.uppyDashboardSettings.disabled = false
        //console.log(this.propsUppyUploader, this.uppyDashboardSettings, `INI UPPY`)
      }
      if (this.shopData.shopLocation) {
        this.markers = this.shopData.shopLocation
        this.mapOptions = {
          ...this.mapOptions,
          center: {
            lat: this.shopData?.shopLocation[0]?.position?.lat,
            lng: this.shopData?.shopLocation[0]?.position?.lng,
          },
          zoom: 15,
        }
      }

      // //console.log(this.shopData.shopLogo, this.propsUppyUploader, this.uppyDashboardSettings, `INI DI MOUNTED`)

      this.form = {
        name: this.shopData ? this.shopData.name : '',
        tax: this.shopData ? this.shopData.tax : 0,
        enableTax: this.shopData ? this.shopData.enableTax : false,
        address: this.shopData.address
          ? this.shopData.address
          : {
              enableDeliveryPricing: false,
              enableDeliveryPricingInter: false,
              city: {},
              province: {},
              subdistrict: {},
              detail: '',
              availableCourier: [],
              pos: '',
              origin: {},
              availableCourierInter: [],
            },
        urlGenerate: this.shopData.urlGenerate ? this.shopData.urlGenerate : [],
        excelId: this.shopData.excelId ? this.shopData.excelId : '',
        shopType: this.shopData.shopType ? this.shopData.shopType : {},
        phoneNumber: this.shopData.phoneNumber ? this.shopData.phoneNumber : '',
        selectedContactList: this.shopData.selectedContactList ? this.shopData.selectedContactList : {},
        pricing: this.shopData
          ? this.shopData.pricing
          : {
              enableCash: true,
              enableXendit: false,
              enablePaypal: false,
              keyXendit: '',
              keyPaypal: '',
            },
        driveInfo: this.shopData
          ? this.shopData.driveInfo
          : {
              kind: '',
              id: '',
              name: '',
              mimeType: '',
              link: '',
            },
        shopLogo: this.shopData.shopLogo ? this.shopData.shopLogo : null,
        primaryColor: this.shopData.primaryColor ? this.shopData.primaryColor : '#C5DBA4FF',
        secondaryColor: this.shopData.secondaryColor ? this.shopData.secondaryColor : '#ACD4CBFF',
        tertiaryColor: this.shopData.tertiaryColor ? this.shopData.tertiaryColor : '#C99358FF',
        shopLocation: this.shopData.shopLocation.length ? this.shopData.shopLocation : [],
      }

      //   // this.edit = true
    }
    setTimeout((this.uppyShow = true), 3000)
    await this.$store.dispatch('eOrdering/getProvinceRajaOngkir')
    await this.$store.dispatch('eOrdering/getCityRajaOngkir')
    await this.$store.dispatch('eOrdering/getAvailableLocalCourier')
    await this.$store.dispatch('eOrdering/getAvailableInterCourier')

    // this.$eventBus.$on(process.env.VUE_APP_EVENT_UPDATE_SETTINGS, async payload => {
    //   if (payload.id === 'contact_list') {
    //     await this.$store.dispatch('global/setSettingsById', payload)
    //     this.replicateFromPouchDbGlobal(process.env.VUE_APP_DB_SETTING_NAME, this.user, async data => {
    //       await this.loadSetting(true)
    //       await this.loadTable()
    //     })

    //     // await this.loadSetting()
    //   }
    // })
  },

  methods: {
    getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.setLocation, this.locationError)
      } else {
        this.showSnackbarWarning = true
        this.formValidationMessage = 'Geolocation is not supported by this browser.'
      }
    },
    setLocation(pos) {
      this.mapOptions = {
        ...this.mapOptions,
        center: { lat: pos.coords.latitude, lng: pos.coords.longitude },
        zoom: 15,
      }

      // console.log({ lat: pos.coords.latitude, lng: pos.coords.longitude })
      if (this.setCurrentLocation) {
        this.markers = [
          {
            position: {
              lat: pos.coords.latitude,
              lng: pos.coords.longitude,
            },
            animation: null,
          },
        ]
      } else {
        this.markers = []
      }
    },
    locationError(error) {
      if (error.PERMISSION_DENIED) {
        this.showSnackbarWarning = true
        this.formValidationMessage = 'User denied the request for Geolocation.'
      } else if (error.POSITION_UNAVAILABLE) {
        this.showSnackbarWarning = true
        this.formValidationMessage = 'Location information is unavailable.'
      } else if (error.TIMEOUT) {
        this.showSnackbarWarning = true
        this.formValidationMessage = 'The request to get user location timed out.'
      } else {
        this.showSnackbarWarning = true
        this.formValidationMessage = 'An unknown error occurred.'
      }
    },

    addMarker($event) {
      // Stop last marker from bouncing
      // if (this.markers.length) this.markers[this.markers.length - 1].animation = null
      // Add new marker with a bounce

      const position = $event.latLng.toJSON()

      // //console.log(position)
      this.setCurrentLocation = false
      this.markers = [{ position, animation: null }]
    },
    removeMarker(index) {
      this.markers.splice(index, 1)
    },
    async getSubdistrict() {
      // //console.log(this.form.address.city, `INI DATA CITY`)
      this.subdistricts = await this.$store.dispatch('eOrdering/getSubdistrictRajaOngkir', {
        cityId: this.form.address.city.city_id,
      })

      // //console.log(this.subdistricts, `INI SUB DISTRICT`)
    },
    openNewList() {
      this.$refs.dialogAddListContact.open('').then(async confirm => {
        if (confirm.status) {
          this.loading = true
          this.listContactValue.push({
            id: this.$nanoid(),
            list_name: confirm.name,
            deleteable: true,
            created_at: new Date().getTime(),
            last_modified: new Date().getTime(),
          })

          const dataContactlist = await this.loadSettingsById('contact_list', true)
          if (dataContactlist) dataContactlist.value = this.listContactValue

          this.updateSettings(dataContactlist, async x => {})
            .then(async r => {
              const { id } = await this.listContactValue[this.listContactValue.length - 1]
              await this.addContactListColumn(id)
              await this.loadSetting()
              await this.loadTable()

              this.loading = false

              // this.importContact({
              //   status: true,
              //   data: id,
              // }).then(async () => {
              //   this.replicateFromPouchDbGlobal(process.env.VUE_APP_DB_CONTACTS_NAME, this.user, async info => {
              //     await this.loadSetting()
              //     await this.loadTable()
              //     this.loading = false
              //   })
              //   // await this.loadSetting()
              // })
            })
            .catch(err => {
              this.loading = false
            })
        }
      })
    },
    async loadTable() {
      const obj = (await this.loadContactsAll()).contacts.filter(el => el._id !== el.phone_number)
      const listContactObj = this.listContactValue.map(el => {
        // //console.log(el.id)
        el.active_contact = obj.filter(elm => elm._id.substring(0, 8) == el.id).length

        return el
      })
      this.listContactValue = [...listContactObj]
      this.totalContact = obj.totalContact
    },
    async loadSetting(isExist = false) {
      if (!isExist) {
        await this.loadSettings()
      }
      const res = await this.loadSettingContactsList(this.allSettings)
      this.labelData = res.labelData
      this.contactListSetting = res.contactListSetting
      this.listContactValue = res.listContactValue
      this.deleteUnusedContact(this.contactListTable, this.listContactValue)
    },
    async getFile(e) {
      const res = {}
      const reader = new FileReader()
      reader.readAsBinaryString(e.target.files[0])
      // eslint-disable-next-line no-shadow
      reader.onload = async e => {
        const binaryData = e.target.result
        const workbook = XLSX.read(binaryData, { type: 'binary' })
        workbook.SheetNames.forEach((el, i) => {
          const sheetNames = workbook.SheetNames
          const data = XLSX.utils.sheet_to_json(workbook.Sheets[el])
          res[sheetNames[i].toLowerCase()] = data
        })
        if (Object.keys(res).length !== 0) {
          this.showSnackbarSuccess = true
          this.excelTempData = res
          this.shopTypes.forEach(type => {
            if (type.text === res['shop detail'][0]['Shop Type']) {
              this.form.name = res['shop detail'][0].Name
              this.form.shopType = type
              this.form.address.detail = res['shop detail'][0].Address
            }
          })

          // //console.log(true, this.form)
          // //console.log(res, `INI DATA`)
        }
      }
    },
    saveShop() {
      if (this.validateAirtable.status) {
        this.form = {
          name: '',
          address: {
            enableDeliveryPricing: false,
            enableDeliveryPricingInter: false,
            city: {},
            province: {},
            subdistrict: {},
            detail: '',
            availableCourier: [],
            pos: '',
            origin: {},
            availableCourierInter: [],
          },
          urlGenerate: [],
          shopType: { templateId: '', orderTypes: [] },
          phoneNumber: '',
          enableTax: false,
          tax: 0,
          selectedContactList: {},
          pricing: {
            enableCash: true,
            enableXendit: false,
            enablePaypal: false,
            keyXendit: '',
            keyPaypal: '',
          },
          shopLogo: null,
          primaryColor: '#C5DBA4FF',
          secondaryColor: '#ACD4CBFF',
          tertiaryColor: '#C99358FF',
          shopLocation: [],
        }
        this.$emit('closeModal', false)
        this.closeDialog(true)
        this.loadingSave = false
      }
    },
    // eslint-disable-next-line camelcase
    onChangeProvince() {
      this.form.address.city = {}
      const data = this.cities.data.filter(el => {
        return el.province_id === this.form.address.province.province_id
      })
      data.forEach(el => {
        el.text = `${el.type} ${el.city_name}`
      })
      this.cityData = data
    },
    async fetchProduct(shop_id) {
      const payload = {
        apiKey: this.form.airtableKey,
        baseAirTable: this.form.airtableId,
        shop_id,
      }
      const insertAirtableData = await this.$store.dispatch('eOrdering/fetchAirtableProducts', payload)
      if (insertAirtableData.status) {
        this.validateAirtable = {
          status: true,
          message: 'Airtable is valid !',
        }
        this.loadingSave = false

        this.$emit('loading', false)

        return true
      }

      this.validateAirtable = {
        status: false,
        message: 'Airtable is not valid !',
      }

      return false
    },
    viewMessage(item) {
      this.show5History = true
      this.showChat = item
    },
    closeSnackbarWarning() {
      this.showSnackbarWarning = false
    },
    closeDialogErrorMessage() {
      // this.dialogErrorMessage = false
      // this.errorMessage = ''
    },
    closeConfirmDeleteMessage() {
      this.confirmDeleteMessage = false
      this.deleteData = {}
    },
    openConfirmDeleteMessage(itemDeleted) {
      this.confirmDeleteMessage = true
      this.deleteData = itemDeleted
    },
    openAddNewLink() {
      // this.addNewLink = true
      this.$router.push('/e-ordering/create')
    },
    openDialogSnipet(iframe) {
      this.snipet = iframe
      this.dialogSnipet = true
    },
    async copyURL(Url) {
      try {
        await navigator.clipboard.writeText(Url)
        this.showSnackbarCopiedSuccessfully = true
      } catch ($e) {
        this.showSnackbarCopyNotSupported = true
      }

      await this.$store.dispatch('eOrdering/createOrdering', {
        form: this.form,
        token: this.user,
      })
      this.confirmDeleteMessage = false
      this.deleteData = {}

      // } else {
      // this.openDialogErr(res.message)

      // }
    },
    validateShopData(data) {
      let flag = true
      //console.log(data.shopLogo, `INI DATA Length Logo`)
      if (!data.name) {
        flag = false
      } else if (data.address.enableDeliveryPricing && Object.keys(data.address.city).length === 0) {
        flag = false
      } else if (data.address.enableDeliveryPricing && Object.keys(data.address.province).length === 0) {
        flag = false
      } else if (data.address.enableDeliveryPricing && Object.keys(data.address.subdistrict).length === 0) {
        flag = false
      } else if (data.address.enableDeliveryPricing && data.address.availableCourier.length === 0) {
        flag = false
      } else if (!data.address.detail) {
        flag = false
      } else if (data.address.enableDeliveryPricingInter && data.address.availableCourierInter.length === 0) {
        flag = false
      } else if (data.address.enableDeliveryPricingInter && Object.keys(data.address.origin).length === 0) {
        flag = false
      } else if (data.address.enableDeliveryPricingInter && !data.address.pos) {
        flag = false
      } else if (Object.keys(data.shopType).length === 0) {
        flag = false
      } else if (!data.pricing.enableCash && !data.pricing.enableXendit && !data.pricing.enablePaypal) {
        flag = false
      } else if (data.pricing.enableXendit && !data.pricing.keyXendit) {
        flag = false
      } else if (data.pricing.enableXendit && !data.pricing.keyPaypal) {
        flag = false
      } else if (!data.shopLogo) {
        flag = false
      } else if (data.shopLocation.length === 0) {
        flag = false
      } else if (data.tax > 100) {
        flag = false
      } else if (!data.phoneNumber) {
        flag = false
      } else if (!data.shopLogo.uploadURL) {
        flag = false
      }

      return flag
    },
    async saveGenerateLink() {
      // //console.log(this.form, `INI FORM NYA`)

      // //console.log(this.form.selectedContactList, `INI KONTAK`)
      if (this.$refs['uppy-data']?.uppy?.getFiles()[0]) {
        this.form.shopLogo = {
          uploadURL: this.$refs['uppy-data']?.uppy?.getFiles()[0].uploadURL,
          type: this.$refs['uppy-data']?.uppy?.getFiles()[0].type,
        }
      }
      this.form.shopLocation = this.markers

      // const { address, name, phoneNumber, shopType, shopLogo, shopLocation } = this.form

      // //console.log(this.form, `INI FORM NYA`)

      if (!this.validateShopData(this.form)) {
        this.showSnackbarWarning = true
        this.formValidationMessage = this.$t('EOrdering.formValidationMessage')

        // //console.log(true, address, shopLocation, name, shopLogo, phoneNumber, shopType, `BLM LNGKP`)
      } else if (this.shopData !== null) {
        this.$emit('loading', true)
        this.$refs['form-add-template'].validate()

        // this.form.shopLogo = {
        //   uploadURL: this.$refs['uppy-data']?.uppy?.getFiles()[0].uploadURL,
        //   type: this.$refs['uppy-data']?.uppy?.getFiles()[0].type,
        // }
        // this.form.shopLocation = this.markers

        this.loadingSave = true

        const editOrder = await this.$store.dispatch('eOrdering/editOrdering', {
          form: this.form,
          id: this.shopData.id,
          sub_id: this.user.sub_id,
        })
        if (editOrder.status) {
          await this.getShop({ sub_id: this.user.sub_id })
          this.$emit('closeModal', false)
          this.$emit('loading', false)
          this.loadingSave = false
          this.closeDialog(true)
          this.markers = []
          this.mapOptions = {
            center: { lat: 0.3, lng: 118 },
            zoom: 4,
            fullscreenControl: false,
            mapTypeControl: false,
            rotateControl: false,
            scaleControl: false,
            streetViewControl: false,
            zoomControl: false,
          }
        } else {
          this.loadingSave = false
        }
      } else {
        this.$emit('loading', true)
        this.$refs['form-add-template'].validate()

        // //console.log(this.form, `INI FORM`)

        let payload = {
          shopName: '',
          sub_id: '',
          sheetId: '',
        }

        if (this.form.sheetId) {
          // //console.log(true, this.form, `INI ADA FORM`)
          payload = {
            shopName: this.form.name,
            sub_id: this.user.sub_id,
            sheetId: this.form.sheetId,
          }
        } else {
          payload = {
            shopName: this.form.name,
            sub_id: this.user.sub_id,
            sheetId: this.form.shopType.value.templateId,
          }
        }

        // //console.log(payload, `INI APYLOAD`)

        this.loadingSave = true

        const result = await this.$store.dispatch('eOrdering/fetchSheetProducts', payload)

        // //console.log(result, `INI RES`)

        if (result.status) {
          this.form.excelId = result.sheetData.id
          this.form.driveInfo = result.driveInfo

          // this.form.shopLogo = {
          //   uploadURL: this.$refs['uppy-data']?.uppy?.getFiles()[0].uploadURL,
          //   type: this.$refs['uppy-data']?.uppy?.getFiles()[0].type,
          // }
          // this.form.shopLocation = this.markers
          const addOrder = await this.$store.dispatch('eOrdering/createOrdering', {
            form: this.form,
            sub_id: this.user.sub_id,
          })
          if (addOrder.status) {
            if (this.form.name.match(/[ ]/g)) {
              this.form.name = this.form.name.replace(/[ ]/g, '_')
            }
            // eslint-disable-next-line no-useless-escape
            if (this.form.name.match(/[-!@#$%^&*()+|~=`{}\[\]:";'<>?,.\/]/g)) {
              // eslint-disable-next-line no-useless-escape
              this.form.name = this.form.name.replace(/[-!@#$%^&*()+|~=`{}\[\]:";'<>?,.\/]/g, '')
            }
            this.slugPayload = {
              slug: `${this.form.name.toLowerCase()}-${this.$nanoid()}`,
              airtableDb: result.sheetData.id,
              shopDb: addOrder.data.data._id,
              sub_id: this.user.sub_id,
            }

            // //console.log(this.slugPayload)

            await this.$store.dispatch('eOrdering/createSlugShop', this.slugPayload)
            await this.getShop({ sub_id: this.user.sub_id })

            // await this.fetchProduct(addOrder.data.data.id)
            this.markers = []
            this.mapOptions = {
              center: { lat: 0.3, lng: 118 },
              zoom: 4,
              fullscreenControl: false,
              mapTypeControl: false,
              rotateControl: false,
              scaleControl: false,
              streetViewControl: false,
              zoomControl: false,
            }
            this.loadingSave = false
            this.uppyShow = false

            this.closeDialog(true)
          } else {
            this.uppyShow = false
            this.loadingSave = false
          }
        }
      }
    },
    async closeDialog(data) {
      // this.form = {
      //   name: '',
      //   address: { enableDeliveryPricing: false, city: {}, province: {}, detail: '', availableCourier: [] },
      //   urlGenerate: [],
      //   shopType: [],
      //   phoneNumber: '',
      //   selectedContactList: {},
      // }
      this.validateSlug = false
      this.validateChannel = false
      this.markers = []
      this.mapOptions = {
        center: { lat: 0.3, lng: 118 },
        zoom: 4,
        fullscreenControl: false,
        mapTypeControl: false,
        rotateControl: false,
        scaleControl: false,
        streetViewControl: false,
        zoomControl: false,
      }

      // this.validateAirtable = {
      //   status: '',
      //   message: '',
      // }
      this.$emit('closeModal', data)
    },
  },
}
</script>
<style scoped>
::v-deep .v-label {
  font-size: 1em;
}
::v-deep .v-label--active {
  font-size: 1.25em;
  font-weight: bold;
}
.v-input .v-label {
  font-size: 1em !important;
}
.v-input .v-label--active {
  font-size: 1.25em !important;
  font-weight: bold !important;
}
.mobile-col {
  display: block;
}
.width-dialog {
  max-width: 680px;
}
.height-dialog {
  height: 500px;
  overflow-y: auto;
}
.order-text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
}
.mobile-col-show {
  display: none;
}
.dot {
  height: 40px;
  width: 40px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

@media (max-width: 1100px) {
  .order-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 19px;
    display: flex;
    align-items: center;
  }
}

.mobile-span {
  display: inherit;
}
.margin-start4 {
  margin-left: 16px;
}
.wrapper {
  justify-content: center;
  padding: 20px 0;
  width: auto;
  text-align: center;
}
.description {
  text-align: center;
}
.map {
  width: 80%;
  height: 300px;
  display: inline-block;
  max-height: 100%;
  overflow: auto;
  border: 2px ridge silver;
  background-color: rgb(229, 227, 223);
}

@media screen and (max-width: 500px) {
  .description {
    display: none;
  }
}
@media (max-width: 600px) {
  .margin-start4 {
    margin-left: 0px;
  }
  .order-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;
  }
  .mobile-col-show {
    display: block;
  }
  .mobile-span {
    display: none;
  }
  .col-margin-top-mobile {
    margin-top: -30px;
  }
}
@media (max-width: 960px) {
  .mobile-col {
    display: none;
  }
  .map {
    width: 100%;
    height: 300px;
    display: inline-block;
    max-height: 100%;
    overflow: auto;
    border: 2px ridge silver;
    background-color: rgb(229, 227, 223);
  }
}
</style>
