import { initializeApp } from 'firebase/app'
import { getDatabase } from 'firebase/database'
import { CACHE_SIZE_UNLIMITED, enableIndexedDbPersistence, initializeFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'

const config = {
  apiKey: 'AIzaSyDy1IcKejGMtGQmT_xqohBVglhWmVsR6QI',
  authDomain: 'cartenz-chat.firebaseapp.com',
  projectId: 'cartenz-chat',
  storageBucket: 'cartenz-chat.appspot.com',
  messagingSenderId: '22384798765',
  appId: '1:22384798765:web:c713ae9330bc0fd14d9ca4',
  measurementId: 'G-L1W00P3C81',
  databaseURL: 'https://cartenz-chat.firebaseio.com',
}

// const config = {
//   apiKey: 'AIzaSyCw0FFn2ezDXGhMsXQtn082_amKqGdwxgo',
//   authDomain: 'i-say-api.firebaseapp.com',
//   databaseURL: 'https://i-say-api.firebaseio.com',
//   projectId: 'i-say-api',
//   storageBucket: 'i-say-api.appspot.com',
//   messagingSenderId: '434922091483',
//   appId: '1:434922091483:web:0483c7e77305df72176dfe',
//   measurementId: 'G-7ETLQ0DMCK',
// }

// let serviceAccount = require('../../cartenz-chat-firebase-adminsdk-ldbqf-7103050ae5.json')

// initializeApp({
// 	credential: admin.credential.cert(serviceAccount)
// })

const app = initializeApp(config)

export const firestoreDb = initializeFirestore(app, {
  cacheSizeBytes: CACHE_SIZE_UNLIMITED,
})

enableIndexedDbPersistence(firestoreDb).catch(err => {
  if (err.code == 'failed-precondition') {
    // Multiple tabs open, persistence can only be enabled
    // in one tab at a a time.
    // ...
    //console.log('failed-precondition')
  } else if (err.code == 'unimplemented') {
    // The current browser does not support all of the
    // features required to enable persistence
    // ...
    //console.log('unimplemented')
  }
})
export const realtimeDb = getDatabase()
export const storage = getStorage()
