/* eslint-disable */

export function getBroadcastListData(state) {
  return state.broadcastListData
}

export function getBroadcastScheduledData(state) {
  return state.broadcastScheduledData
}

export function getBroadcastStatistic(state) {
  return state.broadcastStatistic
}

export function getBroadcastContacts(state) {
  return state.broadcastContacts
}

export function getBroadcastContactsList(state) {
  return state.broadcastContactsList
}

export function getBroadcastExtraField(state) {
  return state.broadcastExtraField
}

export function getDefaultCustomMessage(state) {
  return state.defaultCustomMessage
}

export function getForwardMessages(state) {
  return state.forwardMessages
}
