export function getLoanSubs(state) {
  return state.loanSubs
}
export function getLoanSub(state) {
  return state.loanSub
}
export function getLoanTypes(state) {
  return state.loanTypes
}
export function getLoanType(state) {
  return state.loanType
}
export function getLoanSubsNeedMyApproval(state) {
  return state.loanSubsNeedMyApproval
}
export function getDisbursementsNeedApproval(state) {
  return state.disbursementsNeedApproval
}
